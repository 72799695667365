import React, { useCallback, useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { Button, Icon } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import styles from './Profile.module.scss';
import { Dialog } from '@material-ui/core';
import Avatar from 'react-avatar-edit';
import { userActions } from '../../../redux/actions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import adminActions from '../../../redux/actions/admin';
import { clientAccountStatuses } from '../../../constants';
import { loadClientSubmissions } from '../../../redux/actions/poll';
import SubmissionsTable from './SubmissionsTable';
import SubmissionPreviewModal from '../../../components/SubmissionPreviewModal/SubmissionModal';

import _ from 'lodash';
import { object } from 'joi';

const ClientProfile = ({ user }) => {
  const { client, image: userImage, name: userName } = user;
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [avatarColor, setAvatarColor] = useState('white');
  const userType = useSelector((state) => state.auth.userType);
  const userId = useSelector((state) => state?.user?.id);
  const history = useHistory();
  const [submissionsData, setSubmissionsData] = useState(null);
  const [activeSubmissionData, setActiveSubmissionData] = useState(null);
  const [openSuspendModal, setOpenSuspendModal] = useState(false);
  const [openSubmissionsModal, setOpenSubmissionsModal] = useState(false);
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);

  console.log(user);

  const onCrop = (preview) => {
    setImage(preview);
  };

  const upload = async () => {
    setOpen(false);
    if (image) {
      await userActions.setUserImage(image);
      setImage(null);
      await userActions.loadUser();
    }
  };

  const onOpen = () => {
    setImage(null);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setImage(null);
  };

  const shouldShowEditButtons = useMemo(() => {
    if (userType === 'client' && userId === user.id) {
      return (
        <>
          <div className={styles.buttonsContainer}>
            <button
              className={styles.profileButton}
              onClick={() => {
                history.push('/client/profile/edit');
              }}
            >
              <p className={styles.socialButtonText}>Edit profile</p>
            </button>
            <button
              className={`${styles.preferencesButton} ${styles.profileButton}`}
              onClick={() => {
                history.push('/client/profile/edit-preferences');
              }}
            >
              <p className={styles.socialButtonText}>coach preferences</p>
            </button>
          </div>
          <div className={styles.buttonsContainer}>
            <button
              className={`${styles.profileButton}`}
              onClick={() => {
                history.push('/client/profile/edit-preferences');
              }}
            >
              <p className={styles.socialButtonText}>group chat tier</p>
            </button>
          </div>
        </>
      );
    }

    return;
  }, [userType, userId]);

  useEffect(async () => {
    const data = await loadClientSubmissions(user.client.userId);
    setSubmissionsData(data);
  }, []);

  const getValue = useCallback((value) => {
    if (_.isObject(value)) {
      if (_.isArray(value)) {
        const concatinatedValue = value.join(', ');
        return concatinatedValue;
      } else {
        return value.name;
      }
    }

    if (value === 'true' || value === 'false' || _.isBoolean(value)) {
      if (_.isBoolean(value)) {
        return value ? 'Yes' : 'No';
      }
      return value === 'true' ? 'Yes' : 'No';
    }

    return value;
  }, []);

  const [userData, setUserData] = useState(null);
  const [coachPreference, setCoachPreference] = useState(null);
  useEffect(() => {
    const data = [];
    const notInclude = ['id', 'clientId', 'api', 'nutritionists', 'coachPreference'];
    Object.entries(user.client).map(([key, value]) => {
      // const obj = {};
      // obj[key] = getValue(value);
      if (!notInclude.includes(key)) {
        data.push([key, value]);
      }
    });
    setUserData(data);
    if (user.client.coachPreference) {
      const coachPData = [];
      Object.entries(user.client.coachPreference).map(([key, value]) => {
        if (!notInclude.includes(key)) {
          coachPData.push([key, getValue(value)]);
        }
      });
      setCoachPreference(coachPData);
    }
  }, [user]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: '',
          marginTop: '4rem',
          position: 'relative'
        }}
      >
        <div
          className={styles.profile}
          style={{
            display: 'flex',
            alignItems: '',
            marginTop: '4rem',
            position: 'relative'
          }}
        >
          <div
            style={{
              borderRadius: '50%',
              position: 'relative',
              overflow: 'hidden',
              width: '250px',
              height: '250px',
              display: 'flex'
            }}
          >
            <div className={styles.userImage}>
              <img
                style={{
                  width: 'auto',
                  height: '100%',
                  margin: '0 auto',
                  overflow: 'hidden',
                  borderRadius: '50%'
                }}
                src={userImage}
                alt=""
              />
              {userType === 'client' && userId === user.id ? (
                <div className={styles.imageHover} onClick={onOpen}>
                  <AddAPhotoIcon fontSize={'large'} htmlColor={'black'} />
                </div>
              ) : null}
            </div>
          </div>
          <div style={{ marginLeft: '2rem' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <p
                style={{
                  fontSize: '2.5rem',
                  margin: '3rem 0 0 0',
                  fontFamily: 'Bebas Neue',
                  color: '#EE79B3',
                  letterSpacing: '2.5px'
                }}
              >
                {userName}
              </p>
              <p
                style={{
                  margin: '3.3rem 0 0 1.5rem',
                  lineHeight: '3rem',
                  fontSize: '1.7rem',
                  fontFamily: 'Bebas Neue',
                  color: '#c4eb37',
                  letterSpacing: '2.5px'
                }}
              >
                {client.gender}
              </p>
            </div>

            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: '1.1rem',
                  letterSpacing: '2px'
                }}
              >
                {`Birthdate: ${moment(client.birthdate).format('MM.DD.YYYY')}`}
              </p>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <p style={{ fontSize: '1.1rem', letterSpacing: '1px' }}>
                  Height: <span style={{ color: '#EE79B3' }}>{client.height}</span>
                </p>
                <p style={{ fontSize: '1.1rem', marginLeft: '1rem', letterSpacing: '1px' }}>
                  Weight: <span style={{ color: '#EE79B3' }}>{client.weight + ' pounds'}</span>
                </p>
              </div>
              {client.api && (
                <p style={{ fontSize: '1.2rem', letterSpacing: '1px' }}>
                  <span style={{ color: '#c4eb37' }}>API: </span>
                  {client.api}
                </p>
              )}
              {shouldShowEditButtons}
            </div>
          </div>
          {userType === 'admin' && (
            <div
              className="d-flex flex-row-reverse mb-2"
              style={{
                height: '40px',
                width: '100px',
                marginLeft: '30px',
                marginTop: '50px'
              }}
            >
              <Button
                onClick={async () => {
                  setOpenSuspendModal(true);
                }}
                color="primary"
                variant="contained"
              >
                {client.status === clientAccountStatuses.ACTIVE ? 'Suspend' : 'Unsuspend'}
              </Button>
            </div>
          )}
          {(userType === 'admin' || userType === 'nutritionist') && (
            <div
              className="d-flex flex-row-reverse mb-2"
              style={{
                height: '40px',
                width: '120px',
                marginLeft: '30px',
                marginTop: '50px'
              }}
            >
              <Button
                onClick={async () => {
                  setOpenSubmissionsModal(true);
                }}
                color="primary"
                variant="contained"
              >
                Submissions
              </Button>
            </div>
          )}
        </div>

        <div
          className={styles.profile_leftSection_contactInfo}
          style={{ display: 'flex', flexWrap: 'wrap' }}
        >
          <span
            style={{ marginRight: '30px' }}
            className={styles.profile_leftSection_contactInfo_detail}
          >
            <span className={styles.profile_leftSection_contactInfo_title}>Client Information</span>
            {userData &&
              userData.map((el) => (
                <div
                  className={`${styles.profile_leftSection_contactInfo_detail_phoneBlck} ${styles.flexRow} ${styles.mt4}`}
                >
                  <span className={styles.key}>
                    <strong>{el[0]}</strong>
                  </span>
                  <span className={styles.value}>{getValue(el[1])}</span>
                </div>
              ))}
          </span>
          <span className={styles.profile_leftSection_contactInfo_detail}>
            <span className={styles.profile_leftSection_contactInfo_title}>Client Preferences</span>
            {coachPreference &&
              coachPreference.map((el) => (
                <div
                  className={`${styles.profile_leftSection_contactInfo_detail_phoneBlck} ${styles.flexRow} ${styles.mt4}`}
                >
                  <span className={styles.key}>
                    <strong>{el[0]} : &nbsp;</strong>
                  </span>
                  <span className={styles.value}>{getValue(el[1])}</span>
                </div>
              ))}
          </span>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div className={styles.modalContainer}>
          <Icon
            onClick={onClose}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <div>
            <div className={styles.avatarWrapper}>
              <div
                onMouseEnter={() => {
                  setAvatarColor('#EE79B3');
                }}
                onMouseLeave={() => {
                  setAvatarColor('white');
                }}
              >
                <Avatar
                  labelStyle={{
                    color: avatarColor,
                    cursor: 'pointer',
                    fontSize: '20px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  borderStyle={{
                    fontSize: '20px',
                    border: `2px dashed ${avatarColor}`,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  width={300}
                  height={200}
                  onCrop={onCrop}
                  onClose={() => setImage(null)}
                />
              </div>
              {image ? <img className={styles.avatarPreview} src={image} alt="Preview" /> : null}
            </div>
            <button onClick={upload} className={styles.uploadButton}>
              Upload
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openSuspendModal}
        onClose={() => setOpenSuspendModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div className={styles.modalContainer}>
          <Icon
            onClick={onClose}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <div>
            <h2
              style={{
                fontFamily: 'Bebas Neue',
                fontSize: '50px',
                margin: 'auto',
                textAlign: 'center'
              }}
            >
              Suspension
            </h2>
            {client.status === clientAccountStatuses.ACTIVE ? (
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginTop: '15px'
                }}
              >
                Are you sure you want to suspend this user?
              </p>
            ) : (
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginTop: '15px'
                }}
              >
                Are you sure you want to unsuspend this user?
              </p>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '40px'
            }}
          >
            <button className={styles.cancelButton} onClick={() => setOpenSuspendModal(false)}>
              <p className={styles.buttonText}>Cancel</p>
            </button>
            <button
              className={styles.confirmButton}
              onClick={async () => {
                if (client.status === clientAccountStatuses.ACTIVE) {
                  await adminActions.suspendClient(user.id);
                  await adminActions.loadClients();
                  history.push('/admin/clients');
                } else {
                  await adminActions.unsuspendClient(user.id);
                  await adminActions.loadClients();
                  history.push('/admin/clients');
                }
              }}
            >
              <p className={styles.buttonText}>Confirm</p>
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={openSubmissionsModal}
        onClose={() => setOpenSubmissionsModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div
          className={styles.modalContainer}
          style={{
            height: '650px'
          }}
        >
          <Icon
            onClick={() => setOpenSubmissionsModal(false)}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <div>
            <SubmissionsTable
              data={submissionsData}
              setSubData={setActiveSubmissionData}
              setOpenSubModal={setOpenSubmissionModal}
            />
          </div>
        </div>
      </Dialog>
      <SubmissionPreviewModal
        data={activeSubmissionData?.data[0]?.data}
        open={openSubmissionModal}
        selectedForm={activeSubmissionData?.formUrl}
        onClose={() => setOpenSubmissionModal(false)}
      />
    </>
  );
};

export default ClientProfile;

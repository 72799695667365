import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#c4eb37'
    },
    background: {
      default: '#212128'
    }
  }
});

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  Avatar,
  TableRow,
  Typography,
  Button,
  Card,
  CardContent
} from '@material-ui/core';

import { nutritionistActions, userActions } from '../../../redux/actions';
import { clientStatuses } from '../../../constants';
import { useHistory } from 'react-router';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const columns = [
  {
    mapId: 1,
    id: 'user',
    label: 'Name',
    format: (value) => <Avatar src={value.image} />
  },
  {
    mapId: 2,
    id: 'user',
    label: 'Name',
    format: (value) => value.name
  },
  {
    mapId: 3,
    id: 'subscription',
    label: 'Subscription Status',
    format: (sub) => {
      if (!sub) {
        return 'No subscription';
      }
      let statusText = capitalizeFirstLetter(sub.status);
      if (sub.cancel_at) {
        statusText = `Plan is active untill ${moment(sub.cancel_at * 1000).format('LL')}`;
      }

      return statusText;
    }
  },
  {
    mapId: 4,
    id: 'subscription',
    label: 'Tier',
    format: (sub) => {
      if (!sub) {
        return null;
      }
      let result = `$${sub.plan.amount / 100}/mo`;

      return result;
    }
  }
];

export default function PendingClientsTable() {
  const clients = useSelector((state) => state.nutritionist.clients);
  const history = useHistory();

  const table = useMemo(() => {
    return clients.filter(
      (client) => client.nutritionistClients.status === clientStatuses.APPROVED
    );
  }, [clients]);

  useEffect(() => {
    nutritionistActions.loadClients();
  }, []);

  return (
    <Card
      style={{
        marginTop: 10,
        minWidth: 500,
        maxWidth: 800,
        margin: 10,
        flex: 1
      }}
    >
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography gutterBottom variant="h6" style={{ color: '#c4eb37' }}>
          My clients
        </Typography>

        {_.isEmpty(table) && <Typography gutterBottom>You have no clients yet.</Typography>}
        {!_.isEmpty(table) && (
          <Table size="small">
            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow hover key={row.id} onClick={() => {
                    console.log(row)
                    history.push(`/nutritionist/clients/${row.id}`)

                  }}>
                    {columns.map((column) => {
                      const value = column.format ? column.format(row[column.id]) : row[column.id];

                      return <TableCell key={column.mapId}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}

        <div style={{ display: 'flex', marginTop: 20, justifyContent: 'space-between' }}>
          <a href="/nutritionist/clients">
            <Button color="primary" variant="contained" style={{ marginLeft: 5 }}>
              My clients
            </Button>
          </a>
        </div>
      </CardContent>
    </Card>
  );
}

import { combineReducers } from 'redux';

import appReducer from './app';
import authReducer from './auth';
import adminReducer from './admin';
import userReducer from './user';
import nutritionistReducer from './nutritionist';
import pollReducer from './poll';
import clientReducer from './client';
import chatReducer from './chat'

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  admin: adminReducer,
  polls: pollReducer,
  nutritionist: nutritionistReducer,
  client: clientReducer,
  chat: chatReducer,
});

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core';
import { Group, Dashboard, Chat, Subscriptions } from '@material-ui/icons';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleIcon from '@material-ui/icons/People';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HelpIcon from '@material-ui/icons/Help';
import PollIcon from '@material-ui/icons/Poll';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import PaymentIcon from '@material-ui/icons/Payment';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import { useSelector } from 'react-redux';
import { appActions } from './../redux/actions';
import { userTypes } from '../constants';
import FileCopy from '@material-ui/icons/FileCopy';

const useStyles = makeStyles({
  list: {
    width: 250
  },
  logoContainer: {
    width: '50%',
    margin: '20px auto'
  }
});

const adminMenuItems = [
  {
    text: 'Dashboard',
    link: '/admin/dashboard',
    icon: <Dashboard />
  },
  {
    text: 'Group Chats',
    link: '/admin/groupchats',
    icon: <ListAltIcon />
  },
  {
    text: 'Applications',
    link: '/admin/applications',
    icon: <ListAltIcon />
  },
  {
    text: 'Coaches',
    link: '/admin/nutritionists',
    icon: <FitnessCenterIcon />
  },
  {
    text: 'Clients',
    link: '/admin/clients',
    icon: <Group />
  },
  {
    text: 'Chat',
    link: '/admin/chat',
    icon: <Chat />
  },

  {
    text: 'Forms',
    link: '/admin/forms',
    icon: <PollIcon />
  },
  {
    text: 'Submissions',
    link: '/admin/submissions',
    icon: <LibraryBooksIcon />
  },
  {
    text: 'FAQ',
    link: '/admin/faq',
    icon: <HelpIcon />
  },
  {
    text: 'Videos',
    link: '/admin/videos',
    icon: <HelpIcon />
  }
];

const nutritionistMenuItems = [
  {
    text: 'Dashboard',
    link: '/nutritionist/dashboard',
    icon: <Dashboard />
  },
  {
    text: 'Subscriptions',
    link: '/nutritionist/subscriptions',
    icon: <Subscriptions />
  },
  {
    text: 'Clients',
    link: '/nutritionist/clients',
    icon: <Group />
  },
  {
    text: 'Profile',
    link: '/nutritionist/profile',
    icon: <PersonIcon />
  },
  {
    text: 'Referrals',
    link: '/nutritionist/referral',
    icon: <InsertLinkIcon />
  },
  {
    text: 'Chat',
    link: '/nutritionist/chat',
    icon: <Chat />
  },
  {
    text: 'Get paid',
    link: '/nutritionist/get-paid',
    icon: <PaymentIcon />
  },

  {
    text: 'Forms',
    link: '/nutritionist/forms',
    icon: <PollIcon />
  },
  {
    text: 'Submissions',
    link: '/nutritionist/submissions',
    icon: <LibraryBooksIcon />
  },
  {
    text: 'FAQ',
    link: '/nutritionist/faq',
    icon: <HelpIcon />
  }
];

const clientMenuItems = [
  {
    text: 'Dashboard',
    link: '/client/dashboard',
    icon: <Dashboard />
  },
  {
    text: 'Profile',
    link: '/client/profile',
    icon: <PersonIcon />
  },
  {
    text: 'Subscriptions',
    link: '/client/subscriptions',
    icon: <MoneyIcon />
  },
  {
    text: 'My coaches',
    link: '/client/my-nutritionists',
    icon: <PersonAddIcon />
  },
  {
    text: 'Chat',
    link: '/client/chat',
    icon: <Chat />
  },
  {
    text: 'Coaches',
    link: '/client/nutritionists',
    icon: <PeopleIcon />
  },
  {
    text: 'Saved Coaches',
    link: '/client/saved',
    icon: <BookmarkBorderIcon />
  },
  //fk2
  {
    text: 'Forms',
    link: '/client/forms',
    icon: <FileCopy />
  },
  {
    text: 'FAQ',
    link: '/client/faq',
    icon: <HelpIcon />
  }
];

const getMenuItems = (userType) => {
  switch (userType) {
    case userTypes.admin:
      return adminMenuItems;
    case userTypes.nutritionist:
      return nutritionistMenuItems;
    case userTypes.client:
      return clientMenuItems;
    default:
      return [];
  }
};

const Sidebar = () => {
  const app = useSelector((state) => state.app);
  const auth = useSelector((state) => state.auth);
  const unread = useSelector((state) => state.chat.totalUnreadCount);
  const menuItems = getMenuItems(auth.userType);
  const classes = useStyles();

  const handleClose = () => {
    appActions.toogleMenu(false);
  };

  return (
    <Drawer open={app.menu.isOpen} onClose={handleClose}>
      <div className={classes.list} role="presentation">
        <List>
          {menuItems.map((item) => (
            <ListItem
              className={item.text === 'Chat' ? 'chat-item' : ''}
              button
              key={item.text}
              style={
                item.link === window.location.pathname
                  ? { background: 'rgba(255, 255, 255, 0.08)' }
                  : {}
              }
              onClick={() => handleClose()}
              component={Link}
              to={item.link}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
              {(item.link === '/client/chat' ||
                item.link === '/nutritionist/chatLists' ||
                item.link === '/admin/chat') &&
              unread &&
              unread > 0 ? (
                <div
                  style={{
                    width: '26px',
                    height: '26px',
                    borderRadius: '999999px',
                    background: '#EE79B3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {unread}
                </div>
              ) : null}
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default Sidebar;

import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField
} from '@material-ui/core';
import { filterTable } from '../../../utils/helper';

const groupcolumns = [
  {
    id: 'groupName',
    label: 'Group Name'
  },
  {
    id: 'nutritionist.firstName',
    label: 'Name'
  },
  {
    id: 'clientsString',
    label: 'Clients'
  }
];

export default function ({ selectGroup, groups }) {
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const table = useMemo(() => {
    const filterFields = [
      'groupName',
      'nutritionist.firstName',
      'nutritionist.lastName',
      'nutritionist.user.email',
      'clientsNames'
    ];

    for (var i = 0; i < groups.length; i++) {
      let clientsNames = '';
      groups[i].chatGroupsClients.map((o) => {
        clientsNames += `${o.client.user.name}, `;
      });
      groups[i].clientsNames = clientsNames;
    }

    // const flats = groups.map((o) => o.flat(2));
    const result = filterTable(groups, filterFields, search);
    return result.slice(page * pageSize, (page + 1) * pageSize);
  }, [groups, pageSize, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleGroupSearch = _.debounce((e) => {
    const { value } = e.target;
    setSearch(value);
  }, 500);

  return (
    <div style={{ width: '30%' }}>
      <div className="d-flex justify-content-between align-items-center">
        <Typography
          style={{
            fontFamily: 'Bebas Neue',
            color: '#EE79B3',
            fontSize: '2.5rem',
            letterSpacing: '2px'
          }}
          component="h1"
          variant="h5"
        >
          Groups
        </Typography>

        <TextField
          className="my-2"
          variant="outlined"
          label="Search"
          name="search"
          onChange={handleGroupSearch}
        />
      </div>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {groupcolumns.map((column) => (
                  <TableCell
                    style={{ fontFamily: 'Bebas Neue', fontSize: '1.2rem' }}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow
                    hover
                    style={{ cursor: 'pointer' }}
                    key={row.id}
                    onClick={() => selectGroup(row.channelId)}
                  >
                    <TableCell>{row.groupName}</TableCell>
                    <TableCell>
                      {row.nutritionist.firstName + ' ' + row.nutritionist.lastName}
                    </TableCell>
                    <TableCell>{row.clientsNames}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          style={{ color: '#c4eb37' }}
          count={groups.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}

// export default groupsTable;

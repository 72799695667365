const experienceOptions = [
  { name: '1 year', id: 1, value: 1 },
  { name: '2 years', id: 2, value: 2 },
  { name: '3 years', id: 3, value: 3 },
  { name: '5 years', id: 4, value: 5 },
  { name: '5+ years', id: 5, value: 6 }
];

const specialtyOptions = [
  { name: 'Adolescents', id: 20, value: 'Adolescents' },
  { name: 'Body Building', id: 23, value: 'Body Building' },
  {
    name: 'CNS: Certified Nutrition Specialist',
    id: 25,
    value: 'CNS: Certified Nutrition Specialist'
  },
  { name: 'CNS: Sub specialty', id: 26, value: 'CNS: Sub specialty' },
  { name: 'Diabetics', id: 22, value: 'Diabetics' },
  { name: 'Endurance Sports', id: 24, value: 'Endurance Sports' },
  { name: 'Fitness competitions', id: 14, value: 'Fitness competitions' },
  { name: 'Functional Diagnostic Nutrition', id: 6, value: 'Functional Diagnostic Nutrition' },
  {
    name: 'General weight loss and improved health',
    id: 28,
    value: 'General weight loss and improved health'
  },
  { name: 'Geriatric nutrition', id: 16, value: 'Geriatric nutrition' },
  { name: 'Health Coach', id: 30, value: 'Health Coach' },
  { name: 'Holistic Health Coach', id: 27, value: 'Holistic Health Coach' },
  { name: 'Keto Diet', id: 31, value: 'Keto Diet' },
  { name: 'Mediterranean Diet', id: 32, value: 'Mediterranean Diet' },
  { name: 'Men', id: 21, value: 'Men' },
  { name: 'Obesity', id: 33, value: 'Obesity' },
  { name: 'Omnivore', id: 6, value: 'Omnivore' },
  { name: 'Pediatric nutrition', id: 15, value: 'Pediatric nutrition' },
  { name: 'Peri menopause', id: 17, value: 'Peri menopause' },
  { name: 'Pescatarian', id: 10, value: 'Pescatarian' },
  { name: 'Plant Based nutrition', id: 7, value: 'Plant Based nutrition' },
  { name: 'Post menopause', id: 18, value: 'Post menopause' },
  { name: 'Sleep coaching', id: 29, value: 'Sleep coaching' },
  { name: 'Sports Nutrition', id: 12, value: 'Sports Nutrition' },
  { name: 'Vegan', id: 8, value: 'Vegan' },
  { name: 'Vegetarian', id: 9, value: 'Vegetarian' },
  { name: 'Weight gain', id: 13, value: 'Weight gain' },
  { name: 'Weight loss', id: 11, value: 'Weight loss' },
  { name: 'Women', id: 19, value: 'Women' }
];

const certificatesOptions = [
  { name: 'American Council on Exercise', id: 36, value: 'American Council on Exercise' },
  {
    name: 'American Fitness Professionals and Associates',
    id: 37,
    value: 'American Fitness Professionals and Associates'
  },
  {
    name: 'Institute for Integrative Nutrition',
    id: 41,
    value: 'Institute for Integrative Nutrition'
  },
  {
    name: 'Institute for the Psychology of Eating',
    id: 42,
    value: 'Institute for the Psychology of Eating'
  },
  {
    name: 'International Society of Sports Nutrition',
    id: 38,
    value: 'International Society of Sports Nutrition'
  },
  {
    name: 'National Academy of Sports Medicine',
    id: 39,
    value: 'National Academy of Sports Medicine'
  },
  { name: 'Precision Nutrition', id: 34, value: 'Precision Nutrition' },
  { name: 'Primal Health Coach', id: 35, value: 'Primal Health Coach' },
  {
    name: 'The National Exercise and Sports Trainers Association',
    id: 40,
    value: 'The National Exercise and Sports Trainers Association'
  }
];

const sexOptions = [
  { name: 'Female', id: 44, value: 'Female' },
  { name: 'Male', id: 43, value: 'Male' },
  { name: 'Other', id: 45, value: 'Other' }
];

export { experienceOptions, certificatesOptions, specialtyOptions, sexOptions };

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@material-ui/core';

import { clientActions } from '../../../redux/actions';
import CoachCardComponent from './CoachCardComponent';
import CoachFilter from './Filter';
import styles from './listStyles.module.scss';

const NutritionistsList = () => {
  const [page, setPage] = useState(0);
  const [nutritionistList, setNutritionistList] = useState([]);
  const [filter, setFilter] = useState({});

  const nutritionists = useSelector((state) => state.client.nutritionists);
  const nutritionistsCount = useSelector((state) => state.client.nutritionistsCount);
  const recommendedNutritionists = useSelector((state) => state.client.recommendedNutritionists);

  useEffect(() => {
    clientActions.loadNutritionists();
    clientActions.loadRecommendedNutritionists();
    clientActions.getSavedNutritionists();
  }, []);

  useEffect(() => {
    if (page === 0) {
      setNutritionistList(nutritionists);
    } else {
      nutritionistList.push(...nutritionists);
      setNutritionistList([...nutritionistList]);
    }
  }, [nutritionists]);

  const load = async () => {
    const params = { ...filter };
    params.page = page;
    await clientActions.loadNutritionists(params);
  };

  useEffect(() => {
    load();
  }, [page]);

  useEffect(() => {
    setPage(0);
    load();
  }, [filter]);

  const onLoadMore = async () => {
    setPage(page + 1);
  };

  return (
    <div className='coaches-marketplace'>
      <div className='title' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div
          style={{
            fontSize: '4rem',
            fontFamily: 'Bebas Neue',
            letterSpacing: '2.5px',
            color: '#c4eb37'
          }}
        >
          Recommended by Planibble
        </div>

        <a href={'/client/profile/edit-preferences'} style={{ display: 'flex' }}>
          <button className={`${styles.preferencesButton} ${styles.profileButton}`}>
            <p className={styles.socialButtonText}>coach preferences</p>
          </button>
        </a>
      </div>
      <div
        style={{
          marginTop: '2rem',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'repeat(1, 1fr)',
          gap: '2rem'
        }}
      >
        {recommendedNutritionists.map((item, i) => {
          return <CoachCardComponent showSavedIcon={true} item={item} key={i} />;
        })}
      </div>
      <div
        style={{
          marginTop: '2rem',
          fontSize: '4rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '2.5px',
          color: '#c4eb37'
        }}
      >
        Search for additional coaches
      </div>
      <CoachFilter onChange={setFilter} />
      <div
        style={{
          marginTop: 40,
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'repeat(1, 1fr)',
          gap: '2rem'
        }}
      >
        {nutritionistList &&
          nutritionistList.map((item, i) => {
            return <CoachCardComponent showSavedIcon={true} item={item} key={i} />;
          })}
      </div>
      {(page + 1) * 9 < nutritionistsCount && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => onLoadMore()}
            style={{ fontSize: '1.2rem' }}
            variant="contained"
            color="primary"
          >
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};

export default NutritionistsList;

import React, { useState, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { userTypes } from '../../constants';
import { adminActions, chatActions } from '../../redux/actions';
import NutritionistProfile from '../nutritionist/profile';
import ClientProfile from '../client/profile';
import ModerateChatsModal from './ModerateChatsModal';

const Profile = ({ user }) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state?.user);
  const currentUserType = useSelector((state) => state.auth.userType);
  const currentUserId = useSelector((state) => (state.user ? state.user.id : ''));
  const moderatedChats = useMemo(() => currentUserType === 'admin', [currentUserType]);
  const [isModerateChatsModalOpen, setIsModerateChatsModalOpen] = useState(false);

  let link = null;

  if (currentUserType === userTypes.admin) {
    link = `/admin/nutritionists/${user.id}/edit`;
  } else if (currentUserType === userTypes.nutritionist) {
    link = '/nutritionist/profile/edit';
  }

  if (!user) {
    user = currentUser;
  }

  const userType = user.type;

  console.log(currentUserType);

  const onOpenChat = async () => {
    let clientId, nutritionistId;
    if (userType === userTypes.nutritionist) {
      nutritionistId = user.nutritionist.id;
    }
    if (userType === userTypes.client) {
      clientId = user.client.id;
    }
    if (currentUserType === userTypes.admin) {
      await adminActions.initChat({ nutritionistId, clientId, toAdmin: true });
      history.push({ pathname: `/admin/chat` });
    } else {
      if (currentUserType === userTypes.client) {
        clientId = currentUser.client.id;
        history.push({ pathname: `/nutritionist/chat` });
      } else {
        nutritionistId = currentUser.nutritionist.id;
        history.push({ pathname: `/client/chat` });
      }
      await chatActions.initChat({ nutritionistId, clientId });
    }
  };

  const onCloseChat = () => {
    setIsModerateChatsModalOpen(false);
  }

  return (
    <>
      {isModerateChatsModalOpen && (
        <div
          style={{
            position: 'absolute',
            height: '40rem',
            width: '60rem',
            left: '25%',
            top: '7%',
            zIndex: 999,
            paddingTop: '30px'
          }}
        >
          <ModerateChatsModal user={user} />
          <Button
          className="mx-2"
          color="primary"
          variant="contained"
          style={{
            position: 'absolute',
            top: '10px'
          }}
          onClick={onCloseChat} >
            Close
          </Button>
        </div>
      )}

      {(currentUserType === userTypes.nutritionist || currentUserType === userTypes.admin) && (
        <div className="d-flex flex-row-reverse my-2">
          {currentUserId !== user.id && (
            <Button
              onClick={onOpenChat}
              className="mx-2"
              style={{ background: '#424242', color: '#EE79B3' }}
              variant="contained"
            >
              Open chat
            </Button>
          )}

          {moderatedChats && (
            <Button
              className="mx-2"
              onClick={() => setIsModerateChatsModalOpen(!isModerateChatsModalOpen)}
              color="primary"
              variant="contained"
            >
              {isModerateChatsModalOpen ? 'Close chats' : 'Moderate chats'}
            </Button>
          )}

          {link && userType !== userTypes.client && (
            <Link to={link}>
              <Button color="primary" variant="contained" className="mx-2">
                Edit Profile
              </Button>
            </Link>
          )}
        </div>
      )}

      {userType === userTypes.nutritionist && <NutritionistProfile user={user} />}
      {userType === userTypes.client && <ClientProfile user={user} />}
    </>
  );
};

export default Profile;

//import styles from './Form.module.scss';
import appStore from '../../../assets/img/appstore-white.svg';
import FormsList from '../../../components/formsList';
import { usePubNub } from 'pubnub-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { chatActions, userActions } from '../../../redux/actions';
import _ from 'lodash';
import config from '../../../config/index';

const ClientForms = () => {
  const user = useSelector((state) => state.user);
//   var formioUrl = '';
//   useEffect(async () => {
//     const {data} = await loadPoll(150);
//     console.log("then poll", JSON.stringify(data));
//     // formioUrl = data['formUrl'];
//   }, []);

//   // useEffect(() => {
//   //   loadForms(`${formioUrl}form`);
//   // }, []);
  return (
    <div>
      <FormsList />
    </div>
  );
};

export default ClientForms;
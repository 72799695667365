import React, { Component } from 'react';
import Slider from 'react-slick';

import styles from '../landingPage/landing.module.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import iphone2 from '../../assets/img/Iphone2.png';
import iphone3 from '../../assets/img/Iphone3.png';

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const settingsLeft = {
      fade: true,
      vertical: true,
      autoplay: true,
      pauseOnHover: false,
      arrows: false,
      autoplaySpeed: 5000,
      asNavFor: this.state.nav2,
      ref: (slider) => (this.slider1 = slider)
    };
    const settingsRight = {
      slidesToShow: 2,
      focusOnSelect: true,
      autoplay: true,
      vertical: true,
      autoplaySpeed: 5000,
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider)
    };
    const images = [iphone2, iphone3];
    const text = [
      {
        title: 'CONTACT INFO',
        description: 'EMAIL: info@planibble.com',
        description2: 'PHONE: +1 (310) 307-0200'
      },
      {
        title: 'ADDRESS',
        description:
          '2242 South Sepulveda Blvd, Los Angeles, CA 90064'
      }
    ];
    const imagesList = images.map((item) => (
      <div className={styles.imageSlide}>
        <img src={item} className={styles.imageSlider} />
      </div>
    ));
    const textList = text.map((item, i) => (
      <div className="slide-cell">
        <div className={styles.titleSlide}>{item.title}</div>
        <div className={styles.descriptionSlide}>{item.description}</div>
        <div className={styles.descriptionSlide}>{item.description2}</div>
      </div>
    ));

    return (
      <div className={styles.yourPersonalToolkit}>
        <div className={styles.toolkitLeft}>
          <Slider {...settingsLeft}>{imagesList}</Slider>
        </div>
        <div className={styles.toolkitRight}>
          <div className={styles.toolkitTextContainer}>
            <div className={styles.toolkitBlockTitle}>SUPPORT</div>
            <Slider {...settingsRight}>{textList}</Slider>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { Container } from '@material-ui/core';

import Header from './Header';
import Sidebar from './Sidebar';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import CustomRedirect from './Redirect';
import RegisterRedirect from './RegisterRedirect';

import PendingView from '../views/nutritionist/status/pendingView';
import RejectedView from '../views/nutritionist/status/rejectedView';
import SuspendedView from '../views/nutritionist/status/suspendedView';

import AdminChat from '../views/admin/chat';
import ClientSuspendedView from '../views/client/status/suspendedView';
import AdminLogin from '../views/admin/login';
import AdminDashboard from '../views/admin/dashboard';
import AdminGroupChat from '../views/admin/groupchat';
import AdminClients from '../views/admin/clients';
import AdminNutritionist from '../views/admin/nutritionists';
import AdminNutritionistProfile from '../views/admin/nutritionists/profile';
import AdminNutritionistProfileEdit from '../views/admin/nutritionists/profileEdit';
import AdminClientProfile from '../views/admin/clients/profile';
import AdminNutritionistApplications from '../views/admin/nutritionists/applicants';
import AdminFAQ from '../views/admin/faq';
import ClientFAQ from '../views/client/faq';
import AdminPolls from '../views/admin/polls';
import ClientSaved from '../views/client/saved';
import AdminSubmissions from '../views/admin/submissions';
import EditClientProfile from '../views/client/profile/edit';

import ClientDashboard from '../views/client/dashboard';
import ClientForm from '../views/client/form';
import ClientSubmittion from '../views/client/submittion';
import ClientForms from '../views/client/forms';
import ClientSubscriptions from '../views/client/subscriptions';
import Profile from '../views/commonProfile';
import Chat from '../views/chat';

// import NutritionistIntakeForm from '../views/nutritionist/intakeForm';
import NutritionistDashboard from '../views/nutritionist/dashboard';
import NutritionistClients from '../views/nutritionist/clients';
import NutritionistClientProfile from '../views/nutritionist/clients/profile';
import NutritionistRefferal from '../views/nutritionist/refferal';
import NutritionistFAQ from '../views/nutritionist/faq';
import NutritionistsPolls from '../views/nutritionist/polls';
import NutritionistSubmissions from '../views/nutritionist/submissions';
import EditProfile from '../views/nutritionist/profile/edit';
import NutritionistSubscriptions from '../views/nutritionist/subscriptions';

import LangingPage from '../views/landingPage';
import SupportPage from '../views/supportPage';
import PrivacyPage from '../views/supportPage/privacy';
import TermsPage from '../views/supportPage/terms';
import FaqPublicPage from '../views/supportPage/faq';

import Login from '../views/login';
import ForgotPassword from '../views/login/forgot-password';
import Register from '../views/login/register';

import { userTypes, nutritionistStatuses, clientAccountStatuses } from '../constants';
import nutritionistsList from '../views/client/nutritionistsList';
import NutritionistInfo from '../views/client/nutritionistInfo';
import MyNutritionists from '../views/client/myNutritionists';
import ClientOnboarding from '../views/client/onboarding';
import GenericNotFound from '../views/genericNotFound';
import Loader from '../components/Loader';
import EditPreferences from '../views/client/profile/edit/preferences';

import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import config from '../config/index';
import GetPaid from '../views/nutritionist/getpaid';

import RedirectTo from './RedirectTo';

import AdminVideos from '../views/admin/videos';

const Skeleton = () => {
  const { isLogged } = useSelector((state) => state.auth);
  const currentUser = useSelector((state) => state.user);

  const pubnubConfig = {
    restore: true,
    heartbeatInterval: 0,
    ...config.pubnubKeys,
    uuid: currentUser && currentUser.id,
    profileUrl: currentUser && currentUser.imageUrl
  };

  const pubnub = new PubNub(pubnubConfig);

  let isSidebarEnabled = true;
  const isNutritionist = currentUser?.type === userTypes.nutritionist;

  if (isNutritionist) {
    if (currentUser?.nutritionist?.nutritionistStatus.status !== nutritionistStatuses.APPROVED) {
      isSidebarEnabled = false;
    }
  }

  if (currentUser?.type === userTypes.client) {
    if (currentUser?.client?.status === clientAccountStatuses.SUSPENDED) {
      isSidebarEnabled = false;
    }
  }

  return (
    <>
      <PubNubProvider client={pubnub}>
        {isLogged && (
          <>
            <Header />
            {isSidebarEnabled && <Sidebar />}
          </>
        )}
        <Suspense fallback={<Loader />}>
          <Switch>
            <PublicRoute path="/" exact component={LangingPage} />
            <PublicRoute path="/support" component={SupportPage} />
            <PublicRoute path="/privacy" component={PrivacyPage} />
            <PublicRoute path="/terms" component={TermsPage} />
            <PublicRoute path="/faq" component={FaqPublicPage} />

            <Container style={{ marginTop: 10, paddingBottom: 50 }}>
              <Switch>
                <PublicRoute path="/login" exact component={Login} />
                <PublicRoute path="/forgot-password" exact component={ForgotPassword} />
                <PublicRoute path="/register" exact component={Register} />
                <PublicRoute path="/admin/login" exact component={AdminLogin} />
                <Route path="/redirect" exact component={CustomRedirect} />
                <Route path="/redirect-to/:url/:accesstoken" exact component={RedirectTo} />
                <Route path="/register-redirect" exact component={RegisterRedirect} />
                <PrivateRoute
                  exact
                  path="/admin/dashboard"
                  component={AdminDashboard}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/groupchats"
                  component={AdminGroupChat}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/chat"
                  component={AdminChat}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/faq"
                  component={AdminFAQ}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/videos"
                  component={AdminVideos}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/clients"
                  component={AdminClients}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/nutritionists/:id"
                  component={AdminNutritionistProfile}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/nutritionists/:id/edit"
                  component={AdminNutritionistProfileEdit}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  path="/admin/clients/:id"
                  component={AdminClientProfile}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/nutritionists"
                  component={AdminNutritionist}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/applications"
                  component={AdminNutritionistApplications}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/forms"
                  component={AdminPolls}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/admin/submissions"
                  component={AdminSubmissions}
                  userType={userTypes.admin}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/dashboard"
                  component={NutritionistDashboard}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/subscriptions"
                  component={NutritionistSubscriptions}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/faq"
                  component={NutritionistFAQ}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/clients"
                  component={NutritionistClients}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/get-paid"
                  component={GetPaid}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/forms"
                  component={NutritionistsPolls}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/submissions"
                  component={NutritionistSubmissions}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  path="/nutritionist/clients/:id"
                  component={NutritionistClientProfile}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/referral"
                  component={NutritionistRefferal}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/profile"
                  component={Profile}
                  userType={userTypes.nutritionist}
                />
                <Route
                  exact
                  path="/nutritionist/status/reject"
                  component={RejectedView}
                  userType={userTypes.nutritionist}
                />
                <Route
                  path="/nutritionist/status/pending"
                  component={PendingView}
                  userType={userTypes.nutritionist}
                />
                <Route
                  path="/nutritionist/status/suspended"
                  component={SuspendedView}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/profile/edit"
                  component={EditProfile}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/subscriptions"
                  component={NutritionistSubscriptions}
                  userType={userTypes.nutritionist}
                />
                <PrivateRoute
                  exact
                  path="/client/dashboard"
                  component={ClientDashboard}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/form/:id"
                  component={ClientForm}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/submission/:id"
                  component={ClientSubmittion}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/forms"
                  component={ClientForms}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/faq"
                  component={ClientFAQ}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/profile/edit-preferences"
                  component={EditPreferences}
                  userType={userTypes.client}
                />
                <Route
                  exact
                  path="/client/status/suspended"
                  component={ClientSuspendedView}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/profile/edit"
                  component={EditClientProfile}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/saved"
                  component={ClientSaved}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/nutritionists"
                  component={nutritionistsList}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  path="/client/nutritionists/:id"
                  component={NutritionistInfo}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/profile"
                  component={Profile}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/my-nutritionists"
                  component={MyNutritionists}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/subscriptions"
                  component={ClientSubscriptions}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/client/chat"
                  component={Chat}
                  userType={userTypes.client}
                />
                <PrivateRoute
                  exact
                  path="/nutritionist/chat"
                  component={Chat}
                  userType={userTypes.nutritionist}
                />
                <Route path="/nutritionist" exact component={EditProfile} />
                <Route path="/client-onboarding" exact component={ClientOnboarding} />
                <Route path="/404" exact component={GenericNotFound} />
                <Redirect to="/404" />
              </Switch>
            </Container>
          </Switch>
        </Suspense>
      </PubNubProvider>
    </>
  );
};

export default Skeleton;

import store from '../store';
import mutations from '../mutations';
import { userAxios } from '../../utils/axios';
import user from './user';

const loadClients = async () => {
  const { data } = await userAxios.get('/admin/clients');

  const activeSubscriptions = await userAxios.get('/stripe/adminactivesubscriptions');

  const arr = [];
  activeSubscriptions.data.map((sub) => {
    // arr.push({`${sub.customer}`: sub});
    arr[sub.customer] = sub;
  });
  data.forEach((client) => {
    if (
      client.client.stripeCustomers.length > 0 &&
      arr[client.client.stripeCustomers[0].customerId] != undefined
    ) {
      // console.log(client.client.stripeCustomers[0].customerId);
      client.sub = arr[client.client.stripeCustomers[0].customerId];
    }
  });
  data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  data.sort((a, b) => (a.sub ? -1 : 1));

  store.dispatch({
    type: mutations.admin.loadClients,
    payload: data
  });
};

const deleteUserClient = async (userId) => {
  return await userAxios.delete(`/admin/client/${userId}`);
};

const loadNutritionists = async () => {
  const { data } = await userAxios.get('/admin/nutritionists');

  data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  store.dispatch({
    type: mutations.admin.loadNutritionists,
    payload: data
  });
};

const promoteNutritionist = async (payload) => {
  return userAxios.post('/admin/nutritionist/promote', { ...payload });
};

const loadNutritionistsChanges = async () => {
  const { data } = await userAxios.get('/admin/nutritionistsChangeRequests');

  data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  store.dispatch({
    type: mutations.admin.loadNutritionistChangeRequests,
    payload: data
  });
};

const loadNutritionistApplications = async () => {
  const { data } = await userAxios.get('/admin/nutritionists/applicants');

  store.dispatch({
    type: mutations.admin.loadNutritionistApplicants,
    payload: data
  });
};

const submitNutritionistChange = async (id, data) => {
  return await userAxios.put(`/admin/nutritionists/${id}`, data);
};

const acceptChanges = async (user) => {
  return await userAxios.post(`/admin/nutritionists/${user.nutritionist.id}/changes`, {
    accept: true
  });
};

const rejectChanges = async (user) => {
  return await userAxios.post(`/admin/nutritionists/${user.nutritionist.id}/changes`, {
    reject: true
  });
};

const updateNutritionistStatus = async ({ nutritionistId, data }) => {
  return await userAxios.put(`/admin/nutritionists/${nutritionistId}/status`, data);
};

const loadStats = async () => {
  const { data } = await userAxios.get('/admin/stats');

  store.dispatch({
    type: mutations.admin.loadStats,
    payload: data
  });
};

const approveReview = async (id) => {
  return userAxios.post('/admin/reviews', { status: 'APPROVED', id });
};

const rejectReview = async (id) => {
  return userAxios.post('/admin/reviews', { status: 'REJECTED', id });
};

const saveFAQ = async ({ text, type }) => {
  await userAxios.post('/admin/faq', { text, type });
};

const loadReviewRequests = async () => {
  const { data } = await userAxios.get('/admin/reviews');
  store.dispatch({
    type: mutations.admin.loadReviewRequests,
    payload: data
  });
};

const loadChatData = async () => {
  const { data } = await userAxios.get('/admin/chats');
  store.dispatch({
    type: mutations.chat.loadChatData,
    payload: data
  });
};

const getModeratedChats = async (user) => {
  let clientId, nutritionistId;
  if (user.client) {
    clientId = user.client.id;
  } else {
    nutritionistId = user.nutritionist.id;
  }

  const { data } = await userAxios.get(`/admin/moderated-chats`, {
    params: { clientId, nutritionistId }
  });
  store.dispatch({
    type: mutations.chat.loadChatData,
    payload: data
  });
};

const suspendClient = async (userId) => {
  return userAxios.post('/client/status', {
    userId,
    status: 'SUSPENDED'
  });
};

const unsuspendClient = async (userId) => {
  return userAxios.post('/client/status', {
    userId,
    status: 'ACTIVE'
  });
};

const initChat = async ({ nutritionistId = null, clientId = null, toAdmin = false }) => {
  return await userAxios.post('/admin/chat/init', {
    nutritionistId,
    clientId,
    toAdmin
  });
};

//fk2
const loadExtraSpecialties = async () => {
  const { data } = await userAxios.get('/admin/extra-specialties');
  store.dispatch({
    type: mutations.admin.loadNutritionistExtraSpecialties,
    payload: data
  });
};
const acceptExtraSpecialty = async (id) => {
  return await userAxios.post(`/admin/extra-specialties/change`, {
    accept: true,
    id: id
  });
};

const deleteExtraSpecialty = async (id) => {
  return await userAxios.post(`/admin/extra-specialties/change`, {
    accept: false,
    id: id
  });
};

const loadReportsData = async () => {
  const { data } = await userAxios.get('/stripe/adminreports');

  return data;
};

const loadReportsDataFor = async (id) => {
  const { data } = await userAxios.get(`/stripe/coachreports/${id}`);
  return data;
};

const loadReportsDataMe = async () => {
  const { data } = await userAxios.get(`/stripe/coachreports`);
  return data;
};

const loadAllReportsData = async () => {
  const { data } = await userAxios.get('/stripe/allcoachreports');
  return data;
};

const getGroupchats = async () => {
  const { data } = await userAxios.get('/admin/groupChats');
  console.log('axios ', data);
  return data;
};

const insertGroupChat = async (clientIds, nutritionistId, groupName, groupChatType) => {
  const { data } = await userAxios.post('/admin/insertGroupChat', {
    clientIds,
    nutritionistId,
    groupName,
    groupChatType
  });
  // console.log('axios ', data);
  return data;
};

const updateGroupChat = async (clientIds, nutritionistId, groupName, groupChatType, id) => {
  const { data } = await userAxios.post('/admin/updateGroupChat', {
    clientIds,
    nutritionistId,
    groupName,
    groupChatType,
    id
  });
  console.log('axios ', data);
  return data;
};

const deleteGroupChat = async (id) => {
  const { data } = await userAxios.post('/admin/updateGroupChat', {
    clientIds: null,
    nutritionistId: null,
    groupName: null,
    id: id
  });
  console.log('axios ', data);
  return data;
};

const loadNutritionistClients = async (id) => {
  const { data } = await userAxios.get(`admin/nutritionist/${id}/clients`);

  data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  return data;
};

const saveHomepageContent = async ({ data }) => {
  await userAxios.post('/admin/homepagecontent', { data });
};

export default {
  loadChatData,
  loadClients,
  deleteUserClient,
  submitNutritionistChange,
  loadNutritionistsChanges,
  loadNutritionists,
  loadNutritionistApplications,
  updateNutritionistStatus,
  loadStats,
  acceptChanges,
  rejectChanges,
  saveFAQ,
  promoteNutritionist,
  loadReviewRequests,
  approveReview,
  rejectReview,
  getModeratedChats,
  suspendClient,
  unsuspendClient,
  initChat,
  loadExtraSpecialties,
  acceptExtraSpecialty,
  deleteExtraSpecialty,
  loadReportsData,
  loadReportsDataFor,
  loadReportsDataMe,
  loadAllReportsData,
  getGroupchats,
  insertGroupChat,
  updateGroupChat,
  deleteGroupChat,
  loadNutritionistClients,
  saveHomepageContent
};

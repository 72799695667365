import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { adminActions } from '../../../redux/actions';

import ProfileView from '../../commonProfile';

export default function (props) {
  const clients = useSelector((state) => state.admin.clients);
  const [client, setClient] = useState(null);

  useEffect(() => {
    adminActions.loadClients();
  }, []);

  useEffect(() => {
    const id = parseInt(props.match.params.id);
    if (!clients.length) return;

    const result = clients.find((i) => i.client.id === id);
    setClient(result);
  }, [clients]);

  return <div>{client && <ProfileView user={client} />}</div>;
}

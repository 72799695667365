import React, { useState } from 'react';
import { TextField, LinearProgress, Link } from '@material-ui/core';
import styles from './LogIn.module.scss';

import { authActions } from '../../../redux/actions';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default function AdminLogin() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setFormData((old) => ({ ...old, [name]: value }));
  };


  const handleSubmit = async () => {
    setError(null);
    setIsLoading(true);
    try {
      await authActions.admin.login(formData);
    } catch (e) {
      if (e.message) setError(e.message);
      else setError('Login failed');
    }

    setIsLoading(false);
  };

  const handleKeySubmit = async (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className={styles.userTypeContainer}>
      <div className={styles.contentContainer}>
        <p className={styles.title}>Admin Login</p>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoFocus
          onChange={handleFormChange}
          onKeyPress={handleKeySubmit}
        />
        <TextField
          onChange={handleFormChange}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          id="password"
          type={showPassword ? 'text' : 'password'}
          onKeyPress={handleKeySubmit}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment variant="filled" position="end">
                <IconButton
                  style={{ backgroundColor: 'transparent' }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {!isLoading && (
          <button className={styles.signInButton} onClick={handleSubmit}>
            <p className={styles.socialButtonText}>Sign In</p>
          </button>
        )}
        {isLoading && <LinearProgress />}
        {error && <div style={{ color: 'red', textAlign: 'center' }}> {error}</div>}

        <Link href="/login" style={{ position: 'fixed', right: 10, bottom: 10 }}>
          Regular login
        </Link>
      </div>
    </div>
  );
}

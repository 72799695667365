import { useSelector } from 'react-redux';
import styles from './index.module.scss';

const GenericNotFound = () => {
  const { isLogged } = useSelector((state) => state.auth);

  return (
    <div>
      <div className={styles.container} style={{ marginTop: isLogged ? '-64px' : 0 }}>
        <h1 className={styles.title}>404</h1>
        <h2 className={styles.subTitle}>page not found</h2>
      </div>
    </div>
  );
};

export default GenericNotFound;

const specialities = [
  'Plant Based nutrition',
  'Vegan',
  'Vegetarian',
  'Pescatarian',
  'Functional Diagnostic Nutrition',
  'Weight loss',
  'Sports Nutrition',
  'Weight gain',
  'Fitness competitions',
  'Pediatric nutrition',
  'Geriatric nutrition',
  'Peri menopause',
  'Post menopause',
  'Women',
  'Adolescents',
  'Men',
  'Diabetics Type I',
  'Diabetics Type II',
  'Body Building',
  'Endurance Sports',
  'CNS: Certified Nutrition Specialist',
  'CNS: Sub specialty',
  'Holistic Health Coach',
  'General weight loss and improved health',
  'Sleep coaching',
  'Health Coach',
  'Keto Diet',
  'Mediterranean Diet',
  'Morbid Obesity',
  'Bio Hacker'
];

export default specialities;
import React, { useEffect, useState } from 'react';
import styles from './Index.module.scss';
import { adminActions } from '../../../redux/actions';
import GroupsTable from './groups';
import CoachesTable from './coaches';
import ClientsTable from './clients';
import { Grid, TextField, Icon, IconButton, Select, MenuItem } from '@material-ui/core';
import { DataUsageSharp } from '@material-ui/icons';
import { groupChatTypes } from '../../../constants';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

const initialData = {
  groupName: 'Group Name',
  coach: null,
  clients: [],
  id: 0,
  groupChatType: 'PLAN_1',
  locked: false
};

const AdminGroupChat = () => {
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});
  const [tablesData, SetTablesData] = useState(null);
  // const [groupCoach, setGroupCoach] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(async () => {
    getTablesData();
  }, []);

  const getTablesData = async () => {
    const data = await adminActions.getGroupchats();
    // console.log('fk2 data', data);
    SetTablesData(data);
    clearData();
  };

  const errorExist = (param) => errors.hasOwnProperty(param);

  const onChange = (e) => {
    const capacities = data.clients.map(
      (o) => groupChatTypes[o.coachPreference.groupChatType].capacity
    );
    const limit = Math.min(...capacities);
    if (e.target.name == 'groupChatType' && groupChatTypes[e.target.value].capacity > limit) {
      e.preventDefault();
      return alert('you have added a client who selected a smaller size group chat');
    }
    console.log(data);
    setData((old) => ({
      ...old,
      [e.target.name]: e.target.value
    }));
  };

  const submitForm = async () => {
    //clientIds, nutritionistId, groupName
    console.log('submit', data);
    const clientIds = data.clients.map((o) => o.id);
    const nutritionistId = data.coach.id;
    const groupName = data.groupName;
    const groupChatType = data.groupChatType;
    console.log(clientIds, nutritionistId, groupName);
    if (data.id > 0) {
      await adminActions.updateGroupChat(
        clientIds,
        nutritionistId,
        groupName,
        groupChatType,
        data.id
      );
    } else {
      await adminActions.insertGroupChat(clientIds, nutritionistId, groupName, groupChatType);
    }
    getTablesData();
  };

  const deleteGroup = async () => {
    await adminActions.deleteGroupChat(data.id);
    setDeleteDialogOpen(false);
    getTablesData();
  };

  const clearData = () => {
    setData((old) => ({
      ...initialData,
      clients: []
    }));
  };
  const deleteCoach = () => {
    setData((old) => ({
      ...old,
      ['coach']: null
    }));
  };
  const deleteMember = (id) => {
    const updateClients = data.clients.filter((c) => c.id != id);
    setData((old) => ({
      ...old,
      ['clients']: updateClients
    }));
  };

  const selectGroup = (id) => {
    const updateGroup = tablesData.groups.filter((g) => g.channelId == id)[0];
    console.log('updategroup ', updateGroup);
    // const clients = updateGroup.map((g) => g.chatGroupsClients);
    const clients = updateGroup.chatGroupsClients.map((o) => o.client);
    // console.log('clients ', clients);
    // console.log('fk2 data after selecting ', updateGroup);
    setData((old) => ({
      ...initialData,
      coach: updateGroup.nutritionist,
      groupName: updateGroup.groupName,
      groupChatType: updateGroup.groupChatType,
      clients: clients,
      id: updateGroup.id,
      locked: updateGroup.locked
    }));
  };
  const selectCoach = (id) => {
    // alert(`set coach ${id}`);
    if (data.locked)
      return window.alert("You can't change the Coach since the group has a paid client.");
    const selected = tablesData.nutritionists.filter((o) => o.id == id);
    setData((old) => ({
      ...old,
      coach: selected[0]
    }));
  };
  const selectClient = (id) => {
    console.log(tablesData);

    const clientIds = data.clients.map((o) => o.id);
    const selected = tablesData.clients.filter((o) => o.id == id)[0];

    const limit = groupChatTypes[data.groupChatType].capacity;

    if (clientIds.includes(selected.id)) {
      return;
    }

    if (data.clients.length >= limit) {
      return alert('group is full');
    }
    if (groupChatTypes[selected.coachPreference.groupChatType].capacity < limit) {
      return alert('client you are trying to add has selected group chat plan with a smaller size');
    }
    const newClients = data.clients;
    newClients.push(selected);
    setData((old) => ({
      ...old,
      clients: newClients
    }));
  };

  return (
    <div>
      <ConfirmationDialog
        title="Delete Group"
        text="Are you sure you want to delete this Group?"
        onClose={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
        onSubmit={deleteGroup}
      />
      <form noValidate onSubmit={submitForm}>
        <div style={{ margin: '20px', border: '1px solid yellow', borderRadius: '20px' }}>
          <div className={styles.cardsGeneralContainer}>
            <div>
              <Grid className={styles.fieldsBlock_row} container spacing={2}>
                <label>Group Name</label>
                <TextField
                  error={errorExist('groupName')}
                  helperText={errors?.groupName}
                  variant="outlined"
                  className={styles.inputField}
                  onChange={onChange}
                  value={data.groupName}
                  required
                  fullWidth
                  id="groupName"
                  placeholder="Group name"
                  label=""
                  name="groupName"
                  autoComplete="groupName"
                />
                <label>Group type</label>
                <Select
                  name="groupChatType"
                  fullWidth
                  variant="outlined"
                  value={data.groupChatType || ''}
                  onChange={onChange}
                >
                  {Object.entries(groupChatTypes).map(([key, value]) => {
                    if (value.type != 'NONE')
                      return (
                        <MenuItem value={value.type} key={value.type}>
                          {value.desc}
                        </MenuItem>
                      );
                  })}
                </Select>
              </Grid>
            </div>
            <div>
              <label>Group Coach</label>
              <div style={{ display: 'flex' }}>
                {data.coach && (
                  <div className={styles.memberContainer}>
                    {data.coach.firstName + ' ' + data.coach.lastName}
                    <IconButton
                      style={{ color: !data.locked ? 'red' : 'gray' }}
                      onClick={() =>
                        !data.locked
                          ? deleteCoach()
                          : window.alert(
                              "You can't remove the Coach since the group has a paid client."
                            )
                      }
                    >
                      <Icon className="fa fa-times" />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.cardsGeneralContainer}>
            <div>
              <label>group clients</label>
              <div style={{ display: 'flex' }}>
                {data.clients.length > 0 &&
                  data.clients.map((client) => (
                    <div className={styles.memberContainer}>
                      {client?.user.name} (
                      {groupChatTypes[client.coachPreference.groupChatType].capacity})
                      <IconButton
                        style={{ color: !client.locked ? 'red' : 'gray' }}
                        onClick={() =>
                          !client.locked
                            ? deleteMember(client.id)
                            : window.alert(
                                "You can't remove the Client since he/she has paid for his/her membership."
                              )
                        }
                      >
                        <Icon className="fa fa-times" />
                      </IconButton>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className={styles.buttonsContainer}>
                <button
                  className={styles.profileButton}
                  onClick={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                >
                  <p className={styles.socialButtonText}>Save group</p>
                </button>
                {!data.locked && (
                  <button
                    className={`${styles.preferencesButton} ${styles.profileButton}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setDeleteDialogOpen(true);
                    }}
                  >
                    <p className={styles.socialButtonText}>Delete group</p>
                  </button>
                )}
                <button
                  className={`${styles.preferencesButton} ${styles.profileButton}`}
                  onClick={(e) => {
                    e.preventDefault();
                    clearData();
                  }}
                >
                  <p className={styles.socialButtonText}>Clear</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {tablesData && (
        <div className={styles.cardsGeneralContainer}>
          <GroupsTable groups={tablesData.groups} selectGroup={selectGroup} />
          <CoachesTable coaches={tablesData.nutritionists} selectCoach={selectCoach} />
          <ClientsTable clients={tablesData.clients} selectClient={selectClient} />
        </div>
      )}
    </div>
  );
};

export default AdminGroupChat;

import React from 'react';
import { PubNubProvider } from 'pubnub-react';
import PubNub from 'pubnub';

import ChatContainer from '../chat/ChatContainer';
import config from '../../config';

function Chat({ user }) {
  console.log(9, user);
  const pubnubConfig = {
    ...config.pubnubKeys,
    uuid: user.id,
    profileUrl: user.image
  };
  const pubnubInstance = new PubNub(pubnubConfig);

  return (
    <PubNubProvider client={pubnubInstance}>
      <ChatContainer userModerate={user} isAdmin={true} />
    </PubNubProvider>
  );
}

export default Chat;

import React from 'react';

import { Grid, TextField } from '@material-ui/core';

const FieldText = ({
  title,
  handleChange,
  rows,
  value,
  name,
  placeholder,
  errorExist,
  errors,
  styles,
  maxLength
}) => {
  return (
    <div className={styles.fieldsBlock}>
      <Grid className={styles.fieldsBlock_column} container spacing={2}>
        <Grid item xs={12}>
          <label>{title}</label>
          <TextField
            value={value}
            error={errorExist && errorExist(name)}
            helperText={errors[name]}
            variant="outlined"
            className={styles.inputField}
            multiline
            onChange={handleChange}
            required
            fullWidth
            rows={rows}
            id={name}
            placeholder={placeholder}
            inputProps={{ maxLength }}
            label=""
            name={name}
            autoComplete={name}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FieldText;

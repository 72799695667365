import mutations from '../mutations';

const initialState = { data: [], forms: [], active: false, loaded: false};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.poll.loadPolls:
      return { ...state, data: action.payload, loaded: true };
    case mutations.poll.loadMySubmittions:
      return {
        ...state,
        mySubmittions: action.payload
      };
    case mutations.poll.loadForms:
      return {
        ...state,
        forms: action.payload
      };
    case mutations.poll.disable:
      return {
        ...state,
        active: false
      };
    case mutations.poll.activate:
      return {
        ...state,
        active: true
      };
    default:
      return state;
  }
};

export default reducer;

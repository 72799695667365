import { nutritionistActions, chatActions } from '../../../redux/actions';

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import PendingClientsTable from './pendingClientsTable';
import { clientStatuses } from '../../../constants';
import client from '../../../redux/actions/client';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const columns = [
  {
    mapId: 1,
    id: 'user',
    label: 'Name',
    format: (value) => value.name
  },
  {
    mapId: 2,
    id: 'birthdate',
    label: 'Birthdate',
    format: (value) => moment(value).format('MM.DD.YYYY')
  },
  {
    mapId: 3,
    id: 'user',
    label: 'Email',
    format: (value) => value.email
  },
  {
    mapId: 4,
    id: 'subscription',
    label: 'Subscription Status',
    format: (sub) => {
      if (!sub) {
        return 'No subscription';
      }
      let statusText = capitalizeFirstLetter(sub.status);
      if (sub.cancel_at) {
        statusText = `Plan is active untill ${moment(sub.cancel_at * 1000).format('LL')}`;
      }

      return statusText;
    }
  },
  {
    mapId: 7,
    id: 'subscription',
    label:'Specialties Title',
    format: (sub) => {
      if (!sub) {
        return 'No subscription';
      }
      let extraText = capitalizeFirstLetter(sub.extraSpecialtiesTitle);     

      return extraText;
    }

  },
  {
    mapId: 6,
    id: 'id',
    label: ''
  }
];

export default function ClientsTable() {
  const history = useHistory();
  const clients = useSelector((state) => state.nutritionist.clients);
  const { id } = useSelector((state) => state.user.nutritionist);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const properClientsArr = useMemo(() => {
    return clients.filter(
      (client) => client.nutritionistClients.status === clientStatuses.APPROVED
    );
  }, [clients]);

  var table = useMemo(() => {
    return properClientsArr.slice(page * pageSize, (page + 1) * pageSize);
  }, [properClientsArr, pageSize, page]);

  useEffect(() => {
    nutritionistActions.loadClients(); 
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const onOpenChat = async (clientId) => {
    await chatActions.initChat({ nutritionistId: id, clientId });
    history.push({
      pathname: `/nutritionist/chat`
    });
  };


  //fk2 
  const [showHide, setShowHide] = useState(false);
  const hideUnhideClient = async (value, hide) => {
    const data = await nutritionistActions.changeClientVisibility({nutritionistId: id, clientId: value, hide: hide});
    history.go(0); 
  }

  table = useMemo(() => {
    if (!showHide) {
      return clients.filter(
        (client) => client.nutritionistClients.isHidden == false
      );
    }
    else {return clients}
    
  }, [properClientsArr, showHide]);

  return (
    <>
      <div style={{ marginTop: 30 }}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography
            style={{
              fontFamily: 'Bebas Neue',
              letterSpacing: '2.5px',
              fontSize: '2.5rem',
              color: '#EE79B3'
            }}
            component="h1"
            variant="h5"
          >
            CLIENTS
          </Typography>
        </div>
      </div>

      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.mapId}>{column.label}</TableCell>
                ))}
                <TableCell
                onClick={() => setShowHide((prev) => !prev)}
                 key='options'>{showHide ? 'Hide inactive' : 'Show inactive'}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.id}
                  >
                    {columns.map((column) => {
                      const value = column.format ? column.format(row[column.id]) : row[column.id];
                      if (column.label === 'Name')
                        return <TableCell
                        onClick={() => {
                          history.push(`/nutritionist/clients/${row.id}`);
                        }}
                         key={column.mapId}>{value}</TableCell>;
                      if (column.id === 'id') {
                        return (
                          <TableCell>
                            <Button
                              onClick={() => onOpenChat(value)}
                              style={{ background: '#424242', color: '#EE79B3' }}
                              variant="contained"
                            >
                              Open chat
                            </Button>
                          </TableCell>
                        );
                      }
                      return <TableCell 
                      onClick={() => {
                        history.push(`/nutritionist/clients/${row.id}`);
                      }}
                       key={column.mapId}>{value}</TableCell>;
                    })}
                    <TableCell>
                      <Button
                        onClick={() => hideUnhideClient(row.id, !row.nutritionistClients.isHidden)}
                        style={{ background: '#424242', color: '#EE79B3' }}
                        variant="contained"
                      >
                        {row.nutritionistClients.isHidden ? 'Unhide' : 'Hide'}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={properClientsArr.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>

      <PendingClientsTable />
    </>
  );
}

import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { loadPolls, loadMySubmittions, disable, activate } from '../../redux/actions/poll';
import ModalCard from '../formModal/ModalCard';
import styles from './index.module.scss';
import { Dialog, Icon } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import config from '../../config';
import { capitalizeFirstLetter } from '../../utils/textHelper';

const FormsList = () => {
    const polls = useSelector((state) => state.polls.data);
    const history = useHistory();
    useEffect(async () => {
        await loadPolls().then(() => console.log("then polls", JSON.stringify(polls)));
    }, []);

    const pollsHistory = useSelector((state) => state.polls.mySubmittions);
    useEffect(async () => {
        await loadMySubmittions().then(() => console.log("then polls history", pollsHistory));
    },[]);

    return (
        <div>
            <h1 className='jss9'>Forms</h1>
            <p>Please fill out the forms below and submit to your coach.</p>
            <hr />
            {polls.map(poll => {
                const formName = poll["formUrl"].split("/")
                const link = config.domain + '/client/form/' + poll["id"]
                return(
                    <div onClick={() => history.push(`/client/form/${poll["id"]}`)}>
                        <a
                            href={link}
                        >
                            <div className={styles.formsListItem}>{capitalizeFirstLetter(formName.at(-1))}</div>
                        </a>
                    </div>
                );
            })}
            {pollsHistory && (
                <h1 className='jss9'>My submissions</h1>
            )}
            <hr />
            {pollsHistory && pollsHistory.map(poll => {
                // const formName = poll["formUrl"].split("/")
                const submittion = poll.form.data;
                const link = config.domain + '/client/submission/' + submittion._id;
                return(
                    <div onClick={() => history.push(`/client/submission/${submittion._id}`)}>
                        <a
                            href={link}
                        >
                            <div className={styles.formsListItem}>{submittion.title}</div>
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export default FormsList;
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import 'react-dropdown/style.css';

import { clientActions } from '../../../redux/actions';
import CoachCardComponent from '../nutritionistsList/CoachCardComponent';
import { clientStatuses } from '../../../constants';

const MyNutritionists = () => {
  const nutritionists = useSelector((state) => state.client.myNutritionists);
  console.log(nutritionists);

  const getNutritionists = async () => {
    await clientActions.getMyNutritionists();
  };

  useEffect(() => {
    getNutritionists();
  }, []);

  const myNutritionists = useMemo(() => {
    return nutritionists.filter(
      (nutritionist) => nutritionist.nutritionistClients.status === clientStatuses.APPROVED
    );
  }, [nutritionists]);

  const pendingNutritionists = useMemo(() => {
    return nutritionists.filter(
      (nutritionist) => nutritionist.nutritionistClients.status === clientStatuses.PENDING
    );
  }, [nutritionists]);

  const rejectedNutritionists = useMemo(() => {
    return nutritionists.filter(
      (nutritionist) => nutritionist.nutritionistClients.status === clientStatuses.REJECTED
    );
  }, [nutritionists]);

  return (
    <>
      {nutritionists.length === 0 && (
        <div
          style={{
            marginTop: '100px',
            fontSize: '3.5rem',
            fontFamily: 'Bebas Neue',
            letterSpacing: '2.5px',
            color: '#c4eb37',
            textAlign: 'center'
          }}
        >
          Sorry, but you haven't applied to any of the coaches
        </div>
      )}
      {myNutritionists.length > 0 && (
        <>
          <div
            style={{
              fontSize: '4rem',
              fontFamily: 'Bebas Neue',
              letterSpacing: '2.5px',
              color: '#c4eb37'
            }}
          >
            Current coaches
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'repeat(1, 1fr)',
              gap: '2rem'
            }}
          >
            {myNutritionists.map((item, i) => {
              return <CoachCardComponent item={item} i={i} />;
            })}
          </div>
        </>
      )}

      {pendingNutritionists.length > 0 && (
        <>
          <div
            style={{
              fontSize: '4rem',
              fontFamily: 'Bebas Neue',
              letterSpacing: '2.5px',
              color: '#c4eb37'
            }}
          >
            Pending coaches
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'repeat(1, 1fr)',
              gap: '2rem'
            }}
          >
            {pendingNutritionists.map((item, i) => {
              return <CoachCardComponent item={item} i={i} />;
            })}
          </div>
        </>
      )}

      {rejectedNutritionists.length > 0 && (
        <>
          <div
            style={{
              fontSize: '4rem',
              fontFamily: 'Bebas Neue',
              letterSpacing: '2.5px',
              color: '#c4eb37'
            }}
          >
            Unaproved coaches
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: 'repeat(1, 1fr)',
              gap: '2rem'
            }}
          >
            {rejectedNutritionists.map((item, i) => {
              return <CoachCardComponent item={item} i={i} />;
            })}
          </div>
        </>
      )}
    </>
  );
};

export default MyNutritionists;

import React, { useState, useEffect, useMemo } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Typography,
  TextField,
  Collapse,
  Icon
} from '@material-ui/core';

import { filterTable } from '../../../utils/helper';
import { adminActions } from '../../../redux/actions';
import ChangeRequest from '../../../components/changeRequest';

const columns = [
  {
    id: 'nutritionist.firstName',
    label: 'First name'
  },
  {
    id: 'nutritionist.lastName',
    label: 'Last name'
  },
  {
    id: 'nutritionist.email',
    label: 'Email'
  },
  {
    id: 'createdAt',
    label: 'Date created',
    format: (value) => moment(value).format('MM.DD.YYYY')
  }
];

export default function () {
  const [expandedIndex, setExpandedIndex] = React.useState(null);
  const nutritionists = useSelector((state) => state.admin.changeRequests);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    adminActions.loadNutritionistsChanges();
  }, []);

  const table = useMemo(() => {
    const filterFields = ['nutritionist.firstName', 'nutritionist.lastName', 'nutritionist.email'];
    const result = filterTable(nutritionists, filterFields, search);
    return result.slice(page * pageSize, (page + 1) * pageSize);
  }, [nutritionists, pageSize, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleSearch = _.debounce((e) => {
    const { value } = e.target;
    setSearch(value);
  }, 500);

  const handleReject = async (row) => {
    await adminActions.rejectChanges(row);
    await adminActions.loadNutritionistsChanges();
  };

  const handleAccept = async (row) => {
    await adminActions.acceptChanges(row);
    await adminActions.loadNutritionistsChanges();
  };

  const toogleExpand = (i) => {
    if (i === expandedIndex) setExpandedIndex(null);
    else setExpandedIndex(i);
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div className="d-flex justify-content-between align-items-center">
        <Typography
          style={{
            fontFamily: 'Bebas Neue',
            color: '#EE79B3',
            fontSize: '2.5rem',
            letterSpacing: '2px'
          }}
          component="h1"
          variant="h5"
        >
          Change requests
        </Typography>

        <TextField
          className="my-2"
          variant="outlined"
          label="Search"
          name="search"
          onChange={handleSearch}
        />
      </div>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((column) => (
                  <TableCell
                    style={{ fontFamily: 'Bebas Neue', fontSize: '1.2rem' }}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row, i) => {
                return (
                  <>
                    <TableRow hover key={row.id} onClick={() => toogleExpand(i)}>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                        >
                          {expandedIndex === i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      {columns.map((column) => {
                        const value = column.format
                          ? column.format(_.get(row, column.id))
                          : _.get(row, column.id);
                        return <TableCell key={column.id}>{value}</TableCell>;
                      })}
                      <TableCell align="right" size="small" style={{ width: 140 }}>
                        <IconButton onClick={() => handleReject(row)}>
                          <Icon className="fa fa-times" />
                        </IconButton>
                        <IconButton onClick={() => handleAccept(row)}>
                          <Icon className="fa fa-check" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={expandedIndex === i} timeout="auto" unmountOnExit>
                          <ChangeRequest
                            nutritionist={row.nutritionist}
                            updates={row.nutritionist.nutritionistChangeRequest.updates}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          style={{ color: '#c4eb37' }}
          count={nutritionists.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}

import React, { useState } from 'react';
import { Container, Button } from '@material-ui/core';

import styles from './landing.module.scss';
import logo from '../../assets/img/logo.svg';
import appStore from '../../assets/img/appstore-white.svg';
import facebook from '../../assets/img/icons/facebook.svg';
import twitter from '../../assets/img/icons/twitter.svg';
import instagram from '../../assets/img/icons/instagram.svg';

import googlePlayImage from '../../assets/img/google-play.svg';

import { publicActions } from '../../redux/actions';
import { isEmail } from '../../utils/helper';

export default function () {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  const changeEmail = (e) => {
    const { value } = e.target;
    setMessage(null);
    setEmail(value);
  };

  const subscribe = async () => {
    if (!isEmail(email)) {
      return setMessage('Email is not valid.');
    }

    await publicActions.subscribeEmail({ email });
    setMessage('You have successfully subscribed');
    setEmail('');
  };

  return (
    <div className={styles.footer}>
      <Container>
        <div className={styles.footerContent}>
          <div className={styles.footerTop}>
            <div>
              <img src={logo} className={styles.logo} />
              <div className={styles.footerDescription}>Join our mailing list</div>
            </div>
            <div>
              <a
                href="https://apps.apple.com/app/planibble/id1593873040"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.logoMobile}
              >
                <img
                  style={{ height: '42px', marginRight: '10px' }}
                  src={appStore}
                  className={styles.logo}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.planibble"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.logoMobile}
              >
                <img src={googlePlayImage} className={styles.logo} />
              </a>
            </div>
          </div>
          <div>
            <input
              type="email"
              placeholder="Email"
              className={styles.FooterInput}
              value={email}
              onChange={changeEmail}
            />
            <Button
              variant="contained"
              className={styles.footerSubscribeButton}
              onClick={subscribe}
            >
              Subscribe
            </Button>
            <p>{message}</p>
          </div>
          <div className={styles.footerIcons}>
            <a href="https://www.facebook.com/planibble">
              <img src={facebook} className={styles.logo} />
            </a>
            <a href="https://twitter.com/planibble">
              <img src={twitter} className={styles.logo} />
            </a>
            <a href="https://www.instagram.com/planibble/">
              <img src={instagram} className={styles.logo} />
            </a>
          </div>
          <div className={styles.footerBottom}>
            <div>© 2021 Planibble. All Rights Reserved</div>
            <div className={styles.footerLinks}>
              <a href="/faq">FAQ</a>
              <a
                target="_blank"
                href="https://app.termly.io/document/privacy-policy/67df39f4-5867-4d20-994b-63634d2b6fb8"
              >
                Privacy Policy
              </a>
              <a href="/support">Contact Us</a>
              <a
                target="_blank"
                href="https://app.termly.io/document/terms-of-use-for-online-marketplace/5d502556-50f4-4a69-a286-75d605182759"
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import styles from './landing.module.scss';

import { publicActions } from '../../redux/actions';
import { isEmail } from '../../utils/helper';

export default function () {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);

  const changeEmail = (e) => {
    const { value } = e.target;
    setMessage(null);
    setEmail(value);
  };

  const subscribe = async () => {
    if (!isEmail(email)) {
      return setMessage('Email is not valid.');
    }

    await publicActions.subscribeEmail({ email });
    setMessage('You have successfully subscribed');
    setEmail('');
  };

  return (
    <div className={styles.stayUpToDateBlock}>
      <div className={styles.stayUpToDateContent}>
        <div className={styles.stayUpToDateTitle}>STAY UP TO DATE</div>
        <div className={styles.stayUpToDateDescription}>
          Enter your email for special offers, news, and more from Planibble
        </div>
        <div className={styles.stayUpToDateInputBlock}>
          <input
            type="email"
            placeholder="Email"
            className={styles.stayUpToDateInput}
            value={email}
            onChange={changeEmail}
          />
          <Button
            variant="contained"
            className={styles.downloadNowDownloadButton}
            onClick={subscribe}
          >
            Subscribe
          </Button>
        </div>
        <p className={styles.message}>{message}</p>
        <div className={styles.stayUpToDateNote}>
          
        </div>
      </div>
    </div>
  );
}

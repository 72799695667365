import store from '../store';
import mutations from '../mutations';
import { userAxios } from '../../utils/axios';
import _ from 'lodash';

const loadUser = async () => {
  const { data } = await userAxios.get('/user');
  store.dispatch({
    type: mutations.user.set,
    payload: data
  });
};

const connectUsers = async (nutritionistId, clientId) => {
  const { data } = await userAxios.post('/user/connect-users', {
    nutritionistId,
    clientId
  });

  if (data) {
    store.dispatch({
      type: mutations.user.changeClientStatus,
      payload: { ...data }
    });
  }
};

const getHiredUsers = async (nutritionistId) => {
  const { data } = await userAxios.post('/user/get-hired-users', {
    nutritionistId
  });
  store.dispatch({
    type: mutations.nutritionist.loadChatClients,
    payload: data
  });
};

const setUserName = async (name) => {
  return await userAxios.put('/user/name', { name });
};

const setUserImage = async (image) => {
  const formData = new FormData();
  formData.append('image', image);
  return userAxios.post('/user/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const verifyRecaptcha = async (token, email) => {
  return await userAxios.post('/recaptcha',{ token, email });
};

export default {
  setUserImage,
  loadUser,
  connectUsers,
  getHiredUsers,
  setUserName,
  verifyRecaptcha
};

const howDidFind = [
  {value:  'Current Employee',
    label:   'Current Employee',
  },
  {value:    'Newspaper Ad',
    label:     'Newspaper Ad',
  },
  {value:    'Radio/TV Ad',
    label:     'Radio/TV Ad',
  },
  {value:   'Search Engine',
    label:    'Search Engine'
  },
];

export default howDidFind;
import React, { useEffect, useState } from 'react';
import CardList from './CardList';
import config from '../../../config';
import SubmissionsTable from './SubmissionsTable';
import PendingTable from './PendingTable';
import SubmissionsModal from '../../../components/SubmissionPreviewModal/SubmissionModal';
import { loadNutritionistPendingSubmissions, loadNutritionistSubmissions } from '../../../redux/actions/poll';

const Submissions = () => {
  const [submissions, setSubmissions] = useState(null);
  const [selectedForm, setSelectedForm] = useState('');
  const formioNutritionist = config.formio.nutritionist;
  const [step, setStep] = useState(0);
  const [pendingSubmissions, setPendingSubmissions] = useState([]);
  const [openSubModal, setOpenSubModal] = useState(false);
  const [subData, setSubData] = useState({});

  useEffect(async () => {
    if (selectedForm !== '') {
      setStep(1);
      const submissions = await loadNutritionistSubmissions(selectedForm);
      setSubmissions(submissions);
      const pendingSubmissions = await loadNutritionistPendingSubmissions(selectedForm);
      setPendingSubmissions(pendingSubmissions);
    } else {
      setStep(0);
    }
  }, [selectedForm]);

  const Table = () => {
    if (submissions === null) {
      return '';
    }
    if (selectedForm) {
      return (
        <>
          <SubmissionsTable
            data={submissions}
            setSubData={setSubData}
            setOpenSubModal={setOpenSubModal}
          />
          <PendingTable data={pendingSubmissions} />
        </>
      );
    }
    return '';
  };
  return (
    <>
      <CardList
        selectedCard={selectedForm}
        setSelectedCard={setSelectedForm}
        formsUrl={formioNutritionist}
        title="Submissions"
        activeStep={step}
      />
      <Table />
      <SubmissionsModal
        open={openSubModal}
        data={subData}
        onClose={() => {
          setOpenSubModal(false);
        }}
        selectedForm={selectedForm}
      />
    </>
  );
};

export default Submissions;

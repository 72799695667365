import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, Grid, Input, Icon, Link } from '@material-ui/core';
import config from '../../../config';

const InputFile = ({
  title,
  styles,
  name,
  value,
  initialFileName,
  handleChange,
  errors,
  errorExist,
  handleDeleteFile,
  field,
  id
}) => {
  const [isUrl, setIsUrl] = useState(false);

  useEffect(() => {
    try {
      new URL(initialFileName);
      setIsUrl(true);
    } catch (e) {
      setIsUrl(false);
    }
  }, [initialFileName]);

  return (
    <>
      {!initialFileName ? (
        <div className={styles.fieldsBlock}>
          <Grid className={styles.fieldsBlock_column} container>
            <FormControl error={errorExist(field)}>
              <label>{title}</label>
              <Input
                id={id}
                className={styles.fieldsBlock_column_fileInput}
                value={value}
                name={name}
                onChange={handleChange}
                type="file"
              />
              <FormHelperText className={styles.myHelperText}>
                {errors[field] && errors[field]}
              </FormHelperText>
            </FormControl>
          </Grid>
        </div>
      ) : (
        <div className={styles.fieldsBlock}>
          <Grid className={styles.fieldsBlock_column} container>
            <label>{title}</label>
            <div className="d-flex align-items-center">
              {isUrl ? (
                <Link target="_blank" href={initialFileName}>
                  {initialFileName}{' '}
                  <Icon style={{ marginLeft: '5px' }} className="fa fa-eye" />
                </Link>
              ) : (
                initialFileName
              )}
              <span onClick={() => handleDeleteFile(field)}>
                <Icon style={{ marginLeft: '5px' }} className="fa fa-remove" />
              </span>
            </div>
          </Grid>
        </div>
      )}
    </>
  );
};

export default InputFile;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { usePubNub } from 'pubnub-react';
import { useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Button, IconButton, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import config from '../config/index';

import { appActions, authActions, chatActions, userActions } from './../redux/actions';
import logo from './../assets/img/logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  rightButton: {
    marginLeft: 'auto'
  },
  header: {
    backgroundColor: '#454642'
  }
}));

export default function ButtonAppBar() {
  const history = useHistory();
  const classes = useStyles();

  const { userType } = useSelector((state) => state.auth);
  const nutritionist = useSelector((state) => state.nutritionist);
  const user = useSelector((state) => state.user);

  // const pubnubConfig = {
  //   restore: true,
  //   heartbeatInterval: 0,
  //   ...config.pubnubKeys,
  //   uuid: user ? user.id : "Admin",
  //   profileUrl: user ? user.image : ''
  // };

  // const pubnub = usePubNub();

  // const channels = useSelector((state) => state.chat.channels)
  // const timetokens = useSelector((state) => state.chat.timetokens)

  // let intervalSet = false

  const handleToogle = () => {
    appActions.toogleMenu();
  };

  const handleLogout = () => {
    authActions.logout();
    history.push('/redirect');
  };

  // useEffect(() => {
  //   // if (channels.length && timetokens.length && pubnub && !intervalSet) {
  //   // chatActions.getChannelsUnreadInfo(pubnub, channels, timetokens);
  //   //   console.log(channels, timetokens);
  //   //   intervalSet = true
  //   //   setInterval(() => {
  //   //     // chatActions.getTimetokens(pubnub, user.id)
  //   //     chatActions.getChannelsUnreadInfo(pubnub, channels, timetokens);
  //   //   }, 5000);
  //   // }
  // }, [channels, timetokens]);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} onClick={handleToogle}>
            <MenuIcon />
          </IconButton>
          <a href="/redirect">
            <img src={logo} />
          </a>

          <Button color="primary" onClick={handleLogout} className={classes.rightButton}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React, { useEffect } from 'react';
import DownloadNowBlock from './DownloadNowBlock';

import Header from './Header';
import OwnYourBodyBlock from './OwnYourBodyBlock';
import WeUnderstandBlock from './WeUnderstandBlock';
import YourPesonalToolkitBlock from './YourPesonalToolkitBlock';
import StayUpToDateBlock from './StayUpToDateBlock';
import Footer from './Footer';

const LandingPage = () => {
  return (
    <>
      <Header />
      <WeUnderstandBlock />
      <OwnYourBodyBlock />
      <YourPesonalToolkitBlock />
      <DownloadNowBlock />
      <StayUpToDateBlock />
      <Footer />
    </>
  );
};

export default LandingPage;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { nutritionistStatuses, clientStatuses } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10
  }
}));

export default function NutritionistStepper() {
  const classes = useStyles();
  const nutritionist = useSelector((state) => state.user.nutritionist);
  const clients = useSelector((state) => state.nutritionist.clients);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Get an admin approval', 'Connect you bank account', 'Get your first client'];

  useEffect(() => {
    let step = 0;

    if (nutritionist.nutritionistStatus.status === nutritionistStatuses.APPROVED) {
      step = 1;

      if (nutritionist.stripeAccount?.submitted) {
        step = 2;

        const clientExist = clients.find(
          (client) => client.nutritionistClients.status === clientStatuses.APPROVED
        );

        if (clientExist) {
          step = 3;
        }
      }
    }

    setActiveStep(step);
  }, [nutritionist, clients]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

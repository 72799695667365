import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { userTypes, nutritionistStatuses, clientAccountStatuses } from '../constants';

const PrivateRoute = (props) => {
  const { component: Component, path, exact, userType: routeUserType } = props;
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const { isLogged, userType } = auth;

  if (!isLogged || userType !== routeUserType) {
    return <Redirect to="/redirect" />;
  }

  if (userType === userTypes.nutritionist && user.nutritionist) {
    if (user.nutritionist.nutritionistStatus.status === nutritionistStatuses.REJECTED) {
      return <Redirect to="/nutritionist/status/reject" />;
    }
    if (user.nutritionist.nutritionistStatus.status === nutritionistStatuses.PENDING) {
      return <Redirect to="/nutritionist/status/pending" />;
    }
    if (user.nutritionist.nutritionistStatus.status === nutritionistStatuses.SUSPENDED) {
      return <Redirect to="/nutritionist/status/suspended" />;
    }
  }

  if (userType === userTypes.client && user.client) {
    if (user.client.status === clientAccountStatuses.SUSPENDED) {
      return <Redirect to="/client/status/suspended" />;
    }
  }

  if (userType === userTypes.nutritionist && !user.nutritionist) {
    return <Redirect to="/nutritionist" />;
  }

  if (userType === userTypes.client && !user.client) {
    return <Redirect to="/client-onboarding" />;
  }

  return <Route path={path} component={Component} exact={exact} />;
};

export default PrivateRoute;

import React, { useEffect } from 'react';
// import DownloadNowBlock from './DownloadNowBlock';

import Header from '../landingPage/Header';
// import OwnYourBodyBlock from './OwnYourBodyBlock';
// import WeUnderstandBlock from './WeUnderstandBlock';
// import YourPesonalToolkitBlock from './YourPesonalToolkitBlock';
// import StayUpToDateBlock from './StayUpToDateBlock';
import SupportBlock from './SupportBlock';
import Footer from '../landingPage/Footer';
import { ImportantDevices } from '@material-ui/icons';

const PrivacyPage = () => {
  return (
    <>
      <Header />
        <SupportBlock />
      <Footer />
    </>
  );
};

export default PrivacyPage;
import { adminActions } from '../../../redux/actions';

import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField
} from '@material-ui/core';

import { filterTable } from '../../../utils/helper';
import Checkbox from '@material-ui/core/Checkbox';

const columns = [
  {
    id: 'nutritionist.firstName',
    label: 'First name'
  },
  {
    id: 'nutritionist.lastName',
    label: 'Last name'
  },
  {
    id: 'nutritionist.email',
    label: 'Email'
  },
  {
    id: 'nutritionist.state',
    label: 'State'
  },
  {
    id: 'nutritionist.certifications',
    label: 'Certifications',
    format: (value) => {
      return value.join(', ');
    }
  },
  {
    id: 'createdAt',
    label: 'Date created',
    format: (value) => moment(value).format('MM.DD.YYYY')
  }
];

const filterFields = [
  'nutritionist.firstName',
  'nutritionist.lastName',
  'nutritionist.email',
  'nutritionist.certifications'
];

export default function ({
  selectedNutritionists,
  setSelectedNutritionists,
  selectAll,
  setSelectAll,
}) {
  const nutritionists = useSelector((state) => state.admin.nutritionists);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    adminActions.loadNutritionists();
  }, []);

  const table = useMemo(() => {
    const result = filterTable(nutritionists, filterFields, search);
    return result.slice(page * pageSize, (page + 1) * pageSize);
  }, [nutritionists, pageSize, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCheckbox = (id) => {
    const find = selectedNutritionists.indexOf(id);
    if (find > -1) {
      selectedNutritionists.splice(find, 1);
    } else {
      selectedNutritionists.push(id);
    }
    setSelectedNutritionists([...selectedNutritionists]);
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedNutritionists(nutritionists.map((e) => e.id));
      setSelectAll(true);
    } else {
      setSelectedNutritionists([]);
      setSelectAll(false);
    }
  };
  return (
    <div style={{ marginTop: 30 }}>
      <div className="d-flex justify-content-between align-items-center">
        <Typography component="h1" variant="h5">
          Nutritionists
        </Typography>

        <TextField
          className="my-2"
          variant="outlined"
          label="Search"
          name="search"
          value={search}
          onChange={handleSearch}
        />
      </div>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  {' '}
                  Select all{' '}
                  <Checkbox  checked={selectAll} onChange={handleSelectAll} />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell key={row.id}>
                      <Checkbox
                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={selectedNutritionists.includes(row.id)}
                        onChange={() => {
                          handleCheckbox(row.id);
                        }}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = column.format
                        ? column.format(_.get(row, column.id))
                        : _.get(row, column.id);
                      if (column.id === 'nutritionist.firstName')
                        return (
                          <TableCell key={column.id}>
                            <Link
                              to={`/admin/nutritionists/${row.id}`}
                              style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        );
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={nutritionists.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}

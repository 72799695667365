import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePubNub } from 'pubnub-react';
import { Grid, Paper } from '@material-ui/core';

import ChatList from './ChatList';
import Chat from './Chat';
import { adminActions, chatActions } from '../../../redux/actions';
import Loader from '../../../components/Loader';

function ChatContainer() {
  const pubnub = usePubNub();
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const chats = useSelector((state) => state.chat.chats);
  const channelIds = useMemo(() => chats.map((chat) => chat.channelId), [chats]);

  const loadChatData = async () => {
    await adminActions.loadChatData();
    setIsLoaded(true);
  };

  const loadMessages = async (channelIds) => {
    await chatActions.loadMessages({ channels: channelIds, pubnub });
  };

  const handleMessage = async (event) => {
    const { file, message, publisher, timetoken, channel } = event;

    const newMessage = {
      //com.mco
      message: file ? { file, message } : message,
      // message: file ? { file } : message,
      userId: publisher === 'admin' ? 'admin' : parseInt(publisher, 10),
      date: new Date(parseInt(timetoken, 10) / 10000),
      //com.mco
      timetoken: timetoken,
      newMsg: true
    };

    //com.mco
    chatActions.setMessagesToChannel({ channelId: channel, messages: [newMessage] , newMsg: true});
  };

  //com.mco
  const loadFiles =  (channelIds) => {
    chatActions.loadFiles({ channels: channelIds, pubnub });
  };

  useEffect(() => {
    if (!_.isEmpty(channelIds)) {
      pubnub.subscribe({ channels: channelIds });
      loadMessages(channelIds);
      //com.mco
      loadFiles(channelIds);
    }
  }, [channelIds]);

  useEffect(() => {
    const interval = setInterval(() => {
      chatActions.loadUnreadChannelInfo({ userId: 'admin', channels: channelIds, pubnub });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [channelIds]);

  useEffect(() => {
    loadChatData();

    const messageListener = {
      message: handleMessage,
      file: handleMessage
    };
    pubnub.addListener(messageListener);

    return () => {
      pubnub.removeListener(messageListener);
    };
  }, []);

  if (!isLoaded) {
    return <Loader />;
  } else {
    return (
      <div style={{ display: 'flex' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper style={{ height: '100%', overflow: 'auto' }}>
              <ChatList onSelect={setSelectedChat} />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            {selectedChat && <Chat chat={selectedChat} />}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ChatContainer;

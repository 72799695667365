import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './reducers';
import config from '../config'

const persistConfig = {
  key: `planibble/${config.environment}`,
  storage,
  whitelist: ['auth', 'user']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const isProduction = false;
const preloadedState = {};

const store = createStore(
  persistedReducer,
  preloadedState,
  isProduction ? compose(applyMiddleware()) : composeWithDevTools(applyMiddleware())
);

export const persistor = persistStore(store);
export default store;

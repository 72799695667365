import React from 'react';

import styles from './Index.module.scss';
import { Button } from '@material-ui/core';

const SuspendedView = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Your account is suspended. Contact administrator for details</h1>
      <a href="mailto: admin@plannible.com">
        <Button color="primary" variant="contained" className={styles.contactButton}>
          Contact via email
        </Button>
      </a>
    </div>
  );
};

export default SuspendedView;
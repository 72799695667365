import clientActions from '../../../redux/actions/client';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CoachCardComponent from '../nutritionistsList/CoachCardComponent';
const Bookmarks = () => {
  const savedNutritionists = useSelector((state) => state.client.savedNutritionists);

  useEffect(() => {
    clientActions.getSavedNutritionists();
  }, []);

  return (
    <>
      <div
        style={{
          fontSize: '4rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '2.5px',
          color: '#c4eb37'
        }}
      >
        Saved coaches
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: 'repeat(1, 1fr)',
          gap: '2rem'
        }}
      >
        {savedNutritionists &&
          savedNutritionists.map((item, i) => {
            return <CoachCardComponent showSavedIcon={true} item={item} i={i} />;
          })}
      </div>
    </>
  );
};

export default Bookmarks;

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { authActions } from '../redux/actions';
import Loader from '../components/Loader';

// import { useSearchParams } from "react-router-dom";

const redirectRoutes = {
  admin: '/admin/dashboard',
  nutritionist: '/nutritionist/dashboard',
  client: '/client/dashboard'
};

const RedirectTo = (props) => {
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { isLogged, userType } = auth;

  console.log(props.match.params.url);

  const redirectUrl = props.match.params.url;
  const accessToken = props.match.params.accesstoken;


  const completeLogin = async (accessToken) => {
    await authActions.completeLogin(accessToken);
    history.push(`/${redirectUrl.replaceAll('-', '/')}`);
  };

    completeLogin(`Bearer ${accessToken}`);
    return <Loader />;
  

};

export default RedirectTo;

import store from '../store';
import mutations from '../mutations';
import { userAxios } from '../../utils/axios';

const subscribeEmail = async ({ email }) => {
  await userAxios.post('/email-subscribe', { email });
};

const loadFAQ = async ({ type }) => {
  const { data } = await userAxios.get('/faq', {
    params: { type }
  });

  store.dispatch({
    type: mutations.app.loadFAQ,
    payload: data
  });
};

const loadHomepageContent = async ({ type }) => {
  const params = {};
  if (type != 'all') params.type = type;
  const { data } = await userAxios.get('/homepagecontent', {
    params
  });
  return data;
};

export default {
  loadFAQ,
  subscribeEmail,
  loadHomepageContent
};

import auth0 from '../../utils/auth0';
import store from '../store';
import mutations from '../mutations';
import { guestAxios, initUserAxiosInstance, userAxios } from '../../utils/axios';
import { initPubnubInstance } from '../../utils/pubnub';

const logout = () => {
  store.dispatch({
    type: mutations.auth.logout
  });
};

const adminLogin = async ({ email, password }) => {
  try {
    const { data } = await guestAxios.post('/admin/auth/login', {
      email,
      password
    });
    const { token, userType } = data;

    initUserAxiosInstance({ token, logoutAction: logout });
    initPubnubInstance({});

    store.dispatch({
      type: mutations.auth.login,
      payload: { token, userType }
    });
  } catch (e) {
    return Promise.reject({ message: 'Login unsucessfull' });
  }
};

const completeLogin = async (accessToken) => {
  initUserAxiosInstance({ token: accessToken, logoutAction: logout });

  const { data } = await userAxios.get('/user');
  const { id, image, type } = data;

  initPubnubInstance({ userId: id, image });
  store.dispatch({
    type: mutations.user.set,
    payload: data
  });
  store.dispatch({
    type: mutations.auth.login,
    payload: { token: accessToken, userType: type }
  });

  return { userType: type };
};

const completeRegister = async ({ token, refKey, userType }) => {
  const { data } = await userAxios.post(
    '/register',
    {
      token,
      refKey,
      userType
    },
    {
      headers: {
        Authorization: token
      }
    }
  );

  const { success, message } = data;
  if (success) {
    return Promise.resolve();
  } else return Promise.reject({ message });
};

const loginSocial = async ({ type }) => {
  console.log(type);
  if (type === 1) {
    console.log('apple2');
    // return auth0.authorize({ connection: type, redirectUri: "https://dev-pszfgqxb.us.auth0.com/login/callback" });
    return auth0.authorize({ connection: 'apple', redirectUri: "https://www.planibble.com/redirect"});
  }
  else {
    return auth0.authorize({ connection: type });
  }
};

const registerSocial = async ({ type, userType, refKey }) => {
  let state = userType;
  if (refKey) {
    state += `-${refKey}`;
  }
  return auth0.authorize({
    connection: type,
    redirectUri: window.location.origin + '/register-redirect',
    state
  });
};

const loginManual = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    auth0.login(
      {
        email,
        password,
        realm: 'Username-Password-Authentication'
      },
      (err, result) => {
        console.log({ err });
        if (err) return reject({ message: 'Wrong email / password' });
        return resolve(result);
      }
    );
  });
};

const registerManual = ({ data, userType, refKey }) => {
  const { email, password } = data;

  return new Promise((resolve, reject) => {
    auth0.signup(
      {
        connection: 'Username-Password-Authentication',
        email,
        password
      },
      function (err, result) {
        if (err) {
          return reject({
            message: `${err.description}. This email is already registered. Please use another email address.`
          });
        } else {
          let state = userType;
          if (refKey) {
            state += `-${refKey}`;
          }
          auth0.login(
            {
              email,
              password,
              realm: 'Username-Password-Authentication',
              redirectUri: window.location.origin + '/register-redirect',
              state
            },
            (err, result) => {
              if (err) return reject({ message: err.error_description });
              return resolve(result);
            }
          );
        }
      }
    );
  });
};

const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    auth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email
      },
      (err, result) => {
        if (err) return reject({ message: err.error_description });
        return resolve(result);
      }
    );
  });
};

export default {
  admin: {
    login: adminLogin
  },
  loginSocial,
  registerSocial,
  registerManual,
  loginManual,
  completeLogin,
  completeRegister,
  resetPassword,
  logout
};

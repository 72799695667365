import _ from 'lodash';
import moment from 'moment';

import mutations from '../mutations';

const initialState = {
  clients: [],
  applications: [],
  nutritionists: [],
  changeRequests: [],
  statistic: {},
  reviews: [],
  extraSpecialties: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.admin.loadClients:
      return {
        ...state,
        clients: action.payload
      };
    case mutations.admin.loadReviewRequests:
      return {
        ...state,
        reviews: action.payload
      };
    case mutations.admin.loadNutritionists:
      return {
        ...state,
        nutritionists: action.payload
      };
    case mutations.admin.loadNutritionistChangeRequests:
      return {
        ...state,
        changeRequests: action.payload
      };
    case mutations.admin.loadNutritionistApplicants:
      return {
        ...state,
        applications: _.sortBy(action.payload, (i) => -moment(i.createdAt))
      };
    case mutations.admin.loadStats:
      return {
        ...state,
        statistic: action.payload
      };
    case mutations.admin.getAdminChats:
      return {
        ...state,
        adminChats: action.payload
      };
    case mutations.admin.loadNutritionistExtraSpecialties:
      return {
        ...state,
        extraSpecialties: action.payload
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import { useHistory } from 'react-router';

import OnboardingFormPreferences from '../../onboarding/OnboardingFormPreferences';
import styles from '../../onboarding/IntakeForm.module.scss';

const EditPreferences = ({}) => {
  const history = useHistory();

  return (
    <div className={styles.intakeForm}>
      <div className={styles.intakeForm_header}>
        <h4 className={styles.intakeForm_header_title}>Coach Preferences Form</h4>
        <span className={styles.intakeForm_header_description}>
          Please fill out all the required fields
        </span>
      </div>
      <div>
        <OnboardingFormPreferences onSubmit={() => history.goBack()} />
      </div>
    </div>
  );
};

export default EditPreferences;

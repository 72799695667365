import React, { useState, useMemo } from 'react';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';

const columns = [
  {
    id: 'name',
    label: 'Name',
  }
];

export default function SubmissionsTable({ data, setSubData, setOpenSubModal }) {
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const table = useMemo(() => {
    if (data) {
      return data.slice(page * pageSize, (page + 1) * pageSize);
    }
    return null;
  }, [data, pageSize, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const Row = ({ row }) => {
    console.log("row", row);
    return (
      <>
        <TableRow
          onClick={() => {
            setSubData(row);
            setOpenSubModal(true);
          }}
          hover
        >
          {columns.map((column) => {
            const value = column.format ? column.format(row[column.id]) : row[column.id];
            return <TableCell key={column.id}>{value}</TableCell>;
          })}
        </TableRow>
      </>
    );
  };

  if (data === null) {
    return(
      <h2
        style={{
          margin: '30px auto',
          width: '500px',
          fontSize: '4rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '2.5px',
          color: '#ee79b3',
          textAlign: 'center'
        }}
      >
        Loading...
      </h2>
    )
  }


  if (!data.length) {
    return (
      <h2
        style={{
          margin: '30px auto',
          width: '500px',
          fontSize: '4rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '2.5px',
          color: '#ee79b3',
          textAlign: 'center'
        }}
      >
        No submissions
      </h2>
    );
  }

  return (
    <>
      <h2
        style={{
          margin: '30px auto',
          width: '500px',
          fontSize: '4rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '2.5px',
          color: '#fff',
          textAlign: 'center'
        }}
      >
        Submissions
      </h2>
      <Paper style={{ marginTop: 30 }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return <Row row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={data.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </>
  );
}

import React from 'react';

import styles from './Index.module.scss';

const RejectedView = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Your request was rejected</h1>
    </div>
  );
};

export default RejectedView;

import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography, CircularProgress } from '@material-ui/core';
import _ from 'lodash';

import { nutritionistActions } from '../../../redux/actions';

const GetPaid = ({}) => {
  const [loaded, setLoaded] = useState(false);
  const [accountStatus, setAccountStatus] = useState(null);

  const loadStripeAcountStatus = async () => {
    const data = await nutritionistActions.loadStripeAccountStatus();
    setAccountStatus(data);
    setLoaded(true);
  };

  useEffect(() => {
    loadStripeAcountStatus();
  }, []);

  const handleOnboard = () => {
    const url = accountStatus.links.onboarding;
    if (url) window.open(url, '_blank');
  };

  const handleAccountPortal = () => {
    const url = accountStatus.links.login;
    if (url) window.open(url, '_blank');
  };

  return (
    <Card
      style={{
        marginTop: 10,
        minWidth: 400,
        maxWidth: 600,
        margin: 10
      }}
    >
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography gutterBottom variant="h6" style={{ color: '#c4eb37' }}>
          Get paid
        </Typography>

        {loaded && (
          <React.Fragment>
            {!accountStatus.onboarded && (
              <Button color="primary" variant="contained" onClick={handleOnboard}>
                Connect your bank account to get paid
              </Button>
            )}

            {!_.isEmpty(accountStatus.errors) && (
              <div style={{ marginTop: 10 }}>
                Your account is submitted with errors. Please login to your account and fill needed
                information.
                <ul>
                  {accountStatus.errors.map((e) => (
                    <li style={{ color: 'red' }}>{e}</li>
                  ))}
                </ul>
              </div>
            )}

            {accountStatus.onboarded && !accountStatus.submitted && (
              <>
                <Typography gutterBottom>
                  Please, go to "My bank account portal" and fill in all necessary information to
                  proceed with the bank account connection.
                </Typography>
                <Button color="primary" variant="contained" onClick={handleAccountPortal}>
                  My bank account portal
                </Button>
              </>
            )}
            {accountStatus.onboarded && accountStatus.submitted && (
              <>
                <Typography gutterBottom>Your bank account is submitted.</Typography>
                <Button color="primary" variant="contained" onClick={handleAccountPortal}>
                  Payout & My bank account portal
                </Button>
              </>
            )}
          </React.Fragment>
        )}

        {!loaded && (
          <React.Fragment>
            <CircularProgress style={{ margin: 'auto' }} />
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
};

export default GetPaid;

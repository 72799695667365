import React, { useEffect, useState } from 'react';

import { TextField, Grid, Button, MenuItem } from '@material-ui/core';

import states from '../../constants/usa-states';

import validationSchema from './firstFormValidation';
import ValidationService from '../../utils/validation-service';

import RadioButtonsGroup from '../common/RadioButtonsGroup';

import styles from './IntakeForm.module.scss';
import DoubleChoice from '../common/DoubleChoice';
import _ from 'lodash';
import genders from '../../constants/genders';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import FieldText from '../common/TextField';

const initialData = {
  firstName: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  phone: '',
  state: '',
  email: '',
  zipcode: '',
  hoursWeekly: '',
  canWorkAtNights: '',
  isOlder: '',
  gender: '',
  schedule: '',
  calendarUrl: ''
};

const FormSubmitStepFirst = ({ submitForm, nutritionistInitial = {}, email }) => {
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!_.isEmpty(nutritionistInitial)) {
      let dataUser = {};
      Object.entries(initialData).forEach(([key]) => {
        if (nutritionistInitial[key] !== null) {
          dataUser[key] = String(nutritionistInitial[key]);
        }
      });
      setData(prev => ({
        ...prev,
          ...dataUser
      }));
    } else {
      let dataUser = {};

      if (email) {
        dataUser.email = email;
      }
      setData(prev => {
        return {
          ...prev,
          ...dataUser
        }
      });
    }
  }, [nutritionistInitial, email]);

  const onChange = (e) => {
    setData((old) => ({
      ...old,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmitForm = (e) => {
    setErrors(initialData);
    const errorsFields = ValidationService.validate(validationSchema.create, data);
    setErrors({
      ...errorsFields
    });
    console.log(errorsFields);
    if (_.isEmpty(errorsFields)) {
      submitForm(data);
    }
    else {
      const errorElement = document.getElementById(Object.keys(errorsFields)[0]);
      errorElement?.scrollIntoView({ behavior: 'smooth' })
    }
  };

  const errorExist = (param) => errors.hasOwnProperty(param);

  return (
    <form noValidate onSubmit={submitForm}>
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_row} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <label>First name *</label>
            <TextField
              inputProps={{ maxLength: 25 }}
              error={errorExist('firstName')}
              helperText={errors?.firstName}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              value={data.firstName}
              required
              fullWidth
              id="firstName"
              placeholder="First name"
              label=""
              name="firstName"
              autoComplete="firstName"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Last name *</label>
            <TextField
              inputProps={{ maxLength: 25 }}
              error={errorExist('lastName')}
              helperText={errors?.lastName}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              required
              fullWidth
              value={data.lastName}
              id="lastName"
              placeholder="Last name"
              label=""
              name="lastName"
              autoComplete="lastName"
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={2}>
          <Grid item xs={12}>
            <label>Address *</label>
            {/* <TextField*/}
            {/*  error={errorExist('address1')}*/}
            {/*  helperText={errors?.address1}*/}
            {/*  variant="outlined"*/}
            {/*  className={styles.inputField}*/}
            {/*  onChange={onChange}*/}
            {/*  value={data.address1}*/}
            {/*  required*/}
            {/*  fullWidth*/}
            {/*  id="address1"*/}
            {/*  placeholder="Address"*/}
            {/*  label=""*/}
            {/*  name="address1"*/}
            {/*  autoComplete="address1"*/}
            {/*/>*/}
            <GooglePlacesAutocomplete
              selectProps={{
                value: { label: data.address1, value: 0 },
                placeholder: 'First address',
                onChange: (e) => {
                  setData((data) => {
                    return { ...data, address1: e.label };
                  });
                },
                styles: {
                  container: (provided) => ({
                    ...provided,
                    fontSize: 16,
                    border: 'none',
                    '&:hover': {
                      borderColor: 'red'
                    }
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    '&:hover': {
                      borderColor: 'white'
                    },
                    boxShadow: state.isFocused && !errorExist('address1') && '0 0 0 1px #368075',
                    fontSize: 16,
                    height: 54,
                    borderColor: errorExist('address1') ? '#F44334' : 'grey',
                    background: '#303030'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: 16,
                    color: 'white'
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    fontSize: 16,
                    color: 'white'
                  }),
                  menu: (provided) => ({
                    ...provided,
                    background: '#303030'
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: 16,
                    color: 'grey',
                    background: '#303030'
                  })
                }
              }}
            />
            <span style={{ fontSize: 12, marginTop: '5px', color: '#F44334' }}>
              {errors?.address1}
            </span>
          </Grid>
          <Grid item xs={12}>
            <label>Address 2</label>
            <TextField
              inputProps={{ maxLength: 100 }}
              error={errorExist('address2')}
              helperText={errors?.address2}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              value={data.address2}
              required
              fullWidth
              id="address2"
              placeholder="Second address"
              label=""
              name="address2"
              autoComplete="address2"
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={5}>
              <label>City *</label>
              <TextField
                inputProps={{ maxLength: 25 }}
                error={errorExist('city')}
                helperText={errors?.city}
                variant="outlined"
                className={styles.inputField}
                onChange={onChange}
                value={data.city}
                required
                fullWidth
                id="city"
                placeholder="City"
                label=""
                name="city"
                autoComplete="city"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <label>State</label>
              <TextField
                error={errorExist('state')}
                helperText={errors?.state}
                variant="outlined"
                id="select"
                onChange={onChange}
                label=""
                name="state"
                value={data.state}
                className={styles.inputField_select}
                select
              >
                {states.map((state, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <label>Zipcode *</label>
              <TextField
                // inputProps={{ pattern: "^[0-9]{5}(?:-[0-9]{4})?$" }}
                error={errorExist('zipcode')}
                helperText={errors?.zipcode}
                variant="outlined"
                className={styles.inputField}
                onChange={onChange}
                required
                fullWidth
                id="zipcode"
                value={data.zipcode}
                placeholder="zipcode"
                label=""
                name="zipcode"
                autoComplete="zipcode"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <RadioButtonsGroup
        field="gender"
        styles={styles}
        title="Gender *"
        value={data.gender}
        handleChange={onChange}
        errorExist={errorExist}
        errors={errors}
        withOther={false}
        items={genders}
      />
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={2}>
          <Grid item xs={12}>
            <label>Phone *</label>
            <TextField
              error={errorExist('phone')}
              helperText={errors?.phone}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              required
              value={data.phone}
              fullWidth
              id="phone"
              placeholder="Phone"
              label=""
              name="phone"
              autoComplete="phone"
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={2}>
          <Grid item xs={12}>
            <label>Email *</label>
            <TextField
              inputProps={{ maxLength: 100 }}
              disabled={email}
              error={errorExist('email')}
              helperText={errors?.email}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              required
              value={data.email}
              fullWidth
              id="email"
              placeholder="Email"
              label=""
              name="email"
              autoComplete="email"
            />
          </Grid>
        </Grid>
      </div>
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={2}>
          <Grid item xs={12}>
            <label>How many hours can you work weekly? *</label>
            <TextField
              error={errorExist('hoursWeekly')}
              helperText={errors?.hoursWeekly}
              variant="outlined"
              className={styles.inputField}
              value={data.hoursWeekly}
              onChange={onChange}
              required
              fullWidth
              id="hoursWeekly"
              type="number"
              placeholder="How many hours can you work weekly?"
              label=""
              InputProps={{ inputProps: { min: 0, max: 60 } }}
              name="hoursWeekly"
              autoComplete="hoursWeekly"
            />
          </Grid>
        </Grid>
      </div>
      <DoubleChoice
        title="Can you work at nights? *"
        withOtherOption={false}
        withExplainOption={false}
        required
        field="canWorkAtNights"
        value={data.canWorkAtNights}
        handleChange={onChange}
        errors={errors}
        errorExist={errorExist}
        styles={styles}
      />
      <DoubleChoice
        title="Are you 18 or older? *"
        withOtherOption={false}
        withExplainOption={false}
        required
        field="isOlder"
        value={data.isOlder}
        handleChange={onChange}
        errors={errors}
        errorExist={errorExist}
        styles={styles}
      />
      <FieldText
        title="Working schedule and time zone"
        placeholder="10 am - 6 pm Monday - Friday - PST"
        name="schedule"
        errors={errors}
        errorExist={errorExist}
        required
        rows={5}
        maxLength={140}
        styles={styles}
        handleChange={onChange}
        value={data.schedule}
      />
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={2}>
          <Grid item xs={12}>
            <label>Calendar Link (Calendly preferred) *</label>
            <TextField
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              value={data.calendarUrl}
              fullWidth
              id="calendarUrl"
              placeholder="calendarUrl"
              label=""
              name="calendarUrl"
            />
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} className={styles.intakeForm_actionButtons}>
        <Button
          onClick={(e) => handleSubmitForm(e)}
          variant="contained"
          color="primary"
          className={styles.intakeForm_actionButtons_nextBtn}
        >
          Next
        </Button>
      </Grid>
    </form>
  );
};

export default FormSubmitStepFirst;

import { nutritionistActions, userActions } from '../../../redux/actions';

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { clientStatuses } from '../../../constants';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = date + ' ' + month + ' ' + year;
  return time;
}

const columns = [
  {
    mapId: 1,
    id: 'user',
    label: 'Name',
    format: (value) => value.client.user.name
  },
  {
    mapId: 2,
    id: 'email',
    label: 'Email',
    format: (value) => value.client.user.email
  },
  {
    mapId: 3,
    id: 'date',
    label: 'Date',
    format: (value) => timeConverter(value.date)
  },
  {
    mapId: 4,
    id: 'sum',
    label: 'Sum',
    format: (value) => `$${value.sum}`
  }
];

export default function GetPaid() {
  const history = useHistory();
  const clients = useSelector((state) => state.nutritionist.clients);
  const { id } = useSelector((state) => state.user.nutritionist);
  const table = useSelector((state) =>
    state.nutritionist.payouts ? Object.values(state.nutritionist.payouts) : []
  );

  useEffect(() => {
    nutritionistActions.loadClients();
    nutritionistActions.getPayouts();
  }, []);

  return (
    <>
      <div style={{ marginTop: 30 }}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography
            style={{
              fontFamily: 'Bebas Neue',
              letterSpacing: '2.5px',
              fontSize: '2.5rem',
              color: '#EE79B3'
            }}
            component="h1"
            variant="h5"
          >
            TRANSACTIONS
          </Typography>
        </div>
      </div>

      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.mapId}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.client.id}
                    onClick={() => {
                      history.push(
                        `/nutritionist/clients/${row.client.nutritionistClients.clientId}`
                      );
                    }}
                  >
                    {columns.map((column) => {
                      const value = column.format ? column.format(row) : row;
                      return <TableCell key={column.mapId}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={properClientsArr.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        /> */}
      </Paper>
    </>
  );
}

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { adminActions } from '../../../redux/actions';

import ProfileView from '../../commonProfile';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const nutritionists = useSelector((state) => state.admin.nutritionists);
  const [nutritionist, setNutritionist] = useState(null);

  useEffect(() => {
    adminActions.loadNutritionists();
  }, []);

  useEffect(() => {
    const id = parseInt(props.match.params.id);
    console.log(id, nutritionists);
    const result = nutritionists.find((i) => i.nutritionist.id === id);
    console.log({ result });
    //adminActions.getNutritionistsChats(nutritionists.find((i) => i.id === id).nutritionist.id)
    setNutritionist(result);
  }, [nutritionists]);

  return <div>{nutritionist && <ProfileView user={nutritionist} />}</div>;
}

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Container,
  Modal
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { clientActions } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap'
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  }
}));

const tiers = [
  // {
  //   title: 'Standard',
  //   price: 100,
  //   description: [
  //     'One 15-minute call per month',
  //     'Certified coach to help you better your health and body composition.',
  //     '1-3 years of experience',
  //     'Unlimited chat',
  //     'One live 15-20 minutes per month.'
  //   ]
  // },
  // {
  //   title: 'Black',
  //   price: 200,
  //   description: [
  //     'Certified coach with 3-5 years of experience to coach you to better health and body composition.',
  //     'Unlimited  Chat',
  //     '2 live 15-20 minute sessions per month.'
  //   ]
  // },
  {
    title: 'Lux',
    price: 450,
    description: [
      'Certified coach with 5 plus years  of experience.  Expert coach to guide you to better health and body composition.',
      'Unlimited Chat',
      'Four 15-20 minute conversations with your coach per month.'
    ]
  }
];

const SubscriptionModal = ({ open, nutritionistId, onClose, availableTiers }) => {
  const classes = useStyles();

  const handleSubscribe = async (amount) => {
    const result = await clientActions.subscribeForNutritionist({
      nutritionistId,
      amount
    });

    console.log(result);

    const { url, error } = result;
    if (error) {
      window.alert(error);
      return onClose();
    }

    if (url) window.open(url);
  };

  return (
    <div>
      <Modal open={open} onClose={() => onClose()}>
        <Grid
          container
          spacing={5}
          alignItems="center"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15rem',
            paddingLeft: '6rem',
            paddingRight: '6rem'
          }}
        >
          {tiers
            .filter((t) => _.includes(availableTiers, t.price))
            .map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                <Card style={{ border: '1px solid #c4eb37', borderRadius: '10px' }}>
                  <CardHeader
                    title={tier.title}
                    titleTypographyProps={{ align: 'center', fontFamily: 'Bebas Neue' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h2"
                        variant="h3"
                        style={{ fontFamily: 'Bebas Neue' }}
                        color="textPrimary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography
                        style={{ fontFamily: 'Bebas Neue' }}
                        variant="h6"
                        color="textSecondary"
                      >
                        /mo
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      style={{
                        background: '#2F2F38',
                        margin: '0 1rem 1rem 1rem',
                        color: '#EE79B3',
                        fontSize: '1.5rem',
                        fontFamily: 'Bebas Neue'
                      }}
                      onClick={() => handleSubscribe(tier.price)}
                    >
                      Subscribe
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Modal>
    </div>
  );
};

export default SubscriptionModal;

import mutations from '../mutations';

const initialState = {
  myNutritionists: [],
  savedNutritionists: [],
  nutritionists: [],
  nutritionistsCount: 0,
  recommendedNutritionists: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.client.getMyNutritionists:
      return {
        ...state,
        myNutritionists: action.payload
      };
    case mutations.client.getSavedNutritionists:
      return {
        ...state,
        savedNutritionists: action.payload
      };

    case mutations.client.loadNutritionists:
      return {
        ...state,
        nutritionists: action.payload
      };
    case mutations.client.loadRecommendedNutritionists:
      return {
        ...state,
        recommendedNutritionists: action.payload
      };
    case mutations.client.setNutritionistsCount:
      return {
        ...state,
        nutritionistsCount: action.payload
      };
    default:
      return state;
  }
};

export default reducer;

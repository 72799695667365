import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OnboardingForm from '../../onboarding/OnboardingForm.js';
import moment from 'moment';
import { clientActions, userActions } from '../../../../redux/actions';

const EditProfile = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const prepareInitialClient = (user) => {
    const initialClient = user.client;
    initialClient.email = user.email;
    const [firstName, lastName] = [
      user.name.split(' ')[0],
      user.name.split(' ').slice(1).join(' ')
    ];
    initialClient.firstName = firstName;
    initialClient.lastName = lastName;
    initialClient.gender = initialClient.gender[0].toUpperCase() + initialClient.gender.slice(1);
    return initialClient;
  };

  const initialClient = prepareInitialClient(user);

  const prepareClient = (data) => {
    const { birthdate, gender, weight, height, firstName, lastName } = data;

    const client = {
      gender: gender.toLowerCase(),
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      weight,
      height
    };

    const userName = `${firstName} ${lastName}`;

    return { client, userName };
  };

  const submit = async (newClient) => {
    const { client, userName } = prepareClient(newClient);

    await clientActions.updateClientData(client);
    await userActions.setUserName(userName);
    await userActions.loadUser();
    history.push('/client/profile');
  };

  return <OnboardingForm submitForm={submit} email={user.email} initialClient={initialClient} />;
};

export default EditProfile;

const certifications = [
  'Precision Nutrition',
  'Primal Health Coach',
  'American Council on Exercise',
  'American Fitness Professionals and Associates',
  'International Society of Sports Nutrition',
  'National Academy of Sports Medicine',
  'The National Exercise and Sports Trainers Association',
  'Institute for Integrative Nutrition',
  'Institute for the Psychology of Eating'
];

export default certifications;
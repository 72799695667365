import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { authActions } from '../redux/actions';
import Loader from '../components/Loader';

const redirectRoutes = {
  admin: '/admin/dashboard',
  nutritionist: '/nutritionist/dashboard',
  client: '/client/dashboard'
};

const AppRedirect = () => {
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { isLogged, userType } = auth;

  const completeLogin = async (accessToken) => {
    const userType = await authActions.completeLogin(accessToken);
    history.push(redirectRoutes[userType]);
  };

  if (!isLogged) {
    const query = queryString.parse(window.location.hash);
    const { access_token, token_type } = query;

    if (access_token && token_type) {
      completeLogin(`${token_type} ${access_token}`);
      return <Loader />;
    }

    return <Redirect to="/login" />;
  } else {
    return <Redirect to={redirectRoutes[userType]} />;
  }
};

export default AppRedirect;

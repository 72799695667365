import React from 'react';

import styles from './Index.module.scss';

const MessageSplash = ({ message }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{message}</h1>
    </div>
  );
};

export default MessageSplash;

import React, { useState } from 'react';
import { TextField, Typography, LinearProgress, Link } from '@material-ui/core';
import styles from './Forgot.module.scss';

import { authActions } from '../../redux/actions';
import { isEmail } from '../../utils/helper';

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const [email, setEmail] = useState('');
  const [successMesage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormChange = ({ target }) => {
    const { value } = target;
    setEmail(value);
  };

  const handleSubmit = async () => {
    if (!isEmail(email)) {
      return setError('Email is not valid');
    }
    setError(null);
    setIsLoading(true);

    try {
      const successMesage = await authActions.resetPassword(email);
      setSuccessMessage(successMesage);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.userTypeContainer}>
      <div className={styles.contentContainer}>
        {!successMesage && (
          <>
            <p className={styles.title}>Reset password</p>
            <Typography variant="subtitle2" className={styles.hint}>
              Enter your e-mail and instructions will be sent to you!
            </Typography>

            <TextField
              className="my-2"
              variant="outlined"
              fullWidth
              label="Email Address"
              name="email"
              autoFocus
              onChange={handleFormChange}
            />

            {!isLoading && (
              <button className={styles.signInButton} onClick={handleSubmit}>
                <p className={styles.socialButtonText}>Send reset password email</p>
              </button>
            )}
            {isLoading && <LinearProgress />}
            {error && <div className="text-center text-danger"> {error}</div>}
          </>
        )}
        {successMesage && (
          <Typography variant="subtitle1" className={styles.message}>
            {successMesage}
          </Typography>
        )}

        <Link href="/login" className={styles.back}>
          Back to Sign In
        </Link>
      </div>
    </div>
  );
}

import { Form } from 'react-formio';
import { submitPoll } from '../../redux/actions/poll';
import React from 'react';
import Loader from '../Loader';

const ModalCard = ({ poll, setPoll, setOpen }) => {
  if (poll === null) {
    return <Loader />;
  }
  return (
    <>
      <h2
        style={{
          fontSize: '35px',
          marginLeft: '1rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '1.5px',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        Please fill out the form below, and send to your coach.
      </h2>
      <Form
        src={poll.formUrl ?? ''}
        onSubmit={async (obj) => {
          await submitPoll(poll.id, poll.recipientId);
          setOpen(false);
          setPoll(null);
        }}
        submission={{
          data: {
            recipientId: poll.recipientId.toString(),
            senderId: poll.senderId === null ? 'null' : poll.senderId.toString(),
            admin: poll.admin.toString()
          }
        }}
      />
    </>
  );
};

export default ModalCard;

import React, { useState, useEffect, useRef, useMemo, createRef } from 'react';
import { usePubNub } from 'pubnub-react';
import { useSelector } from 'react-redux';
import { Card, Fab, IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import SimpleBar from 'simplebar-react';

import 'simplebar/dist/simplebar.min.css';
import { Wrapper } from './Chat.style';

import Header from '../../components/chat/Header/Header';
import MessagesList from '../../components/chat/messageList';
import ModerateChatMessageList from '../../components/chat/messageList/moderateChatMessageList';
import MessageInput from '../../components/chat/messageInput/MessageInput';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Container from '../chat/chatlistStyles.scss';

import Pubnub from 'pubnub';

import { chatActions } from '../../redux/actions';

import _, { last } from 'lodash';

function Chat({ chat, userModerate }) {
  const user = useSelector((state) => state.user);
  const pubnub = usePubNub();

  const refDown = useRef(null);
  const scroll = useRef(null);
  const listRef = useRef(null);

  //com.mco
  const refUp = useRef(null);

  const [replied, setReplied] = useState({});
  const [cardType, setCardType] = useState('chat');

  const updateLastReadTime = async () => {
    if (userModerate) return;

    //com.mco badge
    const userData = await chatActions.notificationsCount({ userId: user.id, count: 0 });

    await pubnub.objects.setMemberships({
      uuid: user.id,
      channels: [
        {
          id: chat.channelId,
          custom: {
            lastReadTime: +new Date() * 10000
          }
        }
      ]
    });
  };

  useEffect(() => {
    updateLastReadTime();
    const interval = setInterval(updateLastReadTime, 2000);

    return () => {
      clearInterval(interval);
      updateLastReadTime();
    };
  }, []);

  //com.mco
  const allMessages = useSelector((state) => state.chat.messages);
  const allFiles = useSelector((state) => state.chat.files);

  const isImage = (uri) => {
    let imageRegex = /(jpeg|png|jpg)\b/;
    return imageRegex.test(uri);
  };

  const chats = useSelector((state) => state.chat.chats);
  const channelIds = useMemo(() => chats.map((chat) => chat.channelId), [chats]);

  useEffect(() => {
    chatActions.loadFiles({ channels: channelIds, pubnub });
  }, [allMessages]);

  const mediaFiles = useMemo(() => {
    const filtered = {};

    Object.entries(allFiles).map(([key, value]) => {
      if (_.isArray(value)) {
        filtered[key] = value.filter((file, index) => isImage(file.uri));
      }
    });
    return filtered;
  }, [allFiles]);

  const docFiles = useMemo(() => {
    const filtered = {};

    Object.entries(allFiles).map(([key, value]) => {
      if (_.isArray(value)) {
        filtered[key] = value.filter((file, index) => !isImage(file.uri));
      }
    });
    return filtered;
  }, [allFiles]);

  const [showDownButton, setShowDownButton] = useState(false);

  const handleScroll = (event) => {
    const maxScroll = scroll.current.scrollHeight - scroll.current.offsetHeight;
    if (scroll.current.scrollTop <= maxScroll - 900) {
      setShowDownButton(true);
    } else {
      setShowDownButton(false);
    }
    //com.mco
    if (scroll.current.scrollTop == 0 && allMessages[chat.channelId]) {
      chatActions.moreLoadMessages({
        channels: [chat.channelId],
        pubnub: pubnub,
        startTimeToken: allMessages[chat.channelId][0].timetoken
      });
      setTimeout(() => listRef.current.moreLoadCallback(), 100);
    }
  };

  useEffect(() => {
    scroll.current.addEventListener('scroll', handleScroll, true);
    return () => {
      if (scroll && scroll.current)
        scroll.current.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  // const changeHandler = async (event) => {
  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('mco messages');
      // await pubnub.sendFile({ channel: chat.channelId, file });
    }
  };

  const refDownCallback = () => {};

  const sendMessage = async (message, fileAttached) => {
    // if(fileAttached && message){
    if (fileAttached) {
      let messagePayload = {};

      if (message) messagePayload.text = message;

      // messagePayload.text = message;
      messagePayload.repliedMT = replied.repliedMT;
      messagePayload.repliedTT = replied.repliedTT;
      messagePayload.repliedFU = replied.repliedFU;

      setReplied({});
      const result = await pubnub.sendFile({
        channel: chat.channelId,
        file: fileAttached,
        message: messagePayload
      });
      if (result && result.id && result.timetoken && result.name) {
        await sendPPNotification('New-File-Sent', true);
        await updateLastReadTime();
      }
    } else if (message) {
      // await pubnub.publish({ channel: chat.channelId, message });
      await sendPPNotification(message, false);
      await updateLastReadTime();
    }
  };

  //fk2 pubnub notification
  const sendPPNotification = async (message, isFile) => {
    let body = message.length > 20 ? message.substring(0, 20) : message;
    body += '...';

    let title;
    if (isFile) title = 'New file from ' + user.name;
    else title = 'New chat from ' + user.name;

    //com.mco badge
    const userData = await chatActions.notificationsCount({ userId: getChannelUserID(), count: 1 });

    const notificationCount = userData.data.notificationCount;

    // let builder = Pubnub.notificationPayload('New Message', message);
    let builder = Pubnub.notificationPayload(title, body);

    builder.apns.configurations = [
      { targets: [{ topic: 'com.planibble.planibbleios', environment: 'production' }] }
    ];
    builder.sound = 'default';

    //com.mco badge
    builder.badge = notificationCount;

    let messagePayload = builder.buildPayload(['apns2', 'fcm']);

    // messagePayload.message = {"repliedMT":repliedMT,"repliedTT":repliedTT,"repliedFU": repliedFU};
    messagePayload.text = message;
    messagePayload.repliedMT = replied.repliedMT;
    messagePayload.repliedTT = replied.repliedTT;
    messagePayload.repliedFU = replied.repliedFU;

    setReplied({});

    // messagePayload.roomID = 'ewuiogw9vewg0';
    // console.log(JSON.stringify(messagePayload));
    // pubnub.publish(
    //     {
    //         message: messagePayload,
    //         channel: chat.channelId,
    //     },
    //     function (status, response) {
    //         // Handle publish results
    //     }
    // );

    let newMessagePayload = {
      pn_apns: {
        aps: {
          alert: {
            title: title,
            body: body
          }
        },
        pn_push: [
          {
            push_type: 'alert',
            targets: [
              {
                environment: 'production',
                topic: 'BUNDLE_ID_FOR_APP_1'
              }
            ],
            version: 'v2'
          }
        ]
      },
      pn_gcm: {
        notification: {
          title: title,
          body: body,
          sound: 'default'
        }
      },
      text: replied.repliedMT,
      repliedTT: replied.repliedTT,
      repliedFU: replied.repliedFU
    };

    console.log(replied);
    pubnub.publish(
      {
        message: messagePayload,
        channel: chat.channelId
      },
      function (status, response) {
        // Handle publish results
      }
    );
  };

  // const setReplied = (args) =>{
  //   //{messageText, url,  index, timetoken}
  //   console.log(args.caption);
  //   console.log(args.url);
  //   console.log(args.index);
  //   console.log(args.timetoken);
  //   setReplied(args);
  //   // setRepliedTT(args.timetoken);
  //   // setRepliedFU(args.url);
  //   // setRepliedMT(args.messageText);

  //   // pubnub.deleteMessages(
  //   //   {
  //   //     channel: chat.channelId,
  //   //     start: Number(args.timetoken) + 2,
  //   //     end: args.timetoken,
  //   //   },
  //   //   function (status, response) {
  //   //     console.log(status, response);
  //   //   }
  //   // );

  // };

  const getCreatedDate = (fileCreatedDate) => {
    return fileCreatedDate.split('T')[0];
  };

  const getCreatedTime = (fileCreatedDate) => {
    const time = fileCreatedDate.split('T')[1];

    return time.substring(0, time.length - 1);
  };

  const getFormatedCreatedDateTime = (fileCreatedDate) => {
    const part1 = fileCreatedDate.split('T')[0];
    const part2 = getCreatedTime(fileCreatedDate);
    return part1 + ' [' + part2 + ']';
  };

  //com.mco badge
  const getChannelUserID = () => {
    let userId;
    if (chat.toAdmin) userId = -1;
    else if (chat.client) userId = chat.client.user.id;
    else if (chat.nutritionist) userId = chat.nutritionist.user.id;
    else if (chat.chatGroupsClients.length > 0) userId = chat.chatGroupsClients[0].client.user.id; // fk2 group chat

    return userId;
  };

  return cardType === 'chat' ? (
    <Card style={{ height: '100%', position: 'relative' }}>
      <Header chat={chat} onClick={(args) => setCardType(args)} />
      <SimpleBar style={{ height: '40rem' }} scrollableNodeProps={{ ref: scroll }}>
        {/* com.mco */}
        <div ref={refUp}></div>
        {userModerate ? (
          <ModerateChatMessageList ref={listRef} chat={chat} setReplied={setReplied} />
        ) : (
          <MessagesList ref={listRef} chat={chat} setReplied={setReplied} />
        )}
        <div ref={refDown}></div>
      </SimpleBar>
      <div
        style={{
          position: 'absolute',
          fontSize: '3rem',
          marginTop: '0.3rem',
          right: '2rem',
          bottom: '8rem',
          width: '3rem',
          height: '3rem',
          borderRadius: '99999px',
          background: '#2F2F38',
          display: showDownButton ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          scrollBehavior: 'smooth'
        }}
        onClick={() => {
          //com.mco
          // refDown.current.scrollIntoView({ behavior: 'smooth' });
          listRef.current.refDownCallback();
        }}
      >
        <Fab size="medium">
          <KeyboardArrowDownIcon fontSize="large" />
        </Fab>
      </div>

      <Wrapper>
        {
          // com.mco
          replied && replied.repliedTT ? (
            <div
              style={{
                width: '100%',
                float: 'left',
                borderLeft: 'gray 15px solid',
                borderTop: 'gray 1px solid',
                borderRadius: '10px 0 10px 10px'
              }}
            >
              <div>
                <IconButton
                  style={{ width: '48px', height: '48px', paddingBottom: '8px', cursor: 'pointer' }}
                  onClick={() => {
                    setReplied({});
                  }}
                >
                  <Cancel />
                  {/* com.mco */}
                </IconButton>
                <>
                  {replied.repliedFU && isImage(replied.repliedFU) && (
                    <img style={{ width: '20%', height: '20%' }} src={replied.repliedFU} />
                  )}
                  <span style={{ marginLeft: '2rem' }} />{' '}
                  {replied.repliedMT
                    ? replied.repliedMT
                    : replied.repliedFU
                    ? replied.repliedFU.substring(
                        replied.repliedFU.lastIndexOf('/') + 1,
                        replied.repliedFU.lastIndexOf('?')
                      )
                    : '...'}
                </>
              </div>
            </div>
          ) : (
            <></>
          )
        }
      </Wrapper>

      <Wrapper>
        {userModerate ? (
          <div
            style={{
              width: '100%',
              height: '3rem',
              background: '#2F2F38',
              display: 'flex',
              alignItems: 'center',
              padding: '2px 14px 2px 14px',
              borderRadius: '16px'
            }}
          >
            Chat is readonly
          </div>
        ) : (
          // com.mco
          <MessageInput
            sendMessage={(message, fileAttached) => sendMessage(message, fileAttached)}
            changeHandler={(event, caption) => changeHandler(event, caption)}
          />
        )}
      </Wrapper>
    </Card>
  ) : cardType === 'media' ? (
    <Card>
      <Header chat={chat} onClick={(args) => setCardType(args)} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: 'repeat(1, 1fr)',
          gap: '1rem',
          paddingLeft: '20px'
        }}
      >
        {mediaFiles[chat.channelId] &&
          mediaFiles[chat.channelId].map((item, i) => {
            // console.log('com.mco');
            return (
              <div>
                <div
                  style={{
                    borderRadius: 20,
                    position: 'relative',
                    overflow: 'hidden',
                    width: '150px',
                    height: '150px',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <img
                    style={{
                      width: 'auto',
                      height: '100%',
                      margin: '0 auto',
                      display: 'inline'
                    }}
                    src={item.uri}
                    onClick={() => window.open(item.uri, '_blank')}
                    alt=""
                  />
                </div>
                <div>{getFormatedCreatedDateTime(item.created)}</div>
                {/* <div >{item.name}</div> */}
              </div>
            );
          })}
      </div>
    </Card>
  ) : (
    <Card>
      <Header chat={chat} onClick={(args) => setCardType(args)} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: 'repeat(1, 1fr)',
          gap: '1rem',
          paddingLeft: '20px'
        }}
      >
        {docFiles[chat.channelId] &&
          docFiles[chat.channelId].map((item, i) => {
            // console.log('com.mco');
            return (
              <div style={{ cursor: 'pointer' }} onClick={() => window.open(item.uri, '_blank')}>
                {item.name}
                <br />
                {getFormatedCreatedDateTime(item.created)}
              </div>
            );
          })}
      </div>
    </Card>
  );
}

export default Chat;

import { Dialog, Icon } from '@material-ui/core';
import styles from '../formModal/Index.module.scss';
import React from 'react';
import { Form } from 'react-formio';

const SubmissionsModal = ({data, open, onClose, selectedForm}) => {
  if (open) {
    console.log("modal selectfor", data);
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{
        paper: styles.modal
      }}
    >
      <div className={`preview ${styles.modalContainer}`}>
        <Icon
          onClick={onClose}
          className={`fa fa-times ${styles.modalCross}`}
          style={{ fontSize: 30 }}
        />
        <Form
          src={selectedForm}
          submission={{data}}
          options={{
            readOnly: true,
          }}
        />
      </div>
    </Dialog>
  );
};

export default SubmissionsModal;
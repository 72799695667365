import axios from 'axios';
import config from '../config';

const unauthorizedStatusCode = 401;

const guestAxios = axios.create({
  baseURL: config.apiUrl,
  timeout: 10000
});

// eslint-disable-next-line no-var
const userAxios = axios.create({
  baseURL: config.apiUrl,
  timeout: 300000,
  headers: {
    accept: 'application/json'
  }
});

function initUserAxiosInstance({ token, logoutAction }) {
  userAxios.defaults.headers.common.Authorization = token;

  userAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      if (status === unauthorizedStatusCode) {
        if (logoutAction) logoutAction();
      } else {
        const { data } = error.response;
        console.log(data);
      }

      return Promise.reject(error);
    }
  );
}

export { guestAxios, userAxios, initUserAxiosInstance };

import { nutritionistActions } from '../../../redux/actions';

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

const columns = [
  {
    id: 'user',
    label: 'Name',
    format: value => value.name
  },
  {
    id: 'birthdate',
    label: 'Birthdate',
    format: (value) => {
      return moment(value).format('MM.DD.YYYY')
    }
  },
  {
    id: 'user',
    label: 'Email',
    format: value => value.email
  },
  {
    id: 'createdAt',
    label: 'Date created',
    format: (value) => moment(value).format('MM.DD.YYYY')
  }
];

export default function ClientsTable({selectedClients, setSelectedClients, selectAll, setSelectAll}) {
  const clients = useSelector((state) => state.nutritionist.clients);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const table = useMemo(() => {
    return clients.slice(page * pageSize, (page + 1) * pageSize);
  }, [clients, pageSize, page]);


  useEffect(() => {
    nutritionistActions.loadClients();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleCheckbox = (id) => {
    const find = selectedClients.indexOf(id);
    if (find > -1) {
      selectedClients.splice(find, 1);
    } else {
      selectedClients.push(id);
    }
    setSelectedClients([...selectedClients]);
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedClients(clients.map((e) => e.user.id));
      setSelectAll(true);
    } else {
      setSelectedClients([]);
      setSelectAll(false);
    }
  };


  return (
    <>
      <Paper style={{ marginTop: 30 }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div>Select all </div> <Checkbox checked={selectAll} onChange={handleSelectAll} />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.label}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedClients.includes(row.user.id)}
                        onChange={() => {
                          handleCheckbox(row.user.id);
                        }}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = column.format ? column.format(row[column.id]) : row[column.id];
                      if (column.id === 'name')
                        return (
                          <TableCell key={column.id}>
                            <Link
                              to={`/nutritionist/clients/${row.id}`}
                              style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        );
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={clients.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </>
  );
}

import styles from './Form.module.scss';
import appStore from '../../../assets/img/appstore-white.svg';
import FormModalSingle from '../../../components/formModalSingle';
import { usePubNub } from 'pubnub-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { chatActions, userActions } from '../../../redux/actions';
import _ from 'lodash';
import config from '../../../config/index';
import Loader from '../../../components/Loader';

import { loadPoll} from '../../../redux/actions/poll';
import React, { useState } from 'react';

const ClientForm = (props) => {
  const [loaded, setLoaded] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(async () => {
    await loadPoll(props.match.params.id);
    setLoaded(true);
    
    // console.log("then poll", JSON.stringify(data));
    // formioUrl = data['formUrl'];
  }, []);

if (!loaded) {
  return <Loader />;
} else
  return (
    <div>
      <FormModalSingle />
    </div>
  );
};

export default ClientForm;

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import {
  Table,
  TableBody,
  TableCell,
  Avatar,
  TableRow,
  Typography,
  Button,
  Card,
  Icon,
  IconButton,
  CardContent
} from '@material-ui/core';

import { nutritionistActions } from '../../../redux/actions';
import { clientStatuses } from '../../../constants';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useHistory } from 'react-router';

const columns = [
  {
    mapId: 1,
    id: 'user',
    label: 'Name',
    format: (value) => <Avatar src={value.image} />
  },
  {
    mapId: 2,
    id: 'user',
    label: 'Name',
    format: (value) => value.name
  }
];

export default function PendingClientsTable() {
  const clients = useSelector((state) => state.nutritionist.clients);

  const table = useMemo(() => {
    return clients.filter((client) => client.nutritionistClients.status === clientStatuses.PENDING);
  }, [clients]);

  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [requestToProcess, setRequestToProcess] = useState(null);

  const history = useHistory();

  useEffect(() => {
    nutritionistActions.loadClients();
  }, []);

  const handlePreReject = async (row) => {
    setRejectDialogOpen(true);
    setRequestToProcess(row);
  };

  const handlePreAccept = async (row) => {
    setApproveDialogOpen(true);
    setRequestToProcess(row);
  };

  const handleReject = async () => {
    const clientId = requestToProcess.id;
    const status = clientStatuses.REJECTED;
    await nutritionistActions.changeUserStatus({ status, clientId });
    setRequestToProcess(null);
    setRejectDialogOpen(false);
  };

  const handleAccept = async () => {
    const clientId = requestToProcess.id;
    const status = clientStatuses.APPROVED;
    await nutritionistActions.changeUserStatus({ status, clientId });
    setRequestToProcess(null);
    setApproveDialogOpen(false);
  };

  return (
    <>
      <ConfirmationDialog
        title="Reject client request"
        text="Are you sure you want to reject client request?"
        onClose={() => setRejectDialogOpen(false)}
        open={rejectDialogOpen}
        onSubmit={handleReject}
      />
      <ConfirmationDialog
        title="Approve client request"
        text="Are you sure you want to approve client request?"
        onClose={() => setApproveDialogOpen(false)}
        open={approveDialogOpen}
        onSubmit={handleAccept}
      />
      <Card
        style={{
          marginTop: 10,
          minWidth: 400,
          maxWidth: 800,
          margin: 10,
          flex: 1
        }}
      >
        <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography gutterBottom variant="h6" style={{ color: '#c4eb37' }}>
            Pending clients
          </Typography>

          {_.isEmpty(table) && <Typography gutterBottom>You have no requests yet.</Typography>}
          {!_.isEmpty(table) && (
            <Table size="small">
              <TableBody>
                {table.map((row) => {
                  return (
                    <TableRow hover key={row.id} onClick={() => {
                      console.log(row)
                      history.push(`/nutritionist/clients/${row.id}`)

                    }}>
                      {columns.map((column) => {
                        const value = column.format
                          ? column.format(row[column.id])
                          : row[column.id];

                        return <TableCell key={column.mapId}>{value}</TableCell>;
                      })}

                      <TableCell align="right" size="small" style={{ width: 140 }}>
                        <IconButton onClick={(e) => {
                          e.stopPropagation();
                          handlePreReject(row)
                        }}>
                          <Icon className="fa fa-times" />
                        </IconButton>
                        <IconButton onClick={(e) => {
                          e.stopPropagation();
                          handlePreAccept(row)
                        }}>
                          <Icon className="fa fa-check" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}

          <div style={{ display: 'flex', marginTop: 20, justifyContent: 'space-between' }}>
            <a href="/nutritionist/clients">
              <Button color="primary" variant="contained">
                Manage requests
              </Button>
            </a>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

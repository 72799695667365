import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Typography,
  TextField,
  Icon
} from '@material-ui/core';

import { adminActions } from '../../../redux/actions';
import { filterTable } from '../../../utils/helper';

const columns = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'client.birthdate',
    label: 'Birthdate',
    format: (value) => moment(value).format('MM.DD.YYYY')
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'createdAt',
    label: 'Date created',
    format: (value) => moment(value).format('MM.DD.YYYY')
  },
  {
    id: 'subscription',
    label: 'Subscription Status'
  },
  {
    id: 'delete',
    label: 'Delete User'
  }
];

export default function ClientsTable() {
  const clients = useSelector((state) => state.admin.clients);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    adminActions.loadClients();
  }, []);

  const table = useMemo(() => {
    const filterFields = ['name', 'email'];
    const result = filterTable(clients, filterFields, search);
    return result.slice(page * pageSize, (page + 1) * pageSize);
  }, [clients, pageSize, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleSearch = _.debounce((e) => {
    const { value } = e.target;
    setSearch(value);
  }, 500);

  const deleteUserClient = async (userId) => {
    if (window.confirm("Are you sure? (This will permanently delete the users data)")) {
      await adminActions.deleteUserClient(userId);
      await adminActions.loadClients();
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div className="d-flex justify-content-between align-items-center">
        <Typography
          style={{
            fontFamily: 'Bebas Neue',
            color: '#EE79B3',
            fontSize: '2.5rem',
            letterSpacing: '2px'
          }}
          component="h1"
          variant="h5"
        >
          Clients
        </Typography>

        <TextField
          className="my-2"
          variant="outlined"
          label="Search"
          name="search"
          onChange={handleSearch}
        />
      </div>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    style={{ fontFamily: 'Bebas Neue', fontSize: '1.2rem' }}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow hover key={row.id}>
                      {columns.map((column) => {
                        const value = column.format
                          ? column.format(_.get(row, column.id))
                          : _.get(row, column.id);
                        if (column.id === 'name')
                          return (
                            <TableCell key={column.id} onClick={() => history.push(`/admin/clients/${row.client.id}`)}>
                                {value}
                            </TableCell>
                          );
                          if (column.id == 'delete')
                            return (
                              <TableCell align="right" size="small" style={{ width: 140 }}>
                                <IconButton onClick={() => deleteUserClient(row.id)}>
                                  <Icon className="fa fa-times" />
                                </IconButton>
                              </TableCell>
                            );
                            if (column.id == 'subscription') {
                              const sub = row.sub != undefined ? `Active ( $ ${row.sub.plan.amount / 100} )` : "deactive";
                            return (
                              <TableCell align="right" size="small" style={{ width: 140 }}>
                                {sub}
                              </TableCell>
                            );}
                        return <TableCell key={column.id} onClick={() => history.push(`/admin/clients/${row.client.id}`)}>{value}</TableCell>;
                      })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          style={{ color: '#c4eb37' }}
          count={clients.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}

import _ from 'lodash';

export const yearsOfExperienceText = (value) => {
  if (value < 1) return 'Less than a year of experience';
  if (value === 1) return '1 year of experience';
  return `${value} years of experience`;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

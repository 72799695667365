import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';

import config from '../../../config';
import { adminActions, publicActions } from '../../../redux/actions';

export default function () {
  const [data, setData] = useState();
  const [type, setType] = useState('nutritionist');
  const faq = useSelector((state) => state.app.faq);

  useEffect(() => {
    const result = faq.find((i) => i.type === type);
    setData(result?.text || '');
  }, [faq, type]);

  useEffect(() => {
    publicActions.loadFAQ({});
  }, []);

  const handleSubmit = async () => {
    await adminActions.saveFAQ({ text: data, type });
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  return (
    <div>
      <Button className="my-2 ml-auto" variant="contained" color="primary" onClick={handleSubmit}>
        Save
      </Button>

      <Select value={type} onChange={handleTypeChange} className="mx-3">
        <MenuItem value={'nutritionist'}>Coach</MenuItem>
        <MenuItem value={'client'}>Client</MenuItem>
      </Select>

      <Editor
        apiKey={config.tinyMCE_key}
        init={{}}
        value={data}
        onEditorChange={(newValue, editor) => setData(newValue)}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
      />
    </div>
  );
}

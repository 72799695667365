import _ from 'lodash';

import mutations from '../mutations';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.user.set:
      return _.pick(action.payload, [
        'id',
        'auth0_id',
        'email',
        'image',
        'name',
        'type',
        'phone',
        'client',
        'nutritionist'
      ]);
    case mutations.auth.logout:
      return initialState;
    case mutations.user.setChats:
      return {
        ...state,
        chats: action.payload
      };
    case mutations.user.updateTimetoken:
      return {
        ...state,
        timetoken: action.payload
      };
    case mutations.user.updateUnreadMessagesInfo:
      return {
        ...state,
        unreadMessagesInfo: action.payload
      };
    case mutations.user.updateUnreadMessagesCount:
      return {
        ...state,
        unreadMessagesCount: action.payload
      };
    case mutations.user.changeClientStatus:
      const newClient = _.clone(state.client, true);

      const id = newClient.nutritionists.findIndex((item) => item.id === action.payload.id);

      if (id !== -1) {
        newClient.nutritionists[id] = action.payload;
      } else {
        newClient.nutritionists.push(action.payload);
      }

      return {
        ...state,
        client: newClient
      };
    case mutations.user.changeAdminChatStatusClient:
      const newClientt = _.clone(state.client, true);
      newClientt.chatWithAdmin = action.payload
      return {
        ...state,
        client: newClientt
      };
    case mutations.user.changeAdminChatStatusNutritionist:
      const newNutritionist = _.clone(state.nutritionist, true);
      newNutritionist.chatWithAdmin = action.payload
      return {
        ...state,
        nutritionist: newNutritionist
      };

    default:
      return state;
  }
};

export default reducer;

import React, { useState, useEffect, useMemo, useRef, memo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Typography,
  TextField
} from '@material-ui/core';

import { adminActions } from '../../../redux/actions';
import { filterTable } from '../../../utils/helper';
import Checkbox from '@material-ui/core/Checkbox';

const columns = [
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'client.birthdate',
    label: 'Birthdate',
    format: (value) => moment(value).format('MM.DD.YYYY')
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'createdAt',
    label: 'Date created',
    format: (value) => moment(value).format('MM.DD.YYYY')
  }
];

const ClientsTable = ({
  selectedClients,
  setSelectedClients,
  selectAll,
  setSelectAll,
}) => {
  const clients = useSelector((state) => state.admin.clients);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    adminActions.loadClients();
  }, []);

  const table = useMemo(() => {
    const filterFields = ['name', 'email'];
    const result = filterTable(clients, filterFields, search);
    return result.slice(page * pageSize, (page + 1) * pageSize);
  }, [clients, pageSize, page, search]);

  console.log("rerender");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    // searchInput.current.focus()
  }, [search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCheckbox = (id) => {
    const find = selectedClients.indexOf(id);
    if (find > -1) {
      selectedClients.splice(find, 1);
    } else {
      selectedClients.push(id);
    }
    setSelectedClients([...selectedClients]);
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedClients(clients.map((e) => e.id));
      setSelectAll(true);
    } else {
      setSelectedClients([]);
      setSelectAll(false);
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div className="d-flex justify-content-between align-items-center">
        <Typography component="h1" variant="h5">
          Clients
        </Typography>

        <TextField
          className="my-2"
          variant="outlined"
          label="Search"
          name="search"
          value={search}
          onChange={handleSearch}
        />
      </div>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell key={12}>
                  <div>Select all </div> <Checkbox checked={selectAll} onChange={handleSelectAll} />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell key={row.id}>
                      <Checkbox
                        checked={selectedClients.includes(row.id)}
                        onChange={() => {
                          handleCheckbox(row.id);
                        }}
                      />
                    </TableCell>
                    {columns.map((column) => {
                      const value = column.format
                        ? column.format(_.get(row, column.id))
                        : _.get(row, column.id);
                      if (column.id === 'name')
                        return (
                          <TableCell key={column.id}>
                            <Link
                              to={`/admin/clients/${row.id}`}
                              style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        );
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={clients.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}

export default memo(ClientsTable);

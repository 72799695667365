import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chart from 'react-apexcharts';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { adminActions, nutritionistActions, userActions } from '../../../redux/actions';

import styles from './Index.module.scss';
import { Icon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const StatCard = ({ text, icon, value, goTo }) => {
  const history = useHistory();
  return (
    <div className={styles.cardContainer} onClick={() => history.push(`${goTo ?? '/'}`)}>
      <div className={styles.cardContentContainer}>
        <div className={styles.iconContainer}>{icon}</div>
        <div item style={{ textAlign: 'right' }}>
          <h1 className={styles.cardTitle}>{value}</h1>
          <Typography variant="body2" color="textSecondary" component="p">
            {text}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const AdminHome = () => {

  // useEffect(() => {
  //   nutritionistActions.loadReportsData();
  // }, []);

  // const reportsData = useSelector((state) => state.nutritionist.reportsData);


  const [reportsData, setReportsData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);


  const loadReportsdata = async () => {
    const data = await adminActions.loadReportsData();
    setReportsData(data);
  };

  useEffect(() => {
    loadReportsdata();
  }, []);

  useEffect(() => {
    let amount = 0;

    if (reportsData != null) {
      console.table(reportsData);
      amount = reportsData.filter((item) => item.type != "payout").reduce((total, item, index) =>
        total + item.amount, 0
      );

      reportsData.forEach((el, index) => {
        reportsData[index].month = new Date(el.created * 1000).getMonth();
      });
      // console.table(reportsData);

      let monthlyData = reportsData.filter((item) => item.type != "payout").reduce((prev, next) => {
        if (next.month in prev) {
          prev[next.month].amount += next.amount;
        } else {
          prev[next.month] = next;
        }
        return prev;
      }, {});

      let result = Object.keys(monthlyData).map(month => monthlyData[month]);

      // console.log("new result", result);

      chartData.series[0].data = result.map((item) => [item.created * 1000, item.amount / 100]);

      setOptionsData(chartData);
      var x = 100;
    }
    setTotalAmount(amount / 100);


  }, [reportsData]);

  const statistic = useSelector((state) => {
    const statistic = {
      nutritionists: state.admin.statistic.nutritionists ?? {}
    };

    const data = {
      options: {
        colors: ['#c4eb37'],
        xaxis: {
          labels: {
            style: {
              colors: 'white'
            }
          },
          categories: []
        },
        yaxis: {
          labels: {
            style: {
              colors: 'white'
            }
          }
        }
      },
      series: [
        {
          name: 'Clients',
          data: []
        }
      ]
    };

    const income = JSON.parse(JSON.stringify(data));
    const users = JSON.parse(JSON.stringify(data));

    if (state.admin.statistic.income) {
      for (var i = 0; i < state.admin.statistic.income.length; i++) {
        income.options.xaxis.categories.push(state.admin.statistic.income[i].label);
        income.series[0].data.push(state.admin.statistic.income[i].value);
      }
    }

    // if (state.admin.statistic.income) {
    //   for (var i = 0; i < reportsData.length; i++) {
    //     income.series[0].data.push([reportsData[i].created, reportsData[i] / 100]);
    //   }
    // }

    if (state.admin.statistic.users) {
      for (var i = 0; i < state.admin.statistic.users.length; i++) {
        users.options.xaxis.categories.push(state.admin.statistic.users[i].label);
        users.series[0].data.push(state.admin.statistic.users[i].value);
      }
    }

    statistic.income = income;
    statistic.users = users;

    return statistic;
  });

  ////////////////////////////////////////////////////////////////

  const chartData = {
    series: [
      {
        name: "Monthly income",
        data:
          [
            // [1361833200000,38.59],
            // [1361919600000,39.60],
          ]
      }],
    colors: ['#c4eb37'],
    yaxis: {
      labels: {
        style: {
          colors: 'white'
        }
      }
    },
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    // annotations: {
    //   yaxis: [{
    //     y: 30,
    //     borderColor: '#999',
    //     label: {
    //       show: true,
    //       text: 'Support',
    //       style: {
    //         color: "#fff",
    //         background: '#00E396'
    //       }
    //     }
    //   }],
    //   xaxis: [{
    //     x: new Date('1 Jan 2022').getTime(),
    //     borderColor: '#999',
    //     yAxisIndex: 0,
    //     label: {
    //       show: true,
    //       text: 'Rally',
    //       style: {
    //         color: "#fff",
    //         background: '#775DD0'
    //       }
    //     }
    //   }]
    // },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 10,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
      min: new Date('1 Jan 2022').getTime(),
      tickAmount: 6,
      labels: {
        style: {
          colors: 'white'
        }
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  };

  const [optionsData, setOptionsData] = useState(chartData);

  //////////////////////////////////////////////////////////

  useEffect(() => {
    adminActions.loadStats();
    // userActions.loadUser();
  }, []);

  const cards = [
    {
      text: 'Total',
      icon: <Icon className="fa fa-users" style={{ fontSize: 50 }} />,
      value: `${statistic.nutritionists.total} coaches`,
      goTo: '/admin/nutritionists'
    },
    {
      text: 'Waiting for approval',
      icon: <Icon className="fa fa-pause" style={{ fontSize: 50 }} />,
      value: `${statistic.nutritionists.pending} coaches`,
      goTo: '/admin/applications'
    },
    {
      text: 'Approved',
      icon: <Icon className="fa fa-check" style={{ fontSize: 50 }} />,
      value: `${statistic.nutritionists.current} coaches`,
      goTo: '/admin/applications'
    },
    {
      text: 'Total Earings',
      icon: <Icon className="fa fa-dollar" style={{ fontSize: 50 }} />,
      value: `${totalAmount} $`
    }
  ];

  return (
    <div className={styles.generalContainer}>
      <div className={styles.cardsGeneralContainer}>
        {cards.map((card) => (
          <StatCard {...card} />
        ))}
      </div>
      <div className={styles.chartsContainer}>
        {
          reportsData &&
          <Grid item xs={6}>
            <h3 className={styles.chartTitle}>Income</h3>
            <Chart

              // options={statistic.income.options}
              // series={statistic.income.series}
              options={optionsData}
              series={optionsData.series}
              type="bar"
              height="500"
              className="chartFontColor"
            />
          </Grid>
        }
        <Grid item xs={6}>
          <h3 className={styles.chartTitle}>Clients</h3>
          <Chart
            options={statistic.users.options}
            series={statistic.users.series}
            type="bar"
            height="500"
            className="chartFontColor"
          />
        </Grid>
      </div>
    </div>
  );
};

export default AdminHome;
import mutations from '../mutations';

const initialState = {
  clients: [],
  chatClients: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.nutritionist.loadClients:
      return {
        ...state,
        clients: action.payload
      };
    case mutations.nutritionist.loadChatClients:
      return {
        ...state,
        chatClients: action.payload
      };
    case mutations.nutritionist.payouts:
      return {
        ...state,
        payouts: action.payload
      };
    case mutations.nutritionist.changeClientStatus:
      const newClients = state.clients.map((item) => {
        if (item.id === action.payload.clientId) {
          item.nutritionistClients.status = action.payload.status;
          return item;
        }

        return item;
      });
      return {
        ...state,
        clients: newClients
      };
    default:
      return state;
  }
};

export default reducer;

import styled from 'styled-components/macro'

export const Wrapper = styled.section`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
`;

export const BackIconWrapper = styled.div`
  cursor: pointer;
  display: none;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 27px;
`;

export const Border = styled.div`
  border-bottom: 1px solid #d0dae5;
  margin: 0 20px;
`;

export const Information = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Name = styled.div`
  font-size: 18px;
  color: #d0dae5;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  margin: 10px 0 5px 0;
`;

export const Description = styled.div`
  font-size: 16px;
  color: #d0dae5;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`;

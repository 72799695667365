import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText, Avatar } from '@material-ui/core';

import { userTypes } from '../../../constants';

const headerStyle = {
  fontSize: '1.5rem',
  marginLeft: '1rem',
  fontFamily: 'Bebas Neue',
  letterSpacing: '1.5px',
  color: '#EE79B3'
};

function AdminChatList({ chats, onSelect }) {
  const unreadCounts = useSelector((state) => state.chat.unreadCounts);

  const nutritionistChats = useMemo(() => {
    return chats.filter((chat) => chat.nutritionistId);
  }, chats);

  const clientChats = useMemo(() => {
    return chats.filter((chat) => chat.clientId);
  }, chats);

  console.log({ nutritionistChats, clientChats });

  return (
    <>
      <p style={headerStyle}>Clients</p>
      {clientChats.map((chat) => {
        const unreadCount = unreadCounts[chat.channelId] || 0;

        return (
          <ListItem button key={chat.channelId} onClick={() => onSelect(chat)}>
            <Avatar src={chat.client.user.image} style={{ margin: '0 10px' }} />
            <ListItemText primary={chat.client.user.name} />
            {unreadCount > 0 && (
              <div
                style={{
                  width: '2rem',
                  height: '2rem',
                  borderRadius: '9999px',
                  background: '#EE79B3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {unreadCount}
              </div>
            )}
          </ListItem>
        );
      })}
      <br />
      <p style={headerStyle}>Coaches</p>
      {nutritionistChats.map((chat) => {
        const unreadCount = unreadCounts[chat.channelId] || 0;

        return (
          <ListItem button key={chat.channelId} onClick={() => onSelect(chat)}>
            <Avatar src={chat.nutritionist.user.image} style={{ margin: '0 10px' }} />
            <ListItemText
              primary={`${chat.nutritionist.firstName} ${chat.nutritionist.lastName}`}
            />
            {unreadCount > 0 && (
              <div
                style={{
                  width: '2rem',
                  height: '2rem',
                  borderRadius: '9999px',
                  background: '#EE79B3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {unreadCount}
              </div>
            )}
          </ListItem>
        );
      })}
    </>
  );
}

function ChatList({ onSelect }) {
  const userType = useSelector((state) => state.auth.userType);
  const chats = useSelector((state) => state.chat.chats);

  return (
    <List style={{ maxHeight: '890px' }}>
      <ListItem>
        <span style={{ fontSize: '20px', margin: '5px auto' }}>Conversations</span>
      </ListItem>
      {_.isEmpty(chats) && (
        <div style={{ textAlign: 'center' }}>
          <p
            style={{
              fontSize: '1.5rem',
              fontFamily: 'Bebas Neue',
              letterSpacing: '1.5px',
              color: '#EE79B3'
            }}
          >
            You don't have any active conversations
          </p>
        </div>
      )}
      {!_.isEmpty(chats) && (
        <>{userType === userTypes.admin && <AdminChatList chats={chats} onSelect={onSelect} />}</>
      )}
    </List>
  );
}

export default ChatList;

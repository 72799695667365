import React, { useEffect, useState } from 'react';
import { Dialog, Icon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-formio';
import axios from 'axios';
import Loader from '../../../components/Loader';
import Stepper from './Stepper';

import styles from './Index.module.scss';

const useStyles = makeStyles({
  formsWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    fontSize: '4rem',
    fontFamily: 'Bebas Neue',
    letterSpacing: '2.5px',
    color: '#c4eb37',
    marginBottom: '30px',
    marginTop: '30px'
  }
});

const CardList = ({ formsUrl, selectedCard, setSelectedCard, title, activeStep }) => {
  const [forms, setForms] = useState(null);
  const [previewForm, setPreviewForm] = useState('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const classes = useStyles();

  const handleCardClick = (e, path) => {
    e.stopPropagation();
    if (selectedCard) {
      formsUrl + path === selectedCard ? setSelectedCard('') : setSelectedCard(formsUrl + path);
    } else {
      setSelectedCard(formsUrl + path);
    }
  };

  useEffect(() => {
    (async function () {
      const { data } = await axios.get(formsUrl + 'form');
      setForms(data);
    })();
  }, []);

  if (forms === null) {
    return <Loader />;
  }

  return (
    <>
      <div className={classes.title}>{title}</div>
      <Stepper activeStep={activeStep}/>
      <div className={classes.formsWrapper}>
        {forms.map((e) => (
          <div
            id={e.path}
            style={{
              backgroundColor: selectedCard === formsUrl + e.path ? '#2f2f38' : '',
              borderColor: selectedCard === formsUrl + e.path ? '#c4eb37' : ''
            }}
            onClick={(event) => handleCardClick(event, e.path)}
            className={styles.formCardContainer}
          >
            <h5 className={styles.cardTitle}>{e.title}</h5>
            <p
              className={styles.cardPreview}
              onClick={(event) => {
                event.stopPropagation();
                setPreviewForm(e.path);
                setOpenPreviewModal(true);
              }}
            >
              preview
            </p>
          </div>
        ))}
      </div>
      <Dialog
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div className={`preview ${styles.modalContainer}`}>
          <Icon
            onClick={() => setOpenPreviewModal(false)}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <Form src={formsUrl + `${previewForm}`} />
        </div>
      </Dialog>
    </>
  );
};

export default CardList;

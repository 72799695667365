import store from '../store';
import mutations from '../mutations';
import { userAxios } from '../../utils/axios';
import axios from 'axios';

export const loadPolls = async () => {
  try {
    const { data } = await userAxios.get(`/poll/user`);

    store.dispatch({
      type: mutations.poll.loadPolls,
      payload: data
    });
  } catch (e) {
    return Promise.reject({ message: 'Failed to load polls' });
  }
};

//fk2
export const loadMySubmittions = async () => {
  try {
    const { data } = await userAxios.get(`/poll/mySubmissions`);
    console.log("then polls history", data, mutations.poll.loadMySubmittions, mutations.poll.loadPolls);
    if (data.name != 'Error') {
      store.dispatch({
        type: mutations.poll.loadMySubmittions,
        payload: data
      });
    }
  } catch (e) {
    return Promise.reject({ message: 'Failed to load polls' });
  }
}


export const loadPoll = async (pollId) => {
  try {
    const { data } = await userAxios.get(`/poll/by/${pollId}`);
    store.dispatch({
      type: mutations.poll.loadPolls,
      payload: [data]
    });
    console.log("then poll", JSON.stringify(data));
    // return data["formUrl"];
  } catch (e) {
    return Promise.reject({ message: 'Failed to load poll' });
  }
};
//fk2

export const submitPolls = async (recipientIds, formUrls, senderId = null) => {
  try {
    await userAxios.post('/poll', {
      recipientIds: recipientIds,
      formUrls: formUrls,
      senderId: senderId ? senderId : null,
      admin: !senderId
    });
  } catch (e) {
    return Promise.reject({ message: 'Failed to load polls' });
  }
};

export const submitPoll = async (pollId, userId) => {
  try {
    await userAxios.post(`/poll/${pollId}`);
    await loadPolls(userId);
  } catch (e) {
    return Promise.reject({ message: 'Failed to load polls' });
  }
};

export const loadForms = async (formUrl) => {
  try {
    const { data } = await axios.get(formUrl);
    store.dispatch({
      type: mutations.poll.loadForms,
      payload: data
    });
  } catch (e) {
    return Promise.reject({ message: 'Failed to load forms' });
  }
};

// turn off modal displaying
export const disable = () => {
  store.dispatch({
    type: mutations.poll.disable
  });
};

// turn on modal displaying
export const activate = () => {
  store.dispatch({
    type: mutations.poll.activate
  });
};

export const loadNutritionistPendingSubmissions = async (form) => {
  const { data } = await userAxios.get('/poll/nutritionist/pending', {
    params: {
      form
    }
  });
  return data;
};

export const loadNutritionistSubmissions = async (form) => {
  const { data } = await userAxios.get('poll/nutritionistSubmissions', {
    params: {
      cardUrl: form
    }
  });
  return data;
};

export const loadAdminPendingSubmissions = async (form) => {
  const { data } = await userAxios.get(`/poll/admin/pending`, {
    params: {
      form
    }
  });
  return data;
};

export const loadClientSubmissions = async (id) => {
  const { data } = await userAxios.get(`/poll/client/${id}/submissions`);
  return data;
};

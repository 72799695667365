import { nutritionistActions, chatActions } from '../../../redux/actions';

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Select,
  Typography,
  MenuItem,
  Button
} from '@material-ui/core';
import { clientStatuses } from '../../../constants';

const columns = [
  {
    mapId: 1,
    id: 'user',
    label: 'Name',
    format: (value) => value.name
  },
  {
    mapId: 2,
    id: 'birthdate',
    label: 'Birthdate',
    format: (value) => moment(value).format('MM.DD.YYYY')
  },
  {
    mapId: 3,
    id: 'user',
    label: 'Email',
    format: (value) => value.email
  },
  {
    mapId: 4,
    id: 'createdAt',
    label: 'Date created',
    format: (value) => moment(value).format('MM.DD.YYYY')
  },
  {
    mapId: 5,
    id: 'nutritionistClients',
    label: 'Status',
    format: (value) => value.status
  },
  {
    mapId: 6,
    id: 'id',
    label: ''
  }
];

export default function PendingClientsTable() {
  const history = useHistory();
  const clients = useSelector((state) => state.nutritionist.clients);
  const { id } = useSelector((state) => state.user.nutritionist);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const properClientsArr = useMemo(() => {
    return clients.filter((client) => client.nutritionistClients.status === clientStatuses.PENDING || client.nutritionistClients.status ===  clientStatuses.REJECTED);
  }, [clients]);

  const table = useMemo(() => {
    return properClientsArr.slice(page * pageSize, (page + 1) * pageSize);
  }, [properClientsArr, pageSize, page]);

  useEffect(() => {
    nutritionistActions.loadClients();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const onOpenChat = async (clientId) => {
    await chatActions.initChat({ nutritionistId: id, clientId });
    history.push({ pathname: `/nutritionist/chat` });
  };

  const handleChange = async (e, clientId, nutritionistId) => {
    const { value } = e.target;
    await nutritionistActions.changeUserStatus({
      status: value,
      clientId,
      nutritionistId
    });
  };

  return (
    <>
      <div style={{ marginTop: 30 }}>
        <div className="d-flex justify-content-between align-items-center">
          <Typography
            style={{
              fontFamily: 'Bebas Neue',
              letterSpacing: '2.5px',
              fontSize: '2.5rem',
              color: '#EE79B3'
            }}
            component="h1"
            variant="h5"
          >
            PENDING CLIENTS
          </Typography>
        </div>
      </div>

      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.mapId}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    {columns.map((column) => {
                      const value = column.format ? column.format(row[column.id]) : row[column.id];
                      if (column.label === 'Name')
                        return (
                          <TableCell key={column.mapId}>
                            <Link
                              to={`/nutritionist/clients/${row.id}`}
                              style={{ textDecoration: 'none', color: '#c4eb37' }}
                            >
                              {value}
                            </Link>
                          </TableCell>
                        );
                      if (column.id === 'nutritionistClients') {
                        return (
                          <TableCell key={column.mapId}>
                            <Select
                              value={value}
                              onChange={(event) =>
                                handleChange(event, row.id, row.nutritionistClients.nutritionistId)
                              }
                              labelId="status-label"
                              name="status"
                              style={{ width: '125px' }}
                            >
                              {_.map(clientStatuses, (status) => (
                                <MenuItem value={status}>{status}</MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                        );
                      }
                      if (column.id === 'id') {
                        return (
                          <TableCell>
                            <Button
                              onClick={() => onOpenChat(value)}
                              style={{ background: '#424242', color: '#EE79B3' }}
                              variant="contained"
                            >
                              Open chat
                            </Button>
                          </TableCell>
                        );
                      }
                      return <TableCell key={column.mapId}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={properClientsArr.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </>
  );
}

import { Container, LinearProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import FormSubmitStepFirst from './form-submit-step1';
import FormSubmitStepSecond from './form-submit-step2';
import PreviewData from './preview-data';
import styles from './IntakeForm.module.scss';

const IntakeForm = ({ nutritionist: nutritionistInitial, onSubmit, email }) => {
  const [step, setStep] = useState(1);
  const [nutritionist, setNutritionits] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const submitFirstForm = (data) => {
    // setFirstFormData(data);
    setNutritionits({
      ...nutritionist,
      ...data
    });
    setStep(2);
  };

  const backToFirstStep = () => {
    setStep(1);
  };

  const backToSecondStep = () => {
    setStep(2);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const submitSecondForm = (data) => {
    setNutritionits({
      ...nutritionist,
      ...data
    });
    setStep(3);
    //fk2
    // submit();
    setIsLoading(true);
  };

  const prepareNutritionist = (data) => {
    let formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return formData;
  };

  const submit = useCallback(() => {
    const data = prepareNutritionist(nutritionist);
    onSubmit(data);
  }, [nutritionist]);

  return (
    <Container maxWidth="md">
      <div className={styles.intakeForm}>
        <div className={styles.intakeForm_header}>
          <h4 className={styles.intakeForm_header_title}>
            {step === 3 ? 'Form result' : 'Coach Intake Form'}
          </h4>
          <span className={styles.intakeForm_header_description}>
            {step === 3
              ? 'Check your info and submit form or return to edit'
              : 'Please fill out all the required fields and attach your resume below'}
          </span>
          <span
            style={ {fontStyle: 'italic'} }
           className={styles.intakeForm_header_description}>
            <iframe style={{display: 'block', margin: 'auto'}} width="560" height="315" src="https://www.youtube.com/embed/kvjznOa-Fjc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br />
          * If you exit this page before pressing submit at the end of the form on the second page, your information will not be saved. You will be able to edit and update your profile after submission.
          </span>
        </div>
        {isLoading && 
        <div>
          <LinearProgress />
          <p>Please wait!</p>
        </div>
        }
        <div style={{ display: step === 1 ? 'block' : 'none' }}>
          <FormSubmitStepFirst
            nutritionistInitial={nutritionistInitial}
            submitForm={submitFirstForm}
            email={email}
          />
        </div>
        <div style={{ display: step === 2 ? 'block' : 'none' }}>
          <FormSubmitStepSecond
            nutritionistInitial={nutritionistInitial}
            backToFirstStep={backToFirstStep}
            submitForm={submitSecondForm}
          />
        </div>
        <div style={{ display: step === 3 ? 'block' : 'none' }}>
          {step === 3 && (
            <PreviewData
              styles={styles}
              backToSecondStep={backToSecondStep}
              nutritionist={nutritionist}
              submitData={submit}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default IntakeForm;

import {
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  LinearProgress
} from '@material-ui/core';
import React, { useState } from 'react';

import { isEmail } from '../../../utils/helper';
import { nutritionistActions } from '../../../redux/actions';

export default function () {
  const [email, setEmail] = useState('');
  const [successMesage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = ({ target }) => {
    setSuccessMessage(null);
    const { value } = target;
    setEmail(value);
  };

  const handleSubmit = async () => {
    if (!isEmail(email)) {
      return setError('Email is not valid');
    }
    setError(null);
    setIsLoading(true);

    try {
      await nutritionistActions.submitRefferal(email);
      setSuccessMessage('Invitation email sent!');
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeySubmit = async (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: 100 }}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Introduce Planibble To a New Coach And Get a Referral Bonus
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            There are many reasons to use Plannible. One of them is our Referral program. You can
            enrich your group of Clients by invite. Wanted to work with your friends? Invite them to
            Plannible! Just write down the personal email address of your friend, then click on the
            ‘Invite’ button. We will send the invitation to the platform. When your friend becomes
            an authorised user, you can cooperate through the Plannible.
          </Typography>

          <div className="mt-4 d-flex justify-content-between">
            {!isLoading && (
              <>
                <TextField
                  label="Email"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  value={email}
                  onKeyPress={handleKeySubmit}
                />

                <Button
                  className="ml-2"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleSubmit}
                >
                  Invite
                </Button>
              </>
            )}
          </div>
          {error && <div className="text-center text-danger"> {error}</div>}

          {successMesage && (
            <Typography variant="subtitle1" className="text-center">
              {successMesage}
            </Typography>
          )}
          {isLoading && <LinearProgress />}
        </CardContent>
      </Card>
    </Container>
  );
}

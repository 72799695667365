import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField
} from '@material-ui/core';
import { filterTable } from '../../../utils/helper';
import { groupChatTypes } from '../../../constants';

const clientscolumns = [
  {
    id: 'user.name',
    label: 'Name'
  },
  {
    id: 'coachPreference.groupChatType',
    label: 'Plan',
    format: (value) => groupChatTypes[value].desc
  }
];

export default function ({ clients, selectClient }) {
  //   const users = clients.map((o) => o.user);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const table = useMemo(() => {
    const filterFields = ['user.name', 'user.email', 'coachPreference.groupChatType'];
    const result = filterTable(clients, filterFields, search);
    return result.slice(page * pageSize, (page + 1) * pageSize);
  }, [clients, pageSize, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleGroupSearch = _.debounce((e) => {
    const { value } = e.target;
    setSearch(value);
  }, 500);

  return (
    <div style={{ width: '30%' }}>
      <div className="d-flex justify-content-between align-items-center">
        <Typography
          style={{
            fontFamily: 'Bebas Neue',
            color: '#EE79B3',
            fontSize: '2.5rem',
            letterSpacing: '2px'
          }}
          component="h1"
          variant="h5"
        >
          Clients
        </Typography>

        <TextField
          className="my-2"
          variant="outlined"
          label="Search"
          name="search"
          onChange={handleGroupSearch}
        />
      </div>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {clientscolumns.map((column) => (
                  <TableCell
                    style={{ fontFamily: 'Bebas Neue', fontSize: '1.2rem' }}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((row) => {
                // console.log('clients', row);
                return (
                  <TableRow
                    hover
                    style={{ cursor: 'pointer' }}
                    key={row.id}
                    onClick={() => selectClient(row.id)}
                  >
                    {clientscolumns.map((column) => {
                      let value = column.format
                        ? column.format(_.get(row, column.id))
                        : _.get(row, column.id);
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          style={{ color: '#c4eb37' }}
          count={clients.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}

// export default groupsTable;

import styled from 'styled-components/macro'

  export const Input = styled.input`
  display: none;
`;


export const Container = styled.div`
  margin: 10px 0;
`;


import React from 'react';

import NutritionistStepper from '../../dashboard/stepper';

import styles from './Index.module.scss';

const PendingView = () => {
  return (
    <>
      <NutritionistStepper />
      <div className={styles.container}>
        <h1 className={styles.title}>
          Your request is under review. You will get email confirmation!
          After confirmation you will be able to edit or update your profile.
        </h1>
      </div>
    </>
  );
};

export default PendingView;

import React, { useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText, Avatar, Button } from '@material-ui/core';

import { userTypes } from '../../constants';
import { chatActions } from '../../redux/actions';
import chat from '../../redux/actions/chat';

const headerStyle = {
  fontSize: '1.5rem',
  marginLeft: '1rem',
  fontFamily: 'Bebas Neue',
  letterSpacing: '1.5px',
  color: '#EE79B3'
};

function AdminChat({ chat, onSelect }) {
  const user = useSelector((state) => state.user);
  const unreadCounts = useSelector((state) => state.chat.unreadCounts);

  if (!user) return <div />;

  const initAdminChat = async () => {
    let nutritionistId, clientId;
    if (user.type === userTypes.client) {
      clientId = user.client.id;
    }
    if (user.type === userTypes.nutritionist) {
      nutritionistId = user.nutritionist.id;
    }
    onSelect(null);
    await chatActions.initChat({ nutritionistId, clientId, toAdmin: true });
    await chatActions.loadChatData();
  };

  if (!chat) {
    return (
      <Button color="primary" style={{ margin: 10 }} onClick={initAdminChat}>
        Request chat with admin
      </Button>
    );
  }

  const unreadCount = unreadCounts[chat.channelId] || 0;

  return (
    <ListItem button key={chat.channelId} onClick={() => onSelect(chat)}>
      <Avatar style={{ margin: '0 10px' }}> A </Avatar>
      <ListItemText primary={'Planibble Admin'} />
      {unreadCount > 0 && (
        <div
          style={{
            width: '2rem',
            height: '2rem',
            borderRadius: '9999px',
            background: '#EE79B3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {unreadCount}
        </div>
      )}
    </ListItem>
  );
}

function ClientChatList({ chats, onSelect }) {
  const unreadCounts = useSelector((state) => state.chat.unreadCounts);

  return (
    <>
      <p style={headerStyle}>Coaches</p>
      {chats.map((chat) => {
        const unreadCount = unreadCounts[chat.channelId] || 0;
        let chatTitle = `${chat.nutritionist.firstName} ${chat.nutritionist.lastName}`;
        let avatarSrc = chat.nutritionist.user.image;
        let isGroupChat = false;
        if (chat.groupChatType != 'NONE') {
          chatTitle = `${chat.groupName}`;
          avatarSrc = 'none';
          isGroupChat = true;
        }
        return (
          <ListItem button key={chat.channelId} onClick={() => onSelect(chat)}>
            <Avatar
              src={avatarSrc}
              style={{ margin: '0 10px', border: isGroupChat ? '3px rgb(196, 235, 55) solid' : '' }}
            />
            <ListItemText primary={chatTitle} />
            {isGroupChat ? 'Group' : ''}
            {isGroupChat && chat.locked != false ? '(locked)' : ''}
            {unreadCount > 0 && (
              <div
                style={{
                  width: '2rem',
                  height: '2rem',
                  borderRadius: '9999px',
                  background: '#EE79B3',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {unreadCount}
              </div>
            )}
          </ListItem>
        );
      })}
    </>
  );
}

function NutritionistChatList({ chats, onSelect }) {
  const unreadCounts = useSelector((state) => state.chat.unreadCounts);

  return (
    <>
      <p style={headerStyle}>Clients</p>
      {chats.map((chat) => {
        console.log('coach chat', chat);
        const unreadCount = unreadCounts[chat.channelId] || 0;

        let chatTitle = chat.client?.user.name;
        let avatarSrc = chat.client?.user ? chat.client?.user.image : null;
        let isGroupChat = false;
        if (chat.groupChatType != 'NONE') {
          chatTitle = `${chat.groupName}`;
          avatarSrc = 'none';
          isGroupChat = true;
        }
        return (
          <>
            <ListItem button key={chat.channelId} onClick={() => onSelect(chat)}>
              <Avatar
                src={avatarSrc}
                style={{
                  margin: '0 10px',
                  border: isGroupChat ? '3px rgb(196, 235, 55) solid' : ''
                }}
              />
              <ListItemText primary={chatTitle} />
              {isGroupChat ? 'Group' : ''}
              {unreadCount > 0 && (
                <div
                  style={{
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '9999px',
                    background: '#EE79B3',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {unreadCount}
                </div>
              )}
            </ListItem>
          </>
        );
      })}
    </>
  );
}

function ChatList({ onSelect, user }) {
  const chats = useSelector((state) => state.chat.chats);
  console.log('chats ', chats);
  const regularChats = useMemo(() => chats.filter((c) => !c.toAdmin), [chats]);
  const adminChat = useMemo(() => chats.find((c) => c.toAdmin), [chats]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        minHeight: 400
      }}
    >
      <AdminChat chat={adminChat} onSelect={onSelect} />

      <List style={{ maxHeight: '890px' }}>
        <ListItem>
          <span style={{ fontSize: '20px', margin: '5px auto' }}>Conversations</span>
        </ListItem>
        {_.isEmpty(regularChats) && (
          <div style={{ textAlign: 'center' }}>
            <p
              style={{
                fontSize: '1.5rem',
                fontFamily: 'Bebas Neue',
                letterSpacing: '1.5px',
                color: '#EE79B3'
              }}
            >
              You don't have any active conversations
            </p>
          </div>
        )}
        {!_.isEmpty(regularChats) && (
          <>
            {user.type === userTypes.client && (
              <ClientChatList chats={regularChats} onSelect={onSelect} />
            )}

            {user.type === userTypes.nutritionist && (
              <NutritionistChatList chats={regularChats} onSelect={onSelect} />
            )}
          </>
        )}
      </List>

      
    </div>
  );
}

export default ChatList;

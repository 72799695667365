import React, { useMemo, useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  Icon,
  Button,
  IconButton
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Divider } from '@material-ui/core';

import styles from './Profile.module.scss';
import Avatar from 'react-avatar-edit';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { nutritionistActions, userActions } from '../../../redux/actions';
import { useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import adminActions from '../../../redux/actions/admin';
import { useHistory } from 'react-router';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import PromotionModal from './promotionModal';
import moment from 'moment';
import { nutritionistStatuses } from '../../../constants';

import { TextField, Grid, MenuItem } from '@material-ui/core';
import FieldText from '../../../../src/components/common/TextField';
import RadioButtonsGroup from '../../../../src/components/common/RadioButtonsGroup';
import { ColorLensOutlined } from '@material-ui/icons';

import { Card } from '@material-ui/core';

import Chart from 'react-apexcharts';
import NutritionistClientsTable from '../../admin/nutritionists/clients';

const formatedData = {
  firstName: 'First name',
  lastName: 'Last name',
  address1: 'Address',
  address2: 'Address2',
  city: 'City',
  state: 'State',
  email: 'Email',
  hoursWeekly: 'How many hours can you work weekly?',
  canWorkAtNights: 'Can you work at nights?',
  isOlder: 'Are you 18 or older?',
  gender: 'Gender',
  phone: 'Phone',
  zipcode: 'Zipcode',
  felony: 'Have you ever been convicted of a felony?',
  felonyExplain: 'Felony explain',
  howDidFind: 'How did you find out about this position?',
  schedule: 'Work schedule',
  expYears: 'Years of Experience',
  dietitian: 'Are you a Registered Dietitian?',
  degree: 'Nutrition Degree',
  cssd: 'CSSD: Certified Specialist in Sports Dietetics',
  certifications: 'Do you hold any of the following Nutrition Certifications?',
  specialities: 'Do you hold any of the following Nutrition Specialties?',
  preferableChat: 'Preferable choice of communication',
  resume: 'Resume',
  coverLetter: 'Cover letter',
  currentOffering: 'Current services offered',
  liabilityInsurance: 'Liability insurance'
};

const NutritionistProfile = ({ user }) => {
  const { nutritionist, image: userImage } = user;
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [avatarColor, setAvatarColor] = useState('pink');
  const [displayPromotion, setDisplayPromotion] = useState(false);
  const [promotionTime, setPromotionTime] = useState(
    nutritionist.nutritionistOptions.promotionTime === null
      ? null
      : moment(nutritionist.nutritionistOptions.promotionTime)
  );

  const userType = useSelector((state) => state.auth.userType);
  const userId = useSelector((state) => state?.user?.id);
  const availability = useSelector((state) => state.user?.nutritionist?.availability);
  const history = useHistory();

  const mainNutritionistInformation = useMemo(() => {
    const mainInfo = _.pick(nutritionist, [
      'hoursWeekly',
      'canWorkAtNights',
      'isOlder',
      'felony',
      'felonyExplain',
      'expYears',
      'schedule',
      'dietitian',
      'degree',
      'cssd',
      'certifications',
      'specialities',
      'preferableChat',
      'resume',
      'coverLetter',
      'liabilityInsurance'
    ]);
    return mainInfo;
  }, [nutritionist]);

  if (moment(nutritionist.nutritionistOptions.promotionTime).diff(moment()) < 0) {
    (async () => {
      await adminActions.promoteNutritionist({
        nutritionistId: user.nutritionist.id,
        promoted: false,
        promotionTime: null
      });

      await adminActions.loadNutritionists();
      setPromotionTime(null);
    })();
  }

  const getValue = useCallback((value, isFile) => {
    if (_.isObject(value)) {
      if (_.isArray(value)) {
        const concatinatedValue = value.join(', ');
        return concatinatedValue;
      } else {
        return value.name;
      }
    }

    if (value === true || value === false) {
      return value === true ? 'Yes' : 'No';
    }

    if (value === 'true' || value === 'false') {
      return value === 'true' ? 'Yes' : 'No';
    }

    if (isFile) {
      return (
        <a target="_blank" href={value} rel="noreferrer">
          {value}
        </a>
      );
    }

    return value;
  }, []);

  const onCrop = (preview) => {
    setImage(preview);
  };

  const upload = async () => {
    setOpen(false);
    if (image) {
      await userActions.setUserImage(image);
      setImage(null);
      await userActions.loadUser();
    }
  };

  const onOpen = () => {
    setImage(null);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setImage(null);
  };

  const availabilitySwitchHandler = async () => {
    console.log(!availability);
    await nutritionistActions.setAvailability(!availability);
  };

  const promote = async () => {
    await adminActions.promoteNutritionist({
      nutritionistId: user.nutritionist.id,
      promoted: true,
      promotionTime
    });

    await adminActions.loadNutritionists();
    await adminActions.loadNutritionistApplications();
  };

  const unpromote = async () => {
    await adminActions.promoteNutritionist({
      nutritionistId: user.nutritionist.id,
      promoted: false,
      promotionTime
    });

    await adminActions.loadNutritionists();
    await adminActions.loadNutritionistApplications();
  };

  const updatePromotionTime = async (date) => {
    await adminActions.promoteNutritionist({
      nutritionistId: user.nutritionist.id,
      promoted: nutritionist.nutritionistOptions.promoted,
      promotionTime: date
    });
  };

  //fk2
  const initialData = {
    specialties: '',
    price: '',
    description: ''
  };
  const [data, setData] = useState(initialData);
  const errors = { description: 'desc' };
  // const data = {"price": "price"}
  const prices = [
    { label: '$50', value: 50 },
    { label: '$100', value: 100 }
  ];
  const [openExtra, setOpenExtra] = useState(false);

  const [hasExtra, setHasExtra] = useState(false);
  const [extraSpecialtiesList, setExtraSpecialtiesList] = useState(null);
  const getExtraSpecialties = async () => {
    const nutritionistId = nutritionist.id;
    console.log(nutritionistId);
    const result = await nutritionistActions.getExtraSpecialties(nutritionistId);
    console.log(result.data);
    setExtraSpecialtiesList(result.data);
    setHasExtra(true);
  };

  useEffect(() => {
    getExtraSpecialties();
  }, []);

  const onOpenExtra = () => {
    setOpenExtra(true);
  };

  const onChange = (e) => {
    setData((old) => ({
      ...old,
      [e.target.name]: e.target.value
    }));
  };

  const onCloseExtra = () => {
    setOpenExtra(false);
  };

  const extraSpecialties = (data) => {
    let formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
      console.log(key + ' :' + value);
    });

    return formData;
  };
  const submitData = async () => {
    const formData = extraSpecialties(data);
    await nutritionistActions.setExtraSpecialties(data);
    history.go(0);
    // let myForm = document.getElementById('extraSpecialties');
    // console.log(formData.values);
    //
  };

  //fk2
  const chartData = {
    series: [
      {
        name: 'Earnings',
        data: [
          // [1327359600000,30.95],
        ]
      }
    ],
    colors: ['#c4eb37'],
    yaxis: {
      labels: {
        style: {
          colors: 'white'
        }
      }
    },
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 10,
      style: 'hollow'
    },
    xaxis: {
      type: 'datetime',
      min: new Date('1 Jan 2022').getTime(),
      tickAmount: 6,
      labels: {
        style: {
          colors: 'white'
        }
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    }
  };

  const [reportsData, setReportsData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [optionsData, setOptionsData] = useState(chartData);

  const loadReportsdata = async () => {
    if (userType === 'admin') {
      const data = await adminActions.loadReportsDataFor(nutritionist.id);
      setReportsData(data);
    } else {
      const data = await adminActions.loadReportsDataMe();
      setReportsData(data);
    }
  };

  useEffect(() => {
    loadReportsdata();
  }, []);

  useEffect(() => {
    let amount = 0;

    if (reportsData != null) {
      console.table(reportsData);
      amount = reportsData
        .filter((item) => item.type != 'payout')
        .reduce((total, item, index) => total + item.amount, 0);

      reportsData.forEach((el, index) => {
        reportsData[index].month = new Date(el.created * 1000).getMonth();
      });
      console.table(reportsData);

      let monthlyData = reportsData
        .filter((item) => item.type != 'payout')
        .reduce((prev, next) => {
          if (next.month in prev) {
            prev[next.month].amount += next.amount;
          } else {
            prev[next.month] = next;
          }
          return prev;
        }, {});

      let result = Object.keys(monthlyData).map((month) => monthlyData[month]);

      // console.log("new result", result);

      chartData.series[0].data = result.map((item) => [item.created * 1000, item.amount / 100]);

      setOptionsData(chartData);
      var x = 100;
    }
    setTotalAmount(amount / 100);
  }, [reportsData]);

  return (
    <>
      <div className={styles.profile}>
        <div className={styles.profile_leftSection}>
          <div className={styles.profile_leftSection_wrapper}>
            <div className={styles.profile_profileLogo}>
              <img className={styles.profileImage} src={userImage} alt="logo" />
              {userType === 'nutritionist' && userId === nutritionist.userId ? (
                <div onClick={onOpen} className={styles.imageHover}>
                  <AddAPhotoIcon fontSize={'large'} htmlColor={'black'} />
                </div>
              ) : null}
            </div>
            <div className={styles.profile_leftSection_promotion}>
              <div
                className={styles.profile_leftSection_promotion_star}
                onClick={() => {
                  if (
                    userType === 'admin' &&
                    nutritionist.nutritionistStatus.status === nutritionistStatuses.APPROVED
                  ) {
                    setDisplayPromotion(true);
                  }
                }}
              >
                {nutritionist.nutritionistOptions.promoted ? (
                  <StarIcon
                    style={{ color: 'yellow', cursor: userType === 'admin' ? 'pointer' : 'cursor' }}
                  />
                ) : (
                  <StarBorderIcon
                    style={{ color: 'yellow', cursor: userType === 'admin' ? 'pointer' : 'cursor' }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.profile_name}>
            <span>
              {nutritionist.firstName} {nutritionist.lastName}
            </span>
          </div>
          <Divider className={styles.divider} />
          <div className={styles.profile_leftSection_contactInfo}>
            <span className={styles.profile_leftSection_contactInfo_title}>
              Contact Information
            </span>
            <div className={styles.profile_leftSection_contactInfo_detail}>
              <div
                className={`${styles.profile_leftSection_contactInfo_detail_phoneBlck} ${styles.flexRow} ${styles.mt4}`}
              >
                <span className={styles.key}>Phone:</span>
                <span className={styles.value}>{nutritionist.phone}</span>
              </div>
              <div
                className={`${styles.profile_leftSection_contactInfo_detail_emailBlck} ${styles.flexRow} ${styles.mt4}`}
              >
                <span className={styles.key}>Email:</span>
                <span className={styles.value}>{nutritionist.email}</span>
              </div>
              <div
                className={`${styles.profile_leftSection_contactInfo_detail_addressBlck} ${styles.mt4}`}
              >
                <span className={styles.key}>Address:</span>
                <div className={`${styles.value} ${styles.flexColumn}`}>
                  <span className={styles.address}>{nutritionist.address1}</span>
                  {nutritionist.address2 !== 'undefined' && (
                    <span className={styles.address}>{nutritionist.address2}</span>
                  )}
                </div>
              </div>
              <div
                className={`${styles.profile_leftSection_contactInfo_detail_emailBlck} ${styles.flexRow} ${styles.mt4}`}
              >
                <span className={styles.key}>State:</span>
                <span className={styles.value}>{nutritionist.state}</span>
              </div>
              <div
                className={`${styles.profile_leftSection_contactInfo_detail_emailBlck} ${styles.flexRow} ${styles.mt4}`}
              >
                <span className={styles.key}>city:</span>
                <span className={styles.value}>{nutritionist.city}</span>
              </div>
              <div
                className={`${styles.profile_leftSection_contactInfo_detail_emailBlck} ${styles.flexRow} ${styles.mt4}`}
              >
                <span className={styles.key}>zipCode:</span>
                <span className={styles.value}>{nutritionist.zipcode}</span>
              </div>
              <Divider className={styles.mt4} />
              {nutritionist.currentOffering && (
                <div className={`${styles.profile_leftSection_additionalInfo} ${styles.mt4}`}>
                  <span className={`${styles.profile_leftSection_additionalInfo_title}`}>
                    Current services offered
                  </span>
                  <div
                    className={`${styles.profile_leftSection_additionalInfo_block} ${styles.mt4}`}
                  >
                    {nutritionist.currentOffering}
                  </div>
                </div>
              )}
              {
                /*fk2*/ true && (
                  <div className={`${styles.profile_leftSection_additionalInfo} ${styles.mt4}`}>
                    <span className={`${styles.profile_leftSection_additionalInfo_title}`}>
                      Additional Specialties
                    </span>
                    <IconButton onClick={onOpenExtra}>
                      <AddBoxIcon />
                    </IconButton>
                    <div
                      className={`${styles.profile_leftSection_contactInfo_detail_emailBlck} ${styles.flexRow} ${styles.mt4}`}
                    >
                      <TableContainer component={Paper} style={{ marginTop: 10 }}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Title</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {hasExtra &&
                              extraSpecialtiesList.map((specialty) => (
                                <TableRow>
                                  <TableCell>{specialty.specialties}</TableCell>
                                  <TableCell>{specialty.description}</TableCell>
                                  <TableCell>
                                    {specialty.approved ? 'Approved' : 'Pending'}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )
              }
              <Dialog
                open={openExtra}
                onClose={onCloseExtra}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                classes={{
                  paper: styles.modal
                }}
              >
                <div className={styles.modalContainer}>
                  <form id="extraSpecialties" noValidate>
                    <div className={styles.fieldsBlock}>
                      <Grid className={styles.fieldsBlock_row} container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <label>Extra Specialties *</label>
                          <TextField
                            inputProps={{ maxLength: 25 }}
                            variant="outlined"
                            className={styles.inputField}
                            required
                            fullWidth
                            id="specialties"
                            placeholder="Extra Specialties"
                            label=""
                            value={data.specialties}
                            onChange={onChange}
                            name="specialties"
                            autoComplete="firstName"
                          />
                        </Grid>
                      </Grid>
                      <RadioButtonsGroup
                        field="price"
                        styles={styles}
                        title="Price *"
                        id="price"
                        name="price"
                        value={data.price}
                        handleChange={onChange}
                        errors={errors}
                        withOther={false}
                        items={prices}
                      />
                      <Grid>
                        <Grid>
                          <FieldText
                            title="Extra Specialties Description"
                            placeholder="Extra Specialties Description"
                            errors={errors}
                            name="description"
                            id="description"
                            handleChange={onChange}
                            value={data.description}
                            // onChange={onChange}
                            required
                            rows={5}
                            maxLength={140}
                            styles={styles}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} className={styles.intakeForm_actionButtons}>
                        <Button
                          onClick={submitData}
                          color="primary"
                          variant="contained"
                          className={styles.intakeForm_actionButtons_nextBtn}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </div>
                  </form>
                  <Icon
                    onClick={onCloseExtra}
                    className={`fa fa-times ${styles.modalCross}`}
                    style={{ fontSize: 30 }}
                  />
                </div>
              </Dialog>
              {userType === 'nutritionist' && userId === nutritionist.userId ? (
                <div>
                  Accept new clients ?
                  <Switch
                    checked={availability}
                    onChange={availabilitySwitchHandler}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
              ) : null}
              {userType === 'nutritionist' || true ? ( // fk2 stripe chart
                <Grid container spacing={2} style={{ margin: '10px' }}>
                  <Grid item xs={12}>
                    <Card>
                      <h3 style={{ margin: '10px' }} className={styles.chartTitle}>
                        Income {totalAmount && <span>(Total Earnings: $ {totalAmount})</span>}
                      </h3>
                      <Chart
                        // options={statistic.income.options}
                        // series={statistic.income.series}
                        options={optionsData}
                        series={optionsData.series}
                        type="bar"
                        height="500"
                        className="chartFontColor"
                      />
                    </Card>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles.profile_rightSection}>
          <div className={styles.profile_rightSection_mainInfo}>
            <TableContainer component={Paper}>
              <Table className={styles.table} aria-label="customized table">
                <TableBody>
                  {Object.entries(mainNutritionistInformation).map(([key, value]) => {
                    if (key === 'felonyExplain' && !mainNutritionistInformation.felony) {
                      return;
                    }
                    return (
                      <TableRow key={key}>
                        <TableCell className={styles.table_key} component="th" scope="row">
                          {formatedData[key]}
                        </TableCell>
                        <TableCell className={styles.table_value} align="right">
                          {getValue(
                            value,
                            key === 'resume' ||
                              key === 'coverLetter' ||
                              key === 'liabilityInsurance'
                              ? true
                              : false
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {userType === 'admin' &&
          nutritionist?.nutritionistStatus?.status &&
          nutritionist?.nutritionistStatus?.status === 'APPROVED' ? (
            <Button
              color="primary"
              variant="contained"
              className={styles.suspendButton}
              onClick={() => {
                adminActions.updateNutritionistStatus({
                  nutritionistId: nutritionist.id,
                  data: { status: 'SUSPENDED' }
                });
                history.push('/');
              }}
            >
              SUSPEND ACCOUNT
            </Button>
          ) : (
            ''
          )}
          <NutritionistClientsTable nutritionist={nutritionist} />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div className={styles.modalContainer}>
          <Icon
            onClick={onClose}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <div>
            <div className={styles.avatarWrapper}>
              <div
                onMouseEnter={() => {
                  setAvatarColor('#EE79B3');
                }}
                onMouseLeave={() => {
                  setAvatarColor('white');
                }}
              >
                <Avatar
                  labelStyle={{
                    color: avatarColor,
                    cursor: 'pointer',
                    fontSize: '20px',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  borderStyle={{
                    fontSize: '20px',
                    border: `2px dashed ${avatarColor}`,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                  width={300}
                  height={200}
                  onCrop={onCrop}
                  onClose={() => setImage(null)}
                />
              </div>
              {image ? <img className={styles.avatarPreview} src={image} alt="Preview" /> : null}
            </div>
            <button onClick={upload} className={styles.uploadButton}>
              Upload
            </button>
          </div>
        </div>
      </Dialog>
      <PromotionModal
        updatePromotionTime={updatePromotionTime}
        promote={promote}
        unpromote={unpromote}
        promoted={nutritionist.nutritionistOptions.promoted}
        open={displayPromotion}
        onClose={() => setDisplayPromotion(false)}
        promotionTime={promotionTime}
        setPromotionTime={setPromotionTime}
      />
    </>
  );
};

export default NutritionistProfile;

import React from 'react';

import { Grid, FormControlLabel, FormGroup, Checkbox, TextField } from '@material-ui/core';

const MultipleChoices = ({
  styles,
  items,
  checkPresentOption,
  field,
  handleChangeOptions,
  label,
  withOtherOption,
  otherPlaceholder,
  errorExist,
  otherValue,
  otherName,
  otherId,
  otherHandleChange,
  otherHelperText

}) => {
  return (
    <div className={styles.fieldsBlock}>
      <Grid className={styles.fieldsBlock_column} container spacing={2}>
        <Grid item xs={12}>
          <label>{label}</label>
          <FormGroup>
            {items.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checkPresentOption(field, item)}
                    onChange={() => handleChangeOptions(field, item)}
                    name={item}
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
          {withOtherOption && (
              <Grid item xs={12}>
                <span>{"Other"}</span>
                <TextField
                  inputProps={{
                    maxlength: 200
                  }}
                  error={errorExist && errorExist(otherName)}
                  autoComplete={otherName}
                  name={otherName}
                  variant="outlined"
                  onChange={otherHandleChange}
                  fullWidth
                  value={otherValue}
                  placeholder={otherPlaceholder}
                  className={styles.inputField}
                  id={otherId}
                  helperText={otherHelperText}
                  label=""
                  autoFocus
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MultipleChoices;

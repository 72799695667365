import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';

import { Grid, Button, TextField } from '@material-ui/core';

import howDidFind from '../../constants/howDidFind';
import certifications from '../../constants/certifications';
import specialities from '../../constants/specialities';
import preferableChart from '../../constants/preferableChat';

import validationSchema from './secondFormValidation';
import ValidationService from '../../utils/validation-service';

import styles from './IntakeForm.module.scss';

import RadioButtonsGroup from '../common/RadioButtonsGroup/index';
import MultipleChoices from '../common/MultipleChoices';
import DoubleChoice from '../common/DoubleChoice';
import InputFile from '../common/InputFile';
import FieldText from '../common/TextField';

const initialData = {
  felony: '',
  felonyExplain: '',
  howDidFind: '',
  expYears: '1',
  dietitian: '',
  degree: '',
  cssd: '',
  certifications: [],
  specialities: [],
  preferableChat: [],
  resume: '',
  coverLetter: '',
  currentOffering: '',
  liabilityInsurance: '',
};

const FormSubmitStepSecond = ({ submitForm, nutritionistInitial, backToFirstStep }) => {
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!_.isEmpty(nutritionistInitial)) {
      let dataUser = {};

      Object.entries(data).forEach(([key, value]) => {
        // dataUser[key] = nutritionistInitial[key] === (true || false) ? nutritionistInitial[key].toString() : nutritionistInitial[key];
        if (_.isArray(nutritionistInitial[key])) {
          dataUser[key] = nutritionistInitial[key];
        } else {
          dataUser[key] = nutritionistInitial[key]?.toString();
        }
      });

      if (nutritionistInitial.preferableChat === null) {
        dataUser.preferableChat = [];
      }

      setData(dataUser);
    }
    //fk2
    if (nutritionistInitial !== null){
      const otherCertifications = nutritionistInitial['certifications'].filter(x => certifications.indexOf(x) === -1);
      // document.getElementById('otherCertifications').value = otherCertifications.pop();
      if(otherCertifications.length > 0){
        if(otherCertifications[0] !== null && otherCertifications[0] !== undefined){
          document.getElementById('otherCertifications').value = otherCertifications[0];
        }
      }
    }
    if (nutritionistInitial !== null){
      const otherSpecialties = nutritionistInitial['specialities'].filter(x => specialities.indexOf(x) === -1);
      if (otherSpecialties.length > 0) {
        if(otherSpecialties[0] !== null && otherSpecialties[0] !== undefined){
          document.getElementById('otherSpecialties').value = otherSpecialties[0];
        }
      }
    }
    //fk2
  }, [nutritionistInitial]);

  const onChange = (e) => {
    setData((old) => {
      const { name, value } = e.target;
      const result = {
        ...old,
        [name]: value
      };
//fk2
if(name === 'otherCertifications'){
let otherCertificationsValue = document.getElementById('otherCertifications').value;
    const hasOthers = 
      (otherCertificationsValue === null || otherCertificationsValue === undefined || otherCertificationsValue ==='')
      ? false : true;

      if (!hasOthers){
        // const otherCertifications = document.getElementById('otherCertifications').value
        const oldOtherCertificationsValue = result.certifications.filter(x => certifications.indexOf(x) === -1);
        const oldOtherCertificationsIndex = result.certifications.indexOf(oldOtherCertificationsValue[0]);
        
        if(oldOtherCertificationsIndex !== -1)
        result.certifications.splice(oldOtherCertificationsIndex, 1);
      }

}
if(name === 'otherSpecialties'){
  let otherSpecialtiesValue = document.getElementById('otherSpecialties').value;
      const hasOtherspecialties = 
        (otherSpecialtiesValue === null || otherSpecialtiesValue === undefined || otherSpecialtiesValue ==='')
        ? false : true;
  
        if (!hasOtherspecialties){
          // const otherCertifications = document.getElementById('otherCertifications').value
          const oldOtherSpecialtiesValue = result.specialities.filter(x => specialities.indexOf(x) === -1);
          const oldOtherSpecialtiesVIndex = result.specialities.indexOf(oldOtherSpecialtiesValue[0]);
          
          if(oldOtherSpecialtiesVIndex !== -1)
          result.specialities.splice(oldOtherSpecialtiesVIndex, 1);
        }
  
  }
  //fk2
      if (name === 'felony') {
        result.felonyExplain = '';
      }
      return result;
    });
  };

  const checkPresentOption = useCallback(
    (param, option) => {
      const result = data[param].includes(option);
      return result;
    },
    [data]
  );


  const handleChangeOptions = useCallback(
    (param, option) => {
      const isPresent = data[param].includes(option);

      if (!isPresent) {
        const newData = data[param];
        newData.push(option);
        setData({
          ...data,
          [param]: [...newData]
        });
      } else {
        const newData = data[param].filter((item) => item !== option);
        setData({
          ...data,
          [param]: [...newData]
        });
      }
    },
    [data]
  );

  const handleSubmitForm = (e) => {

    //fk2    
    // const hasOthers = data['certifications'].includes('Other');
    let otherCertificationsValue = document.getElementById('otherCertifications').value;
    const hasOthers = 
      (otherCertificationsValue === null || otherCertificationsValue === undefined || otherCertificationsValue ==='')
      ? false : true;

    // const hasOthers = document.getElementById('otherCertifications').value
    if (hasOthers){
      console.log('has others');
      console.log(data);
      const otherCertifications = document.getElementById('otherCertifications').value
      const oldOtherCertificationsValue = data['certifications'].filter(x => certifications.indexOf(x) === -1);
      const oldOtherCertificationsIndex = data['certifications'].indexOf(oldOtherCertificationsValue[0]);
      
      if(oldOtherCertificationsIndex !== -1)
      data['certifications'].splice(oldOtherCertificationsIndex, 1);

      data['certifications'].push(otherCertifications);
      console.log(data);
    }

    let otherSpecialtiesValue = document.getElementById('otherSpecialties').value;
    const hasOtherspecialties = 
      (otherSpecialtiesValue === null || otherSpecialtiesValue === undefined || otherSpecialtiesValue ==='')
      ? false : true;

    if (hasOtherspecialties){
      console.log('has others');
      console.log(data);
      const otherSpecialties = document.getElementById('otherSpecialties').value
      const oldOtherSpecialtiesValue = data['specialities'].filter(x => specialities.indexOf(x) === -1);
      const oldOtherSpecialtiesIndex = data['specialities'].indexOf(oldOtherSpecialtiesValue[0]);
      
      if(oldOtherSpecialtiesIndex !== -1)
      data['specialities'].splice(oldOtherSpecialtiesIndex, 1);

      data['specialities'].push(otherSpecialties);
      console.log(data);
    }
    //fk2

    setErrors(initialData);

    const errorsFields = ValidationService.validate(validationSchema.create, data);
    setErrors({ ...errorsFields });

    if (_.isEmpty(errorsFields)) {
      submitForm(data);
    }
    else {
      const errorElement = document.getElementById(Object.keys(errorsFields)[0]);
      errorElement?.scrollIntoView({ behavior: 'smooth' })
    }
  };

  const onSelectFileHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //fk2 { validate file size
    const file = e.target.files[0];
    const name = e.target.name;
    console.log('file', name);
    if (file.size > 10e6) {
      if (name === 'resume') 
        setErrors({ ...errors, resume: 'File size should be less than 10MB' });
      else if (name === 'coverLetter')
        setErrors({ ...errors, coverLetter: 'File size should be less than 10MB' });
      else if (name === 'liabilityInsurance')
        setErrors({ ...errors, liabilityInsurance: 'File size should be less than 10MB' });
      return;
    } 
    //fk2 }
    setData((old) => ({
      ...old,
      [e.target.name]: file
    }));
  };

  const handleDeleteFile = (file, e) => {
    setData({
      ...data,
      [file]: ''
    });
  };

  const errorExist = (param) => errors.hasOwnProperty(param);

  return (
    <form noValidate onSubmit={submitForm}>
      <FieldText
        title="Current services offered *"
        placeholder="Info"
        name="currentOffering"
        errors={errors}
        errorExist={errorExist}
        required
        rows={5}
        maxLength={140}
        styles={styles}
        handleChange={onChange}
        value={data?.currentOffering}
      />
      <DoubleChoice
        title="Have you ever been convicted of a felony? *"
        explainTitle="Explain number of conviction(s), nature of offense(s) leading to conviction(s),
  how recently such offense(s) was/were committed, sentence(s) imposed, and type(s)
  of rehabilitation."
        withOtherOption={false}
        withExplainOption={true}
        explainValue={data.felonyExplain}
        field="felony"
        placeholder="Felony explain"
        otherPlaceholder="Add your option here"
        explainField="felonyExplain"
        value={data.felony}
        handleChange={onChange}
        errors={errors}
        errorExist={errorExist}
        styles={styles}
      />
      <RadioButtonsGroup
        field="howDidFind"
        styles={styles}
        title="How did you find out about this position?"
        checkPresentOption={checkPresentOption}
        value={data.howDidFind}
        handleChange={onChange}
        errorExist={errorExist}
        errors={errors}
        items={howDidFind}
      />
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={2}>
          <Grid item xs={12}>
            <label>Years of Experience *</label>
            <TextField
              error={errorExist('expYears')}
              helperText={errors?.expYears}
              variant="outlined"
              className={styles.inputField}
              value={data.expYears}
              onChange={onChange}
              required
              fullWidth
              type="number"
              placeholder="How many years of experience do you have?"
              label=""
              InputProps={{ inputProps: { min: 0, max: 60 } }}
              name="expYears"
              autoComplete="expYears"
            />
          </Grid>
        </Grid>
      </div>
      <DoubleChoice
        title="Are you a Registered Dietitian?*"
        withOtherOption={false}
        withExplainOption={false}
        field="dietitian"
        type="row"
        otherPlaceholder="Add your option here"
        explainField="dietitian"
        value={data.dietitian}
        handleChange={onChange}
        errors={errors}
        errorExist={errorExist}
        styles={styles}
      />
      <DoubleChoice
        title="Nutrition Degree*"
        withOtherOption={true}
        withExplainOption={false}
        field="degree"
        type="row"
        otherPlaceholder="Add your option here"
        value={data.degree}
        handleChange={onChange}
        errors={errors}
        errorExist={errorExist}
        styles={styles}
      />
      <DoubleChoice
        title="CSSD: Certified Specialist in Sports Dietetics*"
        withOtherOption={false}
        withExplainOption={false}
        field="cssd"
        type="row"
        value={data.cssd}
        handleChange={onChange}
        errors={errors}
        errorExist={errorExist}
        styles={styles}
      />
      <MultipleChoices
        styles={styles}
        items={certifications}
        checkPresentOption={checkPresentOption}
        field="certifications"
        handleChangeOptions={handleChangeOptions}
        label={'Do you hold any of the following Nutrition Certifications?'}
        withOtherOption={true}
        otherValue={data.otherCertifications}
        otherHandleChange={onChange}
        otherPlaceholder={"Other Certifications"}
        otherName={"otherCertifications"}
        otherId={"otherCertifications"}
        otherHelperText={errors?.otherCertifications}
      />
      {/* <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={1}>
          <Grid item xs={12}>
            <TextField
              error={errorExist('expYears')}
              helperText={errors?.otherCertifications}
              variant="outlined"
              className={styles.inputField}
              value={data.otherCertifications}
              onChange={onChange}
              fullWidth
              type="text"
              placeholder="Other Certifications"
              label=""
              name="otherCertifications"
              id="otherCertifications"
            />
          </Grid>
        </Grid>
      </div> */}
      <MultipleChoices
        label={'Do you hold any of the following Nutrition Specialties?'}
        styles={styles}
        items={specialities}
        checkPresentOption={checkPresentOption}
        field="specialities"
        handleChangeOptions={handleChangeOptions}
        withOtherOption={true}
        otherValue={data.otherSpecialties}
        otherHandleChange={onChange}
        otherPlaceholder={"Other Specialties"}
        otherName={"otherSpecialties"}
        otherId={"otherSpecialties"}
        otherHelperText={errors?.otherSpecialties}
      />
      <MultipleChoices
        label={'Preferable choice of communication'}
        styles={styles}
        items={preferableChart}
        checkPresentOption={checkPresentOption}
        field="preferableChat"
        handleChangeOptions={handleChangeOptions}
      />
      <InputFile
        title="Upload your resume *"
        styles={styles}
        name="resume"
        id="resume"
        initialFileName={!_.isObject(data?.resume) && data?.resume || data?.resume?.name}
        value={data?.resume?.filename}
        handleChange={onSelectFileHandler}
        errors={errors}
        errorExist={errorExist}
        handleDeleteFile={handleDeleteFile}
        field="resume"
      />
      <InputFile
        title="Upload a cover letter (optional)"
        styles={styles}
        name="coverLetter"
        initialFileName={!_.isObject(data?.coverLetter) && data?.coverLetter || data?. coverLetter?.name}
        value={data?.coverLetter?.filename}
        handleChange={onSelectFileHandler}
        errors={errors}
        errorExist={errorExist}
        handleDeleteFile={handleDeleteFile}
        field="coverLetter"
      />
      <InputFile
        title="Upload a liability insurance (optional)"
        styles={styles}
        name="liabilityInsurance"
        initialFileName={!_.isObject(data?.liabilityInsurance) && data?.liabilityInsurance || data?. liabilityInsurance?.name}
        value={data?.liabilityInsurance?.filename}
        handleChange={onSelectFileHandler}
        errors={errors}
        errorExist={errorExist}
        handleDeleteFile={handleDeleteFile}
        field="liabilityInsurance"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={styles.intakeForm_actionButtons}>
          <Button
            onClick={() => backToFirstStep()}
            color="primary"
            variant="contained"
            className={styles.intakeForm_actionButtons_backBtn}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} className={styles.intakeForm_actionButtons}>
          <Button
            onClick={(e) => handleSubmitForm(e)}
            color="primary"
            variant="contained"
            className={styles.intakeForm_actionButtons_nextBtn}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormSubmitStepSecond;

import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePubNub } from 'pubnub-react';
import { Grid, Paper } from '@material-ui/core';

import ChatList from './ChatList';
import Chat from './Chat';
import { chatActions, adminActions, clientActions } from '../../redux/actions';
import Loader from '../../components/Loader';

import { groupChatTypes } from '../../constants';
import ConfirmationDialog from '../../components/ConfirmationDialog';

function ChatContainer({ userModerate, isAdmin }) {
  const pubnub = usePubNub();
  const currentUser = useSelector((state) => state.user);
  const user = useMemo(() => userModerate || currentUser, [userModerate, currentUser]);
  //const user = user || useSelector((state) => state.user);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const chats = useSelector((state) => state.chat.chats);
  const channelIds = useMemo(() => chats.map((chat) => chat.channelId), [chats]);

  const [refreshDialogOpen, setRefreshDialogOpen] = useState(false);

  const loadChatData = async () => {
    console.log(userModerate);
    if (userModerate) {
      await adminActions.getModeratedChats(userModerate);
    } else {
      await chatActions.loadChatData();
    }

    setIsLoaded(true);
  };

  const loadMessages = async (channelIds) => {
    await chatActions.loadMessages({ channels: channelIds, pubnub });
    setRefreshDialogOpen(false);
  };

  const handleMessage = async (event) => {
    const { file, message, publisher, timetoken, channel } = event;

    const newMessage = {
      //com.mco
      message: file ? { file, message } : message,
      // message: file ? { file } : message,
      userId: publisher === 'admin' ? 'admin' : parseInt(publisher, 10),
      date: new Date(parseInt(timetoken, 10) / 10000),
      //com.mco
      timetoken: timetoken,
      newMsg: true
    };

    chatActions.setMessagesToChannel({ channelId: channel, messages: [newMessage], newMsg: true });
  };

  //com.mco
  const loadFiles = (channelIds) => {
    chatActions.loadFiles({ channels: channelIds, pubnub });
  };

  useEffect(() => {
    if (!_.isEmpty(channelIds)) {
      pubnub.subscribe({ channels: channelIds });
      loadMessages(channelIds);
      //com.mco
      loadFiles(channelIds);
    }
  }, [channelIds]);

  useEffect(() => {
    if (userModerate) return;

    const interval = setInterval(() => {
      chatActions.loadUnreadChannelInfo({ userId: user.id, channels: channelIds, pubnub });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [channelIds]);

  useEffect(() => {
    loadChatData();

    const messageListener = {
      message: handleMessage,
      file: handleMessage
    };
    pubnub.addListener(messageListener);

    return () => {
      pubnub.removeListener(messageListener);
    };
  }, []);

  // fk2
  const selectChat = (e) => {
    // console.log('fk2 selelcted chat', e, user);
    if (e.groupName != 'NOGROUP' && e.locked != false && user.type == 'client' && !isAdmin) {
      handleSubscribe(e);
    } else setSelectedChat(e);
  };

  const handleSubscribe = async (chat) => {
    const result = await clientActions.subscribeForGroupChat({
      nutritionistId: chat.nutritionistId,
      amount: groupChatTypes[user.client.coachPreference.groupChatType].amount,
      chatId: chat.id
    });
    console.log(result);
    const { url, error } = result;
    if (error) {
      window.alert(error);
      // return onClose();
    }
    if (url) {
      setRefreshDialogOpen(true);
      window.open(url);
    }
  };

  if (!isLoaded) {
    return <Loader />;
  } else {
    return (
      <>
        <ConfirmationDialog
          title="Payment"
          text="Click submit to refresh the page if you have paid for the membership"
          onClose={() => setRefreshDialogOpen(false)}
          open={refreshDialogOpen}
          onSubmit={loadMessages}
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper style={{ height: '100%', overflow: 'auto', display: 'flex' }}>
              <ChatList onSelect={selectChat} user={user} />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            {selectedChat && <Chat chat={selectedChat} userModerate={userModerate} />}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ChatContainer;

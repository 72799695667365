import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Button,
  Box
} from '@material-ui/core';

import { nutritionistStatuses } from '../../../constants';
import { adminActions } from '../../../redux/actions';

const useStyles = makeStyles({
  select: {
    minWidth: 150,
    marginLeft: 10
  },
  comment: {
    minWidth: 200,
    marginLeft: 10
  }
});

export default function (props) {
  const classes = useStyles();
  const { nutritionistStatus } = props.nutritionist;
  const [data, setData] = useState(nutritionistStatus);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((old) => ({
      ...old,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    props.setExpandedIndex(null);
    await adminActions.updateNutritionistStatus({
      nutritionistId: props.nutritionist.id,
      data
    });
    await adminActions.loadNutritionistApplications();
  };

  return (
    <Box boxShadow={3} className="p-4 my-2">
      <div className="d-flex my-3">
        <div>
          <FormControl className={classes.select}>
            <InputLabel id="status-label">Application status</InputLabel>
            <Select
              value={data.status}
              onChange={handleChange}
              labelId="status-label"
              name="status"
            >
              {_.map(nutritionistStatuses, (status) => (
                <MenuItem value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {data.status === nutritionistStatuses.REJECTED && (
            <FormControl className={classes.comment}>
              <TextField
                name="rejectionComment"
                label="Rejection comment"
                multiline
                rowsMax={3}
                value={data.rejectionComment}
                onChange={handleChange}
              />
            </FormControl>
          )}
        </div>
        <FormControl className={classes.select}>
          <InputLabel id="interviewStatus-label">Interview status</InputLabel>
          <Select
            labelId="interviewStatus-label"
            value={data.interviewStatus}
            onChange={handleChange}
            name="interviewStatus"
          >
            <MenuItem value={false}>Not interviewed</MenuItem>
            <MenuItem value={true}>Interviewed</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.comment}>
          <TextField
            name="comment"
            label="Comments"
            multiline
            rowsMax={3}
            value={data.comment}
            onChange={handleChange}
          />
        </FormControl>
        <Button className="my-2 ml-auto" variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Checkbox, MenuItem, Select } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';

import {
  experienceOptions,
  certificatesOptions,
  specialtyOptions,
  sexOptions
} from './FilterOptions';

const CoachFilter = ({ onChange }) => {
  const [experience, setExperience] = useState([]);
  const [specialty, setSpecialty] = useState([]);
  const [certs, setCerts] = useState([]);
  const [gender, setGender] = useState([]);
  const [searchString, setSearchString] = useState('');

  const onExperienceSelect = (e) => {
    const { value } = e.target;
    setExperience(value);
  };

  const onGenderSelect = (e) => {
    const { value } = e.target;
    setGender(value);
  };

  const onSpecialtySelect = (e) => {
    const { value } = e.target;
    if (value.length <= 5) {
      setSpecialty(value);
    }
  };

  const onCertsSelect = (e) => {
    const { value } = e.target;
    if (value.length <= 5) {
      setCerts(value);
    }
  };

  useEffect(() => {
    const params = {};

    if (searchString) {
      params.search = searchString;
    }
    if (experience.length) {
      params.experience = experience.map((i) => i.value);
    }
    if (specialty.length) {
      params.specialties = specialty.map((i) => i.value);
    }
    if (certs.length) {
      params.certifications = certs.map((i) => i.value);
    }
    if (gender.length) {
      params.gender = gender.map((i) => i.value);
    }

    onChange(params);
  }, [searchString, experience, specialty, certs, gender]);

  specialtyOptions.sort((a, b) => a.name > b.name);
  certificatesOptions.sort((a, b) => a.name > b.name);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 20
      }}
    >
      <div style={{ flex: 1, minWidth: 300 }}>
        <InputLabel id="experience">Search by name</InputLabel>
        <TextField onChange={(e) => setSearchString(e.target.value)} variant="outlined" fullWidth />
      </div>
      <div style={{ flex: 1, minWidth: 300 }}>
        <InputLabel id="experience">Genders</InputLabel>
        <Select
          variant="outlined"
          fullWidth
          name="genders"
          multiple
          value={gender}
          onChange={onGenderSelect}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 500,
                width: 250
              }
            },
            getContentAnchorEl: null
          }}
          renderValue={(selected) => {
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value) => (
                  <Chip style={{ margin: 2 }} key={value.id} label={value.name} />
                ))}
              </div>
            );
          }}
        >
          {sexOptions.map((option) => (
            <MenuItem key={option.id} value={option}>
              <Checkbox checked={gender.indexOf(option) > -1} />
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div style={{ flex: 1, minWidth: 300 }}>
        <InputLabel id="experience">Experience</InputLabel>
        <Select
          variant="outlined"
          fullWidth
          name="experience"
          multiple
          value={experience}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 500,
                width: 250
              }
            },
            getContentAnchorEl: null
          }}
          onChange={onExperienceSelect}
          renderValue={(selected) => {
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value) => (
                  <Chip style={{ margin: 2 }} key={value.id} label={value.name} />
                ))}
              </div>
            );
          }}
        >
          {experienceOptions.map((option) => (
            <MenuItem key={option.id} value={option}>
              <Checkbox checked={experience.indexOf(option) > -1} />
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div style={{ flex: 4, minWidth: 300 }}>
        <InputLabel id="experience">Specialities</InputLabel>
        <Select
          variant="outlined"
          fullWidth
          name="Specialities"
          multiple
          value={specialty}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 500,
                width: 250
              }
            },
            getContentAnchorEl: null
          }}
          onChange={onSpecialtySelect}
          renderValue={(selected) => {
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value) => (
                  <Chip style={{ margin: 2 }} key={value.id} label={value.name} />
                ))}
              </div>
            );
          }}
        >
          {specialtyOptions.map((option) => (
            <MenuItem key={option.id} value={option}>
              <Checkbox checked={specialty.indexOf(option) > -1} />
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ flex: 4, minWidth: 300 }}>
        <InputLabel id="experience">Certifications</InputLabel>
        <Select
          variant="outlined"
          fullWidth
          name="certifications"
          multiple
          value={certs}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 500,
                width: 250
              }
            },
            getContentAnchorEl: null
          }}
          onChange={onCertsSelect}
          renderValue={(selected) => {
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map((value) => (
                  <Chip style={{ margin: 2 }} key={value.id} label={value.name} />
                ))}
              </div>
            );
          }}
        >
          {certificatesOptions.map((option) => (
            <MenuItem key={option.id} value={option}>
              <Checkbox checked={certs.indexOf(option) > -1} />
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default CoachFilter;

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { TextField, Typography, LinearProgress, Link, Icon } from '@material-ui/core';
import styles from './Register.module.scss';

import { authActions } from '../../redux/actions';
import { isEmail } from '../../utils/helper';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import { userActions } from '../../redux/actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const [userType, setUserType] = useState(null);
  const [formData, setFormData] = useState({ email: '', password: '', confirmPassword: '' });
  const [refKey, setRefKey] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const { ref } = query;
    if (ref) {
      setRefKey(ref);
      setUserType('client');
    }
  }, []);

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setFormData((old) => ({ ...old, [name]: value }));
  };

  const { executeRecaptcha } = useGoogleReCaptcha();
  var recaptchaToken ='';
  const handleVerify = async token => {
    recaptchaToken = token;
    console.log("fk2 token", token);
  }

  const handleSubmit = async () => {
    if (!isEmail(formData.email)) {
      return setError('Email is not valid');
    }
    if (formData.password.length < 6) {
      return setError('Password should have at least 6 symbols');
    }
    if (formData.password !== formData.confirmPassword) {
      return setError('Password and confirmation password do not match');
    }

    setIsLoading(true);
    setError(null);

    try {
      const newToken = await executeRecaptcha("recaptchaRegister");
      // const newToken = await executeRecaptcha();
      // console.log("new token", newToken);
      const responseRecaptcah = await userActions.verifyRecaptcha(newToken, formData.email);

      await authActions.registerManual({ data: formData, userType, refKey });
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialRegister = async (socialType) => {
    authActions.registerSocial({ type: socialType, userType, refKey });
  };

  return (
    <>
      {!userType && (
        <div className={styles.userTypeContainer}>
          <div className={styles.contentContainer}>
            <p className={styles.title}>Please, choose your type of user</p>
            <div className={styles.cardsContainer}>
              <div className={styles.cardContainer} onClick={() => setUserType('client')}>
                <Typography gutterBottom variant="h5" component="h2" className="text-center">
                  Join as a client
                </Typography>
              </div>
              <div
                className={styles.cardContainer_coach}
                onClick={() => setUserType('nutritionist')}
              >
                <Typography gutterBottom variant="h5" component="h2" className="text-center">
                  Apply as a coach
                </Typography>
              </div>
            </div>
            <Link className={styles.cardBack} href="/login">
              <Typography gutterBottom variant="h5" component="h3" className="text-center">
                Back to sign in
              </Typography>
            </Link>
          </div>
        </div>
      )}

      {userType && (
        <div className={styles.userTypeContainerRegister}>
          <div className={styles.contentContainerRegister}>
            <p className={styles.titleRegistration}>
              Join as {userType === 'nutritionist' ? 'coach' : userType}
            </p>
            <GoogleReCaptcha onVerify={handleVerify} />
            <TextField
              className="my-2"
              variant="outlined"
              fullWidth
              label="Email Address"
              name="email"
              autoFocus
              onChange={handleFormChange}
            />
            <TextField
              className="my-2"
              onChange={handleFormChange}
              variant="outlined"
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    <IconButton
                      style={{ backgroundColor: 'transparent' }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              className="my-2"
              onChange={handleFormChange}
              variant="outlined"
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    <IconButton
                      style={{ backgroundColor: 'transparent' }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {!isLoading && (
              <button className={styles.registerButton} onClick={handleSubmit}>
                <p className={styles.socialButtonText}>Sign Up</p>
              </button>
            )}
            {isLoading && <LinearProgress />}
            {error && <div className="text-center text-danger"> {error}</div>}

            <div className={styles.separator}>
              <div className={styles.separatorLine}></div>
              <p className={styles.separatorText}>or</p>
              <div className={styles.separatorLine}></div>
            </div>

            <button className={styles.appleButton} onClick={() => handleSocialRegister('apple')}>
              <Icon className={`fa fa-apple ${styles.icon}`} />
              <p className={styles.socialButtonText}>Continue with Apple</p>
            </button>

            <button
              className={styles.googleButton}
              onClick={() => handleSocialRegister('google-oauth2')}
            >
              <Icon className={`fa fa-google ${styles.icon}`} />
              <p className={styles.socialButtonText}>Continue with Google</p>
            </button>

            {false && <button
              className={styles.facebookButton}
              onClick={() => handleSocialRegister('facebook')}
            >
              <Icon className={`fa fa-facebook ${styles.icon}`} />
              <p className={styles.socialButtonText}>Continue with Facebook</p>
            </button>}

            <Link className="d-flex mt-2" href="/register">
              Back
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

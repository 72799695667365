import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper, Step, StepLabel } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
    marginBottom: 40,
  },
}));

const FormsStepper = ({activeStep}) => {
  const classes = useStyles();
  const steps = ['Select forms', 'Read submissions'];

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default FormsStepper;

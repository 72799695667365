import React, { useEffect, useState } from 'react';
import CardList from './CardList';
import config from '../../../config';
import { userAxios } from '../../../utils/axios';
import SubmissionsTable from './SubmissionsTable';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Stepper from './Stepper';
import PendingTable from './PendingTable';
import styles from '../polls/Index.module.scss';
import SubmissionsModal from '../../../components/SubmissionPreviewModal/SubmissionModal';
import { loadAdminPendingSubmissions } from '../../../redux/actions/poll';

const useStyles = makeStyles({
  tab: {
    marginTop: '40px',
    height: '100px',
    width: '300px',
    marginRight: '10px'
  },
  tabWrapper: {
    height: '100%'
  },
  title: {
    fontSize: '4rem',
    fontFamily: 'Bebas Neue',
    letterSpacing: '2.5px',
    color: '#c4eb37',
    marginBottom: '30px',
    marginTop: '30px'
  }
});

const Submissions = () => {
  const [submissions, setSubmissions] = useState(null);
  const [pendingSubmissions, setPendingSubmissions] = useState(null);
  const [selectedForm, setSelectedForm] = useState('');
  const [tableType, setTableType] = useState('');
  const formioNutritionist = config.formio.nutritionist;
  const formioAdmin = config.formio.admin;
  const [step, setStep] = useState(0);

  const [openSubModal, setOpenSubModal] = useState(false);
  const [subData, setSubData] = useState({});

  const classes = useStyles();

  useEffect(async () => {
    if (selectedForm !== '') {
      const { data: submissions } = await userAxios.get('poll/adminSubmissions?cardUrl=' + selectedForm);
      setSubmissions(submissions);
      const pendingSubmissions = await loadAdminPendingSubmissions(selectedForm);
      setPendingSubmissions(pendingSubmissions);
      setStep(1);
    } else {
      setStep(0);
    }
  }, [selectedForm]);

  const Table = () => {
    if (submissions === null) {
      return '';
    }
    if (tableType === 'clients') {
      return (
        <>
          <SubmissionsTable
            data={submissions.clients}
            setSubData={setSubData}
            setOpenSubModal={setOpenSubModal}
          />
          <PendingTable
            data={pendingSubmissions.clients}
          />
        </>
      );
    } else if (tableType === 'nutritionists') {
      return (
        <>
        <SubmissionsTable
          data={submissions.nutritionists}
          setSubData={setSubData}
          setOpenSubModal={setOpenSubModal}
        />
          <PendingTable
            data={pendingSubmissions.nutritionists}
          />
        </>
      );
    }
    return '';
  };

  const TableTabs = () => {
    if (selectedForm && submissions) {
      return (
        <>
          <div className="d-flex justify-content-center">
            <div
              className={styles.cardContainer}
              onClick={() => setTableType('clients')}
              style={{
                backgroundColor: tableType === 'clients' ? '#2f2f38' : '',
                borderColor: tableType === 'clients' ? '#ee79b3' : ''
              }}
            >
              <p className={styles.cardTitle}>clients</p>
            </div>
            <div
              className={styles.cardContainer_coach}
              onClick={() => setTableType('nutritionists')}
              style={{
                backgroundColor: tableType === 'nutritionists' ? '#2f2f38' : '',
                borderColor: tableType === 'nutritionists' ? '#c4eb37' : ''
              }}
            >
              <p className={styles.cardTitle}>Nutritionists</p>
            </div>
          </div>
        </>
      );
    }
    return '';
  };

  return (
    <>
      <div className={classes.title}>Submissions</div>
      <Stepper activeStep={step} />
      <CardList
        selectedCard={selectedForm}
        setSelectedCard={setSelectedForm}
        formsUrl={formioAdmin}
        title="Admin Forms"
        setTableType={setTableType}
      />

      <CardList
        selectedCard={selectedForm}
        setSelectedCard={setSelectedForm}
        formsUrl={formioNutritionist}
        title="Coach Forms"
        setTableType={setTableType}
      />
      <TableTabs />
      <Table />
      <SubmissionsModal
        open={openSubModal}
        data={subData}
        onClose={() => {
          setOpenSubModal(false);
        }}
        selectedForm={selectedForm}
      />
    </>
  );
};

export default Submissions;

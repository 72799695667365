import React, { useEffect } from 'react';
import { Container, Button } from '@material-ui/core';

import styles from './landing.module.scss';
import downloadNowForkImage from '../../assets/img/Fork.png';
import downloadNowPhoneImage from '../../assets/img/Phone.png';
import appStoreImage from '../../assets/img/appstore-black-outlined.svg';
import googlePlayImage from '../../assets/img/google-play.svg';

export default function () {
  return (
    <div id='download-section' className={styles.downloadNowBlock}>
      <div className={styles.downloadNowLeft}>
        <div className={styles.downloadNowImages}>
          <img src={downloadNowForkImage} className={styles.downloadNowForkImage} />
          <img src={downloadNowPhoneImage} className={styles.downloadNowPhoneImage} />
        </div>
      </div>
      <div className={styles.downloadNowRight}>
        <Container>
          <div className={styles.downloadNowContent}>
            <div className={styles.downloadNowBlockTitle}>DOWNLOAD NOW</div>
            <div className={styles.downloadNowDescription}>
              Find your coach, and begin your journey toward optimal health with Planibble today.
            </div>
            <div className={styles.downloadNowButtons}>
              <a href="https://apps.apple.com/app/planibble/id1593873040">
                <Button variant="contained" className={styles.downloadNowDownloadButton}>
                  Download
                </Button>
              </a>
              <a href="/register">
                <Button variant="contained" className={styles.downloadNowRegisterButton}>
                  Register
                </Button>
              </a>
            </div>
            <a href="https://apps.apple.com/app/planibble/id1593873040">
              <Button>
                <img style={{height: '42px'}} src={appStoreImage} className={styles.appStoreImage} />
              </Button>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.planibble">
              <Button>
                <img src={googlePlayImage} className={styles.appStoreImage} />
              </Button>
            </a>
          </div>
        </Container>
      </div>
    </div>
  );
}

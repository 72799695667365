import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { clientActions, nutritionistActions } from '../../../redux/actions';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Icon, IconButton} from '@material-ui/core';
import Loader from '../../../components/Loader';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const NutritionistSubscriptions = () => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [customerPortalLink, setCustomerPortalLink] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);

  const loadSubscriptions = async () => {
    const data = await nutritionistActions.loadSubscriptions();

    const result = data.map((sub) => {
      let statusText = capitalizeFirstLetter(sub.status);
      if (sub.cancel_at) {
        statusText = `Plan is active untill ${moment(sub.cancel_at * 1000).format('LL')}`;
      }

      return {
        ...sub,
        statusText
      };
    });

    setSubscriptions(result);
    setLoaded(true);
  };

  const loadCustomerPortalLink = async () => {
    const data = await nutritionistActions.loadCustomerCoachPortalLink();
    setCustomerPortalLink(data.url);
  };

  const cancelSubscription = async(subId) => {
    const data = {subId: subId}
    console.log(data);
    if (window.confirm("Are you sure you want to cancel your subscription?")) {
      await clientActions.stripeCancelSubscription(data);
      await loadSubscriptions();
    }
  };

  useEffect(() => {
    loadCustomerPortalLink();
    loadSubscriptions();
  }, []);

  if (!loaded) {
    return <Loader />;
  } else
    return (
      <div>
        {customerPortalLink && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href={customerPortalLink}>
              <Button color="primary" variant="contained">
                Manage Subscriptions
              </Button>
            </a>
            <span style={{ marginLeft: "2rem" }}>Request chat with administrator if you want to change your subscription before it expires.</span>
          </div>

        )}
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Subscription Status</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Cancel</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((subscription) => (
                <TableRow key={subscription.id}>
                  <TableCell>{subscription.statusText}</TableCell>
                  <TableCell>{`${subscription.plan.amount / 100}$ /mo`}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => cancelSubscription(subscription.id)}>
                      <Icon className="fa fa-times" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
};

export default NutritionistSubscriptions;

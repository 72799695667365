import _ from 'lodash';

import {useEffect, useState} from "react";

export const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const filterTable = (data, fields, search) => {
  const result = data.filter((item) => {
    return _.find(fields, (field) => {
      const value = _.get(item, field);
      if (_.isArray(value)) {
        return _.find(
          value,
          (searchItem) => searchItem?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      }
      return value?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
    });
  });

  return result;
};

export const useDeviceDetect = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowResize);
      return () => {
          window.removeEventListener('resize', handleWindowResize);
      }
  }, []);

  return (width <= 768);
}

import styled from 'styled-components/macro';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 10px;
`;

export const Content = styled.div`
  padding: 15px;
  font-weight: 400;
  border-radius: 0 15px 15px 15px;
  background-color: white;
  color: #5a5a5a;
  font-size: 16px;
  white-space: pre-wrap;
  width: -moz-fit-content;
  width: fit-content;

  @media screen and (max-width: 400px) {
    padding: 5px;
    font-size: 11px;
  }

  @media screen and (min-width: 401px and max-width: 800px) {
    padding: 8px;
    font-size: 13px;
  }
`;

export const Message = styled.div`
  margin-bottom: 2px;
  max-height: 400;
`;

export const Date = styled.div`
  font-size: 11px;
  margin-top: 6px;
`;

export const MyDate = styled.div`
  font-size: 11px;
  margin-top: 6px;
  margin-left: auto;
`;

export const Image = styled.img`
  width: 400px;
  margin-bottom: 2px;

  @media screen and (max-width: 400px) {
    width: 75px;
    height: 75px;
  }

  @media screen and (min-width: 401px and max-width: 800px) {
    width: 200px;
    height: 200px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px 90px 12px 24px;
  :hover {
    background-color: #424242;
  }
  :first-child {
    margin-top: auto;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  width: -moz-fit-content;
  width: fit-content;
`;

export const Header = styled.div`
  margin: 5px 0;
`;

export const SenderName = styled.span`
  font-size: 15px;
  color: #d0dae5;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;

  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

export const MyContent = styled.div`
  padding: 15px;
  font-weight: 400;
  border-radius: 15px 0px 15px 15px;
  background-color: white;
  color: #5a5a5a;
  font-size: 16px;
  white-space: pre-wrap;
  width: fit-content;
  margin-left: auto;
  width: fit-content;
  @media screen and (max-width: 400px) {
    padding: 5px;
    font-size: 11px;
  }

  @media screen and (min-width: 401px and max-width: 800px) {
    padding: 8px;
    font-size: 13px;
  }
`;

export const MyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 12px;
  :hover {
    background-color: #424242;
  }
  :first-child {
    margin-top: auto;
  }
  margin-left: auto;
`;

export const MySenderName = styled.span`
  font-size: 15px;
  color: #d0dae5;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  float: right;

  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

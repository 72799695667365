import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { loadPolls, disable, activate } from '../../redux/actions/poll';
import ModalCard from './ModalCard';
import styles from './Index.module.scss';
import { Dialog, Icon } from '@material-ui/core';
import moment from 'moment';

const FormModal = () => {
  const [open, setOpen] = useState(false);
  const polls = useSelector((state) => state.polls.data);
  const active = useSelector((state) => state.polls.active);
  const [poll, setPoll] = useState(null);
  const loaded = useSelector(state => state.polls.loaded);
  const id = useSelector(state => state.user.id);


  const displayForms = () => {
    const loginTimeData = localStorage.getItem(`loginTime_user${id}`);
    const loginTime = loginTimeData ? JSON.parse(loginTimeData) : {};
    const {userId, timeStamp, forms} = loginTime;
    if (timeStamp && id === userId && forms ===  JSON.stringify(polls)) {
      const now = moment(new Date());
      const end = moment(timeStamp);
      const duration = now.diff(end, 'minutes');
      if (duration < 30) {
        return;
      }
    }
    localStorage.setItem(`loginTime_user${id}`, JSON.stringify({timeStamp: moment().toISOString(),
      userId: id, forms:  JSON.stringify(polls)}));
    activate();
  };

  useEffect(() => {
    if (loaded) {
      displayForms();
    }
  }, [loaded]);

  useEffect(async () => {
    await loadPolls().then(() => console.log("then polls", JSON.stringify(polls)));
  }, []);

  useEffect(() => {
    if (poll === null && polls.length && active) {
      setPoll(polls.pop());
      setOpen(true);
      if (active && polls.length === 0) {
        disable();
      }
    }
  }, [polls, poll, active]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setPoll(null);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      classes={{
        paper: styles.modal
      }}
    >
      <div className={styles.modalContainer}>
        <Icon
          onClick={() => {
            setOpen(false);
            setPoll(null);
          }}
          className={`fa fa-times ${styles.modalCross}`}
          style={{ fontSize: 30 }}
        />
        <ModalCard poll={poll} setOpen={setOpen} setPoll={setPoll} />
      </div>
    </Dialog>
  );
};

export default FormModal;

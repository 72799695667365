import mutations from '../mutations';
import _ from 'lodash';
import differenceBy from 'lodash/differenceBy'

const initialState = {
  chats: [],
  messages: {},
  unreadCounts: {},
  //com.mco
  files: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.chat.loadChatData:
      return {
        ...state,
        ...action.payload
      };

    case mutations.chat.setMessagesToChannel:
      const { channelId, messages, newMsg } = action.payload;
      if (!state.messages[channelId]) state.messages[channelId] = [];
      // state.messages[channelId].push(...messages);
      //com.mco
      if(newMsg === true)
        state.messages[channelId].push(...messages);
      else
        state.messages[channelId].unshift(...messages);

      return { ...state, messages: { ...state.messages } };

    case mutations.chat.resetMessages:
      return { ...state, messages: {} };

    case mutations.chat.setUnreadCounts:
      return { ...state, unreadCounts: action.payload };

      //com.mco
    case mutations.chat.setFilesToChannel:
      const { pubnub, fChannelId, files } = action.payload;
      // state.files = {};
      if (!state.files[fChannelId]){
        state.files[fChannelId] = [];

        if(files && files.length > 0){
          files.forEach((file) =>{
            file.uri = pubnub.getFileUrl({ channel: fChannelId, id: file.id, name: file.name });
          });
        }

        state.files[fChannelId].push(...files);
      }
      else{
        const diff = differenceBy(files, state.files[fChannelId], 'id');

        if(diff && diff.length > 0){
          diff.forEach((file) =>{
            file.uri = pubnub.getFileUrl({ channel: fChannelId, id: file.id, name: file.name });
          });
        }

        state.files[fChannelId].push(...diff);
      }
      
      // state.files[fChannelId].push(...files);

      state.files[fChannelId].sort((a, b) => a.created < b.created ? 1 : -1)
        return { ...state, files: {...state.files} };

    default:
      return state;
  }
};

export default reducer;

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, LinearProgress } from '@material-ui/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import theme from './theme';
import Skeleton from './skeleton';

import { authActions, userActions } from './redux/actions';
import { initUserAxiosInstance } from './utils/axios';
import { initPubnubInstance } from './utils/pubnub';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import store, { persistor } from './redux/store';

import {
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import config from './config';

function App() {
  const handleBeforeLift = () => {
    const { auth } = store.getState();
    const { token } = auth;
    initUserAxiosInstance({ token, logoutAction: authActions.logout });
  };

  useEffect(() => {
    const { auth, user } = store.getState();
    initPubnubInstance({ userId: user ? user.id : null, image: user ? user.image : null });
    const { token, userType } = auth;
    if (token && userType !== 'admin') {
      userActions.loadUser();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <GoogleReCaptchaProvider
      reCaptchaKey={config.recaptchaSiteKey}
    >
      <CssBaseline />
      <Provider store={store}>
        <PersistGate
          onBeforeLift={handleBeforeLift}
          loading={<LinearProgress />}
          persistor={persistor}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <BrowserRouter>
              <Skeleton />
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </GoogleReCaptchaProvider>
    </ThemeProvider>
  );
}

export default App;

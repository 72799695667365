import mutations from '../mutations';

const initialState = {
  faq: [],
  menu: {
    isOpen: false
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.app.toogleMenu:
      const menu = {
        ...state.menu
      };
      menu.isOpen = action.payload || !menu.isOpen;

      return {
        ...state,
        menu
      };

    case mutations.app.loadFAQ:
      return {
        ...state,
        faq: action.payload
      };
    default:
      return state;
  }
};

export default reducer;

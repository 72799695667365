import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IntakeForm from '../../../../components/intakeForm';
import { nutritionistActions, userActions } from '../../../../redux/actions';

const EditProfile = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const nutritionist = user.nutritionist;

  const submitNutritionist = async (data) => {
    await nutritionistActions.submitNutritionist(data);
    await userActions.loadUser();
    history.push('/nutritionist/dashboard');
  };

  return (
    <IntakeForm nutritionist={nutritionist} onSubmit={submitNutritionist} email={user.email} />
  );
};

export default EditProfile;

import Joi from 'joi';
import moment from 'moment';

const minDate = moment().subtract(16, 'years');

const validationSchema = {
  create: Joi.object({
    firstName: Joi.string().regex(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).required().label('First name').messages({ 'string.pattern.base': 'First name contains unacceptable symbols' }),
    lastName: Joi.string().regex(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).required().label('Last name').messages({ 'string.pattern.base': 'Last name contains unacceptable symbols' }),
    gender: Joi.string().required().label('Gender'),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
      .label('E-mail'),
    height: Joi.string()
      .required()
      .regex(/^[1-9]′ (([0-9]”)|([1][0-1]”))/)
      .message({
        'string.pattern.base': 'Invalid height'
      })
      .label('Height'),
    weight: Joi.number().min(1).max(1500).required().label('Weight'),
    birthdate: Joi.date().max(minDate).required().label('Date of Birth').messages({
      'date.max': 'Sorry, but you need to be at least 16 years old.'
    })
  })
};

export default validationSchema;

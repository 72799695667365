import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IntakeForm from '../../../components/intakeForm';
import { adminActions } from '../../../redux/actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const history = useHistory();
  const nutritionists = useSelector((state) => state.admin.nutritionists);
  const applications = useSelector(state => state.admin.applications);
  const [nutritionist, setNutritionist] = useState(null);

  useEffect(() => {
    (async function () {
      const id = parseInt(props.match.params.id);
      if (!nutritionists.length) {
        await adminActions.loadNutritionists();
      }

      const result = nutritionists.concat(applications).find((i) => i.id === id);
      setNutritionist(result.nutritionist);
    })();
  }, [nutritionists]);

  const submitNutritionist = async (data) => {
    await adminActions.submitNutritionistChange(nutritionist.id, data);
    await adminActions.loadNutritionists();
    history.push('/admin/nutritionists');
  };

  return <IntakeForm nutritionist={nutritionist} onSubmit={submitNutritionist} />;
}

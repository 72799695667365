import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { publicActions } from '../../../redux/actions';

export default function () {
  const faq = useSelector((state) => state.app.faq[0]);
  const userType = useSelector((state) => state.auth.userType);

  useEffect(() => {
    publicActions.loadFAQ({ type: userType });
  }, []);

  return (
    <div>
      <div>
        <div className="content" dangerouslySetInnerHTML={{ __html: faq?.text }}></div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import { userActions, chatActions, publicActions } from '../../../redux/actions';
import { useSelector } from 'react-redux';
import { usePubNub } from 'pubnub-react';
import FormModal from '../../../components/formModal';
import NutritionistStepper from './stepper';
import GetPaidCard from './getPaidCard';
import ClientRequestsCard from './clientRequestsCard';
import MyClientsCard from './myClientsCard';
import ReferalCard from './ReferalCard';
import styles from './Index.module.scss';
import _ from 'lodash';

import CoachPromoteCard from './coachPromoteCard.js';
import CalendarCard from './CalendarCard.js';
// import { Grid } from 'react-formio/lib/components';
import { Card } from '@material-ui/core';

import Chart from 'react-apexcharts';
import { nutritionistActions } from '../../../redux/actions';
import { Grid } from '@material-ui/core';

const NutritionistForm = () => {
  const pubnub = usePubNub();
  const user = useSelector((state) => state.user);
  const nutritionist = useSelector((state) => state.nutritionist);
  // const channels = useSelector((state) => state.chat.channels);
  // const timetokens = useSelector((state) => state.chat.timetokens);

  useEffect(() => {
    //userActions.getChats(user.nutritionist.id, null);
  }, []);

  // useEffect(() => {
  //   if (user.chats) {
  //     chatActions.getChannels(user.chats, 'nutritionist', user.nutritionist.id);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (channels.length) {
  //     chatActions.getTimetokens(pubnub, user.id);
  //     if (!timetokens) {
  //       chatActions.subscribeToMembership(user.id, pubnub, channels);
  //       chatActions.getTimetokens(pubnub, user.id);
  //     }
  //   }
  // }, [channels]);

  //fk2
  const chartData = {
    series: [
      {
        name: 'Earnings',
        data: [
          // [1327359600000,30.95],
        ]
      }
    ],
    colors: ['#c4eb37'],
    yaxis: {
      labels: {
        style: {
          colors: 'white'
        }
      }
    },
    chart: {
      id: 'area-datetime',
      type: 'area',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 10,
      style: 'hollow'
    },
    xaxis: {
      type: 'datetime',
      min: new Date('1 Jan 2022').getTime(),
      tickAmount: 6,
      labels: {
        style: {
          colors: 'white'
        }
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    }
  };

  const [reportsData, setReportsData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [optionsData, setOptionsData] = useState(chartData);
  const [videoData, setVideoData] = useState(null);

  const loadReportsdata = async () => {
    const data = await nutritionistActions.loadReportsData();
    setReportsData(data);
  };

  useEffect(() => {
    loadReportsdata();
  }, []);

  useEffect(() => {
    let amount = 0;

    if (reportsData != null) {
      console.table(reportsData);
      amount = reportsData
        .filter((item) => item.type != 'payout')
        .reduce((total, item, index) => total + item.amount, 0);

      reportsData.forEach((el, index) => {
        reportsData[index].month = new Date(el.created * 1000).getMonth();
      });
      console.table(reportsData);

      let monthlyData = reportsData
        .filter((item) => item.type != 'payout')
        .reduce((prev, next) => {
          if (next.month in prev) {
            prev[next.month].amount += next.amount;
          } else {
            prev[next.month] = next;
          }
          return prev;
        }, {});

      let result = Object.keys(monthlyData).map((month) => monthlyData[month]);

      // console.log("new result", result);

      chartData.series[0].data = result.map((item) => [item.created * 1000, item.amount / 100]);

      setOptionsData(chartData);
      var x = 100;
    }
    setTotalAmount(amount / 100);
  }, [reportsData]);

  useEffect(async () => {
    const vidoeContent = await publicActions.loadHomepageContent({ type: 'Coaches' });
    console.log({ vidoeContent });
    setVideoData(vidoeContent);
  }, []);

  return (
    <>
      <FormModal />
      <NutritionistStepper />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <CalendarCard />
        <GetPaidCard />
        <Card
          style={{
            marginTop: 10,
            minWidth: 300,
            margin: 10
          }}
        >
          {videoData &&
            videoData.map((content) => (
              <div style={{ display: 'inline-block', padding: '10px', maxWidth: '50%' }}>
                <div
                  className={styles.weUnderstandDescription}
                  dangerouslySetInnerHTML={{ __html: content.text }}
                ></div>
              </div>
            ))}
          <br />
        </Card>

        <Grid container spacing={2} style={{ margin: '10px' }}>
          <Grid item xs={12}>
            <Card>
              <h3 style={{ margin: '10px' }} className={styles.chartTitle}>
                Income {totalAmount && <span>(Total Earnings: $ {totalAmount})</span>}
              </h3>
              <Chart
                // options={statistic.income.options}
                // series={statistic.income.series}
                options={optionsData}
                series={optionsData.series}
                type="bar"
                height="500"
                className="chartFontColor"
              />
            </Card>
          </Grid>
        </Grid>

        <ClientRequestsCard />
        <ReferalCard />
        <MyClientsCard />
        <CoachPromoteCard />
      </div>
    </>
  );
};

export default NutritionistForm;

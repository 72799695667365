import React from 'react';
import _ from 'lodash';
import { Table, TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';

const formatedData = {
  firstName: 'First name',
  lastName: 'Last name',
  address1: 'Address',
  address2: 'Address2',
  city: 'City',
  state: 'State',
  email: 'Email',
  hoursWeekly: 'How many hours can you work weekly?',
  canWorkAtNights: 'Can you work at nights?',
  isOlder: 'Are you 18 or older?',
  gender: 'Gender',
  phone: 'Phone',
  zipcode: 'Zipcode',
  felony: 'Have you ever been convicted of a felony?',
  felonyExplain: 'Felony explain',
  howDidFind: 'How did you find out about this position?',
  expYears: 'Years of Experience',
  dietitian: 'Are you a Registered Dietitian?',
  degree: 'Nutrition Degree',
  cssd: 'CSSD: Certified Specialist in Sports Dietetics',
  certifications: 'Do you hold any of the following Nutrition Certifications?',
  specialities: 'Do you hold any of the following Nutrition Specialties?',
  preferableChat: 'Preferable choice of communication',
  resume: 'Resume',
  coverLetter: 'Cover letter',
  currentOffering: 'Current services offered',
  schedule: 'Work schedule'
};

export default function ({ nutritionist, updates }) {
  return (
    <div>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Old Value</TableCell>
            <TableCell>New Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(updates, (value, key) => {
            let oldValue = _.isArray(nutritionist[key])
              ? nutritionist[key].join(', ')
              : nutritionist[key];

            if (_.isBoolean(oldValue)) {
              oldValue = oldValue ? 'Yes' : 'No';
            }

            let newValue = _.isArray(value) ? value.join(', ') : value;

            if (_.isBoolean(newValue)) {
              newValue = newValue ? 'Yes' : 'No';
            }

            return (
              <TableRow key={key}>
                <TableCell>{formatedData[key]}</TableCell>
                <TableCell>{oldValue}</TableCell>
                <TableCell>{newValue}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Header from '../landingPage/Header';
import Footer from '../landingPage/Footer';

import { publicActions } from '../../redux/actions';

export default function () {
  const faq = useSelector((state) => state.app.faq[0]);
  //   const userType = useSelector((state) => state.auth.userType);

  useEffect(() => {
    publicActions.loadFAQ({ type: 'client' });
  }, []);

  return (
    <>
      <Header />
      <div
        className="content MuiContainer-root MuiContainer-maxWidthLg"
        dangerouslySetInnerHTML={{ __html: faq?.text }}
      ></div>
      <Footer />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { authActions } from '../redux/actions';
import Loader from '../components/Loader';
import MessageSplash from '../components/MessageSplash';

export default function () {
  const history = useHistory();
  const [message, setMessage] = useState(null);

  const completeRegister = async (params) => {
    try {
      await authActions.completeRegister(params);
      await authActions.completeLogin(params.token);
      history.push('/redirect');
    } catch (e) {
      setMessage(e.message);
    }
  };

  useEffect(() => {
    const query = queryString.parse(window.location.hash);
    const { access_token, token_type, state } = query;
    const [userType, refKey] = state.split('-');

    completeRegister({
      token: `${token_type} ${access_token}`,
      refKey,
      userType
    });
  }, []);

  return (
    <>
      {message && <MessageSplash message={message} />}
      {!message && <Loader />}
    </>
  );
}

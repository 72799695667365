import styled from 'styled-components/macro'

export const Wrapper = styled.section`
  width: 95%;
  margin: 0 auto 40px auto;
  `;


// export const MessageWrapper = styled.div`
//   height: 700px;
//   overflow-y: scroll;
//   -ms-overflow-style: none;
//   scrollbar-width: none;
//   &::-webkit-scrollbar {
//     display: none;
//   }
//   `
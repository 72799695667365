import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router';

import {
  Grid,
  Button,
  Select,
  MenuItem,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio
} from '@material-ui/core';

import styles from './IntakeForm.module.scss';
import genders from '../../../constants/genders';
import certifications from '../../../constants/certifications.js';
import specialities from '../../../constants/specialities.js';
import yearsOfExperiencesPreferences from '../../../constants/yearsOfExperiencesPreferences.js';
import coachPersonalityPreferences from '../../../constants/coachPersonalityPreferences.js';
import { clientActions, userActions } from '../../../redux/actions';
import Chip from '@material-ui/core/Chip';
import { FormatAlignLeft } from '@material-ui/icons';
import { groupChatTypes } from '../../../constants';

const initialPreferences = {
  gender: [],
  experience: null,
  certifications: [],
  specialities: []
};

const ClientCoachPreferences = ({ onSubmit }) => {
  const history = useHistory();

  const coachPreferences = useSelector((state) =>
    state.user.client ? state.user.client.coachPreference : initialPreferences
  );
  const [data, setData] = useState({});
  const [groupChatTypeDisabled, setGroupChatTypeDisabled] = useState(false);

  useEffect(() => {
    setData(coachPreferences || initialPreferences);
    if (coachPreferences.groupChatType != 'NONE') setGroupChatTypeDisabled(true);
    else setGroupChatTypeDisabled(false);
  }, [coachPreferences]);

  const onChange = (e) => {
    setData((old) => ({
      ...old,
      [e.target.name]: e.target.value
    }));
  };

  const onSelect = (e) => {
    if (e.target.value.length <= 5) {
      setData((old) => ({
        ...old,
        [e.target.name]: e.target.value
      }));
    }
  };

  const onGenderChange = (e, a) => {
    const { checked, name } = e.target;
    let { gender } = data;

    if (checked) {
      gender.push(name);
    } else {
      gender = _.without(gender, name);
    }

    setData((old) => ({ ...old, gender }));
  };

  const onGroupChatTypeChange = (e) => {
    const { value, name } = e.target;
    setData((old) => ({ ...old, [name]: value }));
  };

  const handleSubmitForm = async () => {
    await clientActions.updateCoachPreferences(data);
    await userActions.loadUser();

    if (onSubmit) {
      return onSubmit();
    }

    history.push('/redirect');
  };

  certifications.sort();
  specialities.sort();
  console.log('data', data.groupChatType, groupChatTypes.PLAN_1.type);
  return (
    <form>
      <div className={styles.fieldsBlock}>
        <FormGroup>
          <p>Group chat information</p>
          <FormLabel>Preferred Plan</FormLabel>
          {groupChatTypeDisabled && (
            <>*** Contact Admin if you want to change your Preferred Plan.</>
          )}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={data.groupChatType}
            name="groupChatType"
            onChange={onGroupChatTypeChange}
          >
            {Object.entries(groupChatTypes).map(([key, value]) => {
              return (
                <FormControlLabel
                  checked={value.type == data.groupChatType}
                  value={value.type}
                  control={<Radio />}
                  label={value.desc}
                  disabled={groupChatTypeDisabled}
                />
              );
            })}
          </RadioGroup>
        </FormGroup>
        <FormGroup>
          <FormLabel>Gender</FormLabel>
          {genders.map((item) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={_.includes(data?.gender, item.value)}
                    onChange={onGenderChange}
                    name={item.value}
                  />
                }
                label={item.label}
              />
            );
          })}
        </FormGroup>

        <Grid className={styles.fieldsBlock_column_input} container spacing={2}>
          <Grid item xs={12}>
            <label>Coach experience</label>
            <Select
              name="experience"
              fullWidth
              variant="outlined"
              value={data?.experience || ''}
              onChange={onChange}
            >
              <MenuItem value={null}>Other</MenuItem>
              {yearsOfExperiencesPreferences.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        {/* personality */}
        <Grid className={styles.fieldsBlock_column_input} container spacing={2}>
          <Grid item xs={12}>
            <label>Coach personality</label>
            <Select
              name="personality"
              fullWidth
              variant="outlined"
              value={data?.personality || ''}
              onChange={onChange}
            >
              <MenuItem value={null}>Other</MenuItem>
              {coachPersonalityPreferences.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>

        <Grid className={styles.fieldsBlock_column_input} container spacing={2}>
          <Grid item xs={12}>
            <label>Coach certification</label>
            <Select
              variant="outlined"
              fullWidth
              name="certifications"
              multiple
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 500,
                    width: 250
                  }
                },
                getContentAnchorEl: null
              }}
              value={data?.certifications || []}
              onChange={onSelect}
              renderValue={(selected) => {
                return (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => (
                      <Chip style={{ margin: 2 }} key={value} label={value} />
                    ))}
                  </div>
                );
              }}
            >
              {certifications.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={data?.certifications?.indexOf(option) > -1} />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid className={styles.fieldsBlock_column_input} container spacing={2}>
          <Grid item xs={12}>
            <label>Coach speciality</label>
            <Select
              name="specialities"
              fullWidth
              multiple
              variant="outlined"
              value={data.specialities || []}
              onChange={onSelect}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 500,
                    width: 250
                  }
                },
                getContentAnchorEl: null
              }}
              renderValue={(selected) => {
                return (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => (
                      <Chip style={{ margin: 2 }} key={value} label={value} />
                    ))}
                  </div>
                );
              }}
            >
              {specialities.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={data?.specialities?.indexOf(option) > -1} />
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} className={styles.intakeForm_actionButtons}>
        <Button
          onClick={(e) => handleSubmitForm(e)}
          variant="contained"
          color="primary"
          className={styles.intakeForm_actionButtons_nextBtn}
        >
          Submit
        </Button>
      </Grid>
    </form>
  );
};

export default ClientCoachPreferences;

import React, { useMemo, useState } from 'react';
import { Wrapper, Body, Information, Name, Border } from './Header.style';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import { userTypes } from '../../../constants';

import { IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { chatActions } from '../../../redux/actions';

// const Header = ({ chat }) => {
const Header = (props) => {
  const chat = props.chat;
  const userType = useSelector((state) => state.auth.userType);
  const { client, nutritionist, toAdmin } = chat;
  console.log({ chat });
  const history = useHistory();

  //com.mco
  const [cardType, setCardType] = useState('chat');

  const [isGroupChat, setIsGroupChat] = useState(false);

  const chatUser = useMemo(() => {
    if (chat.groupChatType != 'NONE') {
      // fk2 group chat
      setIsGroupChat(true);
      return {
        name: chat.groupName,
        userId: 1,
        image: 'NONE'
      };
    }
    setIsGroupChat(false);

    if (toAdmin && userType !== userTypes.admin) {
      return {
        type: userTypes.admin,
        image: 'A',
        name: 'Planibble Admin'
      };
    }

    if (client) {
      return {
        clientId: client.id,
        type: userTypes.client,
        image: client.user.image,
        name: client.user.name
      };
    }

    if (nutritionist) {
      return {
        nutritionistId: nutritionist.id,
        type: userTypes.nutritionist,
        image: nutritionist.image || nutritionist.user.image,
        name: `${nutritionist.firstName} ${nutritionist.lastName}`
      };
    }
  }, [chat]);

  const profileUrl = useMemo(() => {
    console.log(chatUser);
    if (chatUser.type === 'admin') return null;

    const secondPart =
      chatUser.type === 'client'
        ? `clients/${chatUser.clientId}`
        : `nutritionists/${chatUser.nutritionistId}`;

    const result = `/${userType}/${secondPart}`;
    console.log(result);
    return result;
  }, [userType, chatUser]);

  const goToProfile = () => {
    if (profileUrl) history.push(profileUrl);
  };

  const handleClick = (args) => {
    setCardType(args);
    props.onClick(args);
  };

  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const deleteChannel = async (event) => {
    const res = await chatActions.deleteChannel(chat.channelId);
    history.go(0);
    setRejectDialogOpen(false);
  };

  return (
    <Wrapper style={{ height: 'auto' }}>
      <ConfirmationDialog
        title="Delete this chat"
        text="Are you sure you want to delete this chat?"
        onClose={() => setRejectDialogOpen(false)}
        open={rejectDialogOpen}
        onSubmit={deleteChannel}
      />
      <Body>
        <Information>
          <div
            onClick={goToProfile}
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '1rem',
              paddingTop: '1rem'
            }}
          >
            <Avatar
              src={chatUser.image}
              style={{
                width: '50px',
                height: '50px',
                border: isGroupChat ? '3px rgb(196, 235, 55) solid' : ''
              }}
            />
            <div style={{ marginLeft: '1rem', cursor: 'pointer' }}>
              <Name>{chatUser.name}</Name>

              <div
                style={{
                  margin: '0 0px'
                }}
              >
                {isGroupChat ? 'Clients: ' : ''}
                {isGroupChat &&
                  chat.chatGroupsClients.map((gClient) => {
                    return <>{'- ' + gClient.client.user?.name}</>;
                  })}
              </div>
            </div>
          </div>
        </Information>
        {userType == 'admin' ? (
          <div>
            <IconButton
              style={{ width: '48px', height: '48px', paddingBottom: '8px', cursor: 'pointer' }}
              onClick={() => {
                setRejectDialogOpen(true);
              }}
            >
              <Cancel />
            </IconButton>
          </div>
        ) : (
          <></>
        )}
        <div style={{ marginLeft: '10rem', cursor: 'pointer' }} onClick={() => handleClick('chat')}>
          <Name style={{ color: cardType === 'chat' ? 'rgb(196, 235, 55)' : '#d0dae5' }}>
            {'Chat'}
          </Name>
        </div>
        <div style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={() => handleClick('media')}>
          <Name style={{ color: cardType === 'media' ? 'rgb(196, 235, 55)' : '#d0dae5' }}>
            {'Media'}
          </Name>
        </div>
        <div
          style={{ marginLeft: '1rem', cursor: 'pointer' }}
          onClick={() => handleClick('documents')}
        >
          <Name style={{ color: cardType === 'documents' ? 'rgb(196, 235, 55)' : '#d0dae5' }}>
            {'Documents'}
          </Name>
        </div>
      </Body>
      <Border />
    </Wrapper>
  );
};

export default Header;

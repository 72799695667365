import React from 'react';
import { PubNubProvider } from 'pubnub-react';

import ChatContainer from './ChatContainer';
import { pubnubInstance } from '../../../utils/pubnub';

function ClientChat() {
  return (
    <PubNubProvider client={pubnubInstance}>
      <ChatContainer />
    </PubNubProvider>
  );
}

export default ClientChat;

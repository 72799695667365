export const userTypes = {
  admin: 'admin',
  nutritionist: 'nutritionist',
  client: 'client'
};

export const nutritionistStatuses = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED'
};

export const clientStatuses = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING'
};

export const clientAccountStatuses = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED'
};

export const groupChatTypes = {
  NONE: { type: 'NONE', desc: 'NONE' },
  // PLAN_1: { type: 'PLAN_1', desc: '2 CLIENT GROUP - $225', capacity: 2, amount: 0 },
  PLAN_1: { type: 'PLAN_1', desc: '2 CLIENT GROUP - $225', capacity: 2, amount: 225 },
  PLAN_2: { type: 'PLAN_2', desc: '3 CLIENT GROUP - $150', capacity: 3, amount: 150 },
  PLAN_3: { type: 'PLAN_3', desc: '6 CLIENT GROUP - $75', capacity: 6, amount: 75 }
};

import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import _ from 'lodash';
import CopyIcon from '@material-ui/icons/FileCopy';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { nutritionistActions } from '../../../redux/actions';

import config from '../../../config';

import styles from '../../../components/intakeForm/IntakeForm.module.scss';
import {
    TextField,
    Grid,
  } from '@material-ui/core';

import img from '../../../assets/img/icons/ic-calendar.png';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const initialData = {
    calendarUrl: ''
  };

const CalendarCard = ({}) => {
    const history = useHistory();
  const nutritionist = useSelector((state) => state.user.nutritionist);
  const [nutritionistData, setNutritionistData] = useState(initialData);
  const link = useMemo(() => {
    return `${nutritionist.calendarUrl ?? 'https://calendly.com'}`;
  }, [nutritionist]);

  const handleCopy = () => {
    copyToClipboard(link);
  };

  //new 
  useEffect(() => {
    setNutritionistData({
        calendarUrl: nutritionist.calendarUrl
      });
  }, [nutritionist]);

  const onChange = (e) => {
    setNutritionistData((old) => ({
      ...old,
      [e.target.name]: e.target.value
    }));
  };

  const submitNutritionist = async (data) => {
    await nutritionistActions.submitNutritionist(data);
    // await userActions.loadUser();
    // history.push('/nutritionist/dashboard');
    history.go(0);
  };

  const submitForm = (data) => {
    // setFirstFormData(data);
    setNutritionistData({
      ...nutritionistData,
      ...data
    });
    submit();
  };

  const prepareNutritionist = (data) => {
    let formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return formData;
  };

  const submit = useCallback(() => {
    const data = prepareNutritionist(nutritionistData);
    // for (var pair of data.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]); 
    // }
    submitNutritionist(data);
  }, [nutritionistData]);

  //fk2




//   useEffect(() => {
//     const script = document.createElement('script');
  
//     script.src = "https://assets.calendly.com/assets/external/widget.js";
//     script.async = true;
  
//     document.body.appendChild(script);
  
//     return () => {
//       document.body.removeChild(script);
//     }
//   }, []);

  if (!nutritionist) {
    return <></>;
  }

  return (
    <Card
      style={{
        marginTop: 10,
        minWidth: 300,
        maxWidth: 400,
        margin: 10
      }}
    >
      <CardContent style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography gutterBottom variant="h6" style={{ color: '#c4eb37' }}>
          My Calendar
        </Typography>
        <a 
            style={{textDecoration: 'underline', color: '#c4eb37', textAlign: 'right', marginTop: '-30px'}}
            href='https://calendly.com'>
              Calendar Settings
          </a>
        <div
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
        >
          <div
            style={{width: '20%'}}
          >
            <img src={img} style={{ width: '50px', height: '50px', marginLeft: '10px' }} />
          </div>
          <div>
          <a
            style={{textDecoration: 'underline', color: '#c4eb37'}}
            href='https://planibble-files.s3.us-west-2.amazonaws.com/resume/Calendly+How+to-1.pdf' >How to create/add calendar
          </a><br />
          </div>
        </div>
        <form noValidate onSubmit={submitForm}>
        <div className={styles.fieldsBlock}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <label>Calendar Link (Calendly preferred) *</label>
                <TextField
                variant="outlined"
                //className={styles.inputField}
                onChange={onChange}
                value={nutritionistData.calendarUrl}
                fullWidth
                id="calendarUrl"
                placeholder="calendarUrl"
                label=""
                name="calendarUrl"
                />
                <label style={{color: 'red'}}>** Changes must be approved by Admin</label>
            </Grid>
            </Grid>
            <Grid item xs={12} className={styles.intakeForm_actionButtons}>
                <Button
                onClick={(e) => submit(e)}
                variant="contained"
                color="primary"
                className={styles.intakeForm_actionButtons_nextBtn}
                >
                Save
                </Button>
            </Grid>
        </div>
        </form>
        <Button size="large" startIcon={<CopyIcon />} onClick={handleCopy}>
          Copy my calendar link
        </Button>
      </CardContent>
    </Card>
  );
};

export default CalendarCard;

import React, { useState } from 'react';
import { TextField, LinearProgress, Link, Icon } from '@material-ui/core';

import styles from './LogIn.module.scss';

import { authActions } from '../../redux/actions';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
  GoogleReCaptcha
} from "react-google-recaptcha-v3";
import { userActions } from '../../redux/actions';

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormChange = ({ target }) => {
    const { name, value } = target;
    setFormData((old) => ({ ...old, [name]: value }));
  };

  var recaptchaToken ='';
  const handleVerify = async token => {
    // const responseRecaptcah = await userActions.verifyRecaptcha(token);
    // console.log("fk2 recaptcha from server", responseRecaptcah);
    recaptchaToken = token;
    console.log("fk2 token", token);
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const newToken = await executeRecaptcha("recaptchaLogin");
      // const newToken = await executeRecaptcha();
      // console.log("new token", newToken);
      const responseRecaptcah = await userActions.verifyRecaptcha(newToken, formData.email);
      console.log("fk2 recaptcha from server", responseRecaptcah);
      // if (responseRecaptcah.data.score >= 0.5) 
      await authActions.loginManual(formData);
      // eval(responseRecaptcah.data);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeySubmit = async (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSocialLogin = async (type) => {
    authActions.loginSocial({ type });
  };

  return (
    <div className={styles.userTypeContainer}>
      <div className={styles.contentContainer}>
      <GoogleReCaptcha onVerify={handleVerify} />
        <p className={styles.title}>Login</p>

        <TextField
          className="my-2"
          variant="outlined"
          fullWidth
          label="Email Address"
          name="email"
          autoFocus
          onChange={handleFormChange}
          onKeyPress={handleKeySubmit}
        />

        <TextField
          className="my-2"
          onChange={handleFormChange}
          variant="outlined"
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment variant="filled" position="end">
                <IconButton
                  style={{ backgroundColor: 'transparent' }}
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
          onKeyPress={handleKeySubmit}
        />
        {!isLoading && (
          <button className={styles.signInButton} onClick={handleSubmit}>
            <p className={styles.socialButtonText}>Sign In</p>
          </button>
        )}
        {isLoading && <LinearProgress />}
        {error && <div className="text-center text-danger"> {error}</div>}

        <div className={styles.forgotContainer}>
          <Link href="/forgot-password">Forgot password?</Link>
          <Link href="/register">Register</Link>
        </div>

        <div className={styles.separator}>
          <div className={styles.separatorLine}></div>
          <p className={styles.separatorText}>or</p>
          <div className={styles.separatorLine}></div>
        </div>

        <button className={styles.aplleButton} onClick={() => handleSocialLogin(1)}>
          <Icon className={`fa fa-apple ${styles.icon}`} />
          <p className={styles.socialButtonText}>Continue with Apple</p>
        </button>

        <button className={styles.googleButton} onClick={() => handleSocialLogin('google-oauth2')}>
          <Icon className={`fa fa-google ${styles.icon}`} />
          <p className={styles.socialButtonText}>Continue with Google</p>
        </button>

        {false && <button className={styles.facebookButton} onClick={() => handleSocialLogin('facebook')}>
          <Icon className={`fa fa-facebook ${styles.icon}`} />
          <p className={styles.socialButtonText}>Continue with Facebook</p>
        </button>}

        <Link href="/">Back</Link>

        <Link href="/admin/login" style={{ position: 'fixed', right: 10, bottom: 80 }}>
          Admin login
        </Link>
      </div>
    </div>
  );
}

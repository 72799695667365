import React, { useCallback, useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Rating from '@material-ui/lab/Rating';

import clientActions from '../../../redux/actions/client';
import { yearsOfExperienceText } from '../../../utils/textHelper';
import SkillList from './coachCardElements/SkillList';
import TopRightInfo from './coachCardElements/TopRight';

const CoachCardComponent = ({ item, showSavedIcon }) => {
  const { id } = item;
  const history = useHistory();
  const savedNutritionists = useSelector((state) => state.client.savedNutritionists);
  const isSaved = useMemo(() => {
    return savedNutritionists.find((savedNutritionist) => savedNutritionist.id === item.id);
  }, [savedNutritionists, item]);

  const reviewsCount = useMemo(() => {
    if (item.nutritionistsReviews) {
      return item.nutritionistsReviews.length;
    }
    return 0;
  }, [item]);

  const rating = useMemo(() => {
    if (item?.nutritionistsReviews?.length) {
      const sumRating = item.nutritionistsReviews.reduce((res, cur) => {
        return res + cur.rating;
      }, 0);
      return sumRating / item.nutritionistsReviews.length;
    }
    return 0;
  }, [item]);

  const saveHandler = useCallback(
    async (id) => {
      if (!isSaved) {
        await clientActions.saveNutritionist(id);
      } else {
        await clientActions.unsaveNutritionist(id);
      }
      clientActions.getSavedNutritionists();
    },
    [isSaved]
  );

  return (
    <div className="hvr-float-shadow" style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          flex: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#2F2F38',
          padding: '1.5rem',
          borderRadius: '20px',
          maxWidth: '100%'
        }}
      >
        {showSavedIcon && (
          <div>
            <div style={{ position: 'absolute', left: 10, top: 10, zIndex: 1 }}>
              <Tooltip
                title={
                  <span style={{ fontSize: 14, cursor: 'default' }}>
                    {isSaved ? 'Remove from saved' : 'Save'}
                  </span>
                }
                placement="top"
              >
                <Fab
                  style={{
                    height: '15px',
                    width: '35px',
                    backgroundColor: isSaved ? 'yellow' : 'grey'
                  }}
                  color="primary"
                  onClick={(e) => saveHandler(id)}
                >
                  <BookmarkIcon />
                </Fab>
              </Tooltip>
            </div>
          </div>
        )}

        <TopRightInfo nutritionist={item} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            position: 'relative',
            flex: 1
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                borderRadius: 1000,
                position: 'relative',
                overflow: 'hidden',
                width: '200px',
                height: '200px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img
                style={{
                  width: 'auto',
                  height: '100%',
                  margin: '0 auto',
                  display: 'inline'
                }}
                src={item.image || item.user.image}
                alt=""
              />
            </div>
            <p
              style={{
                maxWidth: 300,
                fontSize: 24,
                color: '#EE79B3',
                textAlign: 'center',
                fontFamily: 'Bebas Neue',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                letterSpacing: '2px'
              }}
            >
              {`${item.firstName} ${item.lastName}`.toUpperCase()}
            </p>
            {item.currentOffering && (
              <p
                style={{
                  maxWidth: 300,
                  fontFamily: 'Bebas Neue',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  fontSize: '1.1rem'
                }}
              >
                {item.currentOffering}
              </p>
            )}
            <span style={{ fontSize: '18px' }}>
              {item.expYears && yearsOfExperienceText(item.expYears)}
            </span>
            <div style={{ marginTop: '10px' }}>
              {item.availability ? (
                <div style={{ color: '#CCFF00', fontSize: '15px' }}>Available Now</div>
              ) : (
                <div style={{ color: '#f44336', fontSize: '15px' }}>Not Available Now</div>
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
              <Rating name="disabled" precision={0.5} value={rating} readOnly />
            </div>
            <div style={{ marginTop: '10px' }}>Reviews: {reviewsCount}</div>

            <div style={{ padding: '10px 0' }}>
              <SkillList items={item.specialities} title="Specialities" />
              <SkillList items={item.certifications} title="Certifications" />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 'auto',
            paddingTop: 25
          }}
        >
          <div
            onClick={() => history.push({ pathname: `nutritionists/${item.id}` })}
            style={{
              borderRadius: 100,
              cursor: 'pointer',
              width: 160,
              backgroundColor: '#212128',
              color: '#EE79B3',
              paddingTop: '10px',
              fontSize: '20px',
              paddingBottom: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            Learn more
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachCardComponent;

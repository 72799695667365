import { adminActions } from '../../../redux/actions';

import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField
} from '@material-ui/core';

import { filterTable } from '../../../utils/helper';
import { useHistory } from 'react-router';
import { ColorLensOutlined } from '@material-ui/icons';

const columns = [
  {
    id: 'nutritionist.firstName',
    label: 'First name'
  },
  {
    id: 'nutritionist.lastName',
    label: 'Last name'
  },
  {
    id: 'nutritionist.email',
    label: 'Email'
  },
  // {
  //   id: 'nutritionist.state',
  //   label: 'State'
  // },
  {
    id: 'nutritionist.certifications',
    label: 'Certifications',
    format: (value) => {
      return value.join(', ');
    }
  },
  {
    id: 'nutritionist.earnings',
    label: 'Earnings',
    format: (value) => "$" + value 
  },
  {
    id: 'createdAt',
    label: 'Date created',
    format: (value) => moment(value).format('MM.DD.YYYY')
  }
];

const filterFields = [
  'nutritionist.firstName',
  'nutritionist.lastName',
  'nutritionist.email',
  'nutritionist.certifications'
];

export default function () { 
  const nutritionists = useSelector((state) => state.admin.nutritionists);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const history = useHistory();

  const [newNutritionists, setNewNutritionists] = useState(nutritionists);

  useEffect(() => {
    adminActions.loadNutritionists();
  }, []);

  const table = useMemo(() => {
    const result = filterTable(newNutritionists, filterFields, search);
    return result.slice(page * pageSize, (page + 1) * pageSize);
  }, [newNutritionists, pageSize, page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const handleSearch = _.debounce((e) => {
    const { value } = e.target;
    setSearch(value);
  }, 500);

  const [allReportsData, setallReportsData] = useState(null);
  const loadAllReportsdata = async () => {
    const data = await adminActions.loadAllReportsData();
    setallReportsData(data);
    //console.table(data);
  };

  useEffect(() => {
    loadAllReportsdata();
  }, [nutritionists]);

  useEffect(() => {
    if (!allReportsData) return;
    nutritionists.forEach(nutritionist => {
      let amount = allReportsData.filter((item) => item.destination == nutritionist.nutritionist.stripeAccount?.expressAccountId).reduce((total, item, index) =>
        total + item.amount, 0
      );
      nutritionist.nutritionist.earnings = amount / 100;
      // console.log(nutritionist.nutritionist);
    });
    nutritionists.sort((a, b) => a.nutritionist.earnings > b.nutritionist.earnings ? -1 : 1);
    setNewNutritionists(nutritionists);
    console.log("new",newNutritionists);
  }, [allReportsData]);

  return (
    <div style={{ marginTop: 30 }}>
      <div className="d-flex justify-content-between align-items-center">
        <Typography
          style={{
            fontFamily: 'Bebas Neue',
            color: '#EE79B3',
            fontSize: '2.5rem',
            letterSpacing: '2px'
          }}
          component="h1"
          variant="h5"
        >
          Coaches
        </Typography>

        <TextField
          className="my-2"
          variant="outlined"
          label="Search"
          name="search"
          onChange={handleSearch}
        />
      </div>
      <Paper>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    style={{ fontFamily: 'Bebas Neue', fontSize: '1.2rem' }}
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.map((row) => {
                console.log(row);
                return (
                  <TableRow
                    hover
                    style={{cursor: 'pointer'}}
                    key={row.id}
                    onClick={() => history.push(`/admin/nutritionists/${row.nutritionist.id}`)}
                  >
                    {columns.map((column) => {
                      let value = column.format
                        ? column.format(_.get(row, column.id))
                        : _.get(row, column.id);
                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          style={{ color: '#c4eb37' }}
          count={newNutritionists.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </div>
  );
}

import { DataGrid } from '@material-ui/data-grid';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import ClientsTable from './table';

const AdminClients = () => {
  return (
    <div>
      <ClientsTable />
    </div>
  );
};

export default AdminClients;

const appMutations = {
  toogleMenu: 'APP/TOOGLE_MENU',
  loadFAQ: 'APP/LOAD_FAQ'
};

const authMutations = {
  logout: 'APP/LOGOUT',
  login: 'APP/LOGIN'
};

const userMutations = {
  set: 'USER/SET',
  setChats: 'USER/SET_CHATS',
  changeClientStatus: 'USER/CHANGE_CLIENT_STATUS',
  updateTimetoken: 'USER/UPDATE_TIMETOKEN',
  updateUnreadMessagesCount: 'USER/UPDATE_UNREAD_MESSAGES_COUNT',
  updateUnreadMessagesInfo: 'USER/UPDATE_UNREAD_MESSAGES_INFO',
  changeAdminChatStatusNutritionist: 'USER/CHANGE_ADMIN_CHAT_STATUS_NUTRITIONIST',
  changeAdminChatStatusClient: 'USER/CHANGE_ADMIN_CHAT_STATUS_CLIENT'
};

const chatMutations = {
  loadChatData: 'CHAT/LOAD_CHAT_DATA',
  setMessagesToChannel: 'CHAT/SET_MESSAGES_TO_CHANNEL',
  resetMessages: 'CHAT/RESET_MESSAGES',
  setUnreadCounts: 'CHAT/SET_UNREAD_COUNTS',
  //com.mco
  setFilesToChannel: 'CHAT/SET_FILES_TO_CHANNEL'
};

const adminMutations = {
  loadClients: 'ADMIN/LOAD_CLIENTS',
  loadNutritionists: 'ADMIN/LOAD_NUTRITIONISTS',
  loadNutritionistChangeRequests: 'ADMIN/LOAD_NUTRITIONISTS_CHANGE_REQUESTS',
  loadNutritionistApplicants: 'ADMIN/LOAD_NUTRITIONISTS_APPLICANTS',
  loadStats: 'ADMIN/LOAD_STATS',
  loadReviewRequests: 'ADMIN/LOAD_REVIEW_REQUESTS',
  getAdminChats: 'ADMIN/GET_ADMIN_CHATS',
  moderatedChats: 'ADMIN/MODERATED_CHATS',
  loadNutritionistExtraSpecialties: 'ADMIN/LOAD_NUTRITIONISTS_EXTRA_SPECIALTIES',
  loadNutritionistClients: 'ADMIN/LOAD_NUTRITIONIST_CLIENTS'
};

const nutritionistMutations = {
  loadClients: 'NUTRITIONIST/LOAD_CLIENTS',
  loadChatClients: 'NUTRITIONIST/LOAD_CHAT_CLIENTS',
  changeClientStatus: 'NUTRITIONIST/CHANGE_CLIENT_STATUS',
  payouts: 'NUTRITIONIST/PAYOUTS'
};

const clientMutations = {
  loadNutritionists: 'CLIENT/LOAD_NUTRITIONISTS',
  loadRecommendedNutritionists: 'CLIENT/LOAD_RECOMMENDED_NUTRITIONISTS',
  setNutritionistsCount: 'CLIENT/SET_NUTRITIONISTS_COUNT',
  loadMyNutritionists: 'CLIENT/LOAD_MY_NUTRITIONISTS',
  getMyNutritionists: 'CLIENT/GET_MY_NUTRITIONISTS',
  getSavedNutritionists: 'CLIENT/GET_SAVED_NUTRITIONISTS'
};

const pollMutations = {
  loadPolls: 'USER/LOAD_POLLS',
  loadMySubmittions: 'USER/LOAD_MY_SUBMITTIONS',
  loadForms: 'USER/LOAD_FORMS',
  disable: 'USER/DISABLE',
  activate: 'USER/ACTIVATE'
};

export default {
  app: appMutations,
  auth: authMutations,
  chat: chatMutations,
  admin: adminMutations,
  user: userMutations,
  nutritionist: nutritionistMutations,
  client: clientMutations,
  poll: pollMutations
};

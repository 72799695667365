import React from 'react';
import { Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import moment from 'moment';

const TopRightInfo = ({ nutritionist }) => {
  return (
    <div
      style={{
        display: 'flex',
        right: 10,
        top: 10,
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}
    >
      <Tooltip
        title={
          <span style={{ fontSize: 14 }}>
            {nutritionist.expYears > 1 ? (
              <span>Available tiers: 100$/mo, 200$/mo, 300$/mo</span>
            ) : (
              <span>Available tiers: 100$/mo</span>
            )}
          </span>
        }
        placement="top"
      >
        <div
          style={{
            marginTop: '-15px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '16px',
            width: '65px',
            height: '26px',
            // padding: '10px 20px 10px 20px',
            borderRadius: '20px',
            color: '#EE79B3',
            backgroundColor: '#212128'
          }}
        >
          {nutritionist.expYears > 1 ? <span>Tier 2</span> : <span>Tier 1</span>}
        </div>
      </Tooltip>
      {(nutritionist.nutritionistOptions.promoted && moment(nutritionist.nutritionistOptions.promotionTime).diff(moment()) > 0) &&  (
        <Tooltip
          title={<span style={{ fontSize: 14, cursor: 'default' }}>Promoted by Planibble</span>}
          placement="top"
        >
          <StarIcon style={{ color: 'yellow', marginTop: 5, cursor: 'pointer' }} />
        </Tooltip>
      )}
    </div>
  );
};

export default TopRightInfo;

import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Select, MenuItem } from '@material-ui/core';
import { useSelector } from 'react-redux';

import config from '../../../config';
import { adminActions, publicActions } from '../../../redux/actions';

export default function () {
  const [data, setData] = useState([]);
  //   const [type, setType] = useState('nutritionist');

  //   useEffect(() => {
  //     if (data.length == 0) return;
  //     const result = data[0].find((i) => i.type === type);
  //     setData(result?.text || '');
  //   }, [data, type]);

  useEffect(async () => {
    const data = await publicActions.loadHomepageContent({ type: 'all' });
    setData(data);
  }, []);

  const handleSubmit = async () => {
    await adminActions.saveHomepageContent({ data });
  };

  const setValue = (newValue, index) => {
    console.log('fk2 data new value', index, data, newValue);
    const newData = [...data];
    newData[index].text = newValue;
    setData(newData);
  };

  const handleTypeChange = (e) => {
    // setType(e.target.value);
  };

  return (
    <div>
      <Button className="my-2 ml-auto" variant="contained" color="primary" onClick={handleSubmit}>
        Save
      </Button>

      {/* <Select value={type} onChange={handleTypeChange} className="mx-3">
        <MenuItem value={'Coaches'}>Coach</MenuItem>
        <MenuItem value={'Clients'}>Client</MenuItem>
      </Select> */}

      <div style={{ display: 'flex' }}>
        {data.length > 1 &&
          data.map((video, index) => (
            <div>
              <h3>{video.type}</h3>
              <Editor
                apiKey={config.tinyMCE_key}
                init={{}}
                value={video.text}
                onEditorChange={(newValue, editor) => setValue(newValue, index)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image',
                    'charmap print preview anchor help',
                    'searchreplace visualblocks code',
                    'insertdatetime media table paste wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help code'
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import Linkify from 'react-linkify';
import {
  Container,
  Content,
  Date,
  Image,
  Wrapper,
  Body,
  Header,
  SenderName,
  MyContent,
  MyWrapper,
  MySenderName,
  MyDate
} from './MessageList.style';

import { Avatar } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';

import { userTypes } from '../../../constants';
import _, { last } from 'lodash';

import replyImage from '../../../assets/img/icons/response.png';
import { chatActions } from '../../../redux/actions';

const MessagesList = forwardRef((props, ref) => {
  const chat = props.chat;
  const pubnub = usePubNub();

  const userType = useSelector((state) => state.auth.userType);
  const user = useSelector((state) => state.user);
  const allMessages = useSelector((state) => state.chat.messages);
  const messages = useMemo(() => {
    if (_.isArray(allMessages[chat.channelId])) {
      return [...allMessages[chat.channelId]];
    } else return [];
  }, [allMessages, chat]);

  //com.mco

  const [repliedTimetoken, setRepliedTimetoken] = useState(undefined);
  const [goBackTimetoken, setGoBackTimetoken] = useState(undefined);
  const [firstLoad, setFirstLoad] = useState(true);

  useImperativeHandle(ref, () => ({
    refDownCallback() {
      if (goBackTimetoken && refs[goBackTimetoken]) {
        refs[goBackTimetoken].current.scrollIntoView({
          // behavior: 'smooth',
          block: 'start'
        });
        setGoBackTimetoken(undefined);
      } else
        setTimeout(() => {
          scrollToBottom();
        }, 100);
    },

    moreLoadCallback() {
      if (lastTimetoken && refs[lastTimetoken])
        refs[lastTimetoken].current.scrollIntoView({
          // behavior: 'smooth',
          block: 'start'
        });

      setLastTimetoken(filteredMessages[0] ? filteredMessages[0].timetoken : lastTimetoken);
    }
  }));

  const filteredMessages = useMemo(() => {
    if (_.isArray(allMessages[chat.channelId]) && _.isArray(messages)) {
      return [
        ...messages.filter(
          (message, index) =>
            message.message != 'New-File-Sent' && message.message.text != 'New-File-Sent'
        )
      ];
    } else return [];
  }, [messages, allMessages, chat]);

  const [lastTimetoken, setLastTimetoken] = useState(
    filteredMessages[0] ? filteredMessages[0].timetoken : undefined
  );

  const currentUser = useMemo(() => {
    if (userType === userTypes.client) {
      return {
        userId: user.id,
        image: user.image,
        name: user.name
      };
    }
    if (userType === userTypes.nutritionist) {
      return {
        userId: user.id,
        image: user.image,
        name: `${user.nutritionist.firstName} ${user.nutritionist.lastName}`
      };
    }
    if (userType === userTypes.admin) {
      return {
        userId: 'admin',
        image: null,
        name: `Planibble admin`
      };
    }
  }, [user]);

  // const chatUser = useMemo(() => {
  //   if (userType !== userTypes.admin && chat.toAdmin) {
  //     return {
  //       name: 'Planibble admin',
  //       userId: 'admin'
  //     };
  //   }

  //   if (chat.nutritionist) {
  //     const { nutritionist } = chat;
  //     return {
  //       name: `${nutritionist.firstName} ${nutritionist.lastName}`,
  //       userId: nutritionist.user.id,
  //       image: nutritionist.user.image
  //     };
  //   } else if (chat.client) {
  //     const { client } = chat;
  //     return {
  //       name: client.user.name,
  //       userId: client.user.id,
  //       image: client.user.image
  //     };
  //   } else if (chat.chatGroupsClients.length > 0) {
  //     // fk2 group chat
  //     const { client } = chat.chatGroupsClients[0];
  //     return {
  //       name: client.user.name,
  //       userId: client.user.id,
  //       image: client.user.image
  //     };
  //   }
  // }, [chat, userType]);

  // let chatUser1 = null;
  // const getChatUser = (userId) => {
  //   console.log('foreach chat', chat);
  //   // if (chat.nutritionist.user.id == userId) return chatUser;
  //   const groupClient = chat.chatGroupsClients.filter((o) => o.client.user.id == userId)[0];
  //   console.log('foreach chat badesh', groupClient);
  //   return {
  //     name: groupClient.client.user.name,
  //     userId: groupClient.client.user.id,
  //     image: groupClient.client.user.image
  //   };
  // };
  const getAvatar = (userId) => {
    if (chat.toAdmin) return <Avatar> A </Avatar>;
    if (chat.client) return <Avatar src={chat.client.user.image} />;
    const chatUser = chat.chatGroupsClients?.filter((o) => o.client.user.id == userId)[0];
    if (chatUser) {
      return chatUser?.client.user.image ? (
        <Avatar src={chatUser?.client.user.image} />
      ) : (
        <Avatar> A </Avatar>
      );
    } else {
      // coach
      return chat.nutritionist ? (
        <Avatar src={chat.nutritionist.user.image} />
      ) : (
        <Avatar> A </Avatar>
      );
    }
  };
  const getUsername = (userId) => {
    if (chat.toAdmin) return 'Admin';
    if (chat.client) return chat.client.user.name;
    const chatUser = chat.chatGroupsClients?.filter((o) => o.client?.user.id == userId)[0];
    if (chatUser) {
      return chatUser?.client.user.name ? chatUser?.client.user.name : 'No Username';
    }
    // //coach
    else
      return chat.nutritionist
        ? chat.nutritionist.firstName + ' ' + chat.nutritionist.lastName
        : 'No Username';
  };

  const lastElem = useRef(null);
  const userId = user ? user.id : 'admin';

  const scrollToBottom = () => {
    // if(filteredMessages && filteredMessages[filteredMessages.length - 1] && refs[filteredMessages[filteredMessages.length - 1].timetoken]
    //   && refs[filteredMessages[(filteredMessages.length - 1)].timetoken].current)
    //   refs[filteredMessages[filteredMessages.length - 1].timetoken].current.scrollIntoView({ behavior: 'auto' });
    // else
    lastElem.current.scrollIntoView({ behavior: 'auto' });
  };

  //com.mco

  // useEffect(() => {
  //   setTimeout(() => scrollToBottom(), 100);
  // }, [messages]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
    // }, []);
  }, [chat.channelId]);

  //fk2 test delete
  console.log('fk2 messages');
  console.log(messages);

  const setReplied = async (args) => {
    props.setReplied(args);
  };

  const refs = messages.reduce((acc, value) => {
    acc[value.timetoken] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (repliedTimetoken && !refs[repliedTimetoken]) {
      chatActions.moreLoadMessages({
        channels: [chat.channelId],
        pubnub: pubnub,
        startTimeToken: messages[0].timetoken
      });
    } else if (repliedTimetoken && refs[repliedTimetoken]) {
      refs[repliedTimetoken].current.scrollIntoView({ block: 'center' });
      setLastTimetoken(filteredMessages[0] ? filteredMessages[0].timetoken : lastTimetoken);
      setRepliedTimetoken(undefined);
    }
  }, [repliedTimetoken, messages]);

  useEffect(() => {
    if (
      messages[messages.length - 1] &&
      messages[messages.length - 1].newMsg != null &&
      messages[messages.length - 1].newMsg != undefined &&
      messages[messages.length - 1].newMsg === true
    ) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
      messages[messages.length - 1].newMsg = false;
    }
  }, [messages]);

  // useEffect(() => {
  // if(!firstLoad && !repliedTimetoken && lastTimetoken && refs[lastTimetoken] != null && refs[lastTimetoken] != undefined && refs[lastTimetoken].current != null
  // && refs[lastTimetoken].current != undefined){
  //   if(messages[messages.length - 1].newMsg != null && messages[messages.length - 1].newMsg != undefined && messages[messages.length - 1].newMsg === true){
  //     setTimeout(() => {
  //       scrollToBottom();
  //     }
  //       , 100);
  //       messages[messages.length - 1].newMsg = false;
  //   }
  //   else{
  //     refs[lastTimetoken].current.scrollIntoView
  //     ({
  //       // behavior: 'smooth',
  //       block: 'start',
  //     });
  //   }
  // }
  // else{
  //   setFirstLoad(false);
  //   setTimeout(() => {
  //     scrollToBottom();
  //   }
  //     , 100);
  // }

  // setLastTimetoken(filteredMessages[0] ? filteredMessages[0].timetoken : undefined);

  // }, [messages]);

  const handleClick = (repliedT, mainT) => {
    if (
      refs[repliedT] != null &&
      refs[repliedT] != undefined &&
      refs[repliedT].current != null &&
      refs[repliedT].current != undefined
    ) {
      refs[repliedT].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setRepliedTimetoken(undefined);
    } else {
      // chatActions.moreLoadMessages({ channels: [chat.channelId], pubnub: pubnub, startTimeToken: messages[0].timetoken });
      setRepliedTimetoken(repliedT);
    }
    setGoBackTimetoken(mainT);
  };

  return (
    <Container>
      {
        //messages.filter((message, index) => message.message != 'New-File-Sent' && message.message.text != 'New-File-Sent')

        filteredMessages.map((message, index) => {
          let url,
            fileName,
            isFile = false,
            isImage = false;
          let imageRegex = /(jpeg|png|jpg)\b/;
          let caption;

          if (message.message.file) {
            url = pubnub.getFileUrl({
              channel: chat.channelId,
              id: message.message.file.id,
              name: message.message.file.name
            });
            isFile = true;
            fileName = message.message.file.name;
            isImage = imageRegex.test(fileName);
          }

          if (
            message.message.file &&
            message.message.message &&
            typeof message.message.message === 'string'
          )
            caption = message.message.message;
          else if (
            message.message.file &&
            message.message.message &&
            typeof message.message.message.text === 'string'
          )
            caption = message.message.message.text;
          else if (
            message.message.file &&
            message.message.message &&
            !typeof message.message.message === 'string'
          )
            caption = message.message.file.fileName;
          else caption = '';

          //fk2 push notification type
          let messageText = '';
          let repliedMT = undefined;
          let repliedTT = undefined;
          let repliedFU = undefined;
          let isImageRepliedFU = undefined;
          //com.mco
          if (typeof message.message === 'string') {
            messageText = message.message;
          } else if (typeof message.message.text === 'string') {
            messageText = message.message.text;
            repliedMT = message.message.repliedMT;
            repliedTT = message.message.repliedTT;
            repliedFU = message.message.repliedFU;
            if (repliedFU) {
              isImageRepliedFU = imageRegex.test(repliedFU);
              if (repliedMT || repliedMT === '')
                repliedMT = repliedFU.substring(
                  repliedFU.lastIndexOf('/') + 1,
                  repliedFU.lastIndexOf('?')
                );
            }
          } else if (message.message.message) {
            if (typeof message.message.message === 'string') {
              messageText = message.message.message;
            } else {
              messageText = message.message.message.text;
              repliedMT = message.message.message.repliedMT;
              repliedTT = message.message.message.repliedTT;
              repliedFU = message.message.message.repliedFU;
              if (repliedFU) {
                isImageRepliedFU = imageRegex.test(repliedFU);
                if (repliedMT || repliedMT === '')
                  repliedMT = repliedFU.substring(
                    repliedFU.lastIndexOf('/') + 1,
                    repliedFU.lastIndexOf('?')
                  );
              }
            }
            // messageText = message.message.message
            // }
            // else if(message.message.message){
            //   messageText = message.message.message.text
            //   repliedMT = message.message.message.repliedMT;
            //   repliedTT = message.message.message.repliedTT;
            //   repliedFU = message.message.message.repliedFU;
            //   if(repliedFU){
            //     isImageRepliedFU = imageRegex.test(repliedFU);
            //     if(repliedMT || repliedMT === '')
            //     repliedMT = repliedFU.substring(repliedFU.lastIndexOf('/')+1, repliedFU.lastIndexOf('?'));
            //   }
          }

          // "https://ps.pndsn.com/v1/files/sub-c-6cf97ec6-9d1a-11eb-9adf-f2e9c1644994/channels/fd35aa62-8511-4ef0-84d0-69f7ebe31a8c/files/cb6a99a2-4b7c-47ee-8554-0ee834ef5882/cat_picture.jpg?uuid=194&pnsdk=PubNub-JS-Web%2F4.30.1%20undefined"

          return message.userId === userId ? (
            <MyWrapper key={message.timetoken} ref={refs[message.timetoken]}>
              <IconButton
                style={{ width: '48px', height: '48px', paddingBottom: '8px', cursor: 'pointer' }}
                onClick={() => {
                  setReplied({
                    repliedMT: messageText,
                    repliedFU: url,
                    repliedTT: message.timetoken
                  });
                }}
              >
                <img className="reply-button self" src={replyImage} />
              </IconButton>
              <Body>
                <MyContent>
                  {repliedTT ? (
                    repliedFU && isImageRepliedFU ? (
                      // () => window.open(repliedFU, '_blank')
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            borderRadius: 50 / 2,
                            overflow: 'hidden',
                            borderWidth: 3,
                            borderColor: 'red'
                          }}
                          src={repliedFU}
                        />
                        <br />
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    ) : (
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {/* {repliedFU ? 
                (<><Image onClick={() => window.open(url, '_blank')} src={repliedFU} /><br/><label style={{backgroundColor: 'grey'}}>{repliedMT}</label><br/><hr/></>)
                : (<><label style={{backgroundColor: 'grey' }}>{repliedMT}</label><br/><hr/></>)
                } */}
                  {isFile ? (
                    <>
                      {isImage && (
                        <>
                          {/* ref={refs[message.timetoken]} */}
                          <Image onClick={() => window.open(url, '_blank')} src={url} />
                          <br />
                          <Linkify>{caption}</Linkify>
                        </>
                      )}
                      {!isImage && (
                        // ref={refs[message.timetoken]}
                        <div>
                          <a href={url} target="_blank">
                            {fileName}
                          </a>
                          <br />
                          <Linkify>{caption}</Linkify>
                        </div>
                      )}
                    </>
                  ) : (
                    // ref={refs[message.timetoken]}
                    <Linkify>{messageText}</Linkify>
                  )}
                </MyContent>
                <MyDate>{moment(message.date).format('lll')}</MyDate>
              </Body>
              {currentUser.image ? <Avatar src={currentUser.image} /> : <Avatar> A </Avatar>}
            </MyWrapper>
          ) : (
            <Wrapper key={message.timetoken} ref={refs[message.timetoken]}>
              {/* {chatUser1 = getChatUser(message.userId)} */}
              {getAvatar(message.userId)}
              {/* {chatUser.image ? <Avatar src={chatUser.image} /> : <Avatar> A </Avatar>} */}
              <Body>
                <Header>
                  <SenderName>{getUsername(message.userId)}</SenderName>
                </Header>
                <Content>
                  {repliedTT ? (
                    repliedFU && isImageRepliedFU ? (
                      // () => window.open(repliedFU, '_blank')
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            borderRadius: 50 / 2,
                            overflow: 'hidden',
                            borderWidth: 3,
                            borderColor: 'red'
                          }}
                          src={repliedFU}
                        />
                        <br />
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    ) : (
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {isFile ? (
                    <>
                      {isImage && (
                        // ref={refs[message.timetoken]}
                        <Image onClick={() => window.open(url, '_blank')} src={url} />
                      )}
                      {!isImage && (
                        // ref={refs[message.timetoken]}
                        <a href={url} target="_blank">
                          {fileName}
                        </a>
                      )}
                    </>
                  ) : (
                    // ref={refs[message.timetoken]}
                    <Linkify>{messageText}</Linkify>
                  )}
                </Content>
                <Date>{moment(message.date).format('lll')}</Date>
              </Body>
              <IconButton
                style={{
                  width: '48px',
                  height: '48px',
                  marginTop: '30px',
                  paddingBottom: '8px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setReplied({
                    repliedMT: messageText,
                    repliedFU: url,
                    repliedTT: message.timetoken
                  });
                }}
              >
                <img className="reply-button" src={replyImage} />
              </IconButton>
            </Wrapper>
          );
        })
      }
      <div style={{ float: 'left', clear: 'both' }} ref={lastElem}></div>
    </Container>
  );
});

export default MessagesList;

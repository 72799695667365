import React, { useState } from 'react';
import { Container } from '@material-ui/core';

import OnboardingForm from './OnboardingForm.js';
import OnboardingFormPreferences from './OnboardingFormPreferences';
import styles from './IntakeForm.module.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { clientActions, userActions } from '../../../redux/actions';

const ClientOnboarding = () => {
  const { email } = useSelector((state) => state.user);
  const [step, setStep] = useState(1);

  const prepareClient = (data) => {
    const { birthdate, gender, weight, height, firstName, lastName } = data;

    const client = {
      gender: gender.toLowerCase(),
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      weight,
      height
    };

    const userName = `${firstName} ${lastName}`;
    return { client, userName };
  };

  const nextStep = async (newClient) => {
    const { client, userName } = prepareClient(newClient);

    await clientActions.setClientData(client);
    await userActions.setUserName(userName);

    setStep(2);
  };

  return (
    <Container maxWidth="md">
      <div className={styles.intakeForm}>
        <div className={styles.intakeForm_header}>
          <h4 className={styles.intakeForm_header_title}>
            {step === 1 && 'Client Onboarding Form'}
            {step === 2 && 'Coach Preferences Form'}
          </h4>
          <span className={styles.intakeForm_header_description}>
            Please fill out all the required fields
          </span>
        </div>
        <div style={{ display: step === 1 ? 'block' : 'none' }}>
          <OnboardingForm submitForm={nextStep} email={email} initialClient={null} />
        </div>
        <div style={{ display: step === 2 ? 'block' : 'none' }}>
          <OnboardingFormPreferences />
        </div>
      </div>
    </Container>
  );
};

export default ClientOnboarding;

import PubNub from 'pubnub';

import config from '../config';

let pubnubInstance = null;

const initPubnubInstance = ({ userId, image }) => {
  const pubnubConfig = {
    //restore: true,
    heartbeatInterval: 0,
    ...config.pubnubKeys,
    uuid: userId || 'admin',
    profileUrl: image || null
  };
  console.log('initPubnubInstance');
  pubnubInstance = new PubNub(pubnubConfig);
};

export { pubnubInstance, initPubnubInstance };

import ClientsTable from './ClientTable';
import React, { useEffect, useMemo, useState } from 'react';
import config from '../../../config/';
import { useSelector } from 'react-redux';
import { loadForms, submitPolls } from '../../../redux/actions/poll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-formio';
import styles from './Index.module.scss';
import { Dialog, Icon } from '@material-ui/core';
import Stepper from './Stepper';

const useStyles = makeStyles({
  formsWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    fontSize: '4rem',
    fontFamily: 'Bebas Neue',
    letterSpacing: '2.5px',
    color: '#c4eb37',
    marginBottom: '30px',
    marginTop: '30px'
  },
  subtitle: {
    margin: 'auto',
    width: '800px',
    fontSize: '4rem',
    fontFamily: 'Bebas Neue',
    letterSpacing: '2.5px',
    color: '#ee79b3'
  }

});

const NutritionistPolls = () => {
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const clients = useSelector(state => state.nutritionist.clients);
  const forms = useSelector((state) => state.polls.forms);
  const formioUrl = config.formio.nutritionist;
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [formUrl, setFormUrl] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [step, setStep] = useState(0);

  const FormCards = () => {
    return forms === null ? (
      ''
    ) : (
      <>
        <div className={classes.formsWrapper}>
          {forms.filter(form => !form.tags.includes('disabled')).map((e) => (
            <div
              id={e.path}
              style={{
                backgroundColor: selectedCards.includes(formioUrl + e.path) ? '#2f2f38' : '',
                borderColor: selectedCards.includes(formioUrl + e.path) ? '#c4eb37' : ''
              }}
              onClick={(event) => handleCardClick(event, e.path)}
              className={styles.formCardContainer}
            >
              <h5 className={styles.cardTitle}>{e.title}</h5>
              <p
                className={styles.cardPreview}
                onClick={(event) => {
                  event.stopPropagation();
                  setFormUrl(e.path);
                  setOpen(true);
                }}
              >
                preview
              </p>
            </div>
          ))}
        </div>
      </>
    );
  };

  const Table = useMemo(() =>
    selectedCards.length ? (
      <>
        <h2 style={{ marginTop: '40px', fontFamily: 'Bebas Neue', fontSize: '40px' }}>Recipients</h2>
        <ClientsTable
          selectedClients={selectedClients}
          setSelectedClients={setSelectedClients}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
        />
      </>
    ) : (
      ''
    ),[selectedCards, selectedClients]);

  const SendBtn = () => {
    return selectedClients.length ? (
      <button
        className={styles.signInButton}
        onClick={() => {
          submitPolls(selectedClients, selectedCards, user.id);
          setSelectedClients([]);
          setSelectedCards([]);
        }}
      >
        <p className={styles.socialButtonText}>Send</p>
      </button>
    ) : (
      ''
    );
  };

  const handleCardClick = (e, path) => {
    e.stopPropagation();
    const find = selectedCards.indexOf(formioUrl + path);
    if (find > -1) {
      selectedCards.splice(find, 1);
    } else {
      selectedCards.push(formioUrl + path);
    }
    setSelectedCards([...selectedCards]);
    if (!selectedCards.length) {
      setSelectedClients([]);
      setSelectAll(false);
      setStep(0);
    }
    else {
      setStep(1);
    }
  };

  useEffect(() => {
    if (selectedClients.length) {
      setStep(2)
    }
    else if (selectedCards.length) {
      setStep(1)
    }
    else {
      setStep(0)
    }
  }, [selectedClients]);

  useEffect(() => {
    loadForms(`${formioUrl}form`);
  }, []);

  if (!clients.length) {
    return <>
      <div className={classes.title}>Forms</div>
      <h2 className={classes.subtitle}>You will be unable to send forms until you have a client</h2>
    </>
  }

  return (
    <div className="d-flex justify-content-cenPollster" style={{ flexDirection: 'column' }}>
      <div className={classes.title}>Forms</div>
      <Stepper activeStep={step}/>
      <FormCards />
      {Table}
      <SendBtn />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div className={`preview ${styles.modalContainer}`}>
          <Icon
            onClick={() => setOpen(false)}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <Form src={formioUrl + `${formUrl}`} />
        </div>
      </Dialog>
    </div>
  );
};

export default NutritionistPolls;

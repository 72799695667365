import mutations from '../mutations';

const initialState = {
  isLogged: false,
  token: null,
  userType: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case mutations.auth.login:
      const { token, userType } = action.payload;
      return {
        ...state,
        isLogged: true,
        token,
        userType
      };
    case mutations.auth.logout:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

import { userAxios } from '../../utils/axios';
import mutations from '../mutations';
import store from '../store';

const subscribeForNutritionist = async ({ nutritionistId, amount }) => {
  const { data } = await userAxios.post(`/subscribe`, { nutritionistId, amount });

  return data;
};

const subscribeForNutritionistExtra = async ({ nutritionistId, amount, extraSpecialtiesId }) => {
  const extra = true;
  console.log({ nutritionistId, amount, extra, extraSpecialtiesId });
  const { data } = await userAxios.post(`/subscribe`, {
    nutritionistId,
    amount,
    extra,
    extraSpecialtiesId
  });

  return data;
};

const subscribeForGroupChat = async ({ nutritionistId, amount, chatId }) => {
  const { data } = await userAxios.post(`/subscribe/group`, { nutritionistId, amount, chatId });

  return data;
};

const getMyNutritionists = async () => {
  const { data } = await userAxios.get(`/client/my-nutritionists`);

  if (data) {
    store.dispatch({
      type: mutations.client.getMyNutritionists,
      payload: data
    });
  }
};

const getSavedNutritionists = async () => {
  const { data } = await userAxios.get(`/client/saved`);
  if (data) {
    store.dispatch({
      type: mutations.client.getSavedNutritionists,
      payload: data
    });
  }
};

const saveNutritionist = async (nutritionistId) => {
  return userAxios.post('/client/saved', { nutritionistId });
};

const unsaveNutritionist = async (nutritionistId) => {
  return userAxios.delete('/client/saved', { data: { nutritionistId } });
};

const loadNutritionists = async (params) => {
  const { data } = await userAxios.get('/client/nutritionists', { params });
  store.dispatch({
    type: mutations.client.loadNutritionists,
    payload: data.rows
  });
  store.dispatch({
    type: mutations.client.setNutritionistsCount,
    payload: data.count
  });
};

const loadNutritionist = async (id) => {
  const { data } = await userAxios.get(`/client/nutritionists/${id}`);
  return data;
};

const loadRecommendedNutritionists = async () => {
  const { data } = await userAxios.get('/client/recommended-nutritionists');

  store.dispatch({
    type: mutations.client.loadRecommendedNutritionists,
    payload: data
  });
};

const setClientData = async (client) => {
  return await userAxios.post('/user/body', { ...client });
};

const updateCoachPreferences = async (data) => {
  return await userAxios.put('/client/coach-preferences', data);
};

const updateClientData = async (client) => {
  return await userAxios.put('/user', { ...client });
};
const loadSubscriptions = async () => {
  const { data } = await userAxios.get(`/client/subscriptions`);
  return data;
};

const stripeCancelSubscription = async (data) => {
  return await userAxios.post('/client/cancel-subscription', data);
};

const loadCustomerPortalLink = async () => {
  const { data } = await userAxios.get(`/stripe/customer-portal`);
  return data;
};

export default {
  loadNutritionists,
  loadNutritionist,
  loadRecommendedNutritionists,
  loadCustomerPortalLink,
  loadSubscriptions,
  subscribeForNutritionist,
  getMyNutritionists,
  setClientData,
  getSavedNutritionists,
  saveNutritionist,
  unsaveNutritionist,
  updateCoachPreferences,
  updateClientData,
  stripeCancelSubscription,
  subscribeForNutritionistExtra,
  subscribeForGroupChat
};

import store from '../store';
import mutations from '../mutations';
import { userAxios } from '../../utils/axios';
import _ from 'lodash';

const resetMessages = () => {
  store.dispatch({
    type: mutations.chat.resetMessages
  });
};

const loadChatData = async () => {
  resetMessages();
  const { data } = await userAxios.get('/chats');
  console.log('fk2 chats', data);
  store.dispatch({
    type: mutations.chat.loadChatData,
    payload: data
  });
};

const initChat = async ({ nutritionistId = null, clientId = null, toAdmin = false }) => {
  return await userAxios.post('/chat/init', {
    nutritionistId,
    clientId,
    toAdmin
  });
};

//com.mco
const setMessagesToChannel = ({ channelId, messages = [], newMsg }) => {
  store.dispatch({
    type: mutations.chat.setMessagesToChannel,
    payload: { channelId, messages, newMsg }
  });
};

//com.mco
const setFilesToChannel = ({ pubnub, fChannelId, files = [] }) => {
  store.dispatch({
    type: mutations.chat.setFilesToChannel,
    payload: { fChannelId, files, pubnub }
  });
};

//com.mco
const loadFiles = ({ channels, pubnub }) => {
  //result:
  // {
  //   status: number,
  //   data: Array<{
  //     name: string,
  //     id: string,
  //     size: number,
  //     created: string
  //   }>,
  //   next: string,
  //   count: number,
  // }

  channels.forEach((channelId) => {
    pubnub.listFiles(
      {
        channel: channelId,
        limit: 20
        // next: next,
      },
      (status, response) => {
        console.log(status, response);
        const resultFiles = response.data;

        setFilesToChannel({ pubnub: pubnub, fChannelId: channelId, files: resultFiles });
        if (response.next != null) {
          loadFilesPerChannel({ channel: channelId, pubnub: pubnub, next: response.next });
        }
      }
    );
  });
};

const loadFilesPerChannel = ({ channel, pubnub, next = null }) => {
  let params;
  if (next == null) {
    params = {
      channel: channel,
      limit: 20
      // next: next,
    };
  } else {
    params = {
      channel: channel,
      limit: 20,
      next: next
    };
  }
  pubnub.listFiles(params, (status, response) => {
    console.log(status, response);
    const resultFiles = response.data;

    setFilesToChannel({ pubnub: pubnub, fChannelId: channel, files: resultFiles });

    if (response.next != null) {
      loadFilesPerChannel({ channel: channel, pubnub: pubnub, next: response.next });
    }
  });
};

const loadMessages = async ({ channels, pubnub }) => {
  const data = await pubnub.fetchMessages({
    channels,
    count: 20
  });

  _.forEach(data.channels, (messages, channelId) => {
    const resultMessages = messages.map((m) => {
      const { message, uuid, timetoken } = m;

      return {
        message,
        userId: uuid === 'admin' ? 'admin' : parseInt(uuid, 10),
        date: new Date(parseInt(timetoken, 10) / 10000),
        //com.mco
        timetoken: timetoken
      };
    });
    setMessagesToChannel({ channelId, messages: resultMessages, newMsg: false });
  });
};

//com.mco
const moreLoadMessages = ({ channels, pubnub, startTimeToken }) => {
  console.log(startTimeToken);
  // const data = await pubnub.fetchMessages({
  pubnub.fetchMessages(
    {
      channels,
      count: 20,
      start: startTimeToken
    },
    (status, response) => {
      console.log(status, response);
      _.forEach(response.channels, (messages, channelId) => {
        const resultMessages = messages.map((m) => {
          const { message, uuid, timetoken } = m;

          return {
            message,
            userId: uuid === 'admin' ? 'admin' : parseInt(uuid, 10),
            date: new Date(parseInt(timetoken, 10) / 10000),
            timetoken: timetoken
          };
        });
        setMessagesToChannel({ channelId, messages: resultMessages, newMsg: false });
      });
    }
  );

  // _.forEach(data.channels, (messages, channelId) => {
  //   const resultMessages = messages.map((m) => {
  //     const { message, uuid, timetoken } = m;

  //     return {
  //       message,
  //       userId: uuid === 'admin' ? 'admin' : parseInt(uuid, 10),
  //       date: new Date(parseInt(timetoken, 10) / 10000),
  //       timetoken: timetoken
  //     };
  //   });
  //   setMessagesToChannel({ channelId, messages: resultMessages });
  // });
};

const loadUnreadChannelInfo = async ({ userId, channels, pubnub }) => {
  const { data } = await pubnub.objects.getMemberships({
    uuid: userId,
    include: {
      customFields: true
    }
  });

  const lastReadTimes = channels.map((channel) => {
    const record = data.find((item) => item.channel.id === channel);
    let lastReadTime;
    const defaultTime = +new Date('2021') * 10000;
    if (record) {
      lastReadTime = record.custom.lastReadTime || defaultTime;
    } else {
      lastReadTime = defaultTime;
    }

    return {
      channelId: channel,
      lastReadTime
    };
  });

  // console.log(lastReadTimes);
  // console.log(83, {
  //   channels: lastReadTimes.map((i) => i.channelId),
  //   channelTimetokens: lastReadTimes.map((i) => i.lastReadTime.toString())
  // });
  const messageCounts = await pubnub.messageCounts({
    channels: lastReadTimes.map((i) => i.channelId),
    channelTimetokens: lastReadTimes.map((i) => i.lastReadTime.toString())
  });

  // console.log(89, messageCounts);

  store.dispatch({
    type: mutations.chat.setUnreadCounts,
    payload: messageCounts.channels
  });
};

//com.mco badge
const notificationsCount = async ({ userId = -1, count = 0 }) => {
  return await userAxios.post('/chat/notificationscount', {
    userId: userId,
    count: count
  });
};

const deleteChannel = async (channelId) => {
  return await userAxios.post('/chat/deletechannel', { channelId });
};

export default {
  initChat,
  loadChatData,
  loadMessages,
  //com.mco
  moreLoadMessages,
  setFilesToChannel,
  loadFiles,
  setMessagesToChannel,
  resetMessages,
  loadUnreadChannelInfo,
  notificationsCount,
  deleteChannel
};

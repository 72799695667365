const development = {
  environment: 'development',
  // apiUrl: 'http://192.168.1.37:3001',
  // apiUrl: 'http://localhost:3001',
  apiUrl: 'https://apistaging.planibble.com',
  domain: 'http://localhost:3000',
  auth0: {
    domain: 'dev-pszfgqxb.us.auth0.com',
    clientID: '6OeaIfQskXaXMmG4YaoVnF91XEHBy112',
    responseType: 'token id_token',
    scope: 'openid email profile read:current_user',
    audience: 'https://dev-pszfgqxb.us.auth0.com/api/v2/',
    redirectUri: window.location.origin + '/redirect'
  },
  pubnubKeys: {
    publishKey: 'pub-c-022bdc12-4eb2-4826-a15f-16891b5c901f',
    subscribeKey: 'sub-c-6cf97ec6-9d1a-11eb-9adf-f2e9c1644994'
  },
  googleApi: 'AIzaSyCNO-xxZoMxxV2vapgGJjpXPUQ9fuwn6VQ',
  tinyMCE_key: 'efp8szqjlavdr1ggr3wr1p35vgfjkgdfp2lzacikza6874p8',
  formio: {
    admin: 'https://urztvaohsuqbnlv.form.io/',
    nutritionist: 'https://gmvwawvqaeioaym.form.io/',
    adminCreateForms: 'https://portal.form.io/#/project/60e56c012c18f612131f5102/form/'
  },
  recaptchaSiteKey: '6Ldzk2MgAAAAAChYg_I7EWARZiA_SumXp9Ku1t_s'
};

const test = {
  environment: 'test',
  apiUrl: 'https://apitest.planibble.com',
  domain: 'https://test.planibble.com',
  auth0: {
    domain: 'dev-pszfgqxb.us.auth0.com',
    clientID: '6OeaIfQskXaXMmG4YaoVnF91XEHBy112',
    responseType: 'token id_token',
    scope: 'openid email profile read:current_user',
    audience: 'https://dev-pszfgqxb.us.auth0.com/api/v2/',
    redirectUri: window.location.origin + '/redirect'
  },
  pubnubKeys: {
    publishKey: 'pub-c-022bdc12-4eb2-4826-a15f-16891b5c901f',
    subscribeKey: 'sub-c-6cf97ec6-9d1a-11eb-9adf-f2e9c1644994'
  },
  googleApi: 'AIzaSyCNO-xxZoMxxV2vapgGJjpXPUQ9fuwn6VQ',
  tinyMCE_key: 'efp8szqjlavdr1ggr3wr1p35vgfjkgdfp2lzacikza6874p8',
  formio: {
    admin: 'https://urztvaohsuqbnlv.form.io/',
    nutritionist: 'https://gmvwawvqaeioaym.form.io/',
    adminCreateForms: 'https://portal.form.io/#/project/60e56c012c18f612131f5102/form/'
  },
  recaptchaSiteKey: '6Ldzk2MgAAAAAChYg_I7EWARZiA_SumXp9Ku1t_s'
};

const staging = {
  environment: 'staging',
  apiUrl: 'https://apistaging.planibble.com',
  domain: 'https://staging.planibble.com',
  auth0: {
    domain: 'auth.planibble.com',
    clientID: '6OeaIfQskXaXMmG4YaoVnF91XEHBy112',
    responseType: 'token id_token',
    scope: 'openid email profile read:current_user',
    audience: 'https://dev-pszfgqxb.us.auth0.com/api/v2/',
    redirectUri: window.location.origin + '/redirect'
  },
  pubnubKeys: {
    publishKey: 'pub-c-022bdc12-4eb2-4826-a15f-16891b5c901f',
    subscribeKey: 'sub-c-6cf97ec6-9d1a-11eb-9adf-f2e9c1644994'
  },
  googleApi: 'AIzaSyCNO-xxZoMxxV2vapgGJjpXPUQ9fuwn6VQ',
  tinyMCE_key: 'efp8szqjlavdr1ggr3wr1p35vgfjkgdfp2lzacikza6874p8',
  formio: {
    admin: 'https://iggwnjcfjxxsrlo.form.io/',
    nutritionist: 'https://stpbwplugrjcxah.form.io/',
    adminCreateForms: 'https://portal.form.io/#/project/6100fe48c38c8db5a9f3e0c2/form/'
  },
  recaptchaSiteKey: '6Ldzk2MgAAAAAChYg_I7EWARZiA_SumXp9Ku1t_s'
};

const production = {
  environment: 'production',
  apiUrl: 'https://api.planibble.com',
  domain: 'https://www.planibble.com',
  auth0: {
    domain: 'auth.planibble.com',
    clientID: '6OeaIfQskXaXMmG4YaoVnF91XEHBy112',
    responseType: 'token id_token',
    scope: 'openid email profile read:current_user',
    audience: 'https://dev-pszfgqxb.us.auth0.com/api/v2/',
    redirectUri: window.location.origin + '/redirect'
  },
  pubnubKeys: {
    publishKey: 'pub-c-022bdc12-4eb2-4826-a15f-16891b5c901f',
    subscribeKey: 'sub-c-6cf97ec6-9d1a-11eb-9adf-f2e9c1644994'
  },
  googleApi: 'AIzaSyCNO-xxZoMxxV2vapgGJjpXPUQ9fuwn6VQ',
  tinyMCE_key: 'efp8szqjlavdr1ggr3wr1p35vgfjkgdfp2lzacikza6874p8',
  formio: {
    admin: 'https://urztvaohsuqbnlv.form.io/',
    nutritionist: 'https://gmvwawvqaeioaym.form.io/',
    adminCreateForms: 'https://portal.form.io/#/project/60e56c012c18f612131f5102/form/'
  },
  recaptchaSiteKey: '6Ldzk2MgAAAAAChYg_I7EWARZiA_SumXp9Ku1t_s'
};

let result = null;

console.log('process.env.NODE_ENV', process.env.NODE_ENV);
console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);

if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_ENV === 'production') {
    console.log('production');
    result = production;
  } else if (process.env.REACT_APP_ENV === 'staging') {
    console.log('staging');
    result = staging;
  } else if (process.env.REACT_APP_ENV === 'test') {
    console.log('test');
    result = test;
  } else {
    result = test;
  }
} else {
  result = development;
}

export default result;

import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import { CardActionArea, Dialog, Icon } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Modal from '@material-ui/core/Modal';
import { Form } from 'react-formio';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Loader from '../../../components/Loader';
import styles from './Index.module.scss';

const useStyles = makeStyles({
  formCard: {
    height: 150,
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '50px'
  },
  formCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  formsWrapper: {
    display: 'flex'
  },
  formModal: {
    minHeight: '600px',
    width: '600px',
    top: `50%`,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  previewButton: {
    height: '20px',
    marginTop: '5px'
  },
  title: {
    marginTop: '50px',
    fontFamily: 'Bebas Neue'
  }
});

const CardList = ({ formsUrl, selectedCard, setSelectedCard, title, setTableType }) => {
  const [forms, setForms] = useState(null);
  const [previewForm, setPreviewForm] = useState('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const classes = useStyles();

  const handleCardClick = (e, path) => {
    e.stopPropagation();
    if (selectedCard) {
      formsUrl + path === selectedCard ? setSelectedCard('') : setSelectedCard(formsUrl + path);
      setTableType('');
    } else {
      setSelectedCard(formsUrl + path);
      setTableType('');
    }
    if (selectedCard === '') {
      setTableType('');
    }
  };

  useEffect(async () => {
    const { data } = await axios.get(formsUrl + 'form');
    setForms(data);
  }, []);

  if (forms === null) {
    return <Loader />;
  }

  return (
    <>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.formsWrapper}>
        {forms.map((e) => (
          <div
            id={e.path}
            style={{
              backgroundColor: selectedCard === formsUrl + e.path ? '#2f2f38' : '',
              borderColor: selectedCard === formsUrl + e.path ? '#c4eb37' : ''
            }}
            onClick={(event) => handleCardClick(event, e.path)}
            className={styles.formCardContainer}
          >
            <h5 className={styles.cardTitle}>{e.title}</h5>
            <p
              className={styles.cardPreview}
              onClick={(event) => {
                event.stopPropagation();
                setPreviewForm(e.path);
                setOpenPreviewModal(true);
              }}
            >
              preview
            </p>
          </div>
        ))}
      </div>
      <Dialog
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div className={`preview ${styles.modalContainer}`}>
          <Icon
            onClick={() => setOpenPreviewModal(false)}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <Form src={formsUrl + `${previewForm}`} />
        </div>
      </Dialog>
    </>
  );
};

export default CardList;

import React, { useEffect, useState } from 'react';

import { TextField, Grid, Button } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import validationSchema from './onboardingFormValidation.js';
import ValidationService from '../../../utils/validation-service';

import styles from './IntakeForm.module.scss';
import _ from 'lodash';
import genders from '../../../constants/genders';
import RadioButtonsGroup from '../../../components/common/RadioButtonsGroup';

const initialData = {
  email: '',
  firstName: '',
  lastName: '',
  gender: '',
  birthdate: null,
  height: '',
  weight: ''
};

const ClientOnboarding = ({ submitForm, email, initialClient = {} }) => {
  const [data, setData] = useState(initialData);
  const [errors, setErrors] = useState({});

  const [feet, setFeet] = useState(null);
  const [inches, setInches] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(initialClient)) {
      let dataUser = {};

      Object.entries(initialData).forEach(([key]) => {
        dataUser[key] = initialClient[key]?.toString();
      });

      if (dataUser.height) {
        const [feetTemp, inchesTemp] = dataUser.height.match(/\d+/g).map(Number);
        setFeet(feetTemp);
        setInches(inchesTemp);
      }

      setData((prev) => ({
        ...prev,
        ...dataUser
      }));
    } else {
      let dataUser = {};

      if (email) {
        dataUser.email = email;
      }
      setData((prev) => ({
        ...prev,
        ...dataUser
      }));
    }
  }, [initialClient]);

  const onChange = (e) => {
    console.log(data);
    setData((old) => ({
      ...old,
      [e.target.name]: e.target.value
    }));
  };

  const setDate = (e) => {
    setData((old) => ({
      ...old,
      birthdate: e.toDate()
    }));
  };

  const handleSubmitForm = (e) => {
    setErrors(initialData);
    const errorsFields = ValidationService.validate(validationSchema.create, data);

    console.log(errorsFields);

    setErrors({
      ...errorsFields
    });

    console.log(errors);

    if (_.isEmpty(errorsFields)) {
      submitForm(data);
    }
  };

  const errorExist = (param) => errors.hasOwnProperty(param);

  const feetHandler = (e) => {
    if (e.target.value < 10 && e.target.value.length < 2) {
      setFeet(e.target.value);
      setData((prev) => ({
        ...prev,
        height: `${e.target.value}′ ${inches}”`
      }));
    }
  };

  const inchesHandler = (e) => {
    if (e.target.value < 12) {
      setInches(e.target.value);
      setData((prev) => ({
        ...prev,
        height: `${feet}′ ${e.target.value}”`
      }));
    }
  };

  return (
    <form noValidate onSubmit={submitForm}>
      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_row} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <label>First name *</label>
            <TextField
              error={errorExist('firstName')}
              helperText={errors?.firstName}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              value={data.firstName}
              required
              fullWidth
              id="firstName"
              placeholder="First name"
              label=""
              name="firstName"
              autoComplete="firstName"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Last name *</label>
            <TextField
              error={errorExist('lastName')}
              helperText={errors?.lastName}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              required
              fullWidth
              value={data.lastName}
              id="lastName"
              placeholder="Last name"
              label=""
              name="lastName"
              autoComplete="lastName"
            />
          </Grid>
        </Grid>
      </div>

      <div className={styles.fieldsBlock}>
        <Grid className={styles.fieldsBlock_column} container spacing={2}>
          <Grid item xs={12}>
            <label>Email *</label>
            <TextField
              disabled={!!email}
              error={errorExist('email')}
              helperText={errors?.email}
              variant="outlined"
              className={styles.inputField}
              onChange={onChange}
              required
              value={data.email}
              fullWidth
              id="email"
              placeholder="Email"
              label=""
              name="email"
              autoComplete="email"
            />
          </Grid>
        </Grid>
      </div>

      <div className={styles.fieldsBlock}>
        <RadioButtonsGroup
          field="gender"
          styles={styles}
          title="Gender *"
          value={data.gender}
          handleChange={onChange}
          errorExist={errorExist}
          errors={errors}
          withOther={false}
          items={genders}
        />

        <Grid className={styles.fieldsBlock_column_input} container spacing={2}>
          <Grid item xs={12}>
            <label>Date of Birth *</label>
            <DatePicker
              emptyLabel={'Select your date of birth'}
              error={errorExist('birthdate')}
              helperText={errors?.birthdate}
              inputVariant="outlined"
              variant="dialog"
              disableFuture
              openTo="year"
              format="MM/DD/YY"
              views={['year', 'month', 'date']}
              className={styles.inputField}
              value={data.birthdate}
              onChange={setDate}
              required
              id="birthdate"
              placeholder="Date Of Birth"
              label=""
              autoComplete="birthdate"
            />
          </Grid>
        </Grid>
        <br />
        <label>Height</label>
        <Grid className={styles.fieldsBlock_row} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <label>Feet</label>
            <TextField
              error={errorExist('height')}
              helperText={errors?.height}
              variant="outlined"
              className={styles.inputField}
              value={feet}
              onChange={feetHandler}
              required
              fullWidth
              id="height"
              type="number"
              label=""
              name="height"
              autoComplete="height"
              InputProps={{ inputProps: { min: 1, max: 9 } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <label>Inches</label>
            <TextField
              error={errorExist('height')}
              helperText={errors?.height}
              variant="outlined"
              className={styles.inputField}
              value={inches}
              onChange={inchesHandler}
              required
              fullWidth
              id="height"
              type="number"
              label=""
              name="height"
              autoComplete="height"
              InputProps={{ inputProps: { min: 0, max: 11 } }}
            />
          </Grid>
        </Grid>

        <Grid className={styles.fieldsBlock_column_input} container spacing={2}>
          <Grid item xs={12}>
            <label>Weight(pounds) *</label>
            <TextField
              error={errorExist('weight')}
              helperText={errors?.weight}
              variant="outlined"
              className={styles.inputField}
              value={data.weight}
              onChange={onChange}
              required
              fullWidth
              id="weight"
              type="number"
              placeholder="pounds"
              label=""
              InputProps={{ inputProps: { min: 1, max: 1500 } }}
              name="weight"
              autoComplete="weight"
            />
          </Grid>
        </Grid>
      </div>
      <Grid item xs={12} className={styles.intakeForm_actionButtons}>
        <Button
          onClick={(e) => handleSubmitForm(e)}
          variant="contained"
          color="primary"
          className={styles.intakeForm_actionButtons_nextBtn}
        >
          Submit
        </Button>
      </Grid>
    </form>
  );
};

export default ClientOnboarding;

import React, { useState } from 'react';

import _ from 'lodash';

import {
  FormControl,
  TextField,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';

const TYPES = {
  ROW: 'row',
  COLUMN: 'column'
};

const DoubleChoice = ({
  title,
  withOtherOption,
  withExplainOption,
  explainValue,
  explainTitle,
  type = TYPES.COLUMN,
  field,
  explainField,
  value,
  handleChange,
  errors,
  placeholder,
  otherPlaceholder,
  errorExist,
  styles
}) => {
  const [otherOption, setOtherOption] = useState(false);

  return (
    <div className={styles.fieldsBlock}>
      <Grid className={styles.fieldsBlock_row} container spacing={2}>
        <Grid item xs={12} sm={12}>
          <label>{title}</label>
          <FormControl
            error={errorExist && errorExist(field)}
            component="fieldset"
            className={styles.inputField_checkGroup}
          >
            <RadioGroup
              aria-label={field}
              name={field}
              onChange={(e) => {
                handleChange(e);
              }}
              value={value}
            >
              {type === TYPES.ROW ? (
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <FormControlLabel
                      onChange={() => {
                        if (withOtherOption) {
                          setOtherOption(false);
                        }
                      }}
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControlLabel
                      onChange={() => {
                        if (withOtherOption) {
                          setOtherOption(false);
                        }
                      }}
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </>
              )}
              {withOtherOption && (
                <FormControlLabel
                  onChange={(e) => {
                    handleChange(e);
                    setOtherOption(!otherOption);
                  }}
                  value="other"
                  checked={value && value !== 'false' && value !== 'true'}
                  control={<Radio />}
                  label="Other"
                />
              )}
              {otherOption && (
                <Grid item xs={12}>
                  <label>Other</label>
                  <TextField
                    inputProps={{
                      maxlength: 200
                    }}
                    error={errorExist && errorExist(field)}
                    name={field}
                    variant="outlined"
                    required
                    fullWidth
                    placeholder={otherPlaceholder}
                    className={styles.inputField}
                    onChange={handleChange}
                    id={field}
                    autoComplete={field}
                    autoFocus
                  />
                </Grid>
              )}
            </RadioGroup>
            {withExplainOption && value === 'true' && (
              <Grid item xs={12}>
                <span>{explainTitle}</span>
                <TextField
                  inputProps={{
                    maxlength: 200
                  }}
                  error={errorExist && errorExist(explainField)}
                  autoComplete={explainField}
                  name={explainField}
                  variant="outlined"
                  required
                  onChange={handleChange}
                  fullWidth
                  value={explainValue}
                  placeholder={placeholder}
                  className={styles.inputField}
                  id={explainField}
                  label=""
                  autoFocus
                />
              </Grid>
            )}
            <FormHelperText className={styles.myHelperText}>{errors[field]}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default DoubleChoice;

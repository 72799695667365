import React from 'react';
import { CircularProgress } from '@material-ui/core';

import styles from './Index.module.scss';

export default function Loader() {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>Loading</h1>
        <CircularProgress className={styles.spinner}></CircularProgress>
      </div>
    </div>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NutritionistTable from './NutritionistsTable';
import { Form } from 'react-formio';
import { Dialog, Icon } from '@material-ui/core';
import { submitPolls, loadForms } from '../../../redux/actions/poll';
import AddBoxIcon from '@material-ui/icons/AddBox';
import config from '../../../config';
import { useSelector } from 'react-redux';
import Stepper from './Stepper';

import styles from './Index.module.scss';
import ClientsTable from './ClientsTable';

const useStyles = makeStyles({
  formsWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tabWrapper: {
    height: '100%'
  },
  addIcon: {
    width: 40,
    height: 40
  },
  tabsWrapper: {
    display: 'flex'
  },
  title: {
    fontSize: '4rem',
    fontFamily: 'Bebas Neue',
    letterSpacing: '2.5px',
    color: '#c4eb37',
    marginBottom: '30px',
    marginTop: '30px'
  }
});

const AdminPolls = () => {
  const forms = useSelector((state) => state.polls.forms);
  const formioUrl = config.formio.admin;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [formUrl, setFormUrl] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [tableType, setTableType] = useState('');

  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedNutritionists, setSelectedNutritionists] = useState([]);

  const [selectAllClients, setSelectAllClients] = useState(false);
  const [selectAllNutritionists, setSelectAllNutritionists] = useState(false);

  const [nutritionistSearch, setNutritionistSearch] = useState('');
  const [nutritionistPageSize, setNutritionistPageSize] = useState(10);
  const [nutritionistPage, setNutritionistPage] = useState(0);

  const [step, setStep] = useState(0);

  const handleCardClick = (e, path) => {
    e.stopPropagation();
    const find = selectedCards.indexOf(formioUrl + path);

    if (find > -1) {
      selectedCards.splice(find, 1);
    } else {
      selectedCards.push(formioUrl + path);
    }
    setSelectedCards([...selectedCards]);
    if (selectedCards.length < 1) {
      setSelectedClients([]);
      setSelectedNutritionists([]);
      setTableType('');
      setSelectAllClients(false);
      setSelectAllNutritionists(false);
      setStep(0);
    } else {
      setStep(1);
    }
  };

  const FormCards = () => {
    return forms === null ? (
      ''
    ) : (
      <>
        <div className={classes.formsWrapper}>
          {forms.map((e) => (
            <div
              id={e.path}
              style={{
                backgroundColor: selectedCards.includes(formioUrl + e.path) ? '#2f2f38' : '',
                borderColor: selectedCards.includes(formioUrl + e.path) ? '#c4eb37' : ''
              }}
              onClick={(event) => handleCardClick(event, e.path)}
              className={styles.formCardContainer}
            >
              <h5 className={styles.cardTitle}>{e.title}</h5>
              <p
                className={styles.cardPreview}
                onClick={(event) => {
                  event.stopPropagation();
                  setFormUrl(e.path);
                  setOpen(true);
                }}
              >
                preview
              </p>
            </div>
          ))}
          <div
            onClick={() => window.open(config.formio.adminCreateForms, '_blank')}
            className={styles.addFormCardContainer}
          >
            <AddBoxIcon className={classes.addIcon} />
          </div>
        </div>
      </>
    );
  };

  const Table = useMemo(() => {
    if (selectedCards.length > 0 && tableType !== '') {
      return tableType === 'nutritionists' ? (
        <>
          <NutritionistTable
            key="NutritinistsTable"
            selectedNutritionists={selectedNutritionists}
            setSelectedNutritionists={setSelectedNutritionists}
            selectAll={selectAllNutritionists}
            setSelectAll={setSelectAllNutritionists}
            disabled={selectedCards.length < 1}
            search={nutritionistSearch}
            setSearch={setNutritionistSearch}
            page={nutritionistPage}
            setPage={setNutritionistPage}
            pageSize={nutritionistPageSize}
            setPageSize={setNutritionistPageSize}
          />
        </>
      ) : (
        <>
          <ClientsTable
            key="32"
            selectedClients={selectedClients}
            setSelectedClients={setSelectedClients}
            selectAll={selectAllClients}
            setSelectAll={setSelectAllClients}
          />
        </>
      );
    } else {
      return '';
    }
  }, [tableType, selectedClients, selectedNutritionists]);

  const TableTabs = () => {
    return selectedCards.length < 1 ? (
      ''
    ) : (
      <>
        <h2 style={{ marginTop: '40px', fontFamily: 'Bebas Neue', fontSize: '40px' }}>Recipients</h2>
        <div className={classes.tabsWrapper}>
          <div className={styles.cardsContainer}>
            <div
              className={styles.cardContainer}
              onClick={() => setTableType('clients')}
              style={{
                backgroundColor: tableType === 'clients' ? '#2f2f38' : '',
                borderColor: tableType === 'clients' ? '#ee79b3' : ''
              }}
            >
              <p className={styles.cardTitle}>clients</p>
            </div>
            <div
              className={styles.cardContainer_coach}
              onClick={() => setTableType('nutritionists')}
              style={{
                backgroundColor: tableType === 'nutritionists' ? '#2f2f38' : '',
                borderColor: tableType === 'nutritionists' ? '#c4eb37' : ''
              }}
            >
              <p className={styles.cardTitle}>Coaches</p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SendBtn = () => {
    return selectedClients.length || selectedNutritionists.length ? (
      <button
        className={styles.signInButton}
        onClick={() => {
          submitPolls(selectedClients.concat(selectedNutritionists), selectedCards);
          setSelectedClients([]);
          setSelectedNutritionists([]);
          setSelectedCards([]);
          setSelectAllClients(false);
          setSelectAllNutritionists(false);
          setTableType('');
          setStep(0);
        }}
      >
        <p className={styles.socialButtonText}>Send</p>
      </button>
    ) : (
      ''
    );
  };

  useEffect(() => {
    loadForms(`${formioUrl}form`);
  }, []);

  useEffect(() => {
    if (selectedClients.length || selectedNutritionists.length) {
      setStep(2);
    } else if (selectedCards.length) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [selectedClients, selectedNutritionists]);

  return (
    <div className="d-flex justify-content-cenPollster" style={{ flexDirection: 'column' }}>
      <div className={classes.title}>Forms</div>
      <Stepper activeStep={step} />
      <FormCards />
      <TableTabs />
      {Table}
      <SendBtn />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        classes={{
          paper: styles.modal
        }}
      >
        <div className={`preview ${styles.modalContainer}`}>
          <Icon
            onClick={() => setOpen(false)}
            className={`fa fa-times ${styles.modalCross}`}
            style={{ fontSize: 30 }}
          />
          <Form src={formioUrl + `${formUrl}`} />
        </div>
      </Dialog>
    </div>
  );
};

export default AdminPolls;

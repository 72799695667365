import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    Container,
    Modal
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/core/styles';
  import _, { wrap } from 'lodash';
  
  import { clientActions, nutritionistActions } from '../../redux/actions';

  import React, { useState, useEffect } from 'react';
  
  const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none'
      }
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    toolbar: {
      flexWrap: 'wrap'
    },
    toolbarTitle: {
      flexGrow: 1
    },
    link: {
      margin: theme.spacing(1, 1.5)
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6)
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2)
    }
  }));
  
  
  const ExtraSubscriptionModal = ({ open, nutritionistId, onClose }) => {
    const classes = useStyles();

    const [hasExtra, setHasExtra] = useState(false);
    const [extraSpecialtiesList, setExtraSpecialtiesList] = useState(null);
    const getExtraSpecialties = async () => {
      const result = await nutritionistActions.getExtraSpecialties(nutritionistId); 
      console.log(result.data);
      setExtraSpecialtiesList(result.data);
      setHasExtra(true);
    };
    
    useEffect(() => {
      getExtraSpecialties();
    }, []);
  
    const handleSubscribe = async (amount, extraSpecialtiesId) => {
      const result = await clientActions.subscribeForNutritionistExtra({
        nutritionistId,
        amount,
        extraSpecialtiesId
      });
  
      console.log(result);
  
      const { url, error } = result;
      if (error) {
        window.alert(error);
        return onClose();
      }
  
      if (url) window.open(url);
    };
  
    return (
      <div>
        <Modal open={open} onClose={() => onClose()}>
          <Grid
            container
            spacing={5}
            alignItems="center"
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              marginTop: '5rem',
              padding: '0 6rem',
              paddingRight: '6rem',
              flexWrap: 'nowrap',
              overflowX: 'scroll'
            }}
          >
            {hasExtra && extraSpecialtiesList
              .filter((t) => t.approved)
              .map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid item key={tier.id} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                  <Card style={{ border: '1px solid #c4eb37', borderRadius: '10px' }}>
                    <CardHeader
                      title={tier.specialties}
                      titleTypographyProps={{ align: 'center', fontFamily: 'Bebas Neue' }}
                      subheaderTypographyProps={{ align: 'center' }}
                      className={classes.cardHeader}
                    />
                    <CardContent>
                      <div className={classes.cardPricing}>
                        <Typography
                          component="h2"
                          variant="h3"
                          style={{ fontFamily: 'Bebas Neue' }}
                          color="textPrimary"
                        >
                          ${tier.price}
                        </Typography>
                        <Typography
                          style={{ fontFamily: 'Bebas Neue' }}
                          variant="h6"
                          color="textSecondary"
                        >
                          /mo
                        </Typography>
                      </div>
                      <ul>
                          <Typography component="li" variant="subtitle1" align="center" key={tier.id}>
                            {tier.description}
                          </Typography>
                      </ul>
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        variant="contained"
                        style={{
                          background: '#2F2F38',
                          margin: '0 1rem 1rem 1rem',
                          color: '#EE79B3',
                          fontSize: '1.5rem',
                          fontFamily: 'Bebas Neue'
                        }}
                        onClick={() => handleSubscribe(tier.price, tier.id)}
                      >
                        Subscribe
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Modal>
      </div>
    );
  };
  
  export default ExtraSubscriptionModal;
  
import Joi from 'joi';

const validationSchema = {
  create: Joi.object({
    firstName: Joi.string().regex(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).required().label('First name').messages({ 'string.pattern.base': 'First name contains unacceptable symbols' }),
    lastName: Joi.string().regex(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/).required().label('Last name').messages({ 'string.pattern.base': 'Last name contains unacceptable symbols' }),
    address1: Joi.string().required().label('First address'),
    address2: Joi.string().allow(null, '').label('Second address'),
    city: Joi.string().required().label('City'),
    state: Joi.string().allow(null, '').label('State'),
    gender: Joi.string().required().label('Gender'),
    zipcode: Joi.string()
      .regex(/^\d{5}[-\s]?(?:\d{4})?$/)
      .required()
      .messages({ 'string.pattern.base': 'Invalid code' })
      .label('Zip code'),
    phone: Joi.string()
      .regex(/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/)
      .required()
      .messages({ 'string.pattern.base': 'Invalid phone number' })
      .label('Phone'),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
      .label('E-mail'),
    hoursWeekly: Joi.number().min(1).max(60).required().label('Hours of work'),
    canWorkAtNights: Joi.boolean().required().label('Can you work at nights').messages({
      'boolean.base': 'Can you work at nights" cannot be empty'
    }),
    isOlder: Joi.boolean().invalid(false).required().label('Are you 18 or older').messages({
      'any.invalid': 'You should be 18 years old to submit an aplication'
    }),
    schedule: Joi.string().allow(null, '').label('Working schedule'),
    calendarUrl: Joi.string().allow(null, '').label('Calendar Url')
  })
};

export default validationSchema;

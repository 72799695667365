import React, { useState } from 'react';
import { Container, Input } from './MessageInput.style';

import { AttachFile, Cancel, Send } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { Image } from '../messageList/MessageList.style';

import Linkify from 'react-linkify';


// com.mco
const MessageInput = (props) => {
  const [message, setMessage] = useState('');
  const [attachedFile, setAttachedFile] = useState(null);
  const [isImage, setIsImage] = useState(false);

  const attachOnClick = (e) => {
    let imageRegex = /(jpeg|png|jpg)\b/;
    setAttachedFile(e.target.files[0]);
    setIsImage(imageRegex.test(e.target.files[0].name));   
    props.changeHandler(e);
  }

  return (
    <Container>
           {
      attachedFile ?
      <Container style={{ width: '100%', float: 'left' }}>
          <div >
        <IconButton
            style={{ width: '48px', height: '48px', paddingBottom: '8px', cursor: 'pointer' }}
            onClick={() => {
              setAttachedFile(null)
            }}
          > 
          <Cancel/>
           
            </IconButton>
                    <>
                      {isImage && <img style={{ width: '20%', height: '20%'}} onClick={() => window.open(attachedFile, '_blank')} src={URL.createObjectURL(attachedFile)} />}
                      <span style={{ marginLeft: '2rem'}}/>{ attachedFile.name }
                    </>
                
        </div>
        </Container>
        : <></>
      
      }

    <Container>
      
      <div style={{ width: '85%', float: 'left' }}>
        <TextField
          fullWidth={true}
          multiline={true}
          value={message}
          placeholder="Enter Your Message"
          // com.mco
          onKeyPress={(e) => {
            const msg = message.trim();
            if (e.key !== 'Enter' || (!attachedFile && msg.length === 0)) return;
            props.sendMessage(msg, attachedFile);
            setAttachedFile(null);
            // props.sendMessage(msg);
            setMessage('');
            e.preventDefault();
          }}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div style={{ width: '15%', float: 'right' }}>
        <IconButton
          style={{ width: '48px', height: '48px', paddingBottom: '8px', cursor: 'pointer' , color: attachedFile ? 'yellow' : 'white'}}
        >
          <label for="file-input">
            <AttachFile style={{ cursor: 'pointer' }} />
          </label>
          {/* props.changeHandler(e, message) */}
          {/* (e) => setFileAttached(e.target.files[0]) */}
          <Input id="file-input" type="file" onChange={attachOnClick} />
        </IconButton>
        <IconButton
        // com.mco
          onClick={() => {
            const msg = message.trim();
            if (!attachedFile && msg.length === 0) return;
            props.sendMessage(msg, attachedFile);
            setAttachedFile(null);
            setMessage('');
          }}
        >
          <Send />
        </IconButton>
      </div>
    </Container>
    </Container>
  );
};

export default MessageInput;

import store from '../store';
import mutations from '../mutations';

const toogleMenu = (isOpen) => {
  store.dispatch({
    type: mutations.app.toogleMenu,
    payload: isOpen
  });
};

export default {
  toogleMenu
};

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ClientProfile from '../../client/profile';

export default function (props) {
  const clients = useSelector((state) => state.nutritionist.clients);
  const [client, setClient] = useState(null);

  useEffect(() => {
    const id = parseInt(props.match.params.id);
    if (!clients.length) return;

    const result = clients.find((i) => i.id === id);

    const user = {
      client: result,
      image: result.user.image,
      name: result.user.name
    };
    setClient(user);
  }, [clients]);

  return <div>{client && <ClientProfile user={client} />}</div>;
}

import React, { useMemo } from 'react';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import _ from 'lodash';
import CopyIcon from '@material-ui/icons/FileCopy';
import { useSelector } from 'react-redux';

import config from '../../../config';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const GetPaid = ({}) => {
  const nutritionist = useSelector((state) => state.user.nutritionist);
  const link = useMemo(() => {
    return `${config.domain}/register?ref=${nutritionist.referalKey}`;
  }, [nutritionist]);

  const handleCopy = () => {
    copyToClipboard(link);
  };

  return (
    <Card
      style={{
        marginTop: 10,
        minWidth: 300,
        maxWidth: 400,
        margin: 10
      }}
    >
      <CardContent style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Typography gutterBottom variant="h6" style={{ color: '#c4eb37' }}>
          My referal link
        </Typography>
        <Typography gutterBottom variant="h6" style={{ color: '#ee79b3', textAlign: 'center' }}>
          {link}
        </Typography>
        <Button size="large" startIcon={<CopyIcon />} onClick={handleCopy}>
          Copy
        </Button>
      </CardContent>
    </Card>
  );
};

export default GetPaid;

import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styles from './index.module.scss';
import { Icon } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

const PromotionModal = ({ open, onClose, promotionTime, setPromotionTime, promoted, promote, unpromote, updatePromotionTime }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const duration = (() => {
    if (promotionTime) {
      const now = moment(new Date());
      const end = promotionTime;
      const duration = moment.duration(end.diff(now));
      return duration.format();
    }
    return '';
  })();


  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          paper: styles.paper
        }}
      >
        <h2 className={styles.title}>Promotion</h2>
        <div className={styles.wrapper}>
          <DatePicker
            inputVariant="outlined"
            variant="dialog"
            error={error}
            helperText={errorMessage}
            disablePast
            openTo="year"
            format="DD/MM/YYYY"
            views={['year', 'month', 'date']}
            className={styles.inputField}
            value={promotionTime}
            minDate={moment().add(2, 'd')}
            onChange={(date) => {
              console.log("change");
             setPromotionTime(date);
             updatePromotionTime(date);
            }}
            id="birthdate"
            label="Promotion Time"
            autoComplete="birthdate"
          />
        </div>
        <div className={styles.durationWrapper}>
          {promotionTime && (
            <div>
              <h4 className={styles.durationTitle}>Coach will be promoted for</h4>
              <p className={styles.duration}>{duration}</p>
            </div>
          )}
        </div>
        {promoted ? (
          <button className={styles.cancelButton} onClick={() => unpromote()}>
            <p className={styles.buttonText}>Unpromote</p>
          </button>
        ) : (
          <button className={styles.confirmButton} onClick={() => {
            if (!promotionTime) {
              setError(true);
              setErrorMessage("You must pick promotion period")
              return
          }
            promote();
          }}>
            <p className={styles.buttonText}>Promote</p>
          </button>
        )}

        <Icon
          onClick={() => {
            onClose()
          }}
          className={`fa fa-times ${styles.modalCross}`}
          style={{ fontSize: 30 }}
        />
      </Dialog>
    </>
  );
};

export default PromotionModal;
import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography, CircularProgress } from '@material-ui/core';
import _ from 'lodash';

import { nutritionistActions } from '../../../redux/actions';

const CoachPromoteCard = ({}) => {


const handleSubscribe = async () => {
    const result = await nutritionistActions.subscribeForPromote();

    const { url, error } = result.data;
    if (url) {
        window.open(url, '_blank');
    }
};

  return (
<Card
      style={{
        marginTop: 10,
        minWidth: 400,
        maxWidth: 600,
        margin: 10
      }}
    >
      <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
      
      <React.Fragment>
        <>
        <Typography gutterBottom>Get promoted in search results.</Typography>
        <Button color="primary" variant="contained" onClick={() => handleSubscribe()}>
            Payout Promote
        </Button>  
        </>
        </React.Fragment>
      </CardContent>
    </Card>
  );
};

export default CoachPromoteCard;

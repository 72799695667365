import styles from '../form/Form.module.scss';
import appStore from '../../../assets/img/appstore-white.svg';
import FormModalSingle from '../../../components/formModalSingle';
import { usePubNub } from 'pubnub-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { chatActions, userActions } from '../../../redux/actions';
import _ from 'lodash';
import config from '../../../config/index';
import Loader from '../../../components/Loader';

import { loadPoll, loadMySubmittions} from '../../../redux/actions/poll';
import React, { useState } from 'react';

import SubmissionsModal from '../../../components/SubmissionPreviewModal/SubmissionModal';

const ClientSubmittion = (props) => {
  const [loaded, setLoaded] = useState(false);
//   const user = useSelector((state) => state.user);

const [openSubModal, setOpenSubModal] = useState(false);
const [subData, setSubData] = useState({});
const [selectedForm, setSelectedForm] = useState('');

const pollsHistory = useSelector((state) => state.polls.mySubmittions);

useEffect(async () => {
    // await loadPoll(props.match.params.id);
    await loadMySubmittions().then(() => console.log("then polls history", pollsHistory));
}, []);

useEffect(() => {
    if (pollsHistory && pollsHistory.length > 0) {
        // setSelectedForm(props.match.params.id);
        // pollsHistory.map((poll) => {
        //     console.log(poll.data._id, props.match.params.id);
        // });
        const form = pollsHistory.filter(poll => poll.form.data._id == props.match.params.id)[0].form;
        const data = pollsHistory.filter(poll => poll.form.data._id == props.match.params.id)[0].data;
        console.log(form);
        // setSelectedForm(form.url + '/' +  data._id);
        // setSelectedCard(formsUrl + path);
        // setSelectedForm(form.url +  form.data.path);
        setSelectedForm(form.url + '/' + form.data.path + '/submission' + '/' + data._id);
        setSubData(data.data);
        setOpenSubModal(true);
        setLoaded(true);
        console.log('form data', subData);
    }
} , [pollsHistory]);


if (!loaded) {
  return <Loader />;
} else
  return (
    <div>
        <SubmissionsModal
            open={openSubModal}
            data={subData}
            // onClose={(e) => {
            //     setOpenSubModal(false);
            // }}
            selectedForm={selectedForm}
            // setOpenSubModal={setOpenSubModal}
        />
    </div>
  );
};

export default ClientSubmittion;

import Joi from 'joi';

const validationSchema = {
  create: Joi.object({
    felony: Joi.string().empty().label('Felony'),
    felonyExplain: Joi.when('felony', {
      is: Joi.boolean().invalid(false),
      then: Joi.string().required().label('Felony explain')
    }),
    howDidFind: Joi.string().allow(null, ''),
    expYears: Joi.number().integer().min(0).max(60).required().messages({
      'number.min': 'Years of experience can\'t be smaller than 0',
      'number.max': 'Years of experience can\'t be greater than 60',
    }),
    dietitian: Joi.string().empty().label('Registered Dietitian'),
    degree: Joi.string().required().label('Degree'),
    cssd: Joi.string().required().label('Cssd'),
    certifications: Joi.array().items(Joi.string().allow(null).allow('')).label('certifications'),
    otherCertifications: Joi.string().allow(null).allow('').label('Other Certifications'),
    otherSpecialties: Joi.string().allow(null).allow('').label('Other Specialties'),
    specialities: Joi.array().items(Joi.string().allow(null).allow('')).label('specialities'),
    preferableChat: Joi.array().items(Joi.string().allow(null).allow('')).label('Preferable chat'),
    resume: Joi.alternatives(Joi.string().optional(), Joi.object().min(0)).required().messages({
      'alternatives.types': 'resume is required'
    }),
    coverLetter: Joi.alternatives(Joi.string().empty(''), Joi.object()).allow(null).label('Cover letter'),
    liabilityInsurance: Joi.alternatives(Joi.string().empty(''), Joi.object()).allow(null).label('Liability insurance'),
    currentOffering: Joi.string().min(10).max(140).label('Current services offered')
  })
};

export default validationSchema;

import React from 'react';
import { Container, Button } from '@material-ui/core';

import styles from './landing.module.scss';
import logo from '../../assets/img/logo.svg';

export default function () {
  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <Container style={{ marginTop: 10 }}>
          <div className={styles.headerContent}>
            <img src={logo} className={styles.logo} />
            <div className={styles.headerButtons}>
              {/* <div className={styles.logInButton}> Log in</div> */}
              <a href="/login">
                <Button variant="contained" className={styles.logInButton}>
                  Log in
                </Button>
              </a>
              <a href="/register">
                <Button variant="contained" className={styles.registerButton}>
                  Register
                </Button>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

import { useSelector } from 'react-redux';

const PublicRoute = (props) => {
  const { component: Component, path, exact } = props;
  const isLogged = useSelector((state) => state.auth.isLogged);

  if (isLogged) {
    return <Redirect to="/redirect" />;
  }

  return <Route path={path} component={Component} exact={exact} />;
};

export default PublicRoute;

import React, { useEffect, useState } from 'react';
import { Container, Button } from '@material-ui/core';

import styles from './landing.module.scss';
import weUnderstandBlockBackground from '../../assets/img/back_fruits.jpg';

import { publicActions } from '../../redux/actions';

export default function () {
  const [homepageContent, setHomepageContent] = useState([]);
  useEffect(async () => {
    const homepageContent = await publicActions.loadHomepageContent({ type: 'all' });
    setHomepageContent(homepageContent);
  }, []);

  return (
    <div
      style={{
        background: `url(${weUnderstandBlockBackground})center/cover`
      }}
    >
      <div className={styles.weUnderstandBlock}>
        <div className={styles.container}>
          <Container>
            <div className={styles.weUnderstandContent}>
              <div className={styles.weUnderstandTitle}>We understand</div>
              <div className={styles.weUnderstandDescription}>
                Your body is unique. Your journey is individual. Success in changing both is
                achieved by finding your personal food coach at the only marketplace for health and
                nutrition coaches that understands your uniqueness.
              </div>
              <div className={styles.weUnderstandButtons}>
                <a href="https://apps.apple.com/app/planibble/id1593873040">
                  <Button variant="contained" className={styles.footerSubscribeButton}>
                    Download
                  </Button>
                </a>
                <div className={styles.weUnderstandRegiterButtonBlock}>
                  <a href="/register">
                    <Button variant="contained" className={styles.downloadNowDownloadButton}>
                      Register
                    </Button>
                  </a>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {homepageContent &&
                homepageContent.map((content) => (
                  <div style={{ display: 'inline-block', padding: '10px', maxWidth: '47%' }}>
                    <h3 style={{ color: 'black' }}>{content.type}</h3>
                    <div
                      className={styles.weUnderstandDescription}
                      dangerouslySetInnerHTML={{ __html: content.text }}
                    ></div>
                  </div>
                ))}
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Tooltip } from '@material-ui/core';

const SkillList = ({ items, title }) => {
  return (
    <>
      {items.length > 0 && (
        <>
          <p style={{ marginTop: '1rem', marginBottom: 2 }}>{title}:</p>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <>
              {items.slice(0, 4).map((item, i) => {
                return (
                  <Tooltip title={item} placement="top">
                    <div
                      style={{
                        margin: 2,
                        borderRadius: 100,
                        color: '#EE79B3',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        padding: '4px 14px',
                        backgroundColor: '#212128'
                      }}
                    >
                      <span>{item}</span>
                    </div>
                  </Tooltip>
                );
              })}

              {items.length > 4 && (
                <div
                  style={{
                    borderRadius: 100,
                    marginLeft: '4px',
                    width: 55,
                    color: '#EE79B3',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    paddingLeft: '14px',
                    paddingRight: '14px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    backgroundColor: '#212128'
                  }}
                >
                  <span>{`+${items.length - 4}`}</span>
                </div>
              )}
            </>
          </div>
        </>
      )}
    </>
  );
};

export default SkillList;

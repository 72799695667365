import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import Linkify from 'react-linkify';
import {
  Container,
  Content,
  Date,
  Image,
  Wrapper,
  Body,
  Header,
  SenderName,
  MyContent,
  MyWrapper,
  MySenderName,
  MyDate
} from './MessageList.style';

import { Avatar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { usePubNub } from 'pubnub-react';

import _ from 'lodash';

import replyImage from '../../../assets/img/icons/hamburger.svg';
import { chatActions } from '../../../redux/actions';

//com.mco
// const MessagesList = ({ chat, userModerate }) => {
const MessagesList = forwardRef((props, ref) => {
  const chat = props.chat;
  const pubnub = usePubNub();
  const allMessages = useSelector((state) => state.chat.messages);
  const messages = useMemo(() => {
    if (_.isArray(allMessages[chat.channelId])) {
      return [...allMessages[chat.channelId]];
    } else return [];
  }, [allMessages, chat]);

  //com.mco

  const [repliedTimetoken, setRepliedTimetoken] = useState(undefined);
  const [goBackTimetoken, setGoBackTimetoken] = useState(undefined);

  useImperativeHandle(ref, () => ({
    refDownCallback() {
      if (goBackTimetoken && refs[goBackTimetoken]) {
        refs[goBackTimetoken].current.scrollIntoView({
          // behavior: 'smooth',
          block: 'start'
        });
        setGoBackTimetoken(undefined);
      } else
        setTimeout(() => {
          scrollToBottom();
        }, 100);
    },

    moreLoadCallback() {
      if (lastTimetoken && refs[lastTimetoken])
        refs[lastTimetoken].current.scrollIntoView({
          // behavior: 'smooth',
          block: 'start'
        });

      setLastTimetoken(filteredMessages[0] ? filteredMessages[0].timetoken : lastTimetoken);
    }
  }));

  const filteredMessages = useMemo(() => {
    if (_.isArray(allMessages[chat.channelId]) && _.isArray(messages)) {
      return [
        ...messages.filter(
          (message, index) =>
            message.message != 'New-File-Sent' && message.message.text != 'New-File-Sent'
        )
      ];
    } else return [];
  }, [messages, allMessages, chat]);

  const [lastTimetoken, setLastTimetoken] = useState(
    filteredMessages[0] ? filteredMessages[0].timetoken : undefined
  );

  const clientUser = useMemo(() => {
    if (chat.chatGroupsClients.length > 0) {
      // fk2 group chat
      const { client } = chat.chatGroupsClients[0];
      return {
        name: client.user.name,
        userId: client.user.id,
        image: client.user.image
      };
    }
    const { client } = chat;
    return {
      name: client.user.name,
      userId: client.user.id,
      image: client.user.image
    };
  }, [chat]);

  const getAvatar = (userId) => {
    const chatUser = chat.chatGroupsClients?.filter((o) => o.client.user.id == userId)[0];
    if (chatUser) {
      return chatUser?.client.user.image ? (
        <Avatar src={chatUser?.client.user.image} />
      ) : (
        <Avatar> A </Avatar>
      );
    } else {
      // coach
      return chat.nutritionist.user.image ? (
        <Avatar src={chat.nutritionist.user.image} />
      ) : (
        <Avatar> A </Avatar>
      );
    }
  };
  const getUsername = (userId) => {
    const chatUser = chat.chatGroupsClients?.filter((o) => o.client.user.id == userId)[0];
    if (chatUser) return chatUser?.client.user.name ? chatUser?.client.user.name : 'No Username';
    //coach
    else
      return chat.nutritionist.user.name
        ? chat.nutritionist.firstName + ' ' + chat.nutritionist.lastName
        : 'No Username';
  };

  // const nutritionistUser = useMemo(() => {
  //   const { nutritionist } = chat;
  //   return {
  //     name: `${nutritionist.firstName} ${nutritionist.lastName}`,
  //     userId: nutritionist.user.id,
  //     image: nutritionist.user.image
  //   };
  // }, [chat]);

  const lastElem = useRef(null);

  //com.mco
  const scrollToBottom = () => {
    // lastElem.current.scrollIntoView({ behavior: 'smooth' });
    lastElem.current.scrollIntoView({ behavior: 'auto' });
  };

  // useEffect(() => {
  //   setTimeout(() => scrollToBottom(), 100);
  // }, [messages]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
    // }, []);
  }, [chat.channelId]);

  const setReplied = async (args) => {
    props.setReplied(args);
  };

  const refs = messages.reduce((acc, value) => {
    acc[value.timetoken] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (repliedTimetoken && !refs[repliedTimetoken]) {
      chatActions.moreLoadMessages({
        channels: [chat.channelId],
        pubnub: pubnub,
        startTimeToken: messages[0].timetoken
      });
    } else if (repliedTimetoken && refs[repliedTimetoken]) {
      refs[repliedTimetoken].current.scrollIntoView({ block: 'center' });
      setLastTimetoken(filteredMessages[0] ? filteredMessages[0].timetoken : lastTimetoken);
      setRepliedTimetoken(undefined);
    }
  }, [repliedTimetoken, messages]);

  useEffect(() => {
    if (
      messages[messages.length - 1] &&
      messages[messages.length - 1].newMsg != null &&
      messages[messages.length - 1].newMsg != undefined &&
      messages[messages.length - 1].newMsg === true
    ) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
      messages[messages.length - 1].newMsg = false;
    }
  }, [messages]);

  const handleClick = (repliedT, mainT) => {
    if (
      refs[repliedT] != null &&
      refs[repliedT] != undefined &&
      refs[repliedT].current != null &&
      refs[repliedT].current != undefined
    ) {
      refs[repliedT].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setRepliedTimetoken(undefined);
    } else {
      // chatActions.moreLoadMessages({ channels: [chat.channelId], pubnub: pubnub, startTimeToken: messages[0].timetoken });
      setRepliedTimetoken(repliedT);
    }
    setGoBackTimetoken(mainT);
  };

  return (
    <Container>
      {
        // messages
        filteredMessages.map((message, index) => {
          let url,
            fileName,
            isFile = false,
            isImage = false;
          let imageRegex = /(jpeg|png|jpg)\b/;
          let caption;

          if (message.message.file) {
            url = pubnub.getFileUrl({
              channel: chat.channelId,
              id: message.message.file.id,
              name: message.message.file.name
            });
            isFile = true;
            fileName = message.message.file.name;
            isImage = imageRegex.test(fileName);
          }

          //fk2 push notification type
          // let messageText = '';
          // if(typeof(message.message) === 'string'){
          //   messageText = message.message
          // }
          // else{
          //   messageText = message.message.text
          // }

          //com.mco
          if (
            message.message.file &&
            message.message.message &&
            typeof message.message.message === 'string'
          )
            caption = message.message.message;
          else if (
            message.message.file &&
            message.message.message &&
            typeof message.message.message.text === 'string'
          )
            caption = message.message.message.text;
          else if (
            message.message.file &&
            message.message.message &&
            !typeof message.message.message === 'string'
          )
            caption = message.message.file.fileName;
          else caption = '';

          //fk2 push notification type
          let messageText = '';
          let repliedMT = undefined;
          let repliedTT = undefined;
          let repliedFU = undefined;
          let isImageRepliedFU = undefined;
          //com.mco
          if (typeof message.message === 'string') {
            messageText = message.message;
          } else if (typeof message.message.text === 'string') {
            messageText = message.message.text;
            repliedMT = message.message.repliedMT;
            repliedTT = message.message.repliedTT;
            repliedFU = message.message.repliedFU;
            if (repliedFU) {
              isImageRepliedFU = imageRegex.test(repliedFU);
              if (repliedMT || repliedMT === '')
                repliedMT = repliedFU.substring(
                  repliedFU.lastIndexOf('/') + 1,
                  repliedFU.lastIndexOf('?')
                );
            }
          } else if (message.message.message) {
            if (typeof message.message.message === 'string') {
              messageText = message.message.message;
            } else {
              messageText = message.message.message.text;
              repliedMT = message.message.message.repliedMT;
              repliedTT = message.message.message.repliedTT;
              repliedFU = message.message.message.repliedFU;
              if (repliedFU) {
                isImageRepliedFU = imageRegex.test(repliedFU);
                if (repliedMT || repliedMT === '')
                  repliedMT = repliedFU.substring(
                    repliedFU.lastIndexOf('/') + 1,
                    repliedFU.lastIndexOf('?')
                  );
              }
            }
          }

          return message.userId === clientUser.userId ? (
            // <MyWrapper key={index}>
            <MyWrapper key={message.timetoken} ref={refs[message.timetoken]}>
              {/* <IconButton
            style={{ width: '48px', height: '48px', paddingBottom: '8px', cursor: 'pointer' }}
            onClick={() => {setReplied({repliedMT: messageText, repliedFU: url, repliedTT: message.timetoken});}}
          > 
          <img src={replyImage} />
           
            </IconButton> */}
              <Body>
                <MyContent>
                  {repliedTT ? (
                    repliedFU && isImageRepliedFU ? (
                      // () => window.open(repliedFU, '_blank')
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            borderRadius: 50 / 2,
                            overflow: 'hidden',
                            borderWidth: 3,
                            borderColor: 'red'
                          }}
                          src={repliedFU}
                        />
                        <br />
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    ) : (
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {/* {repliedFU ? 
                (<><Image onClick={() => window.open(url, '_blank')} src={repliedFU} /><br/><label style={{backgroundColor: 'grey'}}>{repliedMT}</label><br/><hr/></>)
                : (<><label style={{backgroundColor: 'grey' }}>{repliedMT}</label><br/><hr/></>)
                } */}
                  {isFile ? (
                    <>
                      {isImage && (
                        <>
                          {/* ref={refs[message.timetoken]} */}
                          <Image onClick={() => window.open(url, '_blank')} src={url} />
                          <br />
                          <Linkify>{caption}</Linkify>
                        </>
                      )}
                      {!isImage && (
                        // ref={refs[message.timetoken]}
                        <div>
                          <a href={url} target="_blank">
                            {fileName}
                          </a>
                          <br />
                          <Linkify>{caption}</Linkify>
                        </div>
                      )}
                    </>
                  ) : (
                    // ref={refs[message.timetoken]}
                    <Linkify>{messageText}</Linkify>
                  )}
                </MyContent>
                <MyDate>{moment(message.date).format('lll')}</MyDate>
              </Body>
              <Avatar src={clientUser.image} />
            </MyWrapper>
          ) : (
            // <Wrapper key={index}>
            <Wrapper key={message.timetoken} ref={refs[message.timetoken]}>
              {getAvatar(message.userId)}
              {/* <Avatar src={nutritionistUser.image} /> */}
              <Body>
                <Header>
                  <SenderName>{getUsername(message.userId)}</SenderName>
                </Header>
                <Content>
                  {repliedTT ? (
                    repliedFU && isImageRepliedFU ? (
                      // () => window.open(repliedFU, '_blank')
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            borderRadius: 50 / 2,
                            overflow: 'hidden',
                            borderWidth: 3,
                            borderColor: 'red'
                          }}
                          src={repliedFU}
                        />
                        <br />
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    ) : (
                      <div
                        style={{ backgroundColor: 'lightgrey', cursor: 'pointer' }}
                        onClick={() => handleClick(repliedTT, message.timetoken)}
                      >
                        <label>{repliedMT}</label>
                        <br />
                        <hr />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {isFile ? (
                    <>
                      {isImage && (
                        // ref={refs[message.timetoken]}
                        <Image onClick={() => window.open(url, '_blank')} src={url} />
                      )}
                      {!isImage && (
                        // ref={refs[message.timetoken]}
                        <a href={url} target="_blank">
                          {fileName}
                        </a>
                      )}
                    </>
                  ) : (
                    // ref={refs[message.timetoken]}
                    <Linkify>{messageText}</Linkify>
                  )}
                </Content>
                <Date>{moment(message.date).format('lll')}</Date>
              </Body>
              {/* <IconButton
            style={{ width: '48px', height: '48px', paddingBottom: '8px', cursor: 'pointer' }}
            onClick={() => {
              setReplied({repliedMT: messageText, repliedFU: url, repliedTT: message.timetoken});}}
          > 
          <img src={replyImage} />
           
            </IconButton> */}
            </Wrapper>
          );
        })
      }
      <div style={{ float: 'left', clear: 'both' }} ref={lastElem}></div>
    </Container>
  );
});

export default MessagesList;

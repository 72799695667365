import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';

import styles from './landing.module.scss';
import ownYourBodyImage from '../../assets/img/two_phones.png';

export default function () {
  return (
    <div className={styles.ownYourBodyBlock}>
      <div className={styles.container}>
        <Container>
          <div className={styles.ownYourBodyBlock}>
            <div className={styles.ownYourBodyText}>
              <div className={styles.ownYourBodyTitle}>OWN YOUR BODY!</div>
              <div className={styles.ownYourBodyDescription}>
                Great results require great coaching. Whether you are trying to tone up or slim down
                we’ve got the right ingredients beyond food so you can achieve the body and health
                you deserve.
              </div>
            </div>
            <img src={ownYourBodyImage} className={styles.ownYourBodyImage} />
          </div>
        </Container>
      </div>
    </div>
  );
}

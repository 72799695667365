import React, { useState, useMemo } from 'react';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import lodash from 'lodash';

const columns = [
  {
    id: 'user',
    label: 'Name',
    format: (value) => value.name
  },
  {
    id: 'user',
    label: 'Email',
    format: (value) => value.email
  },
  {
    id: 'user',
    label: 'Date created',
    format: (value) => moment(value.createdAt).format('MM.DD.YYYY')
  }
];

export default function SubmissionsTable({ data, setSubData, setOpenSubModal }) {
  const clients = data;
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const table = useMemo(() => {
    return clients.slice(page * pageSize, (page + 1) * pageSize);
  }, [clients, pageSize, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(event.target.value);
    setPage(0);
  };

  const Row = ({ row }) => {
    return (
      <>
        <TableRow
          onClick={() => {
            setSubData(row.data);
            setOpenSubModal(true);
          }}
          hover
          key={row.id}
        >
          {columns.map((column) => {
            const value = column.format ? column.format(row[column.id]) : row[column.id];
            return <TableCell key={column.id}>{value}</TableCell>;
          })}
        </TableRow>
      </>
    );
  };

  if (!data.length) {
    return (
      <h2
        style={{
          margin: '30px auto',
          width: '400px',
          fontSize: '4rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '2.5px',
          color: '#ee79b3'
        }}
      >
        No submissions
      </h2>
    );
  }

  return (
    <>
      <h2
        style={{
          margin: '30px auto',
          width: '700px',
          fontSize: '4rem',
          fontFamily: 'Bebas Neue',
          letterSpacing: '2.5px',
          color: '#fff',
          textAlign: 'center'
        }}
      >
        Submissions
      </h2>
      <Paper style={{ marginTop: 30 }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {table.map((row) => {
                return <Row row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={clients.length}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
        />
      </Paper>
    </>
  );
}

import store from '../store';
import mutations from '../mutations';
import { userAxios } from '../../utils/axios';
import { userActions } from './index';

const loadClients = async () => {
  const { data } = await userAxios.get('/nutritionist/clients');

  data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  store.dispatch({
    type: mutations.nutritionist.loadClients,
    payload: data
  });
};

const submitNutritionist = async (data) => {
  return await userAxios.post('/user/nutritionist', data);
};

const submitRefferal = async (email) => {
  await userAxios.post('/nutritionist/client/invite', { email });
};

const loadStripeAccountStatus = async () => {
  const { data } = await userAxios.get('/nutritionist/stripe/account-status');
  return data;
};

const changeUserStatus = async (payload) => {
  try {
    const { data } = await userAxios.post('/nutritionist/client/status', { ...payload });

    if (data) {
      store.dispatch({
        type: mutations.nutritionist.changeClientStatus,
        payload: { ...data }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const getPayouts = async () => {
  try {
    const { data } = await userAxios.get('/nutritionist/get-paid-list');

    if (data) {
      store.dispatch({
        type: mutations.nutritionist.payouts,
        payload: { ...data }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const setAvailability = async (status) => {
  await userAxios.put('/nutritionist/availability', { status });
  userActions.loadUser();
};

const changeAdminChatStatus = async (id, newStatus) => {
  const { data } = await userAxios.post(`/nutritionist/change-admin-chat-status`, {
    id,
    newStatus
  });
  return data;
};

//fk2
const subscribeForPromote = async () => {
  return await userAxios.post('/subscribe-promote');
}
const getExtraSpecialties = async (nutritionistId) => {
  return await userAxios.get(`/nutritionist/extra-specialties/${nutritionistId}`);
}
const setExtraSpecialties = async (data) => {
  return await userAxios.post('/nutritionist/extra-specialties',data);
}
const stripeCancelSubscription = async (data) => {
  return await userAxios.post('/client/cancel-subscription', data);
};
const loadSubscriptions = async () => {
  const { data } = await userAxios.get(`/nutritionist/subscriptions`);
  return data;
};
const changeClientVisibility = async (payload) => {
    console.log(payload);
  const data = await userAxios.post(`/nutritionist/clients-visibility`, {
    ...payload
  });
  return data;
};

const loadCustomerCoachPortalLink = async () => {
  const { data } = await userAxios.get(`/stripe/customer-portal-nutritionist`);
  return data;
};

const loadReportsData = async () => {
  const { data } = await userAxios.get('/stripe/coachreports');
  return data;
}

export default {
  loadStripeAccountStatus,
  loadClients,
  submitRefferal,
  submitNutritionist,
  changeUserStatus,
  setAvailability,
  changeAdminChatStatus,
  getPayouts,
  changeClientVisibility,
  loadSubscriptions,
  loadCustomerCoachPortalLink,
  stripeCancelSubscription,
  setExtraSpecialties,
  subscribeForPromote,
  getExtraSpecialties,
  loadReportsData
};

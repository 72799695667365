import React, { useState, useMemo } from 'react';

import _ from 'lodash';

import {
  FormControl,
  TextField,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';

const OTHER_OPTION = 'Other';

const RadioButtonsGroup = ({
  items,
  title,
  field,
  handleChange,
  errorExist,
  errors,
  styles,
  value,
  isOtherANumber,
  otherInputProps,
  withOther
}) => {
  const [otherOption, setOtherOption] = useState(false);

  const handleChangeOtherOption = (e) => {
    setOtherOption(!otherOption);
  };

  const checkOnOtherOption = useMemo(() => {
    const result = items.includes(Number(value));
    return result && !otherOption;
  }, [value, items]);

  return (
    <div className={styles.fieldsBlock}>
      <Grid className={styles.fieldsBlock_column} container spacing={2}>
        <Grid item xs={12}>
          <label>{title}</label>
          <FormControl
            error={errorExist && errorExist(field)}
            component="fieldset"
            className={styles.inputField_checkGroup}
          >
            <RadioGroup
              aria-label={field}
              name={field}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              {items.map((item, index) => (
                <FormControlLabel
                  key={index}
                  onChange={() => setOtherOption(false)}
                  checked={item.value == value && !otherOption}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
              {withOther && (
                <FormControlLabel
                  control={<Radio />}
                  checked={!checkOnOtherOption}
                  onChange={(e) => {
                    handleChangeOtherOption();
                  }}
                  label={OTHER_OPTION}
                />
              )}
              {((withOther && otherOption) || (value && !checkOnOtherOption && withOther)) && (
                <TextField
                  error={errorExist && errorExist(field)}
                  autoComplete={field}
                  name={field}
                  variant="outlined"
                  type={isOtherANumber ? 'number' : ''}
                  required
                  fullWidth
                  value={value}
                  onChange={handleChange}
                  placeholder="Enter your option here"
                  className={styles.inputField}
                  id={field}
                  label=""
                  InputProps={otherInputProps}
                  autoFocus
                />
              )}
            </RadioGroup>
            <FormHelperText className={styles.myHelperText}>{errors[field]}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default RadioButtonsGroup;

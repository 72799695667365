import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userActions, chatActions, clientActions } from '../../../redux/actions/index';

import { Button } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { clientStatuses } from '../../../constants';
import SubscriptionModal from '../../../components/subscriptions/tierSelectionModal';
import { yearsOfExperienceText } from '../../../utils/textHelper';
import ExtraSubscriptionModal from '../../../components/subscriptions/extraSelectionModal';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Container,
  Modal
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap'
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  }
}));

const NutritionistInfo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const client = useSelector((state) => state.user.client);
  const { id: clientId } = client;

  const nutritionistId = parseInt(props.match.params.id);
  const [nutritionist, setNutritionist] = useState(null);
  const [status, setStatus] = useState({ status: '', statusText: '' });
  const [isSubscriptionModalOpen, setModalOpen] = useState(false);

  const [isExtraSubscriptionModalOpen, setExtraModalOpen] = useState(false);

  // const [hasReview, setHasReview] = useState(false);

  const loadNutritionist = async () => {
    const nutr = await clientActions.loadNutritionist(nutritionistId);
    setNutritionist(nutr);
    console.log(nutr.nutritionistsReviews);
  };

  useEffect(() => {
    loadNutritionist();
  }, []);

  useEffect(() => {
    if (nutritionist) defineCorrectHireStatus();
  }, [client, nutritionist]);

  if (!nutritionist) {
    return <div></div>;
  }

  const degree = (() => {
    if (nutritionist?.degree) {
      if (nutritionist.degree === 'true') {
        return 'Has a degree';
      } else if (nutritionist.degree === 'false') {
        return '';
      } else {
        return `Degree: ${nutritionist.degree}`;
      }
    }
    return '';
  })();

  const onHire = async () => {
    await userActions.connectUsers(nutritionistId, clientId);
    await userActions.loadUser();
  };

  const onOpenChat = async () => {
    await chatActions.initChat({ nutritionistId, clientId });
    history.push({ pathname: `/client/chat` });
  };

  const onSubscribe = async () => {
    setModalOpen(true);
  };

  const onExtraSubscribe = async () => {
    setExtraModalOpen(true);
  };

  const defineCorrectHireStatus = () => {
    const nutritionists = client.nutritionists;

    if (nutritionists && nutritionists.length > 0) {
      const currentNutritionist = nutritionists.find((item) => item.id === nutritionist.id);

      if (currentNutritionist) {
        const currentStatus = currentNutritionist.nutritionistClients.status;

        switch (currentStatus) {
          case clientStatuses.PENDING:
            setStatus({
              status: clientStatuses.PENDING,
              statusText: 'request pending',
              color: '#c4eb37'
            });
            break;
          case clientStatuses.APPROVED:
            setStatus({
              status: clientStatuses.APPROVED,
              statusText: 'Client is approved by coach',
              color: '#c4eb37'
            });
            break;
          case clientStatuses.REJECTED:
            setStatus({
              status: clientStatuses.REJECTED,
              statusText: 'rejected by coach',
              color: '#f44336'
            });
            break;

          default:
            break;
        }

        return;
      }
    }
  };

  //fk2
  const hasReview = () => {
    if (nutritionist.nutritionistsReviews) {
      return nutritionist.nutritionistsReviews.length > 0;
    }
    return false;
  };

  return (
    <>
      <SubscriptionModal
        open={isSubscriptionModalOpen}
        nutritionistId={nutritionist.id}
        availableTiers={nutritionist.expYears > 1 ? [100, 200, 300, 450] : [100]}
        onClose={() => setModalOpen(false)}
      />
      <ExtraSubscriptionModal
        open={isExtraSubscriptionModalOpen}
        nutritionistId={nutritionist.id}
        onClose={() => setExtraModalOpen(false)}
      />
      <div
        className="nutritionist-info-container"
        style={{
          display: 'flex',
          alignItems: '',
          marginTop: '4rem',
          position: 'relative'
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            position: '',
            overflow: 'hidden',
            width: '250px',
            height: '250px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <img
            style={{ width: 'auto', height: '100%', margin: '0 auto', display: 'inline' }}
            src={nutritionist.image || nutritionist.user.image}
            alt=""
          />
        </div>
        <div
          style={{
            width: '60%',
            justifyContent: 'end'
          }}
        >
          <p
            style={{
              fontSize: '2.5rem',
              marginLeft: '3rem',
              fontFamily: 'Bebas Neue',
              color: '#EE79B3',
              letterSpacing: '2.5px'
            }}
          >{`${nutritionist.firstName} ${nutritionist.lastName ? nutritionist.lastName : ''}${
            nutritionist.dietitian ? ', a certified dietitian' : ''
          }`}</p>
          <div
            style={{
              display: 'flex',
              marginLeft: '3rem',
              right: '0',
              justifyContent: '',
              marginTop: '',
              fontFamily: 'Bebas Neue',
              color: status.color,
              fontSize: '1.2rem',
              letterSpacing: '1.5px'
            }}
          >
            {status.statusText}
          </div>
          {nutritionist.calendarUrl && (
            <a href={nutritionist.calendarUrl} target="_blank">
              <div
                style={{
                  display: 'flex',
                  marginLeft: '3rem',
                  right: '0',
                  justifyContent: '',
                  marginTop: '',
                  fontFamily: 'Bebas Neue',
                  color: '#EE79B3',
                  fontSize: '1.5rem',
                  letterSpacing: '1.5px'
                }}
              >
                Schedule a Call
              </div>
            </a>
          )}
          <p style={{ fontSize: '1.5rem', marginLeft: '3rem' }}>
            {yearsOfExperienceText(nutritionist.expYears)}
          </p>
          <p style={{ fontSize: '1.5rem', marginLeft: '3rem' }}>{`${
            nutritionist.expYears <= 1 ? '$100 per month' : '$100 / $200 / $300 per month'
          }`}</p>
          <div
            className="nutritionist-info-container__buttons"
            style={{
              display: 'flex',
              marginTop: '2.5rem',
              marginLeft: '3rem',
              justifyContent: '',
              flexWrap: 'wrap'
            }}
          >
            <Button
              onClick={() => onHire()}
              style={{
                marginRight: '1rem',
                background: '#424242',
                color: !!status.status ? '#838383' : '#EE79B3'
              }}
              variant="contained"
              disabled={!!status.status}
            >
              Hire now
            </Button>
            <Button
              onClick={() => onOpenChat()}
              style={{ background: '#424242', color: '#EE79B3', marginRight: '1rem' }}
              variant="contained"
            >
              Open chat
            </Button>
            <Button
              onClick={() => window.open(nutritionist.resume, '_blank')}
              style={{ background: '#424242', color: '#EE79B3', marginRight: '1rem' }}
              variant="contained"
            >
              OPEN RESUME
            </Button>

            <Button
              onClick={() => onExtraSubscribe()}
              style={{ background: '#424242', color: '#EE79B3', marginRight: '1rem' }}
              variant="contained"
            >
              Pay & Subscribe to Extra Specialties
            </Button>
            {status.status === clientStatuses.APPROVED && (
              <Button
                onClick={() => onSubscribe()}
                style={{ background: '#424242', color: '#EE79B3' }}
                variant="contained"
              >
                Pay & Subscribe
              </Button>
            )}
          </div>
        </div>
      </div>

      {hasReview() && (
        <Grid
          container
          spacing={5}
          alignItems="center"
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            marginTop: '1rem',
            // padding: '0 6rem',
            paddingRight: '6rem',
            flexWrap: 'nowrap',
            overflowX: 'scroll'
          }}
        >
          {nutritionist.nutritionistsReviews
            .filter((review) => review.client !== null)
            .map((review) => (
              <Grid
                xs={12}
                sm={6}
                md={4}
                style={{
                  borderRadius: '10px',
                  backgroundColor: '#424242',
                  padding: '1rem',
                  minWidth: '300px',
                  marginRight: '2rem'
                }}
              >
                <div>
                  <div
                    style={{
                      fontSize: '1.1rem',
                      color: '#EE79B3'
                    }}
                  >
                    <img
                      src={review.client.user.image}
                      alt=""
                      style={{
                        width: '20%',
                        height: '20%',
                        borderRadius: '50%',
                        marginRight: '20px'
                      }}
                    />
                    {review.client.user.name}
                  </div>
                </div>
                <hr />
                <div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <Rating name="disabled" precision={0.5} value={review.rating} readOnly />
                  </div>
                  {review.text}
                </div>
              </Grid>
            ))}
        </Grid>
      )}

      <div style={{ marginTop: '40px' }}>
        <div style={{ display: 'flex', marginRight: '20px', alignItems: 'center' }}>
          <h2
            style={{
              fontFamily: 'Bebas Neue',
              color: '#EE79B3',
              letterSpacing: '2.5px',
              fontSize: '1.8rem',
              margin: 0,
              marginRight: '5px',
              minWidth: '300px'
            }}
          >
            Current services offered:
          </h2>
          <p
            style={{
              fontSize: '1.1rem',
              color: 'white',
              display: 'flex',
              alignItems: 'flex-end',
              margin: 0,
              overflowWrap: 'break-word'
            }}
          >
            {nutritionist.currentOffering}
          </p>
        </div>
        {nutritionist.schedule && (
          <div style={{ display: 'flex', marginTop: '30px', alignItems: 'center' }}>
            <h2
              style={{
                fontFamily: 'Bebas Neue',
                color: '#EE79B3',
                letterSpacing: '2.5px',
                fontSize: '1.8rem',
                margin: 0,
                marginRight: '5px',
                minWidth: '300px'
              }}
            >
              Working Schedule:
            </h2>
            <p
              style={{ fontSize: '1.1rem', color: 'white', margin: 0, overflowWrap: 'break-word' }}
            >
              {nutritionist.schedule}
            </p>
          </div>
        )}
      </div>

      <div
        className="nutritionist-info_details"
        style={{ display: 'flex', marginTop: '2rem', flexWrap: 'wrap' }}
      >
        {nutritionist.specialities && nutritionist.specialities.length > 0 && (
          <div style={{ width: '33%' }}>
            <p
              style={{
                fontFamily: 'Bebas Neue',
                color: '#EE79B3',
                letterSpacing: '2.5px',
                fontSize: '2rem',
                marginBottom: ''
              }}
            >
              Specialities:
            </p>
            {nutritionist.specialities.map((item, i) => {
              return (
                <p style={{ fontSize: '1.1rem', marginLeft: '' }} key={i}>
                  &#8226; {item}
                </p>
              );
            })}
          </div>
        )}

        {nutritionist.certifications && nutritionist.certifications.length > 0 && (
          <div style={{ width: '33%' }}>
            <p
              style={{
                fontFamily: 'Bebas Neue',
                color: '#EE79B3',
                letterSpacing: '2.5px',
                fontSize: '2rem',
                marginBottom: ''
              }}
            >
              Ceritificates:
            </p>
            {nutritionist.certifications.map((item, i) => {
              return (
                <p style={{ fontSize: '1.1rem', marginLeft: '' }} key={i}>
                  &#8226; {item}
                </p>
              );
            })}
          </div>
        )}

        <div style={{ width: '33%' }}>
          <p
            style={{
              fontFamily: 'Bebas Neue',
              color: '#EE79B3',
              letterSpacing: '2.5px',
              fontSize: '2rem',
              marginBottom: ''
            }}
          >
            Other information:
          </p>
          <p style={{ fontSize: '1.1rem', marginLeft: '' }}>
            {nutritionist.preferableChat && nutritionist?.preferableChat?.length
              ? `Preferable chat: ${nutritionist.preferableChat}`
              : ''}
          </p>
          <p style={{ fontSize: '1.1rem', marginLeft: '' }}>{degree}</p>
        </div>
      </div>
    </>
  );
};

export default NutritionistInfo;

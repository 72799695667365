import styles from './Dashboard.module.scss';
import appStore from '../../../assets/img/appstore-white.svg';
import FormModal from '../../../components/formModal';
import { usePubNub } from 'pubnub-react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { chatActions, userActions } from '../../../redux/actions';
import _ from 'lodash';
import config from '../../../config/index';

import googlePlayImage from '../../../assets/img/google-play.svg';

const ClientDashboard = () => {
  const user = useSelector((state) => state.user);
  // const nutritionist = useSelector((state) => state.nutritionist);
  // const channels = useSelector((state) => state.chat.channels);
  // const timetokens = useSelector((state) => state.chat.timetokens);

  // const pubnubConfig = {
  //   restore: true,
  //   heartbeatInterval: 0,
  //   ...config.pubnubKeys,
  //   uuid: user && user.id,
  //   profileUrl: user && user.image
  // };

  // const pubnub = usePubNub(pubnubConfig);

  // useEffect(() => {
  //   userActions.getChats(null, user.client.id);
  // }, []);

  // useEffect(() => {
  //   if (user.chats) {
  //     chatActions.getChannels(user.client.nutritionists, 'client', user.client.id);
  //   }
  // }, [user])

  // useEffect(() => {
  //   if (channels.length) {
  //     chatActions.getTimetokens(pubnub, user.id)
  //     if (!timetokens) {
  //       chatActions.subscribeToMembership(user.id, pubnub, channels)
  //       chatActions.getTimetokens(pubnub, user.id)
  //     }
  //   }
  // }, [channels])

  return (
    <div>
      <div className={styles.paper}>
        <div className={styles.container}>
          <h1 className={styles.title}>
            The desktop version of the app has limited functionality. Please switch to the mobile app.
          </h1>

          <div>
            <span className={styles.appleLogo}>
              <a
                href="https://apps.apple.com/us/app/planibble/id1593873040"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.logoMobile}
              >
                <img style={{height: '42px', marginRight: '10px'}} src={appStore} className={styles.logo} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.planibble">
                <img src={googlePlayImage} className={styles.appStoreImage} />
              </a>
            </span>
          </div>

          <h1 style={{ marginTop: '2rem' }} className={styles.title}>
            * The app is in development
          </h1>
        </div>
      </div>
      <FormModal />
    </div>
  );
};

export default ClientDashboard;

import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';

import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Button
} from '@material-ui/core';

const formatedData = {
  firstName: 'First name',
  lastName: 'Last name',
  address1: 'Address',
  address2: 'Address2',
  city: 'City',
  state: 'State',
  email: 'Email',
  hoursWeekly: 'How many hours can you work weekly?',
  canWorkAtNights: 'Can you work at nights?',
  isOlder: 'Are you 18 or older?',
  gender: 'Gender',
  phone: 'Phone',
  zipcode: 'Zipcode',
  felony: 'Have you ever been convicted of a felony?',
  felonyExplain: 'Felony explain',
  howDidFind: 'How did you find out about this position?',
  expYears: 'Years of Experience',
  dietitian: 'Are you a Registered Dietitian?',
  degree: 'Nutrition Degree',
  cssd: 'CSSD: Certified Specialist in Sports Dietetics',
  certifications: 'Do you hold any of the following Nutrition Certifications?',
  specialities: 'Do you hold any of the following Nutrition Specialties?',
  preferableChat: 'Preferable choice of communication',
  resume: 'Resume',
  coverLetter: 'Cover letter',
  currentOffering: 'Current services offered',
  liabilityInsurance: 'Liability insurance',
  schedule: 'Work schedule'
};

const PreviewData = ({ nutritionist, styles, backToSecondStep, submitData }) => {
  const getValue = useCallback((value) => {
    if (_.isObject(value)) {
      if (_.isArray(value)) {
        const concatinatedValue = value.join(', ');
        return concatinatedValue;
      } else {
        return value.name;
      }
    }

    if (value === 'true' || value === 'false' || _.isBoolean(value)) {
      if (_.isBoolean(value)) {
        return value ? 'Yes' : 'No';
      }
      return value === 'true' ? 'Yes' : 'No';
    }

    return value;
  }, []);

  useEffect(() => {
    submitData();
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={styles.intakeForm_table} aria-label="customized table">
          <TableBody>
            {Object.entries(nutritionist).map(([key, value]) => (
              <TableRow key={key} className={styles.intakeForm_tableRow}>
                <TableCell
                  className={styles.intakeForm_tableKey}
                  component="th"
                  scope="row"
                  style={{ minWidth: 300 }}
                >
                  {formatedData[key]}
                </TableCell>
                <TableCell className={styles.intakeForm_tableValue} align="right">
                  {getValue(value)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={styles.intakeForm_actionButtons}>
          <Button
            onClick={() => backToSecondStep()}
            color="primary"
            variant="contained"
            className={styles.intakeForm_actionButtons_backBtn}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} className={styles.intakeForm_actionButtons}>
          <Button
            onClick={() => submitData()}
            color="primary"
            variant="contained"
            className={styles.intakeForm_actionButtons_nextBtn}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PreviewData;

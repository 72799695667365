import React from 'react';

import NutritionistsChangeRequestTable from './changeRequestsTable';
import NutritionistsTable from './table';
import ReviewRequestTable from './reviewRequestTable';
import PendingSpecialtiesTable from './pendingSpecialtiesTable';

const AdminNutritionists = () => {
  return (
    <div>
      <NutritionistsTable />
      <NutritionistsChangeRequestTable />
      <PendingSpecialtiesTable />
      <ReviewRequestTable/>
    </div>
  );
};

export default AdminNutritionists;
